import { Box, Button, Grid } from '@mui/material';
import CustomModal from 'components/globals/modal';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import EzlaJson from './EzlaJson';
import TimeTableInside from './TimeTableInside';
import useStyles from './styles';

const ViewList = ({ label, value }) => {
  const classes = useStyles();
  return (
    <li>
      <div className={classes.title}>{label}</div>
      <div className={classes.value}>{value}</div>
    </li>
  );
};

function ViewTimeRecording({ row }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { holiday_addons } = useSelector((state) => state.timeRecording);
  const { single_employee } = useSelector((state) => state.employee);
  const getDisease = (id) => {
    const formattedHoliday = holiday_addons?.disease_codes?.find((item) => item?.id == id);
    const code = formattedHoliday?.code ?? '';
    const description = formattedHoliday?.description ?? '';
    return code + ' - ' + description;
  };
  const getBreakBenefit = (id) => {
    const formattedHoliday = holiday_addons?.break_benefit_types?.find((item) => item?.id == id);
    const code = formattedHoliday?.code ?? '';
    const description = formattedHoliday?.description ?? '';
    return code + ' - ' + description;
  };

  const checkType = () => {
    if (row?.type == 'sick_leave') {
      return <FormattedMessage id="Sick Leave" defaultMessage="Sick Leave" />;
    } else if (row?.type == 'vacation_leave') {
      return <FormattedMessage id="Vacation Leave" defaultMessage="Vacation Leave" />;
    } else if (row?.type == 'care_leave') {
      return <FormattedMessage id="Care Leave" defaultMessage="Care Leave" />;
    } else {
      return '';
    }
  };

  const checkCareType = (careType) => {
    if (careType == 'child_care') {
      return <FormattedMessage id="Child Care" defaultMessage="Child Care" />;
    } else if (row?.type == 'family_care') {
      return (
        <FormattedMessage id="Care for family member" defaultMessage="Care for family member" />
      );
    } else {
      return '';
    }
  };

  const handleClose = () => {
    setOpen((prev) => !prev);
  };
  return (
    <div className={classes.viewRoot}>
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <ul>
            <ViewList
              label={<FormattedMessage id="Name" defaultMessage="Name" />}
              value={row?.name}
            />
            <ViewList
              label={<FormattedMessage id="Type" defaultMessage="Type" />}
              value={checkType()}
            />
            <ViewList
              label={<FormattedMessage id="Start Date" defaultMessage="Start Date" />}
              value={row?.start_date}
            />
            <ViewList
              label={<FormattedMessage id="End Date" defaultMessage="End Date" />}
              value={row?.end_date}
            />
            {row?.hours && (
              <ViewList
                label={<FormattedMessage id="Hours" defaultMessage="Hours" />}
                value={row?.hours}
              />
            )}
          </ul>
        </Grid>
        {row?.type == 'sick_leave' || row?.type == 'care_leave' ? (
          <>
            <Grid item sm={6}>
              <ul>
                <ViewList
                  label={
                    <FormattedMessage id="Hospital Treatment" defaultMessage="Hospital Treatment" />
                  }
                  value={
                    row?.sick_leave_details?.hospital_treatment == '1' ? (
                      <FormattedMessage id="Yes" defaultMessage="Yes" />
                    ) : (
                      <FormattedMessage id="No" defaultMessage="No" />
                    )
                  }
                />
                <ViewList
                  label={
                    <FormattedMessage
                      id="Print series number"
                      defaultMessage="Print series number"
                    />
                  }
                  value={row?.sick_leave_details?.print_series_number}
                />
                <ViewList
                  label={<FormattedMessage id="Date of Release" defaultMessage="Date of Release" />}
                  value={row?.sick_leave_details?.date_of_release}
                />
                <ViewList
                  label={
                    <FormattedMessage id="Date of Delivery" defaultMessage="Date of Delivery" />
                  }
                  value={row?.sick_leave_details?.date_of_delivery}
                />
                <ViewList
                  label={<FormattedMessage id="Waiting Period" defaultMessage="Waiting Period" />}
                  value={
                    row?.sick_leave_details?.waiting_period == '1' ? (
                      <FormattedMessage id="Yes" defaultMessage="Yes" />
                    ) : (
                      <FormattedMessage id="No" defaultMessage="No" />
                    )
                  }
                />
              </ul>
            </Grid>
            <Grid item sm={12}>
              <ul>
                <ViewList
                  label={<FormattedMessage id="Disease Code" defaultMessage="Disease Code" />}
                  value={getDisease(row?.sick_leave_details?.disease_code_id)}
                />
              </ul>
            </Grid>
            <Grid item sm={12}>
              <ul>
                <ViewList
                  label={
                    <FormattedMessage id="Break Benefit Type" defaultMessage="Break Benefit Type" />
                  }
                  value={getBreakBenefit(row?.sick_leave_details?.break_benefit_type_id)}
                />
              </ul>
            </Grid>
          </>
        ) : null}
        {row?.type == 'care_leave' && (
          <>
            <Grid item sm={12}>
              <ul>
                <ViewList
                  label={<FormattedMessage id="Care Type" defaultMessage="Care Type" />}
                  value={checkCareType(row?.sick_leave_details?.care_type)}
                />
              </ul>
            </Grid>
            <Grid item sm={12}>
              <ul>
                <ViewList
                  label={<FormattedMessage id="Care Person" defaultMessage="Care Person" />}
                  value={row?.sick_leave_details?.care_person}
                />
              </ul>
            </Grid>
          </>
        )}
      </Grid>
      {row?.sick_leave_details?.from_ezla && (
        <Box marginTop={2} textAlign="right">
          <Button variant="contained" onClick={handleClose}>
            <FormattedMessage id="Show Ezla Data" defaultMessage="Show Ezla Data" />
          </Button>
        </Box>
      )}

      <CustomModal
        open={open}
        handleClose={handleClose}
        height="auto"
        headerText={<FormattedMessage id="Ezla Data View" defaultMessage="Ezla Data View" />}>
        <EzlaJson ezlaData={row?.sick_leave_details?.ezla_document} />
      </CustomModal>
      <TimeTableInside
        employeeId={single_employee?.id || row?.employee?.id}
        startDate={row?.start_date}
        type={row?.type}
      />
    </div>
  );
}

export default ViewTimeRecording;
