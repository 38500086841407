import * as actions from './types';
const initialState = {
  payroll_closing: null,
  payroll_closing_loading: false,
  payroll_closing_edit_loading: false,
  pue: null,
  pue_loading: false,
  ezla: null,
  ezla_loading: false,
  add_pue_loading: false
};

const setupReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_COMPANY_PAYROLL_CLOSING_BEGIN:
      return { ...state, payroll_closing_loading: true };

    case actions.GET_COMPANY_PAYROLL_CLOSING_SUCCESS:
      return { ...state, payroll_closing: action.payload, payroll_closing_loading: false };

    case actions.GET_COMPANY_PAYROLL_CLOSING_ERROR:
      return { ...state, payroll_closing_loading: false };

    case actions.EDIT_COMPANY_PAYROLL_CLOSING_BEGIN:
      return { ...state, payroll_closing_edit_loading: true };

    case actions.EDIT_COMPANY_PAYROLL_CLOSING_ERROR:
    case actions.EDIT_COMPANY_PAYROLL_CLOSING_SUCCESS:
      return { ...state, payroll_closing_edit_loading: false };

    case actions.GET_PUE_BEGIN:
      return { ...state, pue_loading: true };

    case actions.GET_PUE_SUCCESS:
      return { ...state, pue: action.payload, pue_loading: false };

    case actions.GET_PUE_ERROR:
      return { ...state, pue_loading: false };

    case actions.GET_EZLA_BEGIN:
      return { ...state, ezla_loading: true, ezla: null };

    case actions.GET_EZLA_SUCCESS:
      return { ...state, ezla: action.payload, ezla_loading: false };

    case actions.GET_EZLA_ERROR:
      return { ...state, ezla_loading: false };

    case actions.CLEAR_EZLA:
      return { ...state, ezla: null };

    case actions.ADD_PUE_BEGIN:
      return { ...state, add_pue_loading: true };

    case actions.ADD_PUE_ERROR:
    case actions.ADD_PUE_SUCCESS:
      return { ...state, add_pue_loading: false };

    default:
      return state;
  }
};

export default setupReducer;
