import { axiosInstance } from 'apis/_axios';

// get my company
export const getCompanyApi = () => {
  return axiosInstance().get('/my-company');
};

// edit company
export const editCompanyApi = (slug, data) => {
  return axiosInstance().post(`/my-company/${slug}`, data);
};

// get payroll closing
export const getPayrollClosingApi = () => {
  return axiosInstance().get('/company-payroll-closing');
};

// edit payroll closing
export const editPayrollClosingApi = (data) => {
  return axiosInstance().post(`/company-payroll-closing`, data);
};

// get pue
export const getPUEApi = () => {
  return axiosInstance().get('/company-ezla-setting');
};

// add / edit pue
export const addPUEApi = (data) => {
  return axiosInstance().post(`/company-ezla-setting`, data);
};

// get ezla data
export const getEzlaApi = (data) => {
  return axiosInstance().get(`/ezla-data`, { params: data });
};
