import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAccountingInfo } from 'redux/auth/actions';
import AccountStat from './components/AccountStat';
import AccountWidget from './components/AccountWidget';

const AccountContainer = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAccountingInfo());
  }, []);
  return (
    <>
      <AccountWidget />
      <AccountStat />
    </>
  );
};

export default AccountContainer;
