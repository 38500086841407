import { Button, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { HookForm } from 'components/form/hookForm';
import { HookFormProvider } from 'components/form/hookFormProvider';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { changeLogo } from 'redux/auth/actions';
import * as Yup from 'yup';
import { editAccountProfile } from '../accounting/redux/actions';
import BusinessAddress from './BusinessAddress';
import Registration from './Registration';
import useStyles from './styles';

function AccountProfile() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [addressError, setAddressError] = useState();
  const { accountingProfile, edit_loading } = useSelector((state) => state.accounting);

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    short_name: Yup.string().required('Short name is required'),
    tax_id: Yup.string().required('Tax id is required'),
    regon: Yup.string().required('Registration no. is required'),
    krs: Yup.string().required('KRS is required'),
    phone_number: Yup.string().typeError('Must be a number').required('Phone number is required'),
    addresses: Yup.array(),
    email: Yup.string().email().required('Account email is required,')
  });

  const defaultValues = {
    name: '',
    short_name: '',
    tax_id: '',
    regon: '',
    bdo: '',
    phone_number: '',
    addresses: [],
    logo: ''
  };

  const changeUserData = (data) => {
    dispatch(changeLogo(data));
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    Object.entries(data)?.map((item) => {
      if (item[0] !== 'addresses') {
        formData.append(item[0], item[1]);
      } else {
        Object.entries(data.addresses[0])?.map((list) => {
          formData.append(`addresses[0][${list[0]}]`, list[1]);
        });
        Object.entries(data.addresses[1])?.map((list) => {
          formData.append(`addresses[1][${list[0]}]`, list[1]);
        });
      }
    });
    dispatch(editAccountProfile(formData, changeUserData));
  };

  return (
    <div className={classes.cardWrapper}>
      <HookFormProvider
        defaultValues={defaultValues}
        resolver={useYupValidationResolver(validationSchema)}>
        <HookForm onSubmit={onSubmit}>
          <Box margin={5}>
            <Registration company={accountingProfile} type="account" />
            <BusinessAddress
              setAddressError={setAddressError}
              addressError={addressError}
              company={accountingProfile}
            />
            <Box paddingBottom={3} textAlign="right" marginRight={4.5}>
              {!edit_loading ? (
                <Button variant="contained" color="primary" type="submit">
                  {<FormattedMessage id="Update" defaultMessage="Update" />}
                </Button>
              ) : (
                <Button variant="contained" color="primary" disabled>
                  <CircularProgress size={20} sx={{ marginRight: '10px' }} />{' '}
                  {<FormattedMessage id="Updating" defaultMessage="Updating" />}
                </Button>
              )}
            </Box>
          </Box>
        </HookForm>
      </HookFormProvider>
    </div>
  );
}

export default AccountProfile;
