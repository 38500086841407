import { Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import useStyles from '../styles';

function AccountStat() {
  const classes = useStyles();
  const { account_info } = useSelector((state) => state.auth);
  return (
    <>
      <Grid container>
        <Grid item sm={6} md={3} lg={2} xl={1.5}>
          <div className={classes.employeeContainer}>
            <div className={classes.title}>
              <FormattedMessage id="Active Company" defaultMessage="Active Company" />
            </div>
            <div className={classes.employee}>
              <div className={classes.count} style={{ color: '#64c368' }}>
                {account_info?.active_companies}
              </div>
              <div className={`${classes.employeeTitle} ${classes.active}`}>
                {<FormattedMessage id="Active" defaultMessage="Active" />}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item sm={6} md={3} lg={2} xl={1.5}>
          <div className={classes.employeeContainer}>
            <div className={classes.title}>
              <FormattedMessage id="Inactive Company" defaultMessage="Inactive Company" />
            </div>
            <div className={classes.employee}>
              <div className={classes.count} style={{ color: '#d24e44' }}>
                {account_info?.inactive_companies}
              </div>
              <div className={`${classes.employeeTitle} ${classes.inactive}`}>
                {<FormattedMessage id="Inactive" defaultMessage="Inactive" />}
              </div>
            </div>
          </div>
        </Grid>

        <Grid item sm={6} md={3} lg={2} xl={1.5}>
          <div className={classes.employeeContainer}>
            <div className={classes.title}>
              <FormattedMessage id="Employment Contract" defaultMessage="Employment Contract" />
            </div>
            <div className={classes.employee}>
              <div className={classes.count} style={{ color: '#64c368' }}>
                {account_info?.employment_contract_count}
              </div>
              <div className={`${classes.employeeTitle} ${classes.active}`}>
                {<FormattedMessage id="Active" defaultMessage="Active" />}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item sm={6} md={3} lg={2} xl={1.5}>
          <div className={classes.employeeContainer}>
            <div className={classes.title}>
              <FormattedMessage id="Contract of Mandate" defaultMessage="Contract of Mandate" />
            </div>
            <div className={classes.employee}>
              <div className={classes.count} style={{ color: '#64c368' }}>
                {account_info?.mandate_contract_count}
              </div>
              <div className={`${classes.employeeTitle} ${classes.active}`}>
                {<FormattedMessage id="Active" defaultMessage="Active" />}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item sm={6} md={3} lg={2} xl={1.5}>
          <div className={classes.employeeContainer}>
            <div className={classes.title}>
              <FormattedMessage
                id="Contract for Specific Work"
                defaultMessage="Contract for Specific Work"
              />
            </div>
            <div className={classes.employee}>
              <div className={classes.count} style={{ color: '#64c368' }}>
                {account_info?.specific_contract_count}
              </div>
              <div className={`${classes.employeeTitle} ${classes.active}`}>
                {<FormattedMessage id="Active" defaultMessage="Active" />}
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default AccountStat;
