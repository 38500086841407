import { HookForm } from 'components/form/hookForm';
import { HookFormProvider } from 'components/form/hookFormProvider';
import React from 'react';
import useStyles from '../styles';
import * as Yup from 'yup';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import ParameterForm from './ParameterForm';
import { singleParameterParser } from '../../../../helpers';
import { upsertParameter } from '../redux/actions';
import { useDispatch } from 'react-redux';

function ParameterAdd({ handleClose, parameter }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const defaultValues = {
    par_name: parameter?.key,
    date_s: '',
    par_type: ''
  };

  const validationSchema = Yup.object({
    date_s: Yup.date().required('Start Date is required'),
    par_value: Yup.string().required('Value is required')
  });

  const onSubmit = (data) => {
    const finalData = singleParameterParser(data);
    console.log(finalData);
    dispatch(upsertParameter(finalData, handleClose));
  };

  return (
    <>
      <div className={classes.content}>
        <HookFormProvider
          defaultValues={defaultValues}
          resolver={useYupValidationResolver(validationSchema)}>
          <HookForm onSubmit={onSubmit}>
            <ParameterForm handleClose={handleClose} />
          </HookForm>
        </HookFormProvider>
      </div>
    </>
  );
}

export default ParameterAdd;
