import { editCompanyApi, getCompanyApi } from 'apis/settings';
import { setCompanyId } from 'redux/auth/actions';
import { errorToast, successToast } from 'utils/toast';
import * as actions from './types';

// get company
export const getCompany = () => (dispatch) => {
  dispatch({
    type: actions.GET_COMPANY_BEGIN
  });
  getCompanyApi()
    .then((res) => {
      dispatch({
        type: actions.GET_COMPANY_SUCCESS,
        payload: res.data?.data
      });
      dispatch(setCompanyId(res.data?.data));
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_COMPANY_ERROR
      });
      errorToast(error);
    });
};

// edit company
export const editCompany = (slug, data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.EDIT_COMPANY_BEGIN
  });
  editCompanyApi(slug, data)
    .then((res) => {
      dispatch({
        type: actions.EDIT_COMPANY_SUCCESS
      });
      handleFunction && handleFunction(res.data?.data);
      successToast('Company edited Successfully');
      dispatch(getCompany());
    })
    .catch((error) => {
      dispatch({
        type: actions.EDIT_COMPANY_ERROR
      });
      errorToast(error);
    });
};
