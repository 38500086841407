import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    '& svg': {
      marginRight: '1.5rem',
      color: theme.palette.grey[600],
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary
      }
    }
  },
  breakLine: {
    whiteSpace: 'unset',
    wordBreak: 'break-all',
    width: '700px'
  },
  modalFooter: {
    width: '100%',
    marginTop: 'auto',
    marginLeft: 'auto',
    background: '#fff',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem 3rem',
    height: '4.9rem'
  },
  bold: {
    fontWeight: '700 !important'
  },
  helperText: {
    position: 'absolute',
    bottom: '-15px',
    left: '0',
    width: '100%',
    color: 'red',
    fontSize: '11px'
  },
  modalFooterButtons: {
    fontFamily: 'Euclid',
    ':&>*': {
      margin: '0 1rem'
    }
  },
  modalFooterCancel: {
    '&:hover': {
      background: '#ddd'
    }
  },
  contentWrap: {
    padding: '0rem 1.5rem'
  },

  rootTab: {
    background: '#fff',
    padding: '0 3.5rem',
    minHeight: '3.8rem',
    '& button': {
      minHeight: '3.8rem',
      fontSize: '1.2rem',
      textTransform: 'capitalize',
      fontWeight: 600,
      padding: '0',
      marginRight: '5rem',
      letterSpacing: '0.6px'
    }
  },
  viewRootTab: {
    background: '#fff',
    padding: '0 3.5rem',
    minHeight: '3.8rem',
    '& button': {
      minHeight: '3.8rem',
      fontSize: '1.2rem',
      textTransform: 'capitalize',
      fontWeight: 600,
      padding: '0',
      marginRight: '2rem',
      letterSpacing: '0.6px'
    }
  },
  fullname: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },

  contractSelectWrapper: {
    padding: '1.4rem 4rem',
    fontSize: '1.4rem',
    '& ul': {
      listStyle: 'square',
      paddingLeft: '1rem'
    },
    '& li': {
      marginBottom: '1rem',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main
      }
    }
  },
  employeeSearch: {
    '& .MuiOutlinedInput-root': {
      padding: '0.6rem',
      paddingLeft: '2rem'
    },
    '& input': {
      padding: '0 !important'
    }
  },

  title: {
    width: '80%',
    paddingLeft: '1rem'
  },
  value: {
    width: '20%',
    textAlign: 'right',
    paddingRight: '1rem',
    borderLeft: `1px solid ${theme.palette.grey[400]}`
  },
  table: {
    lineHeight: '2.3rem',
    fontSize: '1.1rem',
    width: '100%',
    border: `1px solid ${theme.palette.grey[400]}`,
    marginBottom: '2.5rem',
    borderCollapse: 'collapse',
    '& th, td': {
      border: `1px solid ${theme.palette.grey[400]}`,
      borderCollapse: 'collapse'
    }
  },
  employeeBtn: {
    marginLeft: '1rem',
    '& button': {
      padding: '0.6rem 0',
      minWidth: '4rem'
    }
  },
  calculatedView: {
    position: 'relative',
    marginTop: '3rem'
  },
  loader: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'radial-gradient(#2b74f517,#a2c4ff0d, transparent)'
  },
  slash: {
    fontSize: '24px',
    margin: '18px 10px 0px 10px',
    color: 'gray',
    fontWeight: '100'
  },
  departmentBtn: {
    marginTop: '2rem',
    '& button': {
      padding: '0.7rem 0',
      minWidth: '4rem'
    }
  },
  printContractSection: {
    margin: '45px 90px',
    '& .MuiTypography-body1': {
      fontSize: '14px',
      fontWeight: '400'
    }
  },
  signatureWrapper: {
    maxWidth: '200px',
    textAlign: 'center'
  }
}));

export default useStyles;
