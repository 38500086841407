import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import useStyles from '../styles';

function Logo({ company }) {
  const classes = useStyles();
  const imgRef = useRef(null);
  const { setValue } = useFormContext();

  const [image, setImage] = useState();

  const handleClick = () => {
    imgRef.current.click();
  };

  const handleChange = (e) => {
    setValue('logo', e.target.files[0]);
    const newImage = URL.createObjectURL(e.target.files[0]);
    setImage(newImage);
  };

  useEffect(() => {
    company?.logo?.url && setImage(company?.logo?.url);
  }, [company]);

  return (
    <div>
      <div className={classes.logoWrapper} onClick={handleClick}>
        {image ? (
          <img src={image} alt="" />
        ) : (
          <div className={classes.updateLogo}>
            {<FormattedMessage id="Add Logo" defaultMessage="Add Logo" />}
          </div>
        )}
        <input type="file" hidden ref={imgRef} onChange={handleChange} />
      </div>
    </div>
  );
}

export default Logo;
