import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import useStyles from '../styles';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import useToggle from 'hooks/useToggle';
import uploadFile from '../../../../assets/images/uploadFile.svg';
import CustomModal from 'components/globals/modal';
import UploadFile from './UploadFile';
import { FormattedMessage } from 'react-intl';

function NoFiles({ url }) {
  const [uploadOpen, uploadFunction] = useToggle(false);

  const classes = useStyles();
  return (
    <div className={classes.noFilesWrap}>
      <div className={classes.fileImage}>
        <img src={uploadFile} />
      </div>
      <div className={classes.fileTitle}>
        <FormattedMessage
          id="You currently have no files"
          defaultMessage="You currently have no files"
        />
      </div>
      <div className={classes.instruction}>
        <FormattedMessage
          id="You can upload files upto 10mb"
          defaultMessage="You can upload files upto 10mb"
        />
      </div>
      <Box marginTop={2}>
        <Button variant="outlined" onClick={uploadFunction}>
          <FileUploadIcon />
          <FormattedMessage id="Upload File" defaultMessage="Upload File" />
        </Button>
      </Box>
      <CustomModal
        open={uploadOpen}
        handleClose={uploadFunction}
        height="auto"
        width="30rem"
        headerText={<FormattedMessage id="Upload File" defaultMessage="Upload File" />}>
        <UploadFile uploadFunction={uploadFunction} url={url} />
      </CustomModal>
    </div>
  );
}

export default NoFiles;
