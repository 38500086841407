import Protected from 'components/globals/protected';
import Accounting from 'pages/_private/accounting';
import AddAccounting from 'pages/_private/accounting/AddAccounting';
import EditAccounting from 'pages/_private/accounting/EditAccounting';
import Assignment from 'pages/_private/assignment';
import Calendar from 'pages/_private/calendar';
import CompanyPage from 'pages/_private/company';
import Companies from 'pages/_private/company/Company';
import CompanyAssignment from 'pages/_private/companyAssignment';
import Contract from 'pages/_private/contract';
import Dashboard from 'pages/_private/dashboard';
import Document from 'pages/_private/documents';
import Events from 'pages/_private/events';
import Ezla from 'pages/_private/ezla';
import Parameter from 'pages/_private/parameters';
import CPayroll from 'pages/_private/payroll/CPayroll';
import IPayroll from 'pages/_private/payroll/IPayroll';
import Settings from 'pages/_private/settings';
import AccountSettings from 'pages/_private/settings/AccountSetting';
import Setup from 'pages/_private/setup';
import Workers from 'pages/_private/workers';

const DashboardRoutes = [
  {
    path: '/dashboard',
    component: (
      <Protected>
        <Dashboard />
      </Protected>
    )
  },
  {
    path: '/company',
    component: (
      <Protected>
        <CompanyPage />
      </Protected>
    )
  },

  {
    path: '/accounting',
    component: (
      <Protected>
        <Accounting />
      </Protected>
    )
  },
  {
    path: '/accounting/profile',
    component: (
      <Protected>
        <AccountSettings />
      </Protected>
    )
  },
  {
    path: '/accounting/add',
    component: (
      <Protected>
        <AddAccounting />
      </Protected>
    )
  },
  {
    path: '/user-assignment',
    component: (
      <Protected>
        <Assignment />
      </Protected>
    )
  },
  {
    path: '/company-assignment',
    component: (
      <Protected>
        <CompanyAssignment />
      </Protected>
    )
  },
  {
    path: '/accounting/edit/:slug',
    component: (
      <Protected>
        <EditAccounting />
      </Protected>
    )
  },
  {
    path: '/workers',
    component: (
      <Protected>
        <Workers />
      </Protected>
    )
  },
  {
    path: '/parameter',
    component: (
      <Protected>
        <Parameter />
      </Protected>
    )
  },
  {
    path: '/contract',
    component: (
      <Protected>
        <Contract />
      </Protected>
    )
  },
  {
    path: '/calendar',
    component: (
      <Protected>
        <Calendar />
      </Protected>
    )
  },
  {
    path: '/document',
    component: (
      <Protected>
        <Document />
      </Protected>
    )
  },
  {
    path: '/events',
    component: (
      <Protected>
        <Events />
      </Protected>
    )
  },
  {
    path: '/settings',
    component: (
      <Protected>
        <Settings />
      </Protected>
    )
  },
  {
    path: '/payroll/collective',
    component: (
      <Protected>
        <CPayroll />
      </Protected>
    )
  },
  {
    path: '/payroll/items',
    component: (
      <Protected>
        <IPayroll />
      </Protected>
    )
  },
  {
    path: '/setup',
    component: (
      <Protected>
        <Setup />
      </Protected>
    )
  },
  {
    path: '/ezla',
    component: (
      <Protected>
        <Ezla />
      </Protected>
    )
  },
  {
    path: '/companies',
    component: (
      <Protected>
        <Companies />
      </Protected>
    )
  }
];

export default DashboardRoutes;
