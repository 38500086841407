import React from 'react';
import CompanyPage from 'components/locals/company/companies/Index';
import DashboardLayout from 'layouts/dashboardLayout';

function Companies() {
  return (
    <DashboardLayout>
      <CompanyPage />;
    </DashboardLayout>
  );
}

export default Companies;
