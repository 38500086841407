import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import CustomModal from 'components/globals/modal';
import CustomTable from 'components/globals/table';
import useToggle from 'hooks/useToggle';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { translate } from 'utils';
import EditAllowance from '../workers/components/allowance/EditAllowance';
import ViewAllowance from '../workers/components/allowance/ViewAllowance';
import Overtime from '../workers/components/overtime/EditOvertime';
import TimeRecording from '../workers/components/timeRecording/EditTimeRecording';
import ViewSickLeave from '../workers/components/timeRecording/ViewTimeRecording';
import EmployeeView from '../workers/components/viewEmployee/EmployeeView';
import ViewContractTermination from './components/ViewContractTermination';
import ViewOvertime from './components/ViewOvertime';
import ViewStatement from './components/ViewStatement';
import useStyles from './styles';

export default function EventsTable({ handleRefetch }) {
  const { events, events_loading } = useSelector((state) => state.events);
  const rows = events || [];

  const checkType = (row) => {
    if (row?.type == 'sick_leave') {
      return <FormattedMessage id="Sick Leave" defaultMessage="Sick Leave" />;
    } else if (row?.type == 'vacation_leave') {
      return <FormattedMessage id="Vacation Leave" defaultMessage="Vacation Leave" />;
    } else if (row?.type == 'unpaid_leave') {
      return <FormattedMessage id="Unpaid Leave" defaultMessage="Unpaid Leave" />;
    } else if (row?.type == 'allowance') {
      return <FormattedMessage id="Allowance" defaultMessage="Allowance" />;
    } else if (row?.type == 'overtime_amount') {
      return <FormattedMessage id="Overtime Amount" defaultMessage="Overtime Amount" />;
    } else if (row?.type == 'deduction') {
      return <FormattedMessage id="Deduction" defaultMessage="Deduction" />;
    } else if (row?.type == 'statement') {
      return <FormattedMessage id="Statement" defaultMessage="Statement" />;
    } else if (row?.type == 'contract_termination') {
      return <FormattedMessage id="Equivalent Vacation" defaultMessage="Equivalent Vacation" />;
    } else {
      return '';
    }
  };

  const columns = [
    { title: 'S.N.', type: 'Index', isSortable: true, minWidth: 20 },
    {
      field: (row) => <span>{checkType(row)}</span>,
      title: <FormattedMessage id="Type" defaultMessage="Type" />,
      minWidth: 170,
      align: 'right'
    },
    {
      field: 'name',
      title: <FormattedMessage id="Name" defaultMessage="Name" />,
      minWidth: 170,
      align: 'right'
    },

    {
      title: <FormattedMessage id="Date From" defaultMessage="Date From" />,
      minWidth: 170,
      align: 'right',
      field: (row) => {
        if (row.start_date) {
          return row.start_date;
        } else if (row.year && row.month) {
          const date = row?.year + '-' + row?.month;
          return date;
        } else if (row.termination_details && row.termination_details.date) {
          return row?.termination_details?.date;
        }
      }
    },
    {
      title: <FormattedMessage id="Date To" defaultMessage="Date To" />,
      minWidth: 120,
      align: 'right',
      field: (row) => {
        if (row.end_date) {
          return row.end_date;
        } else {
          const date = (row?.year ?? '') + '-' + (row?.month ?? '');
          return date;
        }
      }
    },
    {
      title: <FormattedMessage id="Employee" defaultMessage="Employee" />,
      minWidth: 100,
      isSortable: true,
      field: (row) => <EmployeeViewName row={row} />
    },

    {
      field: 'payroll',
      title: <FormattedMessage id="Payroll" defaultMessage="Payroll" />,
      minWidth: 170,
      align: 'right'
    },

    {
      title: <FormattedMessage id="Value" defaultMessage="Value" />,
      minWidth: 170,
      align: 'right',
      field: (row) => {
        if (row.type == 'contract_termination') {
          return row?.termination_details?.vacation_days;
        } else {
          return row?.final_value;
        }
      }
    },

    {
      field: (row) => {
        return <Actions contractId={row?.id} row={row} handleRefetch={handleRefetch} />;
      },
      title: <FormattedMessage id="Actions" defaultMessage="Actions" />,
      minWidth: 170,
      align: 'center'
    }
  ];

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <CustomTable tableData={rows} tableHeads={columns} loading={events_loading} />
    </Paper>
  );
}

const Actions = ({ contractId, row, handleRefetch }) => {
  const handleClick = () => {
    openFunction();
  };
  const classes = useStyles();
  const [modalOpen, openFunction] = useToggle(false);
  const [terminateModalOpen, terminateFunction] = useToggle(false);

  return (
    <>
      <Box display="none">
        <FormattedMessage id="Edit Events" defaultMessage="Edit Events" />
        <FormattedMessage id="View Events" defaultMessage="View Events" />
      </Box>
      <div className={classes.iconWrapper}>
        <Tooltip title={<FormattedMessage id="View Events" defaultMessage="View Events" />}>
          <VisibilityIcon onClick={terminateFunction} />
        </Tooltip>
        {row?.type != 'statement' && row?.type != 'contract_termination' && (
          <Tooltip title="Edit">
            <EditIcon onClick={handleClick} />
          </Tooltip>
        )}
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        headerText={translate('Edit Events')}>
        {row?.type === 'allowance' || row?.type === 'deduction' ? (
          <EditAllowance
            handleClose={openFunction}
            row={row}
            employeeId={row?.employee?.id}
            handleRefetch={handleRefetch}
          />
        ) : row?.type === 'sick_leave' ||
          row?.type === 'vacation_leave' ||
          row?.type === 'care_leave' ? (
          <TimeRecording
            handleClose={openFunction}
            row={row}
            employeeId={row?.employee?.id}
            handleRefetch={handleRefetch}
          />
        ) : row?.type === 'overtime_amount' ? (
          <Overtime
            handleClose={openFunction}
            row={row}
            employeeId={row?.employee?.id}
            handleRefetch={handleRefetch}
          />
        ) : row?.type === 'statement' ? (
          <Overtime
            handleClose={openFunction}
            row={row}
            employeeId={row?.employee?.id}
            handleRefetch={handleRefetch}
          />
        ) : null}
      </CustomModal>
      <CustomModal
        open={terminateModalOpen}
        handleClose={terminateFunction}
        height="auto"
        width="70rem"
        headerText={translate('View Events')}>
        {row?.type === 'allowance' || row?.type === 'deduction' ? (
          <ViewAllowance row={row} />
        ) : row?.type === 'sick_leave' || row?.type === 'vacation_leave' ? (
          <ViewSickLeave row={row} />
        ) : row?.type === 'overtime_amount' ? (
          <ViewOvertime row={row} />
        ) : row?.type === 'statement' ? (
          <ViewStatement row={row} />
        ) : row?.type === 'contract_termination' ? (
          <ViewContractTermination row={row} />
        ) : null}
      </CustomModal>
    </>
  );
};

const EmployeeViewName = ({ row }) => {
  const [modalOpen, openFunction] = useToggle(false);
  const classes = useStyles();
  const handleClick = () => {
    openFunction();
  };

  const firstName = row?.employee?.first_name ?? '';
  const secondName = row?.employee?.middle_name ?? '';
  const lastName = row?.employee?.last_name ?? '';
  const name = lastName + ' ' + firstName + ' ' + secondName;

  return (
    <>
      <div className={classes.fullname} onClick={handleClick}>
        {name}
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="100rem"
        variant="light"
        headerText={translate('Details of') + ' ' + name}>
        <EmployeeView id={row?.employee?.id} />
      </CustomModal>
    </>
  );
};
