export const CREATE_EVENTS_BEGIN = 'CREATE_EVENTS_BEGIN';
export const CREATE_EVENTS_SUCCESS = 'CREATE_EVENTS_SUCCESS';
export const CREATE_EVENTS_ERROR = 'CREATE_EVENTS_ERROR';

export const GET_EVENTS_BEGIN = 'GET_EVENTS_BEGIN';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_ERROR = 'GET_EVENTS_ERROR';

export const EDIT_EVENTS_BEGIN = 'EDIT_EVENTS_BEGIN';
export const EDIT_EVENTS_SUCCESS = 'EDIT_EVENTS_SUCCESS';
export const EDIT_EVENTS_ERROR = 'EDIT_EVENTS_ERROR';

export const DELETE_EVENTS_BEGIN = 'DELETE_EVENTS_BEGIN';
export const DELETE_EVENTS_SUCCESS = 'DELETE_EVENTS_SUCCESS';
export const DELETE_EVENTS_ERROR = 'DELETE_EVENTS_ERROR';

export const ADD_EVENT_FILTER = 'ADD_EVENT_FILTER';
