import ApartmentIcon from '@mui/icons-material/Apartment';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import useStyles from '../styles';

function AccountWidget() {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleRoute = () => {
    navigate('/accounting/add');
  };
  return (
    <div className={classes.widgetContainer}>
      <div className={classes.title}>
        <FormattedMessage id="widgets" defaultMessage="Widgets" />
      </div>
      <div className={classes.widget} onClick={handleRoute}>
        <ApartmentIcon />
        <div className={classes.widgetTitle}>
          <FormattedMessage id="Add Company" defaultMessage="Add Company" />
        </div>
      </div>
    </div>
  );
}

export default AccountWidget;
