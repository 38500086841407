import React from 'react';
import Allowance from '../../common/allowance/Allowance';

function AllowanceContainer() {
  return (
    <div>
      <Allowance />
    </div>
  );
}

export default AllowanceContainer;
