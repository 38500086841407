import * as actions from './types';
const initialState = {
  assignments: [],
  loading: false,
  user_company: [],
  user_company_loading: false,
  assign_company_loading: false,
  delete_assign_company_loading: false
};

const assignmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_ASSIGNMENT_BEGIN:
      return { ...state, loading: true };

    case actions.GET_ASSIGNMENT_SUCCESS:
      return { ...state, assignments: action.payload, loading: false };

    case actions.GET_ASSIGNMENT_ERROR:
      return { ...state, loading: false };

    case actions.GET_ASSIGNMENT_COMPANY_BEGIN:
      return { ...state, user_company_loading: true };

    case actions.GET_ASSIGNMENT_COMPANY_SUCCESS:
      return { ...state, user_company: action.payload, user_company_loading: false };

    case actions.GET_ASSIGNMENT_COMPANY_ERROR:
      return { ...state, user_company_loading: false };

    case actions.ASSIGN_COMPANY_BEGIN:
      return { ...state, assign_company_loading: true };

    case actions.ASSIGN_COMPANY_SUCCESS:
    case actions.ASSIGN_COMPANY_ERROR:
      return { ...state, assign_company_loading: false };

    case actions.DELETE_ASSIGN_COMPANY_BEGIN:
      return { ...state, delete_assign_company_loading: true };

    case actions.DELETE_ASSIGN_COMPANY_SUCCESS:
    case actions.DELETE_ASSIGN_COMPANY_ERROR:
      return { ...state, delete_assign_company_loading: false };

    default:
      return state;
  }
};

export default assignmentReducer;
