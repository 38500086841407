import React from 'react';
import DashboardLayout from 'layouts/dashboardLayout';
import CollectivePayroll from 'components/locals/payroll/collectivePayroll/CollectivePayroll';

const CPayroll = () => {
  return (
    <DashboardLayout>
      <CollectivePayroll />
    </DashboardLayout>
  );
};

export default CPayroll;
