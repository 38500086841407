import * as React from 'react';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import useStyles from './styles';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import CustomTable from 'components/globals/table';
import { Box, Tooltip } from '@mui/material';
import EditAllowance from './EditOvertime';
import { FormattedMessage } from 'react-intl';
import DeleteIcon from '@mui/icons-material/Delete';
import { translate } from 'utils';
import DeleteOvertime from './DeleteOvertime';

export default function OvertimeTable({ employeeId }) {
  const { employee_contract } = useSelector((state) => state.contract);

  const findAgreementName = (id, row) => {
    const data = employee_contract?.find((item) => item?.id == id);
    return data ? `${data?.agreement_type_name} - ${row?.contract_number}` : '';
  };
  const columns = [
    { title: 'S.N.', type: 'Index', isSortable: true, minWidth: 20 },
    {
      title: <FormattedMessage id="Name" defaultMessage="Name" />,
      minWidth: 100,
      isSortable: true,
      field: 'name'
    },
    {
      field: (row) => {
        return <>{row?.agreement_id ? `${findAgreementName(row?.agreement_id, row)}` : '-'}</>;
      },
      title: <FormattedMessage id="Contract" defaultMessage="Contract" />,
      minWidth: 170,
      align: 'right'
    },
    {
      field: 'hours',
      title: <FormattedMessage id="Hours" defaultMessage="Hours" />,
      minWidth: 120,
      align: 'right'
    },

    {
      field: 'value',
      title: <FormattedMessage id="Value" defaultMessage="Value" />,
      minWidth: 120,
      align: 'right'
    },
    {
      field: 'year',
      title: <FormattedMessage id="Year" defaultMessage="Year" />,
      minWidth: 120,
      align: 'right'
    },
    {
      field: 'month',
      title: <FormattedMessage id="Month" defaultMessage="Month" />,
      minWidth: 120,
      align: 'right'
    },

    {
      field: (row) => {
        return <Actions row={row} employeeId={employeeId} />;
      },
      title: <FormattedMessage id="Actions" defaultMessage="Actions" />,
      minWidth: 170,
      align: 'center'
    }
  ];

  const { loading, overtime } = useSelector((state) => state.overtime);
  const rows = overtime || [];

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <CustomTable tableData={rows} tableHeads={columns} loading={loading} />
    </Paper>
  );
}

const Actions = ({ row, employeeId }) => {
  const classes = useStyles();
  const [modalOpen, openFunction] = useToggle(false);
  const [deleteOpen, openDeleteFunction] = useToggle(false);

  const handleDelete = () => {
    openDeleteFunction();
  };

  const handleClick = () => {
    openFunction();
  };

  return (
    <>
      <Box display="none">
        <FormattedMessage id="Delete Overtime" defaultMessage="Delete Overtime" />
      </Box>
      <div className={classes.iconWrapper}>
        <Tooltip title={<FormattedMessage id="Edit" defaultMessage="Edit" />}>
          <EditIcon onClick={handleClick} />
        </Tooltip>
        <Tooltip title={<FormattedMessage id="Delete" defaultMessage="Delete" />}>
          <DeleteIcon onClick={handleDelete} />
        </Tooltip>
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        headerText={
          <FormattedMessage id="Edit Time Recording" defaultMessage="Edit Time Recording" />
        }>
        <EditAllowance handleClose={openFunction} row={row} employeeId={employeeId} />
      </CustomModal>
      <CustomModal
        open={deleteOpen}
        handleClose={openDeleteFunction}
        height="auto"
        width="45rem"
        headerText={`${translate('Delete Overtime')} - ${row?.name}`}>
        <DeleteOvertime row={row} employeeId={employeeId} handleClose={openDeleteFunction} />
      </CustomModal>
    </>
  );
};
