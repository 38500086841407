import { Grid } from '@mui/material';
import { HookFormInput } from 'components/form/hookFormInput';
import { FormattedMessage } from 'react-intl';

function TimeRecording() {
  return (
    <Grid container spacing={1}>
      <Grid item sm={4}>
        <HookFormInput
          name="parameters.vacation_duration"
          label={
            <FormattedMessage id="The amount of vacation" defaultMessage="The amount of vacation" />
          }
          type="number"
        />
      </Grid>
    </Grid>
  );
}

export default TimeRecording;
