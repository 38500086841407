import * as actions from './types';
const initialState = {
  allowances: [],
  loading: false,
  create_loading: false,
  single_allowance: null,
  single_loading: false,
  edit_loading: false,
  change_status_loading: false,
  delete_loading: false
};

const allowanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CREATE_ALLOWANCE_BEGIN:
      return { ...state, create_loading: true };

    case actions.CREATE_ALLOWANCE_SUCCESS:
      return { ...state, create_loading: false };

    case actions.CREATE_ALLOWANCE_ERROR:
      return { ...state, create_loading: false };

    case actions.GET_ALLOWANCE_BEGIN:
      return { ...state, loading: true };

    case actions.GET_ALLOWANCE_SUCCESS:
      return { ...state, allowances: action.payload, loading: false };

    case actions.GET_ALLOWANCE_ERROR:
      return { ...state, loading: false };

    case actions.GET_SINGLE_ALLOWANCE_BEGIN:
      return { ...state, single_loading: true };

    case actions.GET_SINGLE_ALLOWANCE_SUCCESS:
      return { ...state, single_allowance: action.payload, single_loading: false };

    case actions.GET_SINGLE_ALLOWANCE_ERROR:
      return { ...state, single_loading: false };

    case actions.EDIT_ALLOWANCE_BEGIN:
      return { ...state, edit_loading: true };

    case actions.EDIT_ALLOWANCE_ERROR:
    case actions.EDIT_ALLOWANCE_SUCCESS:
      return { ...state, edit_loading: false };

    case actions.DELETE_ALLOWANCE_BEGIN:
      return { ...state, delete_loading: true };

    case actions.DELETE_ALLOWANCE_ERROR:
    case actions.DELETE_ALLOWANCE_SUCCESS:
      return { ...state, delete_loading: false };

    default:
      return state;
  }
};

export default allowanceReducer;
