import { Button, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { updateSocialSecurity } from '../../redux/actions';
import useStyles from '../../styles';
import OpeningForm from './Form';

function OpeningAdd({ handleClose, employeeId, contractTypeId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const { agreement_types, social_security_loading } = useSelector((state) => state.contract);

  const filterAgreement = agreement_types?.find((list) => list?.id == contractTypeId);
  const [data, setData] = useState({
    employee_id: employeeId,
    year: '',
    month: '',
    social_contribution_base: ''
  });

  const onSubmit = () => {
    if (!data.year) {
      setError('Year is required');
    } else if (!data.month) {
      setError('Month is required');
    } else {
      dispatch(
        updateSocialSecurity({ ...data, agreement_type: filterAgreement?.slug }, handleClose)
      );
    }
  };

  return (
    <>
      <div className={classes.content}>
        <OpeningForm setError={setError} setData={setData} data={data} error={error} />
        <Box className={classes.modalFooter}>
          <Box className={classes.modalFooterButtons}>
            {social_security_loading ? (
              <Button sx={{ marginRight: '1rem' }} variant={'contained'} disabled>
                <CircularProgress size={18} />{' '}
                {<FormattedMessage id="Saving" defaultMessage="Saving" />}
              </Button>
            ) : (
              <Button sx={{ marginRight: '1rem' }} variant={'contained'} onClick={onSubmit}>
                {<FormattedMessage id="Save" defaultMessage="Save" />}
              </Button>
            )}

            <Button
              className={classes.modalFooterCancel}
              variant={'outlined'}
              onClick={handleClose}>
              {<FormattedMessage id="Cancel" defaultMessage="Cancel" />}
            </Button>
          </Box>
        </Box>
      </div>
    </>
  );
}

export default OpeningAdd;
