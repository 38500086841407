import { FormattedMessage } from 'react-intl';
import useStyles from '../styles';

function Overtime({ data }) {
  const classes = useStyles();
  return (
    <div className={classes.employerCost}>
      <table className={classes.table}>
        <tbody>
          <tr>
            <td colSpan={3}>
              <h4 style={{ background: '#d7d7d7', margin: '0' }}>
                <FormattedMessage id="Overtime" defaultMessage="Overtime" />
              </h4>
            </td>
          </tr>
          <tr>
            <td>
              <FormattedMessage id="Type" defaultMessage="Type" />
            </td>
            <td>
              <FormattedMessage id="Hours" defaultMessage="Hours" />
            </td>
            <td>
              <FormattedMessage id="Value" defaultMessage="Value" />
            </td>
          </tr>

          {data?.map((list) => (
            <tr key={list?.id}>
              <td>{list?.description?.split('=>')?.[0]}</td>
              <td>{list?.description?.split('=>')?.[1]}</td>
              <td>{parseFloat(list?.value).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Overtime;
