/* Build in libraries */

/* Third party libraries */
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { HookFormCheckbox } from 'components/UI/Form/HookFormCheckbox/Index';
import { HookFormAutoSelect } from 'components/UI/Form/HookFormSelect';
import { FormattedMessage } from 'react-intl';

function CompanyUserForm({ company }) {
  return (
    <>
      <Box>
        <Box className="formBodyWrapper">
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <HookFormAutoSelect
                name="company_slug"
                label={<FormattedMessage id="Company" defaultMessage="Company" />}
                options={company}
              />
            </Grid>

            <Grid item sm={6}>
              <Box marginTop={4}>
                <HookFormCheckbox
                  name="is_admin"
                  type="checkbox"
                  label={<FormattedMessage id="Is Admin" defaultMessage="Is Admin" />}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default CompanyUserForm;
