import * as React from 'react';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import useStyles from './styles';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import CustomTable from 'components/globals/table';
import { Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditAllowance from './EditAllowance';
import ViewAllowance from './ViewAllowance';
import { FormattedMessage } from 'react-intl';
import { translate } from 'utils';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteAllowance from './DeleteAllowance';

export default function AllowanceTable({ employeeId }) {
  const { employee_contract } = useSelector((state) => state.contract);

  const findAgreementName = (id, row) => {
    console.log(row, 'formData');
    const data = employee_contract?.find((item) => item?.id == id);
    return data ? `${data?.agreement_type_name} - ${row?.contract_number}` : '';
  };

  const columns = [
    { title: 'S.N.', type: 'Index', isSortable: true, minWidth: 20 },
    {
      title: <FormattedMessage id="Name" defaultMessage="Name" />,
      minWidth: 100,
      isSortable: true,
      field: 'name'
    },
    {
      field: (row) => {
        return <>{row?.agreement_id ? `${findAgreementName(row?.agreement_id, row)}` : '-'}</>;
      },
      title: <FormattedMessage id="Contract" defaultMessage="Contract" />,
      minWidth: 170,
      align: 'right'
    },
    {
      field: 'value',
      title: <FormattedMessage id="Value" defaultMessage="Value" />,
      minWidth: 170,
      align: 'right'
    },
    {
      field: (row) => {
        return (
          <>
            {row?.type === 'allowance' && (
              <FormattedMessage id="Allowance" defaultMessage="Allowance" />
            )}
            {row?.type === 'deduction' && (
              <FormattedMessage id="Deduction" defaultMessage="Deduction" />
            )}
          </>
        );
      },
      title: <FormattedMessage id="Type" defaultMessage="Type" />,
      minWidth: 120,
      align: 'right'
    },

    {
      field: (row) => {
        return (
          <>
            {row?.one_time ? (
              <FormattedMessage id="One Time" defaultMessage="One Time" />
            ) : (
              <FormattedMessage id="Recurrence" defaultMessage="Recurrence" />
            )}
          </>
        );
      },
      title: <FormattedMessage id="Data type" defaultMessage="Data Type" />,
      minWidth: 120,
      align: 'right'
    },
    {
      field: (row) => {
        return (
          <>
            {row?.one_time ? (
              <Box>
                <Typography>
                  {<FormattedMessage id="Year" defaultMessage="Year" />}: {row?.year}
                </Typography>
                <Typography>
                  {<FormattedMessage id="Month" defaultMessage="Month" />}: {row?.month}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography>
                  {<FormattedMessage id="Start Date" defaultMessage="Start Date" />}:{' '}
                  {row?.start_date}
                </Typography>
                <Typography>
                  {<FormattedMessage id="End Date" defaultMessage="End Date" />}: {row?.end_date}
                </Typography>
              </Box>
            )}
          </>
        );
      },
      title: <FormattedMessage id="Data Type" defaultMessage="Data Type" />,
      minWidth: 120,
      align: 'right'
    },
    {
      field: (row) => {
        return <Actions row={row} employeeId={employeeId} />;
      },
      title: <FormattedMessage id="Actions" defaultMessage="Actions" />,
      minWidth: 170,
      align: 'center'
    }
  ];

  const { loading, allowances } = useSelector((state) => state.allowance);
  const rows = allowances || [];

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <CustomTable tableData={rows} tableHeads={columns} loading={loading} />
    </Paper>
  );
}

const Actions = ({ row, employeeId }) => {
  const classes = useStyles();
  const [modalOpen, openFunction] = useToggle(false);
  const [viewOpen, openViewFunction] = useToggle(false);
  const [deleteOpen, openDeleteFunction] = useToggle(false);

  const handleDelete = () => {
    openDeleteFunction();
  };

  const handleClick = () => {
    openFunction();
  };

  const handleView = () => {
    openViewFunction();
  };

  return (
    <>
      <div className={classes.iconWrapper}>
        <Box display="none">
          <FormattedMessage
            id="View Allowances and Deduction"
            defaultMessage="View Allowances and Deduction"
          />
          <FormattedMessage
            id="Delete Allowances and Deduction"
            defaultMessage="Delete Allowances and Deduction"
          />
        </Box>
        <Tooltip title={<FormattedMessage id="Edit" defaultMessage="Edit" />}>
          <EditIcon onClick={handleClick} />
        </Tooltip>
        <Tooltip title={<FormattedMessage id="View" defaultMessage="View" />}>
          <VisibilityIcon onClick={handleView} />
        </Tooltip>
        <Tooltip title={<FormattedMessage id="Delete" defaultMessage="Delete" />}>
          <DeleteIcon onClick={handleDelete} />
        </Tooltip>
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        headerText={
          <FormattedMessage
            id="Edit Allowances and Deductions"
            defaultMessage="Edit Allowances and Deductions"
          />
        }>
        <EditAllowance handleClose={openFunction} row={row} employeeId={employeeId} />
      </CustomModal>
      <CustomModal
        open={viewOpen}
        handleClose={openViewFunction}
        height="auto"
        headerText={`${translate('View Allowances and Deduction')} - ${row?.name}`}>
        <ViewAllowance row={row} employeeId={employeeId} />
      </CustomModal>
      <CustomModal
        open={deleteOpen}
        handleClose={openDeleteFunction}
        height="auto"
        width="45rem"
        headerText={`${translate('Delete Allowances and Deduction')} - ${row?.name}`}>
        <DeleteAllowance row={row} employeeId={employeeId} handleClose={openDeleteFunction} />
      </CustomModal>
    </>
  );
};
