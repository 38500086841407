import { axiosInstance } from 'apis/_axios';

// create parameter
export const createParameterApi = (data) => {
  return axiosInstance().post('/parameter', data);
};

// get all parameters
export const getParameterApi = () => {
  return axiosInstance().get('/all-parameter', {
    params: {
      paginate: 0,
      limit: null
    }
  });
};

export const getAdminParameterApi = () => {
  return axiosInstance().get('/current-parameter');
};

// getParameter List by name
export const getParameterByNameApi = (name) => {
  return axiosInstance().get(`/parameter/list_by_name/${name}`);
};

// getParameter By Id
export const getParameterByIdApi = (id) => {
  return axiosInstance().get(`/api/parameter/${id}`);
};

// edit Parameter
export const editParameterApi = (id, data) => {
  return axiosInstance().post(`/api/parameter/${id}`, data);
};

// delete parameter
export const deleteParameterApi = (id) => {
  return axiosInstance().delete(`/parameter/${id}/delete`);
};
