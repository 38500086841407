import { Alert, Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FormattedMessage } from 'react-intl';
import { translate } from 'utils';
import { yearList } from 'utils/dateUtils';
import useStyles from '../../styles';

function OpeningForm({ setData, data, error, setError, disabled = false }) {
  const classes = useStyles();
  const handleChange = (key, data) => {
    setError('');
    setData((prev) => ({ ...prev, [key]: data }));
  };
  const months = [
    { label: translate('January'), value: 1 },
    { label: translate('February'), value: 2 },
    { label: translate('March'), value: 3 },
    { label: translate('April'), value: 4 },
    { label: translate('May'), value: 5 },
    { label: translate('June'), value: 6 },
    { label: translate('July'), value: 7 },
    { label: translate('August'), value: 8 },
    { label: translate('September'), value: 9 },
    { label: translate('October'), value: 10 },
    { label: translate('November'), value: 11 },
    { label: translate('December'), value: 12 }
  ];
  return (
    <Box padding={4}>
      <Grid container spacing={4}>
        <Grid item sm={6}>
          <Autocomplete
            disablePortal
            disabled={disabled}
            id="combo-box-demo"
            options={yearList}
            defaultValue={{ label: data['year'], value: data['year'] }}
            className={classes.employeeSearch}
            isOptionEqualToValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
            onChange={(e, data) => handleChange('year', data?.value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={<FormattedMessage id="Year" value="Year" />}
                InputLabelProps={{ shrink: true }}
                value={data['year']}
              />
            )}
          />
        </Grid>
        <Grid item sm={6}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={months}
            defaultValue={months.find((item) => item.value == parseInt(data.month))}
            className={classes.employeeSearch}
            isOptionEqualToValue={(option) => option.value}
            getOptionLabel={(option) => option.label}
            onChange={(e, data) => handleChange('month', data?.value)}
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                {...params}
                label={<FormattedMessage id="Month" value="Month" />}
                InputLabelProps={{ shrink: true }}
                value={data['month']}
              />
            )}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            type="number"
            label={
              <FormattedMessage
                id="Social Contribution Base"
                defaultMessage="Social Contribution Base"
              />
            }
            onChange={(e) => handleChange('social_contribution_base', e.target.value)}
            fullWidth
            value={data['social_contribution_base']}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      {error && (
        <Box marginTop={2}>
          <Alert severity="error">
            <Typography variant="h6" fontWeight={400}>
              {error}
            </Typography>
          </Alert>
        </Box>
      )}
    </Box>
  );
}

export default OpeningForm;
