import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Grid, Skeleton } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { translate } from 'utils';
import useStyles from './styles';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

const ViewList = ({ label, value }) => {
  const classes = useStyles();
  const { single_loading } = useSelector((state) => state.employee);
  return (
    <li>
      <div className={classes.title}>{label}</div>
      <div className={classes.value}>
        {single_loading ? (
          <Skeleton height={16} animation="wave" variant="rectangular" width={200} />
        ) : (
          value
        )}
      </div>
    </li>
  );
};

export default function BasicDetail() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');
  const { single_employee, tax_offices, nhf } = useSelector((state) => state.employee);
  const { departments } = useSelector((state) => state.department);

  const [employeeDetail, setEmployeeDetail] = useState({});
  const [address, setAddress] = useState([]);
  const [name, setName] = useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const findTaxOffice = (id) => {
    const filteredTax = tax_offices?.find((list) => list?.id == id);
    return filteredTax?.office ?? '';
  };

  const findNHF = (id) => {
    const filteredNHF = nhf?.find((list) => list?.id == id);
    return filteredNHF?.title ?? '';
  };
  useEffect(() => {
    let finalData = [];
    if (single_employee && single_employee?.employee_detail) {
      single_employee.employee_detail?.map((item) => (finalData[item?.key] = item?.value));
      setEmployeeDetail(finalData);
      // single_employee?.address?.map((item, index) => {
      //   if (item.address_type == 'default' || item.address_type == 'registered') {
      //     addressData[item.address_type] = item;
      //   }
      // });
      setAddress(single_employee?.address);
    }
    const firstName = single_employee?.first_name ?? '';
    const secondName = single_employee?.middle_name ?? '';
    const lastName = single_employee?.last_name ?? '';
    setName(lastName + ' ' + firstName + ' ' + secondName);
  }, [single_employee]);

  const checkGender = (value) => {
    if (value == 'male') {
      return <FormattedMessage id="Male" defaultMessage="Male" />;
    } else if (value == 'female') {
      return <FormattedMessage id="Female" defaultMessage="Female" />;
    } else return '';
  };

  return (
    <div className={classes.accordianRoot}>
      <Accordion
        disableGutters
        elevation={0}
        square
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography variant="h5">
            {<FormattedMessage id="Info" defaultMessage="Info" />}
          </Typography>
        </AccordionSummary>
        <MuiAccordionDetails>
          <ul>
            <ViewList label={<FormattedMessage id="Name" defaultMessage="Name" />} value={name} />
            <ViewList label="Pesel" value={single_employee?.pesel} />
            <ViewList
              label={
                <FormattedMessage
                  id="NIP(Tax Identification Number)"
                  defaultMessage="NIP(Tax Identification Number)"
                />
              }
              value={single_employee?.tax_id}
            />
            <ViewList
              label={<FormattedMessage id="Email" defaultMessage="Email" />}
              value={single_employee?.email}
            />
            <ViewList
              label={<FormattedMessage id="Gender" defaultMessage="Gender" />}
              value={checkGender(single_employee?.gender)}
            />
          </ul>
        </MuiAccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography variant="h5">
            {<FormattedMessage id="Detailed Info" defaultMessage="Detailed Info" />}
          </Typography>
        </AccordionSummary>
        <MuiAccordionDetails>
          <MuiAccordionDetails>
            <ul className={classes.twoSection}>
              <li>
                <div className={classes.title}>
                  {<FormattedMessage id="Family Name" defaultMessage="Family Name" />}
                </div>
                <div className={classes.value}>{employeeDetail?.family_name}</div>
              </li>
              <li>
                <div className={classes.title}>
                  {
                    <FormattedMessage
                      id="Identification Number"
                      defaultMessage="Identification Number"
                    />
                  }
                </div>
                <div className={classes.value}>{employeeDetail?.identification_number}</div>
              </li>
              {/* <li>
                <div className={classes.title}>
                  {<FormattedMessage id="Father Name" defaultMessage="Father Name" />}
                </div>
                <div className={classes.value}>{employeeDetail?.father_name}</div>
              </li> */}
              <li>
                <div className={classes.title}>
                  {
                    <FormattedMessage
                      id="State Issued Documents"
                      defaultMessage="State Issued Documents"
                    />
                  }
                </div>
                <div className={classes.value}>{employeeDetail?.state_issued_document}</div>
              </li>
              {/* <li>
                <div className={classes.title}>
                  {<FormattedMessage id="Mother Name" defaultMessage="Mother Name" />}
                </div>
                <div className={classes.value}>{employeeDetail?.mother_name}</div>
              </li> */}
              <li>
                <div className={classes.title}>
                  {<FormattedMessage id="Bank Name" defaultMessage="Bank Name" />}
                </div>
                <div className={classes.value}>{single_employee?.bank_name}</div>
              </li>
              <li>
                <div className={classes.title}>
                  {<FormattedMessage id="Citizenship" defaultMessage="Citizenship" />}
                </div>
                <div className={classes.value}>{employeeDetail?.citizenship}</div>
              </li>
              <li>
                <div className={classes.title}>
                  {<FormattedMessage id="Account Number" defaultMessage="Account Number" />}
                </div>
                <div className={classes.value}>{single_employee?.account_number}</div>
              </li>
              <li>
                <div className={classes.title}>
                  {<FormattedMessage id="Birthplace" defaultMessage="Birthplace" />}
                </div>
                <div className={classes.value}>{employeeDetail?.pob}</div>
              </li>
              <li>
                <div className={classes.title}>
                  {<FormattedMessage id="Id Number" defaultMessage="Id Number" />}
                </div>
                <div className={classes.value}>{employeeDetail?.id_number}</div>
              </li>
              <li>
                <div className={classes.title}>
                  {<FormattedMessage id="Date of Birth" defaultMessage="Date of Birth" />}
                </div>
                <div className={classes.value}>{employeeDetail?.date_of_birth}</div>
              </li>
              <li>
                <div className={classes.title}>
                  {<FormattedMessage id="Phone Number" defaultMessage="Phone Number" />}
                </div>
                <div className={classes.value}>{employeeDetail?.phone}</div>
              </li>
              <li>
                <div className={classes.title}>
                  {
                    <FormattedMessage
                      id="Another Type of Identification"
                      defaultMessage="Another Type of Identification"
                    />
                  }
                </div>
                <div className={classes.value}>{single_employee?.identification_type}</div>
              </li>
              <li>
                <div className={classes.title}>
                  {<FormattedMessage id="Department Of" defaultMessage="Department Of" />}
                </div>
                <div className={classes.value}>
                  {
                    departments?.data?.find((item) => item?.id == single_employee?.department?.id)
                      ?.title
                  }
                </div>
              </li>
            </ul>
          </MuiAccordionDetails>
        </MuiAccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography variant="h5">
            {<FormattedMessage id="Address Data" defaultMessage="Address Data" />}
          </Typography>
        </AccordionSummary>
        <MuiAccordionDetails>
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <ul>
                <li>
                  <div className={classes.title}>
                    {<FormattedMessage id="Street" defaultMessage="Street" />}
                  </div>
                  <div className={classes.value}>{address[0]?.street}</div>
                </li>
                <li>
                  <div className={classes.title}>
                    {<FormattedMessage id="House Number" defaultMessage="House Number" />}
                  </div>
                  <div className={classes.value}>{address[0]?.house_number}</div>
                </li>
                <li>
                  <div className={classes.title}>
                    {<FormattedMessage id="No. of premises" defaultMessage="No. of premises" />}
                  </div>
                  <div className={classes.value}>{address[0]?.number_of_premises}</div>
                </li>

                <li>
                  <div className={classes.title}>
                    {<FormattedMessage id="Zip Code" defaultMessage="Zip Code" />}
                  </div>
                  <div className={classes.value}>{address[0]?.zip}</div>
                </li>
                <li>
                  <div className={classes.title}>
                    {<FormattedMessage id="Post" defaultMessage="Post" />}
                  </div>
                  <div className={classes.value}>{address[0]?.post}</div>
                </li>
              </ul>
            </Grid>
            <Grid>
              <ul>
                <li>
                  <div className={classes.title}>
                    {<FormattedMessage id="City" defaultMessage="City" />}
                  </div>
                  <div className={classes.value}>{address[0]?.city}</div>
                </li>
                <li>
                  <div className={classes.title}>
                    {<FormattedMessage id="Country" defaultMessage="Country" />}
                  </div>
                  <div className={classes.value}>{address[0]?.country}</div>
                </li>
                <li>
                  <div className={classes.title}>
                    {<FormattedMessage id="Voivodeship" defaultMessage="Voivodeship" />}
                  </div>
                  <div className={classes.value}>{address[0]?.voivodeship}</div>
                </li>
                <li>
                  <div className={classes.title}>
                    {<FormattedMessage id="District" defaultMessage="District" />}
                  </div>
                  <div className={classes.value}>{address[0]?.county}</div>
                </li>
                <li>
                  <div className={classes.title}>
                    {<FormattedMessage id="Commune" defaultMessage="Commune" />}
                  </div>
                  <div className={classes.value}>{address[0]?.community}</div>
                </li>
              </ul>
            </Grid>
          </Grid>
        </MuiAccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel4d-header">
          <Typography variant="h5">
            {<FormattedMessage id="ZUS and US" defaultMessage="ZUS and US" />}
          </Typography>
        </AccordionSummary>
        <MuiAccordionDetails>
          <ul>
            <li>
              <div className={classes.title}>
                {<FormattedMessage id="Identifier for ZUS" defaultMessage="Identifier for ZUS" />}
              </div>
              <div className={classes.value}>{employeeDetail?.identifier_for_zus}</div>
            </li>
            <li>
              <div className={classes.title}>
                {<FormattedMessage id="Tax Id" defaultMessage="Tax Id" />}
              </div>
              <div className={classes.value}>{employeeDetail?.tax_id_zus}</div>
            </li>
            <li>
              <div className={classes.title}>
                {
                  <FormattedMessage
                    id="Department of the National Health Fund"
                    defaultMessage="Department of the National Health Fund"
                  />
                }
              </div>
              <div className={classes.value}>{findNHF(employeeDetail?.national_health_fund)}</div>
            </li>
            <li>
              <div className={classes.title}>
                {
                  <FormattedMessage
                    id="Polish Tax Residence"
                    defaultMessage="Polish Tax Residence"
                  />
                }
              </div>
              <div className={classes.value}>
                {translate(employeeDetail?.polish_tax_residence == 'yes' ? 'Yes' : 'No')}
              </div>
            </li>
            <li>
              <div className={classes.title}>
                {<FormattedMessage id="Tax Office" defaultMessage="Tax Office" />}
              </div>
              <div className={classes.value}>{findTaxOffice(employeeDetail?.tax_office)}</div>
            </li>
            <li>
              <div className={classes.title}>
                {
                  <FormattedMessage
                    id="Pension Entitlements"
                    defaultMessage="Pension Entitlements"
                  />
                }
              </div>
              <div className={classes.value}>{translate(employeeDetail?.pension_entitlements)}</div>
            </li>
            <li>
              <div className={classes.title}>
                {<FormattedMessage id="Disability Level" defaultMessage="Disability Level" />}
              </div>
              <div className={classes.value}>{translate(employeeDetail?.disability_level)}</div>
            </li>
            <li>
              <div className={classes.title}>
                {
                  <FormattedMessage
                    id="Date from of issuance"
                    defaultMessage="Date from of issuance"
                  />
                }
              </div>
              <div className={classes.value}>{employeeDetail?.date_of_issuance}</div>
            </li>
            <li>
              <div className={classes.title}>
                {<FormattedMessage id="Expiration Date" defaultMessage="Expiration Date" />}
              </div>
              <div className={classes.value}>{employeeDetail?.expiration_date}</div>
            </li>
            <li>
              <div className={classes.title}>
                {<FormattedMessage id="Issued By" defaultMessage="Issued By" />}
              </div>
              <div className={classes.value}>{employeeDetail?.issued_by}</div>
            </li>
          </ul>
        </MuiAccordionDetails>
      </Accordion>
    </div>
  );
}
