import { Button, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import useStyles from '../styles';
import ViewTable from './ViewTable';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/system';
import useToggle from 'hooks/useToggle';
import CustomModal from 'components/globals/modal';
import AddParameter from './AddParameter';
import { useDispatch, useSelector } from 'react-redux';
import { getParameterByName } from '../redux/actions';
import { FormattedMessage } from 'react-intl';

function ParameterView({ handleClose, parameter }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalOpen, openFunction] = useToggle(false);
  const { isEditable } = useSelector((state) => state.parameter);

  useEffect(() => {
    parameter?.key && dispatch(getParameterByName(parameter.key));
  }, [parameter?.key]);
  console.log(parameter, 'para');

  return (
    <div className={classes.contentWrap}>
      <Box marginTop={3} marginBottom={2}>
        <Grid
          spacing={6}
          container
          justifyContent="space-between"
          direction="row"
          alignItems="center">
          {/* <Grid item sm={4}>
            <FormControl fullWidth>
              <Select value={age} onChange={handleChange}>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item sm={4}>
            {isEditable && (
              <Button variant="contained" fullWidth onClick={openFunction}>
                <AddIcon /> {<FormattedMessage id="Add New Value" defaultMessage="Add New Value" />}
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
      <ViewTable />
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="25rem"
        width="30rem"
        headerText={<FormattedMessage id="Add Parameter" defaultMessage="Add Parameter" />}>
        <AddParameter handleClose={openFunction} parameter={parameter} />
      </CustomModal>
    </div>
  );
}

export default ParameterView;
