import { Button, MenuItem, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { changeDateFormat } from 'utils/dateUtils';
import { terminateContract } from '../../redux/actions';
import useStyles from '../../styles';

function TerminateContract({ contractId, handleClose, refetch }) {
  const classes = useStyles();
  const [endDate, setEndDate] = useState();
  const dispatch = useDispatch();
  const [check, setCheck] = useState(true);
  const [checkDate, setCheckDate] = useState(true);

  const [data, setData] = useState({
    expiration_code_id: '',
    deregistration_reason_id: '',
    legal_basis: '',
    initiator_party: ''
  });
  const { terminateContractStatus, termination_data } = useSelector((state) => state.contract);

  const handleChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleValue = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleTerminate = () => {
    if (!endDate) {
      setCheckDate(false);
    }
    if (endDate && check) {
      const date = changeDateFormat(endDate, 'YYYY-MM-DD');
      dispatch(
        terminateContract({ agreement_id: contractId, date: date, ...data }, () => {
          handleClose(), refetch();
        })
      );
    }
  };

  useEffect(() => {
    if (data?.expiration_code_id) {
      const filterData = termination_data?.expiration_codes?.find(
        (list) => list?.id == data?.expiration_code_id
      );
      if (filterData?.title?.slice(0, 4) == 550) {
        if (data?.legal_basis?.length > 0) {
          setCheck(true);
        } else {
          setCheck(false);
        }
      } else {
        setCheck(true);
      }
    }
  }, [data]);

  useEffect(() => {
    setCheckDate(true);
  }, [endDate]);
  return (
    <>
      <Box display="flex" justifyContent="center" margin={3} flexDirection="column">
        <Typography variant="h6" textAlign="center">
          <FormattedMessage
            id="Enter Contract End Date to terminate Contract"
            defaultMessage="Enter Contract End Date to terminate Contract"
          />
        </Typography>
        <TextField
          label={<FormattedMessage id="Contract End Date" defaultMessage="Contract End Date" />}
          type="date"
          onChange={handleChange}
          sx={{ minWidth: '30rem', marginTop: '30px', marginBottom: '30px' }}
          InputLabelProps={{ shrink: true }}
          helperText={
            !checkDate && (
              <Box className={classes.helperText}>
                <FormattedMessage id="Enter End Date" defaultMessage="Enter End Date" />
              </Box>
            )
          }
        />
        <TextField
          label={
            <FormattedMessage
              id="Reason for deregistration"
              defaultMessage="Reason for deregistration"
            />
          }
          name="deregistration_reason_id"
          onChange={handleValue}
          sx={{ marginTop: '15px' }}
          InputLabelProps={{ shrink: true }}
          select>
          {termination_data?.deregistration_reasons?.map((option) => (
            <MenuItem key={option.id} value={option.id} className={classes.breakLine}>
              {option.title}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label={
            <FormattedMessage
              id="Expiration code/dissolution mode"
              defaultMessage="Expiration code/dissolution mode"
            />
          }
          name="expiration_code_id"
          onChange={handleValue}
          sx={{ marginTop: '25px' }}
          InputLabelProps={{ shrink: true }}
          select>
          {termination_data?.expiration_codes?.map((option) => (
            <MenuItem key={option.id} value={option.id} className={classes.breakLine}>
              {option.title}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          multiline
          name="legal_basis"
          onChange={handleValue}
          InputLabelProps={{ shrink: true }}
          minRows={4}
          label={<FormattedMessage id="Legal Basis" defaultMessage="Legal Basis" />}
          style={{ marginTop: '25px' }}
          helperText={
            !check && (
              <Box className={classes.helperText}>
                <FormattedMessage
                  id="Legal Basis is required"
                  defaultMessage="Legal Basis is required"
                />
              </Box>
            )
          }
        />
        <TextField
          name="initiator_party"
          onChange={handleValue}
          label={
            <FormattedMessage
              id="Party on whose initiative the termination of employment took place"
              defaultMessage="Party on whose initiative the termination of employment took place"
            />
          }
          sx={{ marginTop: '40px' }}
          InputLabelProps={{ shrink: true }}
          select>
          <MenuItem value="employee">
            <FormattedMessage id="Employee" defaultMessage="Employee" />
          </MenuItem>
          <MenuItem value="employer">
            <FormattedMessage id="Employer" defaultMessage="Employer" />
          </MenuItem>
        </TextField>
      </Box>

      <Box className={classes.modalFooter}>
        <Box className={classes.modalFooterButtons}>
          {terminateContractStatus ? (
            <Button sx={{ marginRight: '5rem' }} variant={'contained'} disabled>
              <FormattedMessage id="Terminating" defaultMessage="Terminating" />
            </Button>
          ) : (
            <Button sx={{ marginRight: '5rem' }} variant={'contained'} onClick={handleTerminate}>
              <FormattedMessage id="Terminate" defaultMessage="Terminate" />
            </Button>
          )}
          <Button
            className={classes.modalFooterCancel}
            sx={{ marginRight: '6rem' }}
            variant={'outlined'}
            onClick={handleClose}>
            <FormattedMessage id="Cancel" defaultMessage="Cancel" />
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default TerminateContract;
