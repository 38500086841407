import { createHolidayApi, deleteHolidayApi, fetchCalendarApi } from 'apis/calendar';
import * as actions from './types';

export const fetchCalendar = (year) => async (dispatch) => {
  dispatch({ type: actions.CALENDAR_LOADING });
  fetchCalendarApi(year)
    .then((res) => {
      dispatch({ type: actions.FETCH_CALENDAR_SUCCESS, payload: res.data.data });
    })
    .catch((res) => {
      dispatch({ type: actions.FETCH_CALENDAR_ERROR });
    });
};

export const storeHoliday = (data, handleAction, year) => async (dispatch) => {
  dispatch({ type: actions.CALENDAR_LOADING });
  createHolidayApi(data)
    .then((res) => {
      dispatch({
        type: actions.STORE_HOLIDAY_SUCCESS,
        payload: res.data.data
      });
      year && dispatch(fetchCalendar(year));
      handleAction && handleAction();
    })
    .catch((err) => {
      dispatch({ type: actions.STORE_HOLIDAY_ERROR });
    });
};

export const deleteHoliday = (id, handleAction, year) => async (dispatch) => {
  dispatch({ type: actions.DELETE_HOLIDAY_BEGIN });
  deleteHolidayApi({ date: id })
    .then((res) => {
      dispatch({ type: actions.DELETE_HOLIDAY_SUCCESS });
      handleAction && handleAction();
      year && dispatch(fetchCalendar(year));
    })
    .catch((err) => {
      dispatch({ type: actions.DELETE_HOLIDAY_ERROR });
    });
};
