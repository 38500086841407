import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  viewRootTab: {
    background: '#fff',
    padding: '0 3.5rem',
    minHeight: '3.8rem',
    '& button': {
      minHeight: '3.8rem',
      fontSize: '1.2rem',
      textTransform: 'capitalize',
      fontWeight: 600,
      padding: '0',
      marginRight: '2rem',
      letterSpacing: '0.6px'
    }
  },
  iconWrapper: {
    cursor: 'pointer'
  },
  accordianRoot: {
    fontSize: '1.2rem',
    padding: '0 1rem',
    '& h5': {
      color: theme.palette.primary.dark,
      fontWeight: '600',
      fontSize: '1.4rem',
      letterSpacing: '0.6px'
    },
    '& svg': {
      color: theme.palette.primary.dark,
      fontWeight: 600
    },
    // '& .MuiAccordionDetails-root': {
    //   borderTop: 'none',
    //   paddingTop: '0'
    // },
    '& .MuiAccordionSummary-root': {
      backgroundColor: `#fff !important`
    },
    '& ul': {
      margin: '0',
      padding: '0',
      marginLeft: '1.6rem',
      listStyle: 'none'
    },
    '& li': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingBottom: '1.2rem'
    }
  },
  title: {
    color: theme.palette.grey[700],
    width: '20rem'
  },
  value: {
    color: theme.palette.primary.dark,
    fontWeight: 600,
    letterSpacing: '0.8px'
  },
  twoSection: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& li': {
      width: '50%'
    }
  }
}));

export default useStyles;
