import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentBreadCrumb from './components/DocumentBreadCrumb';
import FileWrapper from './components/FileWrapper';
import NoFiles from './components/NoFiles';
import { getSingleFolder } from './redux/actions';
import CircularProgress from '@mui/material/CircularProgress';
import useStyles from './styles';
import { FormattedMessage } from 'react-intl';

function EmployeeDocumentPanel({ folderId }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { single_folder, single_folder_loading } = useSelector((state) => state.document);

  const [url, setUrl] = useState([
    { name: <FormattedMessage id="Root Directory" defaultMessage="Root Directory" />, id: folderId }
  ]);

  const handleUrl = (newUrl) => {
    setUrl([...url, newUrl]);
  };

  useEffect(() => {
    dispatch(getSingleFolder(url.at(-1)?.id));
  }, [url]);

  return (
    <div className={classes.documentPanel}>
      <DocumentBreadCrumb url={url} setUrl={setUrl} isEmployee={true} />
      {!single_folder_loading ? (
        single_folder?.sub_folders?.length > 0 || single_folder?.files?.length > 0 ? (
          <div>
            {single_folder?.sub_folders?.map((item) => (
              <FileWrapper
                fileType="folder"
                key={item?.id}
                name={item?.name}
                extension={item?.extension}
                id={item?.id}
                url={item?.url}
                handleUrl={handleUrl}
                type="client"
              />
            ))}
            {single_folder?.files?.map((item) => (
              <FileWrapper
                fileType="file"
                key={item?.id}
                name={item?.name}
                extension={item?.extension}
                url={item?.url}
                id={item?.id}
                handleUrl={handleUrl}
                type="client"
              />
            ))}
          </div>
        ) : (
          <NoFiles url={url} />
        )
      ) : (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

export default EmployeeDocumentPanel;
