import { axiosInstance } from 'apis/_axios';

// createFolder
export const createFolderApi = (data) => {
  return axiosInstance().post('/api/folder', data);
};

// get employee folder
export const getAllEmployeeFolderApi = () => {
  return axiosInstance().get('/api/get-employee-folders');
};

// get other folders
export const getOtherFolderApi = () => {
  return axiosInstance().get('/api/folder');
};

// get single folder
export const getSingleFolderApi = (id) => {
  return axiosInstance().get(`/api/folder/${id}`);
};

// upload file
export const uploadFileApi = (folderId, data) => {
  return axiosInstance().post(`/api/folder/${folderId}/store-files`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
