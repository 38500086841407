import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PayrollContainer from './payrollSetup/PayrollContainer';
import PUESetup from './pue';
import { getPayrollClosing, getPUE } from './redux/actions';
import useStyles from './styles';

function Setup() {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPayrollClosing());
    dispatch(getPUE());
  }, []);

  return (
    <div className={classes.cardWrapper}>
      <Box margin={5}>
        <Grid container spacing={4}>
          <Grid item sm={6}>
            <PayrollContainer />
          </Grid>
          <Grid item sm={6}>
            <PUESetup />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Setup;
