import BreadCrumb from 'components/globals/breadCrumb';
import MainAreaLayout from 'layouts/dashboardLayout/mainAreaLayout/MainAreaLayout';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import AssignmentTable from './AssignmentTable';
import FilterForm from './filterForm';
import { getAssignment } from './redux/actions';

function AssignmentPage() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  useEffect(() => {
    dispatch(getAssignment());
  }, []);

  return (
    <>
      <BreadCrumb
        itemName={<FormattedMessage id="User Assignments" defaultMessage="User Assignments" />}
        page={<FormattedMessage id="User Assignments" defaultMessage="User Assignments" />}
      />
      <FilterForm name={name} setName={setName} email={email} setEmail={setEmail} />
      <MainAreaLayout>
        <AssignmentTable name={name} email={email} />
      </MainAreaLayout>
    </>
  );
}

export default AssignmentPage;
