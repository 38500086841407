import React from 'react';
import DashboardLayout from 'layouts/dashboardLayout';
import ItemPayroll from 'components/locals/payroll/itemPayroll/ItemPayroll';

const IPayroll = () => {
  return (
    <DashboardLayout>
      <ItemPayroll />
    </DashboardLayout>
  );
};

export default IPayroll;
