import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContractById } from '../../redux/actions';

import useStyles from '../../styles';
import PrintDocument from './PrintDocument';

function PrintMandateContract({ contractId, handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { single_contract } = useSelector((state) => state.contract);

  useEffect(() => {
    contractId && dispatch(getContractById(contractId));
  }, [contractId]);

  const address = single_contract?.employee?.address?.[0];
  const companyAddress = single_contract?.employee?.company?.addresses?.[0];
  const idNumber = single_contract?.employee?.employee_detail?.find(
    (list) => list?.key === 'identification_number'
  )?.value;

  const checkValue = (value) => {
    if (value == 'null' || !value) {
      return '';
    } else return value;
  };

  console.log(companyAddress, 'comapany');
  console.log(single_contract);
  return (
    <>
      <div className={classes.printContractSection}>
        <Box textAlign="center" margin={5}>
          <Typography variant="h5" className={classes.bold}>
            {'UMOWA ZLECENIE' || single_contract?.agreement_type_name}
          </Typography>
        </Box>
        <Typography variant="body1" className={classes.bold}>
          zawarta w dniu {single_contract?.date_of_signing} W {companyAddress?.city} pomiędzy:
        </Typography>
        <Typography variant="body1">
          {single_contract?.employee?.company?.name ?? ''},{' '}
          {single_contract?.employee?.company?.regon ?? ''},
          {single_contract?.employee?.company?.tax_id ?? ''}
        </Typography>
        <Typography variant="body1">
          {checkValue(companyAddress?.street)} {checkValue(companyAddress?.house_number)}{' '}
          {checkValue(companyAddress?.number_of_premises)} {checkValue(companyAddress?.zip)}{' '}
          {checkValue(companyAddress?.post)} {checkValue(companyAddress?.city)}{' '}
          {checkValue(companyAddress?.country)} {checkValue(companyAddress?.voivodeship)}{' '}
          {checkValue(companyAddress?.county)} {checkValue(companyAddress?.community)}{' '}
        </Typography>
        <Typography variant="body1" className={classes.bold}>
          reprezentowanym przez: {single_contract?.representative ?? ''}
        </Typography>
        <Typography className={classes.bold}>zwanym dalej Zleceniodawcą, a:</Typography>
        <Typography variant="body1">
          {single_contract?.employee?.first_name ?? ''}{' '}
          {single_contract?.employee?.second_name ?? ''}
          {single_contract?.employee?.last_name ?? ''}, {single_contract?.employee?.pesel ?? ''}
        </Typography>
        <Typography variant="body1">zamieszkałym</Typography>
        <Typography variant="body1">
          {checkValue(address?.street)} {checkValue(address?.house_number)}{' '}
          {checkValue(address?.number_of_premises)} {checkValue(address?.zip)}{' '}
          {checkValue(address?.post)} {checkValue(address?.city)} {checkValue(address?.country)}{' '}
          {checkValue(address?.voivodeship)} {checkValue(address?.county)}{' '}
          {checkValue(address?.community)}{' '}
        </Typography>
        <Typography>legitymującym się dowodem osobistym seria i nr {idNumber || ''}</Typography>
        <Typography>zwanym dalej Zleceniobiorcą.</Typography>
        <br />
        <br />
        <br />
        <Typography textAlign="center">§ 1</Typography>
        <Typography variant="h5">
          1. Na podstawie niniejszej umowy Zleceniodawca zleca, a Zleceniobiorca zobowiązuje się do
          wykonania następujących czynności:
        </Typography>
        <Typography>{single_contract?.parameters?.terms_of_mandate}</Typography>
        <Typography>zwanym dalej zleceniem.</Typography>
        <Typography>
          2. Zleceniobiorca wykona powierzone prace z dołożeniem należytej staranności.
        </Typography>
        <br />
        <br />
        <Typography textAlign="center">§ 2</Typography>
        <Typography>
          1. Za wykonanie czynności określonych w §1 Zleceniobiorca otrzyma po ich wykonaniu
          wynagrodzenie w wysokości{' '}
          {single_contract?.parameters?.rate == 'monthly' &&
            single_contract?.gross_salary + ' zł brutto/miesięcznie.'}
          {single_contract?.parameters?.rate == 'hourly' &&
            single_contract?.gross_salary + ' zł brutto za godzinę. '}
        </Typography>
        <Typography>
          2. Wynagrodzenie płatne jest na podstawie rachunku przedstawionego przez Zleceniobiorcę.
        </Typography>
        <br />
        <br />
        <Typography textAlign="center">§ 3</Typography>
        <Typography>
          Zleceniobiorca nie może powierzyć prac wymienionych w § 1 innym osobom bez zgody
          Zleceniodawcy.
        </Typography>
        <br />
        <br />
        <Typography textAlign="center">§ 4</Typography>
        <Typography>
          Umowa została zawarta na czas od {single_contract?.start_date} do{' '}
          {single_contract?.end_date}.
        </Typography>
        <br />
        <br />
        <Typography textAlign="center">§ 5</Typography>
        <Typography>
          1. Zleceniobiorca zobowiązany jest do prowadzenia ewidencji ilości godzin wykonywanego
          zlecenia, która dokumentować ma faktyczny czas wykonania przez Zleceniobiorcę zlecenia.
        </Typography>
        <Typography>
          2. Przed przystąpieniem do wykonania zlecenia, Zleceniobiorca poinformuje Zleceniodawcę o
          planowanej ilości godzin niezbędnej do wykonania zlecenia.
        </Typography>
        <br />
        <br />
        <Typography textAlign="center">§ 6</Typography>
        <Typography>
          1. Każda ze Stron może rozwiązać umowę bez podania powodu, z zachowaniem okresu
          wypowiedzenia, który wynosi ……………………………………………………… .
        </Typography>
        <Typography>
          2. Jeśli Zleceniobiorca wypowie umowę bez ważnego powodu ponosi odpowiedzialność za
          wynikłą stąd szkodę.
        </Typography>
        <br />
        <br />
        <Typography textAlign="center">§ 7</Typography>
        <Typography>
          Z tytułu niniejszej umowy Zleceniobiorca nie nabywa żadnych uprawnień pracowniczych.{' '}
        </Typography>
        <br />
        <br />
        <Typography textAlign="center">§ 8</Typography>

        <Typography>Zmiany umowy wymagają formy pisemnej pod rygorem nieważności.</Typography>
        <br />
        <br />
        <Typography textAlign="center">§ 9</Typography>
        <Typography>
          W sprawach nieuregulowanych niniejszą umową mają zastosowanie przepisy Kodeksu cywilnego.
        </Typography>
        <br />
        <br />
        <Typography textAlign="center">§ 10</Typography>
        <Typography>
          Umowę sporządzono w dwóch jednobrzmiących egzemplarzach po jednym dla każdej ze stron.
        </Typography>

        <Box display="flex" justifyContent="space-between" marginTop={14}>
          <div className={classes.signatureWrapper}>
            <div>
              ..............................................................................
            </div>
            <Typography variant="h6" fontWeight={300}>
              (Zleceniodawca)
            </Typography>
          </div>
          <div className={classes.signatureWrapper}>
            <div>
              ..............................................................................
            </div>
            <Typography variant="h6" fontWeight={300}>
              (Zleceniobiorca)
            </Typography>
          </div>
        </Box>
      </div>
      <PrintDocument handleClose={handleClose} />
    </>
  );
}

export default PrintMandateContract;
