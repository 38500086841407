import * as actions from './types';
const initialState = {
  contracts: [],
  loading: false,
  create_loading: false,
  single_contract: null,
  single_loading: false,
  edit_loading: false,
  delete_status_loading: false,
  agreement_types: [],
  agreement_type_loading: false,
  occupation_code: [],
  occupation_loading: false,
  insurance_code: [],
  insurance_loading: false,
  calculated_agreement: [],
  calculated_agreement_loading: false,
  contributions: [],
  contribution_loading: false,
  contribution_update_loading: false,
  agreement_logs: [],
  agreement_log_loading: false,
  tax_rates: [],
  tax_rate_loading: false,
  employee_contract: [],
  employee_contract_loading: false,
  terminateContractStatus: false,
  termination_data_loading: false,
  termination_data: [],
  social_security_loading: false,
  security_limit_loading: false,
  remove_security_limit_loading: false
};

const contractReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CREATE_CONTRACT_BEGIN:
      return { ...state, create_loading: true };

    case actions.CREATE_CONTRACT_SUCCESS:
      return { ...state, create_loading: false };

    case actions.CREATE_CONTRACT_ERROR:
      return { ...state, create_loading: false };

    case actions.GET_CONTRACT_BEGIN:
      return { ...state, loading: true };

    case actions.GET_CONTRACT_SUCCESS:
      return { ...state, contracts: action.payload, loading: false };

    case actions.GET_CONTRACT_ERROR:
      return { ...state, loading: false };

    case actions.GET_TERMINATION_DATA_BEGIN:
      return { ...state, termination_data_loading: true };

    case actions.GET_TERMINATION_DATA_SUCCESS:
      return { ...state, termination_data: action.payload, termination_data_loading: false };

    case actions.GET_TERMINATION_DATA_ERROR:
      return { ...state, termination_data_loading: false };

    case actions.GET_SINGLE_CONTRACT_BEGIN:
      return { ...state, single_loading: true };

    case actions.GET_SINGLE_CONTRACT_SUCCESS:
      return { ...state, single_contract: action.payload, single_loading: false };

    case actions.GET_SINGLE_CONTRACT_ERROR:
      return { ...state, single_loading: false };

    case actions.EDIT_CONTRACT_BEGIN:
      return { ...state, edit_loading: true };

    case actions.EDIT_CONTRACT_ERROR:
    case actions.EDIT_CONTRACT_SUCCESS:
      return { ...state, edit_loading: false };

    case actions.DELETE_CONTRACT_BEGIN:
      return { ...state, delete_status_loading: true };

    case actions.DELETE_CONTRACT_ERROR:
    case actions.DELETE_CONTRACT_SUCCESS:
      return { ...state, delete_status_loading: false };

    case actions.CLEAR_SINGLE_CONTRACT:
      return { ...state, single_contract: null };

    case actions.GET_ALL_AGREEMENT_TYPE_SUCCESS:
      return { ...state, agreement_type_loading: false, agreement_types: action.payload };

    case actions.GET_ALL_AGREEMENT_TYPE_BEGIN:
      return { ...state, agreement_type_loading: true };

    case actions.GET_ALL_AGREEMENT_TYPE_ERROR:
      return { ...state, agreement_type_loading: false };

    case actions.GET_INSURANCE_CODE_SUCCESS:
      return { ...state, insurance_loading: false, insurance_code: action.payload };

    case actions.GET_INSURANCE_CODE_BEGIN:
      return { ...state, insurance_loading: true };

    case actions.GET_INSURANCE_CODE_ERROR:
      return { ...state, insurance_loading: false };

    case actions.GET_OCCUPATION_CODE_SUCCESS:
      return { ...state, occupation_loading: false, occupation_code: action.payload };

    case actions.GET_OCCUPATION_CODE_BEGIN:
      return { ...state, occupation_loading: true };

    case actions.GET_OCCUPATION_CODE_ERROR:
      return { ...state, occupation_loading: false };

    case actions.CALCULATE_AGREEMENT_SUCCESS:
      return {
        ...state,
        calculated_agreement: action.payload,
        calculated_agreement_loading: false
      };

    case actions.CALCULATE_AGREEMENT_BEGIN:
      return {
        ...state,
        calculated_agreement_loading: true,
        calculated_agreement: []
      };

    case actions.CALCULATE_AGREEMENT_ERROR:
      return {
        ...state,
        calculated_agreement_loading: false
      };

    case actions.GET_CONTRIBUTION_BEGIN:
      return { ...state, contribution_loading: true, contributions: [] };

    case actions.GET_CONTRIBUTION_SUCCESS:
      return { ...state, contributions: action.payload, contribution_loading: false };

    case actions.GET_CONTRIBUTION_ERROR:
      return { ...state, contribution_loading: false };

    case actions.UPDATE_CONTRIBUTION_BEGIN:
      return { ...state, contribution_update_loading: true };

    case actions.UPDATE_CONTRIBUTION_SUCCESS:
    case actions.UPDATE_CONTRIBUTION_ERROR:
      return { ...state, contribution_update_loading: false };

    case actions.CLEAR_CALCULATE:
      return { ...state, calculated_agreement: [] };

    case actions.CLEAR_CONTRIBUTION:
      return { ...state, contributions: [] };

    case actions.GET_AGREEMENT_LOG_BEGIN:
      return { ...state, agreement_log_loading: true, agreement_logs: [] };

    case actions.GET_AGREEMENT_LOG_SUCCESS:
      return { ...state, agreement_logs: action.payload, agreement_log_loading: false };

    case actions.GET_AGREEMENT_LOG_ERROR:
      return { ...state, agreement_log_loading: false };

    case actions.GET_TAX_RATE_BEGIN:
      return { ...state, tax_rate_loading: true, tax_rates: [] };

    case actions.GET_TAX_RATE_SUCCESS:
      return { ...state, tax_rates: action.payload, tax_rate_loading: false };

    case actions.GET_TAX_RATE_ERROR:
      return { ...state, tax_rate_loading: false };

    case actions.GET_EMPLOYEE_CONTRACT_BEGIN:
      return { ...state, employee_contract_loading: true, employee_contract: [] };

    case actions.GET_EMPLOYEE_CONTRACT_SUCCESS:
      return { ...state, employee_contract: action.payload, employee_contract_loading: false };

    case actions.GET_EMPLOYEE_CONTRACT_ERROR:
      return { ...state, employee_contract_loading: false };

    case actions.TERMINATE_CONTRACT_BEGIN:
      return { ...state, terminateContractStatus: true };

    case actions.TERMINATE_CONTRACT_SUCCESS:
    case actions.TERMINATE_CONTRACT_ERROR:
      return { ...state, terminateContractStatus: false };

    case actions.UPDATE_SOCIAL_SECURITY_BEGIN:
      return { ...state, social_security_loading: true };

    case actions.UPDATE_SOCIAL_SECURITY_SUCCESS:
    case actions.UPDATE_SOCIAL_SECURITY_ERROR:
      return { ...state, social_security_loading: false };

    case actions.UPDATE_SECURITY_LIMIT_BEGIN:
      return { ...state, security_limit_loading: true };

    case actions.UPDATE_SECURITY_LIMIT_SUCCESS:
    case actions.UPDATE_SECURITY_LIMIT_ERROR:
      return { ...state, security_limit_loading: false };

    case actions.DELETE_SECURITY_LIMIT_BEGIN:
      return { ...state, remove_security_limit_loading: true };

    case actions.DELETE_SECURITY_LIMIT_SUCCESS:
    case actions.DELETE_SECURITY_LIMIT_ERROR:
      return { ...state, remove_security_limit_loading: false };

    default:
      return state;
  }
};

export default contractReducer;
