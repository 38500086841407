import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import HookFormAutoComplete from 'components/form/hookFormAutoComplete';
import { HookFormInput } from 'components/form/hookFormInput';
import { HookFormSelect } from 'components/form/hookFormSelect';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { translate } from 'utils';

function Zus() {
  const { tax_offices, nhf } = useSelector((state) => state.employee);
  return (
    <Grid container spacing={2}>
      <Box display="none">
        <FormattedMessage id="PESEL" defaultMessage="PESEL" />
        <FormattedMessage id="ID Card" defaultMessage="ID Card" />
        <FormattedMessage id="Passport" defaultMessage="Passport" />
        <FormattedMessage id="Yes" defaultMessage="Yes" />
        <FormattedMessage id="No" defaultMessage="No" />
        <FormattedMessage
          id="4 - a person with a disability certificate issued to persons up to 16 years of age"
          defaultMessage="4 - a person with a disability certificate issued to persons up to 16 years of age"
        />
        <FormattedMessage
          id="3 - a person with a certificate of significant disability"
          defaultMessage="3 - a person with a certificate of significant disability"
        />
        <FormattedMessage
          id="2 - a person with a certificate of moderate disability"
          defaultMessage="2 - a person with a certificate of moderate disability"
        />
        <FormattedMessage
          id="1 - a person with a mild disability certificate"
          defaultMessage="1 - a person with a mild disability certificate"
        />
        <FormattedMessage
          id="0 - a person without a certificate of disability"
          defaultMessage="0 - a person without a certificate of disability"
        />
        <FormattedMessage
          id="2 - a person with an established entitlement to a pension"
          defaultMessage="2 - a person with an established entitlement to a pension"
        />
        <FormattedMessage
          id="1 - a person with an established right to an old-age pension"
          defaultMessage="1 - a person with an established right to an old-age pension"
        />
        <FormattedMessage
          id="0 - a person with no established entitlement to an old-age or disability pension"
          defaultMessage="0 - a person with no established entitlement to an old-age or disability pension"
        />
      </Box>
      <Grid item sm={6}>
        <HookFormAutoComplete
          name="employee_detail.identifier_for_zus"
          label={<FormattedMessage id="Identifier for ZUS" defaultMessage="Identifier for ZUS" />}
          options={[
            { label: '', value: '' },
            { label: translate('PESEL'), value: 'PESEL' },
            { label: translate('ID Card'), value: 'ID Card' },
            { label: translate('Passport'), value: 'Passport' }
          ]}
        />
      </Grid>
      <Grid item sm={6}>
        <HookFormAutoComplete
          name="employee_detail.tax_id_zus"
          label={<FormattedMessage id="Tax Id" defaultMessage="Tax Id" />}
          options={[
            { label: '', value: '' },
            { label: translate('PESEL'), value: 'PESEL' },
            { label: translate('ID Card'), value: 'ID Card' },
            { label: translate('Passport'), value: 'Passport' }
          ]}
        />
      </Grid>
      <Grid item sm={6}>
        <HookFormAutoComplete
          name="employee_detail.national_health_fund"
          label={
            <FormattedMessage
              id="Department of the National Health Fund"
              defaultMessage="Department of the National Health Fund"
            />
          }
          options={nhf?.map((item) => ({ label: item?.title, value: item?.id }))}
        />
      </Grid>

      <Grid item sm={6}>
        <HookFormAutoComplete
          name="employee_detail.polish_tax_residence"
          label={
            <FormattedMessage id="Polish Tax Residence" defaultMessage="Polish Tax Residence" />
          }
          options={[
            { label: '', value: '' },
            { label: translate('Yes'), value: 'yes' },
            { label: translate('No'), value: 'no' }
          ]}
        />
      </Grid>
      <Grid item sm={12}>
        <HookFormAutoComplete
          name="employee_detail.tax_office"
          label={<FormattedMessage id="Tax Office" defaultMessage="Tax Office" />}
          options={tax_offices?.map((item) => ({ label: item?.office, value: item?.id }))}
        />
      </Grid>
      <Grid item sm={12}>
        <HookFormSelect
          name="employee_detail.pension_entitlements"
          label={
            <FormattedMessage id="Pension Entitlements" defaultMessage="Pension Entitlements" />
          }
          options={[
            {
              label: translate(
                '0 - a person with no established entitlement to an old-age or disability pension'
              ),
              value:
                '0 - a person with no established entitlement to an old-age or disability pension'
            },
            {
              label: translate('1 - a person with an established right to an old-age pension'),
              value: '1 - a person with an established right to an old-age pension'
            },
            {
              label: translate('2 - a person with an established entitlement to a pension'),
              value: '2 - a person with an established entitlement to a pension'
            }
          ]}
        />
      </Grid>
      <Grid item sm={12}>
        <HookFormAutoComplete
          name="employee_detail.disability_level"
          label={<FormattedMessage id="Disability Level" defaultMessage="Disability Level" />}
          options={[
            {
              label: translate('0 - a person without a certificate of disability'),
              value: '0 - a person without a certificate of disability'
            },
            {
              label: translate('1 - a person with a mild disability certificate'),
              value: '1 - a person with a mild disability certificate'
            },
            {
              label: translate('2 - a person with a certificate of moderate disability'),
              value: '2 - a person with a certificate of moderate disability'
            },
            {
              label: translate('3 - a person with a certificate of significant disability'),
              value: '3 - a person with a certificate of significant disability'
            },
            {
              label: translate(
                '4 - a person with a disability certificate issued to persons up to 16 years of age'
              ),
              value:
                '4 - a person with a disability certificate issued to persons up to 16 years of age'
            }
          ]}
        />
      </Grid>
      <Grid item sm={4}>
        <HookFormInput
          type="date"
          name="employee_detail[date_of_issuance]"
          label={
            <FormattedMessage id="Date from of issuance" defaultMessage="Date from of issuance" />
          }
          placeholder="Simmons"
        />
      </Grid>
      <Grid item sm={4}>
        <HookFormInput
          type="date"
          name="employee_detail[expiration_date]"
          label={<FormattedMessage id="Expiration Date" defaultMessage="Expiration Date" />}
          placeholder="Simmons"
        />
      </Grid>
      <Grid item sm={4}>
        <HookFormInput
          name="employee_detail[issued_by]"
          label={<FormattedMessage id="Issued by" defaultMessage="Issued by" />}
          placeholder="Simmons"
        />
      </Grid>
    </Grid>
  );
}

export default Zus;
