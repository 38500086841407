import { Grid } from '@mui/material';
import { HookFormInput } from 'components/form/hookFormInput';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

const BusinessAddressForm = ({ index, type }) => {
  const { register } = useFormContext();

  return (
    <>
      <Grid container spacing={3}>
        <input type="hidden" {...register(`addresses[${index}][type]`)} defaultValue={type} />

        <Grid item sm={3}>
          <HookFormInput
            name={`addresses[${index}].country`}
            required={true}
            label={<FormattedMessage id="Country" defaultMessage="Country" />}
            placeholder="poland"
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            required={true}
            name={`addresses[${index}].street`}
            label={<FormattedMessage id="Street" defaultMessage="Street" />}
            placeholder="warsaw"
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            name={`addresses[${index}].house_number`}
            label={<FormattedMessage id="House Number" defaultMessage="House Number" />}
            placeholder="33F"
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            name={`addresses[${index}].number_of_premises`}
            label={<FormattedMessage id="No. of premises" defaultMessage="No. of premises" />}
            placeholder="5"
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            required={true}
            name={`addresses[${index}].zip`}
            label={<FormattedMessage id="Zip Code" defaultMessage="Zip Code" />}
            placeholder="92505"
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            required={true}
            name={`addresses[${index}].post`}
            label={<FormattedMessage id="Post" defaultMessage="Post" />}
            placeholder="595959"
          />
        </Grid>

        <Grid item sm={3}>
          <HookFormInput
            required={true}
            name={`addresses[${index}].community`}
            label={<FormattedMessage id="Town" defaultMessage="Town" />}
            placeholder="Bagbazar"
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            required={true}
            name={`addresses[${index}].county`}
            label={<FormattedMessage id="District" defaultMessage="District" />}
            placeholder="abc"
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            required={true}
            name={`addresses[${index}].voivodeship`}
            label={<FormattedMessage id="Volvodeship" defaultMessage="Volvodeship" />}
            placeholder="xyz"
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            name={`addresses[${index}].city`}
            label={<FormattedMessage id="Commune" defaultMessage="Commune" />}
            placeholder="Sandomierz"
          />
        </Grid>
      </Grid>
    </>
  );
};
export default BusinessAddressForm;
