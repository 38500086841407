import Statement from '../../common/statement';

const StatementContainer = ({ contractId }) => {
  return (
    <>
      <Statement contractId={contractId} />
    </>
  );
};

export default StatementContainer;
