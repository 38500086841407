import { Button } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import AllowanceTable from './AllowanceTable';
import { Box } from '@mui/system';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import AddAllowance from './AddAllowance';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

function Allowance({ employeeId }) {
  const [modalOpen, openFunction] = useToggle(false);
  const [allowanceLength, setAllowanceLength] = useState(false);
  const { watch, setValue } = useFormContext();
  const watchAllowance = watch('allowance_deductions');

  const cancelAdd = () => {
    const filtered = watchAllowance?.filter((list, index) => index != allowanceLength);
    setValue('allowance_deductions', filtered);
    openFunction();
  };

  const handleOpenFunction = () => {
    openFunction();
    setAllowanceLength(watchAllowance?.length || 0);
  };

  return (
    <>
      <Box marginBottom={2}>
        <Button variant="contained" onClick={handleOpenFunction}>
          <AddIcon />
          {<FormattedMessage id="Add" defaultMessage="Add" />}
        </Button>
      </Box>
      <AllowanceTable employeeId={employeeId} />
      <CustomModal
        open={modalOpen}
        handleClose={cancelAdd}
        height="auto"
        headerText={
          <FormattedMessage
            id="Add Allowances and Deductions"
            defaultMessage="Add Allowances and Deductions"
          />
        }>
        <AddAllowance
          handleClose={openFunction}
          employeeId={employeeId}
          cancelAdd={cancelAdd}
          allowanceLength={allowanceLength}
        />
      </CustomModal>
    </>
  );
}

export default Allowance;
