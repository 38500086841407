import * as actions from './types';
import { errorToast, successToast } from 'utils/toast';
import {
  createTimeRecordingApi,
  deleteTimeRecordingApi,
  editTimeRecordingApi,
  getHolidayAddonsApi,
  getHolidayBasisApi,
  getTimeRecordingApi,
  updateHolidayBasisApi
} from 'apis/timerecording';

// create TimeRecording
export const createTimeRecording = (data, handleFunction, handleRefetch) => (dispatch) => {
  dispatch({
    type: actions.CREATE_TIME_RECORDING_BEGIN
  });
  createTimeRecordingApi(data)
    .then((res) => {
      dispatch({
        type: actions.CREATE_TIME_RECORDING_SUCCESS
      });
      handleFunction && handleFunction();
      handleRefetch && handleRefetch();
      dispatch(getTimeRecording(data?.employee_id));
      successToast('TimeRecording created Successfully');
    })
    .catch((error) => {
      dispatch({
        type: actions.CREATE_TIME_RECORDING_ERROR
      });
      errorToast(error);
    });
};

// get all timeRecording
export const getTimeRecording = (employee_id) => (dispatch) => {
  dispatch({
    type: actions.GET_TIME_RECORDING_BEGIN
  });
  getTimeRecordingApi(employee_id)
    .then((res) => {
      dispatch({
        type: actions.GET_TIME_RECORDING_SUCCESS,
        payload: res.data.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_TIME_RECORDING_ERROR
      });
      errorToast(error);
    });
};

// edit timeRecording
export const editTimeRecording =
  (id, data, employeeId, handleFunction, handleRefetch) => (dispatch) => {
    dispatch({
      type: actions.EDIT_TIME_RECORDING_BEGIN
    });
    editTimeRecordingApi(id, data)
      .then((res) => {
        dispatch({
          type: actions.EDIT_TIME_RECORDING_SUCCESS
        });
        handleFunction && handleFunction();
        handleRefetch && handleRefetch();
        successToast('TimeRecording edited Successfully');
        dispatch(getTimeRecording(employeeId));
      })
      .catch((error) => {
        dispatch({
          type: actions.EDIT_TIME_RECORDING_ERROR
        });
        errorToast(error);
      });
  };

// delete timeRecording
export const deleteTimeRecording = (id, employeeId, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.DELETE_TIME_RECORDING_BEGIN
  });
  deleteTimeRecordingApi(id)
    .then((res) => {
      dispatch({
        type: actions.DELETE_TIME_RECORDING_SUCCESS
      });
      handleFunction && handleFunction();
      successToast('TimeRecording deleteed Successfully');
      dispatch(getTimeRecording(employeeId));
    })
    .catch((error) => {
      dispatch({
        type: actions.DELETE_TIME_RECORDING_ERROR
      });
      errorToast(error);
    });
};

// get all holiday basis
export const getHolidayBasis = (data) => (dispatch) => {
  dispatch({
    type: actions.GET_HOLIDAY_BASIS_BEGIN
  });
  getHolidayBasisApi(data)
    .then((res) => {
      dispatch({
        type: actions.GET_HOLIDAY_BASIS_SUCCESS,
        payload: res.data.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_HOLIDAY_BASIS_ERROR
      });
      errorToast(error);
    });
};

// update holiday basis
export const updateHolidayBase = (data, date, handleSuccess) => (dispatch) => {
  dispatch({
    type: actions.UPDATE_HOLIDAY_BASIS_BEGIN
  });
  updateHolidayBasisApi(data)
    .then((res) => {
      dispatch({
        type: actions.UPDATE_HOLIDAY_BASIS_SUCCESS
      });
      handleSuccess && handleSuccess();
      dispatch(
        getHolidayBasis({
          employee_id: data?.employee_id,
          type: data?.type,
          date: date
        })
      );
      successToast('Updated Successfully');
    })
    .catch((error) => {
      dispatch({
        type: actions.UPDATE_HOLIDAY_BASIS_ERROR
      });
      errorToast(error);
    });
};

// get holiday addons
export const getHolidayAddons = () => (dispatch) => {
  dispatch({
    type: actions.GET_HOLIDAY_ADDON_BEGIN
  });
  getHolidayAddonsApi()
    .then((res) => {
      dispatch({
        type: actions.GET_HOLIDAY_ADDON_SUCCESS,
        payload: res.data.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_HOLIDAY_ADDON_ERROR
      });
      errorToast(error);
    });
};
