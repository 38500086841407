import * as actions from './types';
const initialState = {
  departments: [],
  loading: false,
  create_loading: false,
  single_department: null,
  single_loading: false,
  edit_loading: false,
  change_status_loading: false
};

const departmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CREATE_DEPARTMENT_BEGIN:
      return { ...state, create_loading: true };

    case actions.CREATE_DEPARTMENT_SUCCESS:
      return { ...state, create_loading: false };

    case actions.CREATE_DEPARTMENT_ERROR:
      return { ...state, create_loading: false };

    case actions.GET_DEPARTMENT_BEGIN:
      return { ...state, loading: true };

    case actions.GET_DEPARTMENT_SUCCESS:
      return { ...state, departments: action.payload, loading: false };

    case actions.GET_DEPARTMENT_ERROR:
      return { ...state, loading: false };

    case actions.GET_SINGLE_DEPARTMENT_BEGIN:
      return { ...state, single_loading: true };

    case actions.GET_SINGLE_DEPARTMENT_SUCCESS:
      return { ...state, single_department: action.payload, single_loading: false };

    case actions.GET_SINGLE_DEPARTMENT_ERROR:
      return { ...state, single_loading: false };

    case actions.EDIT_DEPARTMENT_BEGIN:
      return { ...state, edit_loading: true };

    case actions.EDIT_DEPARTMENT_ERROR:
    case actions.EDIT_DEPARTMENT_SUCCESS:
      return { ...state, edit_loading: false };

    case actions.CHANGE_DEPARTMENT_BEGIN:
      return { ...state, change_status_loading: true };

    case actions.CHANGE_DEPARTMENT_ERROR:
    case actions.CHANGE_DEPARTMENT_SUCCESS:
      return { ...state, change_status_loading: false };

    case actions.CLEAR_SINGLE_DEPARTMENT:
      return { ...state, single_department: null };

    default:
      return state;
  }
};

export default departmentReducer;
