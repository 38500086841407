import * as React from 'react';
import Paper from '@mui/material/Paper';
import useStyles from './styles';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import CustomTable from 'components/globals/table';
import { Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewAllowance from './ViewAllowance';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import CancelModal from 'components/globals/modal/CancelModal';
import DeleteIcon from '@mui/icons-material/Delete';
import { translate } from 'utils';

export default function AllowanceTable({ employeeId }) {
  const columns = [
    { title: 'S.N.', type: 'Index', isSortable: true, minWidth: 20 },
    {
      title: <FormattedMessage id="Name" defaultMessage="Name" />,
      minWidth: 100,
      isSortable: true,
      field: 'name'
    },

    {
      field: 'value',
      title: <FormattedMessage id="Value" defaultMessage="Value" />,
      minWidth: 170,
      align: 'right'
    },
    {
      field: 'type',
      title: <FormattedMessage id="Type" defaultMessage="Type" />,
      minWidth: 120,
      align: 'right'
    },

    {
      field: (row) => {
        return <>{row?.one_time ? 'One Time' : 'Recurrence'}</>;
      },
      title: <FormattedMessage id="Data type" defaultMessage="Data Type" />,
      minWidth: 120,
      align: 'right'
    },
    {
      field: (row) => {
        return (
          <>
            {row?.one_time ? (
              <Box>
                <Typography>
                  {<FormattedMessage id="Year" defaultMessage="Year" />}: {row?.year}
                </Typography>
                <Typography>
                  {<FormattedMessage id="Month" defaultMessage="Month" />}: {row?.month}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Typography>
                  {<FormattedMessage id="Start Date" defaultMessage="Start Date" />}:{' '}
                  {row?.start_date}
                </Typography>
                <Typography>
                  {<FormattedMessage id="End Date" defaultMessage="End Date" />}: {row?.end_date}
                </Typography>
              </Box>
            )}
          </>
        );
      },
      title: <FormattedMessage id="Data Type" defaultMessage="Data Type" />,
      minWidth: 120,
      align: 'right'
    },
    {
      field: (row, field, index) => {
        return <Actions row={row} employeeId={employeeId} index={index} />;
      },
      title: <FormattedMessage id="Actions" defaultMessage="Actions" />,
      minWidth: 170,
      align: 'center'
    }
  ];

  const { watch } = useFormContext();
  const rows = watch('allowance_deductions') || [];

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <CustomTable tableData={rows} tableHeads={columns} />
    </Paper>
  );
}

const Actions = ({ row, index, employeeId }) => {
  const classes = useStyles();
  const [viewOpen, openViewFunction] = useToggle(false);
  const [deleteOpen, openDeleteFunction] = useToggle(false);
  const { watch, setValue } = useFormContext();
  const allowanceWatch = watch('allowance_deductions');

  const handleView = () => {
    openViewFunction();
  };
  const handleDelete = () => {
    const filteredData = allowanceWatch?.filter((item, ind) => ind != index);
    setValue('allowance_deductions', filteredData);

    openDeleteFunction();
  };

  return (
    <>
      <div className={classes.iconWrapper}>
        <Box display="none">
          <FormattedMessage
            id="View Allowances and Deduction"
            defaultMessage="View Allowances and Deduction"
          />
        </Box>
        <Tooltip title="View">
          <VisibilityIcon onClick={handleView} />
        </Tooltip>
        <Tooltip title="Delete">
          <DeleteIcon onClick={openDeleteFunction} />
        </Tooltip>
      </div>
      <CustomModal
        open={viewOpen}
        handleClose={openViewFunction}
        height="auto"
        headerText={`${translate('View Allowances and Deduction')} - ${row?.name}`}>
        <ViewAllowance row={row} employeeId={employeeId} />
      </CustomModal>
      <CancelModal
        handleClose={handleDelete}
        cancelFunction={openDeleteFunction}
        cancelOpen={deleteOpen}
      />
    </>
  );
};
