import { Box } from '@mui/material';
import CalendarPage from 'components/locals/calendar';
import BreadCrumb from 'components/UI/breadCrumb';
import useToggle from 'hooks/useToggle';
import DashboardLayout from 'layouts/dashboardLayout';
import { FormattedMessage } from 'react-intl';

function Calendar() {
  const [modalOpen, openFunction] = useToggle(false);

  return (
    <DashboardLayout>
      <Box padding={4}>
        <BreadCrumb
          itemName={<FormattedMessage id="Calendar" defaultMessage="Calendar" />}
          page={<FormattedMessage id="Calendar" defaultMessage="Calendar" />}
          handleButton={openFunction}
          buttonTitle={<FormattedMessage id="Add Bank Holiday" defaultMessage="Add Bank Holiday" />}
        />
        <CalendarPage openFunction={openFunction} modalOpen={modalOpen} />
      </Box>
    </DashboardLayout>
  );
}

export default Calendar;
