import * as actions from './types';
import { errorToast, successToast } from 'utils/toast';
import {
  createFolderApi,
  getAllEmployeeFolderApi,
  getOtherFolderApi,
  getSingleFolderApi,
  uploadFileApi
} from 'apis/document';

// create folder
export const createFolder = (data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.CREATE_FOLDER_BEGIN
  });
  createFolderApi(data)
    .then((res) => {
      dispatch({
        type: actions.CREATE_FOLDER_SUCCESS
      });
      handleFunction && handleFunction();
      successToast('Folder created Successfully');
      dispatch(getAllEmployeeFolder());
      dispatch(getOtherFolder());
      data?.parent_id && dispatch(getSingleFolder(data?.parent_id));
    })
    .catch((error) => {
      dispatch({
        type: actions.CREATE_FOLDER_ERROR
      });
      errorToast(error);
    });
};

// get all employee folder
export const getAllEmployeeFolder = () => (dispatch) => {
  dispatch({
    type: actions.GET_ALL_EMPLOYEE_FOLDER_BEGIN
  });
  getAllEmployeeFolderApi()
    .then((res) => {
      dispatch({
        type: actions.GET_ALL_EMPLOYEE_FOLDER_SUCCESS,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_ALL_EMPLOYEE_FOLDER_ERROR
      });
      errorToast(error);
    });
};

// get all other folder
export const getOtherFolder = () => (dispatch) => {
  dispatch({
    type: actions.GET_OTHER_FOLDER_BEGIN
  });
  getOtherFolderApi()
    .then((res) => {
      dispatch({
        type: actions.GET_OTHER_FOLDER_SUCCESS,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_OTHER_FOLDER_ERROR
      });
      errorToast(error);
    });
};

// get single folder
export const getSingleFolder = (id) => (dispatch) => {
  dispatch({
    type: actions.GET_SINGLE_FOLDER_BEGIN
  });
  getSingleFolderApi(id)
    .then((res) => {
      dispatch({
        type: actions.GET_SINGLE_FOLDER_SUCCESS,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_SINGLE_FOLDER_ERROR
      });
      errorToast(error);
    });
};

// upload files
export const uploadFile = (folderId, data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.UPLOAD_FILE_BEGIN
  });
  uploadFileApi(folderId, data)
    .then((res) => {
      dispatch({
        type: actions.UPLOAD_FILE_SUCCESS
      });
      dispatch(getSingleFolder(folderId));
      handleFunction && handleFunction();
      successToast('File Uploaded Successfully');
    })
    .catch((error) => {
      dispatch({
        type: actions.UPLOAD_FILE_ERROR
      });
      errorToast(error);
    });
};
