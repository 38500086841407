import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  modalFooter: {
    width: '100%',
    marginTop: 'auto',
    marginLeft: 'auto',
    background: '#fff',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem 3rem',
    height: '4.9rem'
  },
  modalFooterButtons: {
    fontFamily: 'Euclid',
    ':&>*': {
      margin: '0 1rem'
    }
  },
  modalFooterCancel: {
    '&:hover': {
      background: '#ddd'
    }
  },
  iconWrapper: {
    '& svg': {
      marginRight: '1.5rem',
      color: theme.palette.grey[600],
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary
      }
    }
  },
  title: {
    color: theme.palette.grey[700],
    width: '45%'
  },
  value: {
    color: theme.palette.primary.dark,
    fontWeight: 600,
    letterSpacing: '0.8px'
  },
  viewRoot: {
    margin: '30px 15px',
    fontSize: '12px',
    '& ul': {
      margin: '0',
      padding: '0',
      marginLeft: '1.6rem',
      listStyle: 'none'
    },
    '& li': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingBottom: '1.2rem'
    }
  },
  employeeSearch: {
    '& .MuiOutlinedInput-root': {
      padding: '0.6rem',
      paddingLeft: '2rem',
      background: theme.palette.grey[100]
    },
    '& input': {
      padding: '0 !important'
    }
  },
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.8rem 4.5rem',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  },
  optionWrap: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  hyphen: {
    width: '2rem',
    fontSize: '1.6rem',
    fontWeight: 'lighter',
    textAlign: 'center'
  },
  select: {
    paddingLeft: '2rem',
    marginRight: '2rem',
    border: '0',
    borderLeft: `1px solid ${theme.palette.grey[400]}`,
    width: '18rem'
  },
  dateWrapper: {
    fontSize: '1.6rem',
    display: 'flex',
    alignItems: 'center',
    marginRight: '2rem',
    '& .MuiInputBase-root': {
      background: theme.palette.grey[100],
      borderRadius: '3px',
      padding: '0px 10px 0px 0px'
    },
    '& input': {
      padding: '0.6rem 0.8rem !important',
      paddingRight: '0 !important',
      width: '7rem',
      fontSize: '1.2rem'
    },
    '& .MuiInputAdornment-root': {
      marginLeft: '0'
    }
  }
}));

export default useStyles;
