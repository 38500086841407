import { Box, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { HookFormTextarea } from 'components/form/hookFormTextarea';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import TerminationContainer from '../TerminationContainer';
import { calculateAgreement } from '../redux/actions';
import useStyles from '../styles';
import AllowanceContainer from './components/AllowanceContainer';
import BasicDetail from './components/BasicDetail';
import BasicView from './components/BasicView';
import IncomeTax from './components/DetailedInfo';
import Statement from './components/StatementContainer';
import Zus from './components/Zus';
// import HookFormAutoComplete from 'components/form/hookFormAutoComplete';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function MandateContractForm({ contractSingleId, contractId, employeeId, edit = false }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setVal] = React.useState(0);
  const { watch } = useFormContext();

  const handleChange = (event, newValue) => {
    console.log('tab value', newValue);
    setVal(newValue);
  };

  const allValue = watch();
  const parameters = watch('parameters');

  useEffect(() => {
    const calculateSalary = setTimeout(() => {
      if (allValue?.gross_salary > 0) {
        dispatch(
          calculateAgreement({
            ...allValue,
            employee_id: employeeId,
            agreement_type_id: contractId
          })
        );
      }
    }, 600);

    return () => clearTimeout(calculateSalary);
  }, [
    parameters?.cost_of_getting_income,
    parameters?.labor_fund_insurance,
    parameters?.fgsp_insurance,
    parameters?.pension_insurance,
    parameters?.disability_insurance,
    parameters?.accident_insurance,
    parameters?.sickness_insurance,
    parameters?.nfz_contribution,
    parameters?.pit_exemption,
    parameters?.tax_rate,
    parameters?.tax_relief,
    parameters.shared_taxation,
    parameters.rate,
    employeeId
  ]);

  useEffect(() => {
    const calculateSalary = setTimeout(() => {
      if (allValue?.gross_salary > 0) {
        dispatch(calculateAgreement({ ...allValue, agreement_type_id: contractId }));
      }
    }, 600);

    return () => clearTimeout(calculateSalary);
  }, [allValue?.gross_salary]);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className={classes.rootTab}>
          <Tab
            label={<FormattedMessage id="Basic Information" defaultMessage="Basic Information" />}
            {...a11yProps(0)}
          />
          <Tab
            label={<FormattedMessage id="Income Tax Info" defaultMessage="Income Tax Info" />}
            {...a11yProps(1)}
          />
          <Tab label={<FormattedMessage id="ZUS" defaultMessage="ZUS" />} {...a11yProps(2)} />
          <Tab
            label={
              <FormattedMessage
                id="Other Terms of Mandate"
                defaultMessage="Other Terms of Mandate"
              />
            }
            {...a11yProps(3)}
          />
          {!edit && (
            <Tab
              label={
                <FormattedMessage
                  id="Allowance and Deduction"
                  defaultMessage="Allowance and Deduction"
                />
              }
              {...a11yProps(4)}
            />
          )}

          {edit && (
            <Tab
              label={<FormattedMessage id="Statement" defaultMessage="Statement" />}
              {...a11yProps(4)}
            />
          )}
          {edit && (
            <Tab
              label={<FormattedMessage id="Termination" defaultMessage="Termination" />}
              {...a11yProps(5)}
            />
          )}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className={classes.contentWrap}>
          <BasicDetail contractTypeId={contractId} />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className={classes.contentWrap}>
          <IncomeTax />
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className={classes.contentWrap}>
          <Zus employeeId={employeeId} contractTypeId={contractId} />
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div className={classes.contentWrap}>
          <HookFormTextarea name="parameters.terms_of_mandate" rows={4} />
        </div>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <div className={classes.contentWrap}>
          <TerminationContainer />
        </div>
      </TabPanel>
      {!edit && (
        <TabPanel value={value} index={4}>
          <div className={classes.contentWrap}>
            <AllowanceContainer />
          </div>
        </TabPanel>
      )}
      {edit && (
        <TabPanel value={value} index={4}>
          <div className={classes.contentWrap}>
            <Statement contractId={contractSingleId} />
          </div>
        </TabPanel>
      )}
      <div className={classes.contentWrap}>
        <BasicView />
      </div>
    </>
  );
}

export default MandateContractForm;
