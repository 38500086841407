import { Grid } from '@mui/material';
import React from 'react';
import { translate } from 'utils';
import useStyles from './styles';

function ViewAllowance({ row }) {
  const classes = useStyles();
  // "start_date":"2022-08-20",
  //   "end_date":"2022-08-30",
  //   "is_taxable":false,
  //   "include_socialsecurity_base":false,
  //   "include_sick_base":false,
  //   "include_vacation_base":false,
  //   "year":2022,
  //   "month":8
  const capitalize = (item) => {
    const data = item?.split(' ');
    const changedData = data?.map((list) => list[0].toUpperCase() + list.slice(1, list.length));
    const joinedData = changedData.join(' ');
    return translate(joinedData);
  };
  return (
    <div className={classes.viewRoot}>
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <ul>
            <ViewList label="Name" value={row?.name} />
            <ViewList label="Contract" value={'Employee Contract - ' + row?.agreement_id} />
            <ViewList label="Value" value={row?.value} />
            <ViewList label="Type" value={capitalize(row?.type)} />
            <ViewList label="One Time" value={row?.one_time ? translate('Yes') : translate('No')} />
            {row?.one_time ? (
              <>
                <ViewList label="One Time" value={row?.one_time} />
                <ViewList label="One Time" value={row?.one_time} />
              </>
            ) : (
              <>
                <ViewList label="Start Date" value={row?.start_date} />
                <ViewList label="End Date" value={row?.end_date} />
              </>
            )}
          </ul>
        </Grid>
        <Grid item sm={6}>
          <ul>
            <ViewList
              label="Is taxable"
              value={row?.is_taxable ? translate('Yes') : translate('No')}
            />
            <ViewList
              label="Include social security base"
              value={row?.include_socialsecurity_base ? translate('Yes') : translate('No')}
            />
            <ViewList
              label="Include sick base"
              value={row?.include_sick_base ? translate('Yes') : translate('No')}
            />
            <ViewList
              label="Include vacation base"
              value={row?.include_vacation_base ? translate('Yes') : translate('No')}
            />
          </ul>
        </Grid>
      </Grid>
    </div>
  );
}

export default ViewAllowance;

const ViewList = ({ label, value }) => {
  const classes = useStyles();
  return (
    <li>
      <div className={classes.title}>{label}</div>
      <div className={classes.value}>{value}</div>
    </li>
  );
};
