import { makeStyles } from '@mui/styles';

const usestyles = makeStyles((theme) => ({
  sidebarHeader: {
    marginTop: '1.7rem',
    marginLeft: '2.6rem',
    color: 'red',
    height: '2.9rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  logo: {
    cursor: 'pointer'
  },
  listItems: {
    marginTop: '4rem'
  },
  listItem: {
    height: '3.6rem',
    padding: '0.5rem 0 0.5rem 2.3rem',
    display: 'flex',
    margin: '6px 0 ',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.blue,
      color: '#fff'
    }
  },
  listItemActive: {
    backgroundColor: theme.palette.blue,
    color: '#fff'
  },

  listItemIcons: {
    fontSize: '2rem !important'
  },
  listItemText: {
    marginLeft: '1rem !important',
    fontSize: '14px !important',
    fontWeight: '600 !important',
    lineHeight: '130% !important',
    fontFamily: 'Euclid !important',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    '& svg': {
      marginRight: '1rem'
    }
  },
  logoutButton: {
    display: 'flex',
    margin: '0 1.3rem 10rem 1.3rem',
    height: '3.6rem',
    alignItems: 'center',
    background: '#E5E5EB',
    padding: '0.9rem 1.2rem',
    cursor: 'pointer'
  },

  switchButton: {
    display: 'flex',
    margin: '0 1.3rem 1rem 1.3rem',
    height: '3.6rem',
    alignItems: 'center',
    background: '#E5E5EB',
    padding: '0.9rem 1.2rem',
    cursor: 'pointer',
    '& a': {
      fontSize: '13px',
      textDecoration: 'none',
      marginLeft: '10px',
      fontWeight: '500',
      color: '#202021'
    }
  },
  buttonAdmin: {
    margin: 'auto 0 0rem 0'
  },
  companyButton: {
    display: 'flex',
    height: '3.6rem',
    margin: 'auto 1.3rem 1rem 1.3rem',
    alignItems: 'center',
    background: '#E5E5EB',
    padding: '0.9rem 1.2rem',
    cursor: 'pointer',
    '& a': {
      fontSize: '13px',
      textDecoration: 'none',
      marginLeft: '10px',
      fontWeight: '500',
      color: '#202021'
    }
  },
  logoWrapper: {
    height: '46px',
    width: '46px',
    overflow: 'hidden',
    borderRadius: '5px',
    '& img': {
      height: 'inherit',
      width: 'inherit',
      objectFit: 'cover'
    }
  },
  companyName: {
    fontWeight: '600',
    fontSize: '14px',
    flex: 1,
    marginLeft: '10px',
    color: '#000'
  }
}));

export default usestyles;
