import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearEzla } from '../setup/redux/actions';
import EzlaForm from './EzlaForm';
import JsonViewer from './JsonViewer';

const Ezla = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearEzla());
  }, []);
  return (
    <Box margin={6}>
      <EzlaForm />
      <JsonViewer />
    </Box>
  );
};

export default Ezla;
