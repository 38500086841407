import { axiosInstance } from 'apis/_axios';

// create allowance
export const createAllowanceApi = (data) => {
  return axiosInstance().post('/actions/allowance-deduction', data);
};

// get all allowance
export const getAllowanceApi = (employee_id, contractId) => {
  const agreement_type = contractId ? `&agreement_type=${contractId}` : '';
  return axiosInstance().get(
    `/actions/allowance-deduction?employee_id=${employee_id}${agreement_type}`
  );
};

// get allowance by id
export const getAllowanceByIdApi = (id) => {
  return axiosInstance().get(`/actions//allowance/${id}`);
};

// edit allowance
export const editAllowanceApi = (id, data) => {
  return axiosInstance().put(`/actions/allowance-deduction/${id}`, data);
};

// delete allowance
export const deleteAllowanceApi = (id) => {
  return axiosInstance().delete(`/actions/allowance-deduction/${id}`);
};
