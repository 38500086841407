import { axiosInstance } from 'apis/_axios';

export const fetchCalendarApi = (year) => {
  return axiosInstance().get(`/yearly-closing-period?year=${year}`);
};

export const createHolidayApi = (data) => {
  return axiosInstance().post('/closing-period', data);
};

export const deleteHolidayApi = (data) => {
  return axiosInstance().post(`/closing-period/delete`, data);
};
