import BreadCrumb from 'components/globals/breadCrumb';
import MainAreaLayout from 'layouts/dashboardLayout/mainAreaLayout/MainAreaLayout';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import ParameterTable from './ParameterTable';
import { getAdminParameters, getParameter } from './redux/actions';

function Parameter() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getParameter());
    dispatch(getAdminParameters());
  }, []);

  return (
    <>
      <BreadCrumb
        itemName={<FormattedMessage id="Parameter" defaultMessage="Parameter" />}
        page={<FormattedMessage id="Parameter" defaultMessage="Parameter" />}
      />
      <MainAreaLayout>
        <ParameterTable />
      </MainAreaLayout>
    </>
  );
}

export default Parameter;
