import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '3rem'
  },
  header: {
    '& h1,h2,h3': {
      fontWeight: 400,
      marginBottom: '0',
      marginTop: '0',
      textAlign: 'center'
    },
    '& h1': {
      fontWeight: '600'
    },
    '& ul': {
      padding: 0,
      marginTop: '2rem'
    },
    '& li': {
      display: 'flex',
      justifyContent: 'flex-start'
    }
  },
  title: {
    width: '15rem',
    paddingLeft: '1rem',
    fontSize: '12px'
  },
  value: {
    fontSize: '12px'
  },
  payslipMain: {
    marginTop: '2rem'
  },
  workingSettlement: {
    marginTop: '2rem'
  },
  employerCost: {
    marginTop: '2rem'
  },
  table: {
    width: '100%',
    fontSize: '12px',
    borderCollapse: 'collapse',
    border: '1px solid gray',
    textAlign: 'center',
    '& td': {
      border: '1px solid gray',
      borderCollapse: 'collapse'
    },
    '& .left': {
      textAlign: 'left',
      paddingLeft: '5rem'
    },
    '& .right': {
      textAlign: 'right',
      paddingRight: '2rem',
      fontWeight: '600'
    }
  },
  modalFooter: {
    width: '100%',
    marginTop: 'auto',
    marginLeft: 'auto',
    background: '#fff',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem 3rem',
    height: '4.9rem'
  },
  modalFooterButtons: {
    fontFamily: 'Euclid',
    ':&>*': {
      margin: '0 1rem'
    }
  },
  modalFooterCancel: {
    '&:hover': {
      background: '#ddd'
    }
  },
  editOption: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      fontSize: '14px',
      color: 'gray',
      marginLeft: '5px'
    }
  },
  tableRoot: {
    '& .MuiTableRow-root': {
      verticalAlign: 'baseline'
    },
    '& .MuiTableCell-root': {
      padding: '0px',
      border: '1px solid gray'
    },
    '& table': {
      '& .MuiTableCell-root': {
        border: 'none',
        padding: '5px'
      }
    }
  },
  verticalText: {
    fontSize: '14px',
    width: '160px',
    transform: 'rotate(-90deg) translateY(150px)'
  },
  printDiv: {
    '@media print': {
      marginTop: '60px',
      '& @page': {
        size: 'landscape'
      }
    }
  },
  pageBreak: {
    '@media print': {
      pageBreakAfter: 'always'
    }
  }
}));

export default useStyles;
