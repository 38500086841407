import BreadCrumb from 'components/globals/breadCrumb';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import MainAreaLayout from 'layouts/dashboardLayout/mainAreaLayout/MainAreaLayout';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { changeDateFormat } from 'utils/dateUtils';
import { getEmployee } from '../workers/redux/actions';
import ContractSelect from './ContractSelect';
import ContractTable from './ContractTable';
import FilterForm from './employmentContract/components/filterForm';
import { getContract, getTaxRate } from './redux/actions';

function Contract() {
  const [modalOpen, openFunction] = useToggle(false);
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [employee, setEmployee] = useState('');
  const [agreementId, setAgreementId] = useState('');

  useEffect(() => {
    dispatch(getEmployee());
    dispatch(getTaxRate());
  }, []);

  const refetch = () => {
    const newStart = new Date(startDate);
    const newEnd = new Date(endDate);
    const firstDay = new Date(
      newStart?.getFullYear(),
      newStart?.getMonth(),
      moment(newStart).startOf('month').format('DD')
    );
    const lastDay = new Date(
      newEnd?.getFullYear(),
      newEnd?.getMonth(),
      moment(newEnd).endOf('month').format('DD')
    );
    const start_date = startDate ? changeDateFormat(new Date(firstDay), 'YYYY-MM-DD') : '';
    const end_date = endDate ? changeDateFormat(new Date(lastDay), 'YYYY-MM-DD') : '';
    dispatch(getContract({ start_date, end_date, employee, agreementId }));
  };

  useEffect(() => {
    refetch();
  }, [startDate, endDate, agreementId, employee]);

  return (
    <>
      <BreadCrumb
        itemName={<FormattedMessage id="Contract" defaultMessage="Contract" />}
        page={<FormattedMessage id="Contract" defaultMessage="Contract" />}
        handleButton={openFunction}
        buttonTitle={<FormattedMessage id="Add Contract" defaultMessage="Add Contract" />}
      />
      <FilterForm
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        startDate={startDate}
        endDate={endDate}
        employee={employee}
        setEmployee={setEmployee}
        setAgreementId={setAgreementId}
      />
      <MainAreaLayout>
        <ContractTable refetch={refetch} />
      </MainAreaLayout>

      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="35rem"
        headerText={
          <FormattedMessage
            id="Select the type of contract"
            defaultMessage="Select the type of contract"
          />
        }>
        <ContractSelect handleClose={openFunction} />
      </CustomModal>
    </>
  );
}

export default Contract;
