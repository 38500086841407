import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from 'utils';
import { addPUE, getPUE } from '../redux/actions';
import styles from '../styles';

function PUESetup() {
  const classes = styles();
  const dispatch = useDispatch();
  const { pue, add_pue_loading, pue_loading } = useSelector((state) => state.setup);

  const [values, setValues] = useState({ status: 0, period: 'weekly', period_value: 1, email: '' });

  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleSubmit = () => {
    console.log(values, 'formdata');
    dispatch(addPUE(values));
  };

  useEffect(() => {
    dispatch(getPUE());
  }, []);
  useEffect(() => {
    setValues((prev) => ({
      ...prev,
      period: pue?.periodicity,
      period_value: pue?.periodicity_value,
      email: pue?.pue_email,
      status: pue?.status
    }));
  }, [pue]);

  const periods = [
    { label: translate('Daily'), value: 'daily' },
    { label: translate('Weekly'), value: 'weekly' },
    { label: translate('Monthly'), value: 'monthly' }
  ];
  const weeks = [
    { label: translate('Sunday'), value: '0' },
    { label: translate('Monday'), value: '1' },
    { label: translate('Tuesday'), value: '1' },
    { label: translate('Wednesday'), value: '1' },
    { label: translate('Thursday'), value: '1' },
    { label: translate('Friday'), value: '1' },
    { label: translate('Saturday'), value: '1' }
  ];
  return (
    <>
      <Box display="none">
        <FormattedMessage id="Daily" defaultMessage="Daily" />
        <FormattedMessage id="Weekly" defaultMessage="Weekly" />
        <FormattedMessage id="Monthly" defaultMessage="Monthly" />
        <FormattedMessage id="Sunday" defaultMessage="Sunday" />
        <FormattedMessage id="Monday" defaultMessage="Monday" />
        <FormattedMessage id="Tuesday" defaultMessage="Tuesday" />
        <FormattedMessage id="Wednesday" defaultMessage="Wednesday" />
        <FormattedMessage id="Thursday" defaultMessage="Thursday" />
        <FormattedMessage id="Friday" defaultMessage="Friday" />
        <FormattedMessage id="Saturday" defaultMessage="Saturday" />
      </Box>
      <Box>
        <Box sx={{ borderLeft: '3px solid #4C4B63' }}>
          <Typography className="title">
            {<FormattedMessage id="PUE Settings" defaultMessage="PUE Settings" />}
          </Typography>
        </Box>
        {!pue_loading && (
          <>
            <Box className="formBodyWrapper">
              <Grid container spacing={1}>
                <Grid item sm={8}>
                  <Grid container spacing={1}>
                    <Grid item sm={6}>
                      <Box className={classes.list}>
                        <input
                          type="radio"
                          id="status_inactive"
                          name="status"
                          checked={values.status == 0 ? true : false}
                          onChange={handleChange}
                          value={0}
                        />
                        <label htmlFor="status_inactive">
                          {' '}
                          {<FormattedMessage id="Inactive" defaultMessage="Inactive" />}
                        </label>
                      </Box>
                      <Box className={classes.list}>
                        <input
                          type="radio"
                          id="status_active"
                          name="status"
                          onChange={handleChange}
                          checked={values.status == 1 ? true : false}
                          value={1}
                        />
                        <label htmlFor="status_active">
                          {' '}
                          {<FormattedMessage id="Active" defaultMessage="Active" />}
                        </label>
                      </Box>
                    </Grid>
                    <Grid item sm={12}>
                      <Box className={classes.list}>
                        <Box display="flex" justifyContent="flex-start" alignItems="center">
                          <span>
                            <FormattedMessage id="Periodicity" defaultMessage="Periodicity" />{' '}
                          </span>
                          <Box sx={{ width: '50px', marginLeft: '5px' }}>
                            <select onChange={handleChange} value={values.period} name="period">
                              {periods?.map((item, index) => (
                                <option value={item?.value} key={index}>
                                  {item?.label}
                                </option>
                              ))}
                            </select>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    {values?.period === 'weekly' && (
                      <Grid item sm={12}>
                        <Box className={classes.list}>
                          <Box display="flex" justifyContent="flex-start" alignItems="center">
                            <span>
                              <FormattedMessage
                                id="Day of the Week"
                                defaultMessage="Day of the Week"
                              />{' '}
                            </span>
                            <Box sx={{ width: '50px', marginLeft: '5px' }}>
                              <select
                                onChange={handleChange}
                                name="period_value"
                                value={values.period_value}>
                                {weeks?.map((item, index) => (
                                  <option value={item?.value} key={index}>
                                    {item?.label}
                                  </option>
                                ))}
                              </select>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    {values.period === 'daily' && (
                      <Grid item sm={12}>
                        <Box className={classes.list}>
                          <Box display="flex" justifyContent="flex-start" alignItems="center">
                            <span>
                              <FormattedMessage id="Time" defaultMessage="Time" />{' '}
                            </span>
                            <Box sx={{ width: '50px', marginLeft: '5px' }}>
                              <select
                                onChange={handleChange}
                                name="period_value"
                                value={values.period_value}>
                                {[...Array(24).keys()]?.map((index) => (
                                  <option value={index + 1} key={index}>
                                    {index + 1}
                                  </option>
                                ))}
                              </select>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    )}

                    {values.period === 'monthly' && (
                      <Grid item sm={12}>
                        <Box className={classes.list}>
                          <Box display="flex" justifyContent="flex-start" alignItems="center">
                            <span>
                              <FormattedMessage id="Day" defaultMessage="Day" />{' '}
                            </span>
                            <Box sx={{ width: '50px', marginLeft: '5px' }}>
                              <select
                                onChange={handleChange}
                                name="period_value"
                                value={values.period_value}>
                                {[...Array(30).keys()]?.map((item, index) => (
                                  <option value={index + 1} key={index}>
                                    {index + 1}
                                  </option>
                                ))}
                              </select>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    )}

                    <Grid item sm={12}>
                      <Box>
                        <label htmlFor="">
                          <Typography variant="h5" marginBottom={1}>
                            <FormattedMessage id="Email" defaultMessage="Email" />
                          </Typography>
                        </label>
                      </Box>
                      <TextField
                        type="text"
                        autoComplete="off"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <Box>
                        <label htmlFor="">
                          <Typography variant="h5" marginBottom={1}>
                            <FormattedMessage id="User Password" defaultMessage="User Password" />
                          </Typography>
                        </label>
                      </Box>
                      <TextField
                        type="password"
                        autoComplete="new-password"
                        name="user_password"
                        value={values.user_password}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <Box>
                        <label htmlFor="">
                          <Typography variant="h5" marginBottom={1}>
                            <FormattedMessage
                              id="Password to the zip file containing the report"
                              defaultMessage="Password to the zip file containing the report"
                            />
                          </Typography>
                        </label>
                      </Box>
                      <TextField
                        type="password"
                        name="zip_password"
                        value={values.zip_password}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box paddingBottom={3} textAlign="left" marginLeft={4.5}>
              {!add_pue_loading ? (
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                  {<FormattedMessage id="Update" defaultMessage="Update" />}
                </Button>
              ) : (
                <Button variant="contained" color="primary" disabled>
                  <CircularProgress size={20} sx={{ marginRight: '10px' }} />{' '}
                  {<FormattedMessage id="Updating" defaultMessage="Updating" />}
                </Button>
              )}
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

export default PUESetup;
