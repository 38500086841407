import AssignmentPage from 'components/locals/assignment';
import DashboardLayout from 'layouts/dashboardLayout';

const Assignment = () => {
  return (
    <DashboardLayout>
      <AssignmentPage />
    </DashboardLayout>
  );
};

export default Assignment;
