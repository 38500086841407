import { axiosInstance } from 'apis/_axios';

// create statement
export const createStatementApi = (data) => {
  return axiosInstance().post('/agreement-statement', data);
};

// get all statement
export const getStatementApi = (agreement_id) => {
  return axiosInstance().get(`/agreement-statement?agreement_id=${agreement_id}`);
};

// get statement by id
export const getStatementByIdApi = (id) => {
  return axiosInstance().get(`/agreement-statement'/${id}`);
};

// edit statement
export const editStatementApi = (id, data) => {
  return axiosInstance().put(`/agreement-statement/${id}`, data);
};

// delete statement
export const deleteStatementApi = (id) => {
  return axiosInstance().delete(`/agreement-statement/${id}`);
};
