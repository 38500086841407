import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { editPayrollClosing } from '../redux/actions';
import styles from '../styles';

function PayrollSetup() {
  const dispatch = useDispatch();
  const { payroll_closing_loading, payroll_closing_edit_loading, payroll_closing } = useSelector(
    (state) => state.setup
  );
  const { agreement_types } = useSelector((state) => state.contract);

  const defaultValues = {
    closing: [
      {
        agreement_type: 'employment-contract',
        automatic_closing: 1,
        type: 'current_month',
        day_of_month: 0
      },
      {
        agreement_type: 'contract-of-mandate',
        automatic_closing: 1,
        type: 'current_month',
        day_of_month: 0
      },
      {
        agreement_type: 'contract-for-specific-work',
        automatic_closing: 1,
        type: 'current_month',
        day_of_month: 0
      }
    ],
    representative: ''
  };
  const { register, handleSubmit, setValue, watch } = useForm({
    reValidateMode: true,
    defaultValues: defaultValues
  });

  const onSubmit = (value) => {
    dispatch(editPayrollClosing(value));
  };

  useEffect(() => {
    const employment = payroll_closing?.closing?.find(
      (list) => list?.agreement_type == 'employment-contract'
    );
    const mandate = payroll_closing?.closing?.find(
      (list) => list?.agreement_type == 'contract-of-mandate'
    );
    const specific = payroll_closing?.closing?.find(
      (list) => list?.agreement_type == 'contract-for-specific-work'
    );
    employment && setValue('closing[0]', employment);
    mandate && setValue('closing[1]', mandate);
    specific && setValue('closing[2]', specific);

    payroll_closing?.representative && setValue('representative', payroll_closing?.representative);
  }, [payroll_closing]);

  return (
    <>
      <Box>
        <Box sx={{ borderLeft: '3px solid #4C4B63' }}>
          <Typography className="title">
            {<FormattedMessage id="Payroll Setup" defaultMessage="Payroll Setup" />}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          {!payroll_closing_loading && (
            <>
              <Box className="formBodyWrapper">
                {agreement_types?.map((list, index) => (
                  <PayrollItem
                    list={list}
                    key={list?.id}
                    register={register}
                    index={index}
                    setValue={setValue}
                    watch={watch}
                  />
                ))}
                <Grid item sm={12}>
                  <Box>
                    <label htmlFor="">
                      <Typography variant="h5" marginBottom={1}>
                        <FormattedMessage id="Represent By:" defaultMessage="Represent By:" />
                      </Typography>
                    </label>
                  </Box>
                  <TextField type="text" {...register('representative')} />
                </Grid>
              </Box>
              <Box paddingBottom={3} textAlign="left" marginLeft={4.5}>
                {!payroll_closing_edit_loading ? (
                  <Button variant="contained" color="primary" type="submit">
                    {<FormattedMessage id="Update" defaultMessage="Update" />}
                  </Button>
                ) : (
                  <Button variant="contained" color="primary" disabled>
                    <CircularProgress size={20} sx={{ marginRight: '10px' }} />{' '}
                    {<FormattedMessage id="Updating" defaultMessage="Updating" />}
                  </Button>
                )}
              </Box>
            </>
          )}
        </form>
      </Box>
    </>
  );
}

export default PayrollSetup;

const NextMonth = ({ index, register }) => {
  const classes = styles();
  const monthDays = [...Array(31).keys()].map((list) => ({ label: list + 1, value: list + 1 }));

  return (
    <Box className={classes.list}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <span>
          <FormattedMessage id="Day of the Month" defaultMessage="Day of the Month" />{' '}
        </span>
        <Box sx={{ width: '50px', marginLeft: '5px' }}>
          <select {...register(`closing[${index}].day_of_month`)}>
            {monthDays?.map((item, index) => (
              <option value={item?.value} key={index}>
                {item?.label}
              </option>
            ))}
          </select>
        </Box>
      </Box>
    </Box>
  );
};

const CurrentMonth = ({ setValue, index, watch }) => {
  const classes = styles();
  const prevDays = [...Array(7).keys()].map((list) => ({ label: list + 1, value: list + 1 }));
  const dayOfMonth = watch(`closing[${index}].day_of_month`);
  const handleChange = (value) => {
    setValue(`closing[${index}].day_of_month`, value);
  };
  console.log(dayOfMonth, 'valll');
  return (
    <>
      <Box className={classes.list}>
        <input
          type="radio"
          name={`last_day${index}`}
          value={0}
          checked={dayOfMonth == 0 ? true : false}
          onClick={() => handleChange(0)}
        />
        <label htmlFor="last_day">
          {<FormattedMessage id="Last day of the Month" defaultMessage="Last day of the Month" />}
        </label>
      </Box>
      <Box className={classes.list}>
        <input
          type="radio"
          name={`last_day${index}`}
          checked={dayOfMonth == 0 ? false : true}
          onClick={() => handleChange(1)}
        />
        <label htmlFor="custom_day">
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Box sx={{ width: '40px' }}>
              <select value={dayOfMonth} onChange={(e) => handleChange(e.target.value)}>
                {prevDays?.map((item, index) => (
                  <option value={item?.value} key={index}>
                    {item?.label}
                  </option>
                ))}
              </select>
            </Box>
            <span>
              {
                <FormattedMessage
                  id="days before the last day"
                  defaultMessage="days before the last day"
                />
              }
            </span>
          </Box>
        </label>
      </Box>
    </>
  );
};

const PayrollItem = ({ list, register, index, watch, setValue }) => {
  const classes = styles();
  const [day, setDay] = useState(0);
  const { payroll_closing } = useSelector((state) => state.setup);

  const type = watch(`closing`);

  console.log(type, 'valll');

  return (
    <Box key={list?.id} className={classes.payrollWrapper}>
      <Box className={classes.contractTitle}>{list?.name}</Box>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <Typography variant="h5">
            {
              <FormattedMessage
                id="Select the payroll closing month"
                defaultMessage="Select the payroll closing month"
              />
            }
          </Typography>
          <Box className={classes.list}>
            <input type="radio" {...register(`closing.${index}.type`)} value="current_month" />
            <label htmlFor="current_month">
              {' '}
              {<FormattedMessage id="Current Month" defaultMessage="Current Month" />}
            </label>
          </Box>
          <Box className={classes.list}>
            <input type="radio" {...register(`closing.${index}.type`)} value="next_month" />
            <label htmlFor="next_month">
              {<FormattedMessage id="Next Month" defaultMessage="Next Month" />}
            </label>
          </Box>
        </Grid>
        <Grid item sm={12}>
          <Box sx={{ fontSize: '14px' }}>
            <FormattedMessage
              id="Select payroll closing day"
              defaultMessage="Select payroll closing day"
            />
          </Box>
          {type?.[index]?.['type'] === 'next_month' && (
            <NextMonth
              setDay={setDay}
              day={day}
              backDay={payroll_closing?.day_of_month}
              register={register}
              index={index}
            />
          )}
          {type?.[index]?.['type'] === 'current_month' && (
            <CurrentMonth
              setDay={setDay}
              day={day}
              backDay={payroll_closing?.day_of_month}
              register={register}
              index={index}
              setValue={setValue}
              watch={watch}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
