// this will be the componene for filterForm
import { Box } from '@mui/system';

import { TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { translate } from 'utils';
import { addFilter } from '../redux/actions';
import EmployeeSelect from './EmployeeSelect';
import useStyles from './styles';

// const Example = () => {
//   const [startDate, setStartDate] = useState(new Date());
//   return <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />;
// };

const Filterform = ({
  setStartDate,
  startDate,
  setEndDate,
  endDate,
  setType,
  type,
  employeeId,
  setEmployeeId
}) => {
  const classes = useStyles();
  const handleEmployeeId = (id) => {
    setEmployeeId(id);
  };
  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <Box display="none">
        <FormattedMessage id="Event Type" defaultMessage="Event Type" />
        <FormattedMessage id="Sick Leave" defaultMessage="Sick Leave" />
        <FormattedMessage id="Vacation Leave" defaultMessage="Vacation Leave" />
        <FormattedMessage id="Overtime Amount" defaultMessage="Overtime Amount" />
        <FormattedMessage id="Allowance" defaultMessage="Allowance" />
        <FormattedMessage id="Deduction" defaultMessage="Deduction" />
        <FormattedMessage id="Care Leave" defaultMessage="Care Leave" />
        <FormattedMessage id="Unpaid Leave" defaultMessage="Unpaid Leave" />
        <FormattedMessage id="Statement" defaultMessage="Statement" />
        <FormattedMessage id="Equivalent Vacation" defaultMessage="Equivalent Vacation" />
      </Box>
      <div className={classes.optionWrap}>
        <Box className={classes.dateWrapper}>
          <DesktopDatePicker
            inputFormat="DD/MM/YYYY"
            value={startDate}
            onChange={(value) => {
              dispatch(addFilter({ key: 'startDate', value: value }));
              setStartDate(value);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <div className={classes.hyphen}>-</div>
          <DesktopDatePicker
            inputFormat="DD/MM/YYYY"
            value={endDate}
            onChange={(value) => {
              setEndDate(value);
              dispatch(addFilter({ key: 'endDate', value: value }));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
        <EmployeeSelect handleEmployeeId={handleEmployeeId} employeeId={employeeId} />
        <select
          className={classes.select}
          onChange={(e) => {
            dispatch(addFilter({ key: 'type', value: e.target.value }));
            setType(e.target.value);
          }}
          value={type}>
          <option value="">{translate('Event Type')}</option>
          <option value="sick_leave">{translate('Sick Leave')}</option>
          <option value="unpaid_leave">{translate('Unpaid Leave')}</option>
          <option value="vacation_leave">{translate('Vacation Leave')}</option>
          <option value="overtime_amount">{translate('Overtime Amount')}</option>
          <option value="allowance">{translate('Allowance')}</option>
          <option value="deduction">{translate('Deduction')}</option>
          <option value="care_leave">{translate('Care Leave')}</option>
          <option value="statement">{translate('Statement')}</option>
          <option value="contract_termination">{translate('Equivalent Vacation')}</option>
        </select>
      </div>
    </div>
  );
};

export default Filterform;
