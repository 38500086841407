import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import HookFormAutoComplete from 'components/form/hookFormAutoComplete';
import { HookFormInput } from 'components/form/hookFormInput';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { translate } from 'utils';
import { changeDateFormat } from 'utils/dateUtils';

function BasicDetail({ contractId }) {
  const { single_loading } = useSelector((state) => state.employee);
  const { watch, setValue } = useFormContext();
  const [checkType, setCheckType] = useState(false);
  const contractType = watch('type');
  useEffect(() => {
    if (contractType == 'indefinite') {
      setCheckType(true);
      setValue('end_date', null);
    } else {
      setCheckType(false);
    }
  }, [contractType]);
  return (
    <Grid container spacing={2}>
      <Box display="none">
        <FormattedMessage
          id="Indefinite Period of Time"
          defaultMessage="Indefinite Period of Time"
        />
        <FormattedMessage id="Fixed Term" defaultMessage="Fixed Term" />
      </Box>
      <Grid item sm={4}>
        <HookFormInput
          loading={single_loading}
          name="type_gross"
          label={<FormattedMessage id="Salary Type" defaultMessage="Salary Type" />}
          defaultValue="Gross"
          disabled
        />
      </Grid>
      <Grid item sm={4}>
        <HookFormAutoComplete
          loading={single_loading}
          name="parameters.rate"
          label={<FormattedMessage id="Rate" defaultMessage="Rate" />}
          options={[
            {
              label: translate('Monthly'),
              value: 'monthly'
            },
            {
              label: translate('Hourly'),
              value: 'hourly'
            }
          ]}
        />
      </Grid>
      <Grid item sm={4}>
        <HookFormInput
          name="gross_salary"
          label={<FormattedMessage id="Amount" defaultMessage="Amount" />}
          type="number"
          placeholder="3000"
          loading={single_loading}
        />
      </Grid>
      <Grid item sm={4}>
        <HookFormAutoComplete
          loading={single_loading}
          name="type"
          label={<FormattedMessage id="Type of Contract" defaultMessage="Type of Contract" />}
          options={[
            {
              label: translate('Indefinite Period of Time'),
              value: 'indefinite'
            },
            {
              label: translate('Fixed Term'),
              value: 'fixed'
            }
          ]}
        />
      </Grid>

      <Grid item sm={4}>
        <HookFormInput
          name="date_of_signing"
          type="date"
          label={<FormattedMessage id="Date of Signing" defaultMessage="Date of Signing" />}
          loading={single_loading}
          inputProps={{ max: changeDateFormat(new Date(), 'YYYY-MM-DD') }}
        />
      </Grid>

      <Grid item sm={4}>
        <HookFormInput
          name="start_date"
          type="date"
          label={<FormattedMessage id="Start Date" defaultMessage="Start Date" />}
          loading={single_loading}
        />
      </Grid>

      {checkType ? null : (
        <Grid item sm={4}>
          <HookFormInput
            name="end_date"
            type="date"
            label={<FormattedMessage id="End Date" defaultMessage="End Date" />}
            loading={single_loading}
          />
        </Grid>
      )}

      <Grid item sm={4}>
        <HookFormInput
          name="parameters.place_of_work"
          label={<FormattedMessage id="Place of Work" defaultMessage="Place of Work" />}
          placeholder=""
          loading={single_loading}
        />
      </Grid>
    </Grid>
  );
}

export default BasicDetail;
