import { Alert, Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import BusinessAddressForm from './BusinessAddressForm';

function BusinessAddress({ addressError, setAddressError }) {
  const {
    register,
    watch,
    control,
    formState: { errors }
  } = useFormContext();
  const addressWatch = useWatch({ control, name: 'addresses[0]' });

  console.log(errors);
  useEffect(() => {
    setAddressError && setAddressError('');
  }, [addressWatch]);

  return (
    <>
      <Box margin={4.5}>
        <Box mb={3} display="flex" alignItems="center" columnGap={1}>
          <Typography sx={{ fontSize: '13px' }}>
            <FormattedMessage id="Primary address" defaultMessage="Primary address" />:
          </Typography>
        </Box>
        <BusinessAddressForm type="home" index={0} />
      </Box>
      {addressError && (
        <Box marginTop={2}>
          <Alert severity="error">
            <Typography fontSize={12}>{addressError}</Typography>
          </Alert>
        </Box>
      )}
      <Box
        margin={4.5}
        display="flex"
        alignItems="center"
        columnGap={1}
        sx={{ background: '#fff' }}>
        <input
          type="checkbox"
          value="home"
          {...register('addresses[1][same_address_type]')}
          style={{ cursor: 'pointer' }}
        />

        <Typography sx={{ fontSize: '13px' }}>
          <FormattedMessage
            id="The mailing address is the same as the company's registered address."
            defaultMessage="The mailing address is the same as the company's registered address."
          />
          .
        </Typography>
      </Box>
      {!watch('addresses[1][same_address_type]') ? (
        <Box margin={4.5}>
          <Box display="flex" alignItems="center" columnGap={1}>
            <Typography sx={{ fontSize: '13px' }}>
              <FormattedMessage id="Registered address" defaultMessage="Registered address" />:
            </Typography>
          </Box>
          <BusinessAddressForm type="registered" index={1} />
        </Box>
      ) : (
        false
      )}
      {/* <Box
        margin={4.5}
        display="flex"
        alignItems="center"
        columnGap={1}
        padding="10px"
        sx={{ background: '#fff' }}>
        <input
          type="checkbox"
          value="home"
          {...register('address[2][same_address_type]')}
          style={{ cursor: 'pointer' }}
        />
        <Typography sx={{ fontSize: '13px' }}>
          The registered address is same as the home address.
        </Typography>
      </Box>
      {!watch('address[2][same_address_type]') ? (
        <Box margin={4.5}>
          <Box display="flex" alignItems="center" columnGap={1}>
            <Typography sx={{ fontSize: '13px' }}>Mailing address:</Typography>
          </Box>
          <BusinessAddressForm type="mailing" index={2} />
        </Box>
      ) : (
        false
      )} */}
    </>
  );
}

export default BusinessAddress;
