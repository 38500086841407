import * as actions from './types';
const initialState = {
  listCompany: [],
  listCompany_loading: false,
  companyFilter: {},
  send_payout_loading: false,
  send_mail_loading: false,
  close_pay_list_loading: false
};

const companyListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_COMPANY_LIST_BEGIN:
      return { ...state, listCompany_loading: true, listCompany: [] };

    case actions.GET_COMPANY_LIST_SUCCESS:
      return { ...state, listCompany: action.payload, listCompany_loading: false };

    case actions.GET_COMPANY_LIST_ERROR:
      return { ...state, listCompany_loading: false };

    case actions.ADD_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        listCompany: state.listCompany?.map((list) => {
          const findData = action.payload?.data?.find((item) => item?.collective_id == list?.id);
          if (findData) {
            return {
              ...list,
              queueActions: findData?.queueActions,
              status: findData?.status,
              payouts: findData.payouts
            };
          } else {
            return list;
          }
        })
      };

    case actions.ADD_COMPANY_FILTER:
      return {
        ...state,
        companyFilter: { ...state.companyFilter, [action.payload?.key]: action.payload.value }
      };

    case actions.ADD_BULK_CLOSE_PAY_LIST_BEGIN:
      return { ...state, close_pay_list_loading: true };

    case actions.ADD_BULK_CLOSE_PAY_LIST_ERROR:
    case actions.ADD_BULK_CLOSE_PAY_LIST_SUCCESS:
      return { ...state, close_pay_list_loading: false };

    case actions.ADD_BULK_SEND_MAIL_BEGIN:
      return { ...state, send_mail_loading: true };

    case actions.ADD_BULK_SEND_MAIL_ERROR:
    case actions.ADD_BULK_SEND_MAIL_SUCCESS:
      return { ...state, send_mail_loading: false };

    case actions.ADD_BULK_SEND_PAYOUT_BEGIN:
      return { ...state, send_payout_loading: true };

    case actions.ADD_BULK_SEND_PAYOUT_ERROR:
    case actions.ADD_BULK_SEND_PAYOUT_SUCCESS:
      return { ...state, send_payout_loading: false };

    default:
      return state;
  }
};

export default companyListReducer;
