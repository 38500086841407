import React from 'react';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import useStyles from '../styles';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import EditParameter from './EditParameter';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from 'components/globals/table';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box } from '@mui/system';
import { Button, Skeleton, Typography } from '@mui/material';
import { deleteParameter } from '../redux/actions';
import { FormattedMessage } from 'react-intl';

const columns = [
  { title: '#', type: 'Index', minWidth: 20 },
  {
    field: 'date_s',
    title: <FormattedMessage id="Start Date" defaultMessage="Start Date" />,
    minWidth: 170,
    align: 'right'
  },
  {
    field: 'date_e',
    title: <FormattedMessage id="End Date" defaultMessage="End Date" />,
    minWidth: 170,
    align: 'right'
  },
  {
    field: 'par_value',
    title: <FormattedMessage id="Value" defaultMessage="Value" />,
    minWidth: 170,
    align: 'right'
  },
  {
    field: (row) => <Actions parameter={row} />,
    title: <FormattedMessage id="Actions" defaultMessage="Actions" />,
    minWidth: 170,
    align: 'center'
  }
];

export default function ViewTable() {
  const { singleParameterList, listByNameLoading } = useSelector((state) => state.parameter);

  // useEffect(() => {
  //   singleParameterList &&
  //     setTableData(
  //       singleParameterList.map((item) => ({
  //         id: item?.id,
  //         key: item?.par_name,
  //         start_date: item?.date_s,
  //         end_date: item?.date_e,
  //         value: item?.par_value
  //       }))
  //     );
  // }, [singleParameterList]);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      {!listByNameLoading ? (
        <CustomTable tableData={singleParameterList} tableHeads={columns} />
      ) : (
        <Skeleton height={100} />
      )}
    </Paper>
  );
}

const Actions = ({ parameter }) => {
  const [modalOpen, openFunction] = useToggle(false);
  const [deleteModalOpen, openFunctionDelete] = useToggle(false);
  const dispatch = useDispatch();
  const handleClick = () => {
    openFunction();
  };

  const handleDelete = (id) => {
    dispatch(deleteParameter({ id: id, name: parameter?.par_name }, openFunctionDelete));
  };

  const classes = useStyles();
  return (
    <>
      <div className={classes.iconWrapper}>
        {parameter?.company_editable ? (
          <>
            <EditIcon onClick={handleClick} />
            <DeleteOutlineIcon className={classes.deleteBtn} onClick={openFunctionDelete} />
          </>
        ) : (
          <></>
        )}
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="40rem"
        headerText={<FormattedMessage id="Edit Parameter" defaultMessage="Edit Parameter" />}>
        <EditParameter handleClose={openFunction} parameter={parameter} />
      </CustomModal>
      <CustomModal
        open={deleteModalOpen}
        handleClose={openFunctionDelete}
        height="auto"
        width="40rem"
        headerText={<FormattedMessage id="Delete Parameter" defaultMessage="Delete Parameter" />}>
        <Box marginTop={6} marginBottom={4}>
          <Typography variant="h5" textAlign="center">
            {
              <FormattedMessage
                id="Are you sure to delete the parameter?"
                defaultMessage="Are you sure to delete the parameter?"
              />
            }
          </Typography>
          <Box marginX={4} textAlign="center">
            <Typography variant="p">
              {
                <FormattedMessage
                  id="Deleting intermediate parameter values might affect payroll calculations on those
              dates"
                  defaultMessage="Deleting intermediate parameter values might affect payroll calculations on those
              dates"
                />
              }
            </Typography>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-around">
          <Button variant={'contained'} onClick={() => handleDelete(parameter?.id)}>
            {<FormattedMessage id="Yes" defaultMessage="Yes" />}
          </Button>
          <Button
            className={classes.modalFooterCancel}
            variant={'outlined'}
            onClick={openFunctionDelete}>
            {<FormattedMessage id="No" defaultMessage="No" />}
          </Button>
        </Box>
      </CustomModal>
    </>
  );
};
