/* Build in libraries */

/* Third party libraries */
import { Box, Button, CircularProgress } from '@mui/material';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import * as yup from 'yup';

/* Style */
import { HookForm } from 'components/UI/Form/HookForm';
import { HookFormProvider } from 'components/UI/Form/HookFormProvider';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
// import { addCompanyAdmin } from '../../redux/actions';
import { useEffect, useState } from 'react';
import { assignCompany } from '../redux/actions';
import useStyles from '../styles';
import EditForm from './EditForm';

const EditUserDetails = ({ userId }) => {
  const { user_company, assign_company_loading } = useSelector((state) => state.assignment);
  const [company, setCompany] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();

  const defaultValues = {
    company_slug: '',
    is_admin: false
  };
  const validationSchema = yup.object().shape({
    company_slug: yup.string().required('Company  is required')
  });

  const submit = (data) => {
    data && dispatch(assignCompany(userId, data));
  };

  useEffect(() => {
    const data = user_company?.unassigned_companies?.map((list) => ({
      label: list?.name,
      value: list?.slug
    }));
    data && setCompany(data);
  }, [user_company?.unassigned_companies]);

  return (
    <HookFormProvider
      defaultValues={defaultValues}
      resolver={useYupValidationResolver(validationSchema)}>
      <HookForm onSubmit={submit}>
        <Box px={4.7} py={3}>
          <EditForm company={company} />
        </Box>
        <Box className={classes.modalFooter}>
          <Box className={classes.modalFooterButtons}>
            {!assign_company_loading ? (
              <Button sx={{ marginRight: '1rem' }} variant={'contained'} type="submit">
                <FormattedMessage id="Save" defaultMessage="Save" />
              </Button>
            ) : (
              <Button sx={{ marginRight: 'rem' }} variant={'contained'} disabled>
                <CircularProgress size={18} sx={{ marginRight: '1rem' }} /> Submitting
              </Button>
            )}
          </Box>
        </Box>
      </HookForm>
    </HookFormProvider>
  );
};

export default EditUserDetails;
