import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import useStyles from '../../styles';

function PrintDocument({ handleClose }) {
  const classes = useStyles();
  const { departments } = useSelector((state) => state.department);
  const { single_contract } = useSelector((state) => state.contract);
  const getDepartment = () => {
    const filteredDepartment = departments?.find(
      (list) => list?.id == single_contract?.department_id
    );
    return filteredDepartment?.title ?? '-';
  };
  const documentName = `${single_contract?.contract_number}.docx`;
  const checkValue = (value) => {
    if (value == 'null' || !value) {
      return '';
    } else return value;
  };

  const address = single_contract?.employee?.address?.[0];
  const companyAddress = single_contract?.employee?.company?.addresses?.[0];
  const getContractType = () => {
    if (single_contract?.type == 'trial') {
      return `na okres próbny od ${single_contract?.start_date} do ${single_contract?.end_date}.`;
    }
    if (single_contract?.type == 'fixed') {
      return `na czas określony od ${single_contract?.start_date} do ${single_contract?.end_date}.`;
    }
    if (single_contract?.type == 'indefinite') {
      return `na czas nieokreślony ${single_contract?.start_date}`;
    } else {
      return '';
    }
  };

  const generate = () => {
    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: '……………………………                                                                                           ……………………………'
            }),
            new Paragraph({
              text: '       (pieczątka firmy)                                                                                                         (miejscowość i data) '
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              alignment: 'center',
              children: [
                new TextRun({
                  text: single_contract?.agreement_type_name,
                  bold: true,
                  size: 26
                })
              ]
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: `zawarta w dniu ${single_contract?.date_of_signing} pomiędzy:`
            }),
            new Paragraph({
              text: `${single_contract?.employee?.company?.name ?? ''}, ${
                single_contract?.employee?.company?.regon ?? ''
              }, ${single_contract?.employee?.company?.tax_id ?? ''}`
            }),
            new Paragraph({
              text: `${checkValue(companyAddress?.street)} ${checkValue(
                companyAddress?.house_number
              )} ${checkValue(companyAddress?.number_of_premises)} ${checkValue(
                companyAddress?.zip
              )} ${checkValue(companyAddress?.post)} ${checkValue(
                companyAddress?.city
              )} ${checkValue(companyAddress?.country)} ${checkValue(
                companyAddress?.voivodeship
              )} ${checkValue(companyAddress?.county)} ${checkValue(companyAddress?.community)}`
            }),

            new Paragraph({
              text: `reprezentowanym przez: ${single_contract?.representative ?? ''}`
            }),
            new Paragraph({
              text: `a ${single_contract?.employee?.first_name ?? ''} ${
                single_contract?.employee?.second_name ?? ''
              } ${single_contract?.employee?.last_name ?? ''}, ${single_contract?.employee?.pesel}`
            }),
            new Paragraph({
              text: 'zamieszkała/ym'
            }),
            new Paragraph({
              text: `${checkValue(address?.street)} ${checkValue(
                address?.house_number
              )} ${checkValue(address?.number_of_premises)} ${checkValue(
                address?.zip
              )} ${checkValue(address?.post)} ${checkValue(address?.city)} ${checkValue(
                address?.country
              )} ${checkValue(address?.voivodeship)} ${checkValue(address?.county)} ${checkValue(
                address?.community
              )}`
            }),
            new Paragraph({
              text: getContractType()
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: '1. Strony ustalają następujące warunki zatrudnienia:',
                  size: 23
                })
              ]
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: '1) rodzaj wykonywanej pracy:'
            }),
            new Paragraph({
              text: `${single_contract?.position ?? ''} (${getDepartment()})`
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: '2) miejsce wykonywania pracy:'
            }),
            new Paragraph({
              text: `${single_contract?.parameters?.place_of_work ?? ''}`
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: '3) wymiar czasu pracy:'
            }),
            new Paragraph({
              text: `${single_contract?.parameters?.work_hour_numerator} / ${single_contract?.parameters?.work_hour_denominator}`
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: '4) wynagrodzenie'
            }),
            new Paragraph({
              text: `${single_contract?.gross_salary ?? ''}`
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: '5) inne warunki zatrudnienia'
            }),
            new Paragraph({
              text: `${single_contract?.parameters?.terms_of_employment ?? ''}`
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: `6) Termin rozpoczęcia pracy ${single_contract?.start_date ?? ''}`
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: '7)......(dopuszczalna liczba godzin pracy ponad określony w umowie wymiar czasu pracy, których przekroczenie uprawnia pracownika, oprócz normalnego wynagrodzenia, do dodatku, o którym mowa w art. 151 § 1 Kodeksu pracy- dotyczy umowy o pracę z pracownikiem zatrudnionym w niepełnym wymiarze czasu pracy*).'
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: `2. Przyczyny uzasadniające zawarcie umowy`,
                  size: 23
                })
              ]
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: '(informacja, o której mowa w art. 29 § 11 Kodeksu pracy, o obiektywnych przyczynach uzasadniających zawarcie umowy o pracę na czas określony **)'
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: '……………………………                                                               ……………………………'
            }),
            new Paragraph({
              text: '(data i podpis pracownika)                                         (podpis pracodawcy lub osoby reprezentującej pracodawcę) '
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: '* Dotyczy umowy o pracę z pracownikiem zatrudnianym w niepełnym wymiarze czasu pracy.'
            }),
            new Paragraph({
              text: '**Dotyczy umowy o pracę z pracownikiem zatrudnianym na podstawie umowy o pracę na czas określony w celu, o którym mowa w art. 251 § 4 pkt 1-3 Kodeksu pracy, lub w przypadku, o którym mowa w art. 251 § 4 pkt 4 Kodeksu pracy.'
            })
          ]
        }
      ]
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, documentName);
    });
  };
  return (
    <div>
      <Box className={classes.modalFooter}>
        <Box className={classes.modalFooterButtons}>
          <Button
            className={classes.modalFooterCancel}
            sx={{ marginRight: '6rem' }}
            variant={'contained'}
            onClick={generate}>
            <FormattedMessage id="Export Doc" defaultMessage="Export Doc" />
          </Button>
          <Button
            className={classes.modalFooterCancel}
            sx={{ marginRight: '6rem' }}
            variant={'outlined'}
            onClick={handleClose}>
            <FormattedMessage id="Cancel" defaultMessage="Cancel" />
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default PrintDocument;
