import AccountProfile from 'components/locals/settings/AccountProfile';
import DashboardLayout from 'layouts/dashboardLayout';

const AccountSettings = () => {
  return (
    <DashboardLayout>
      <AccountProfile />
    </DashboardLayout>
  );
};

export default AccountSettings;
