import * as actions from './types';
import { errorToast, successToast } from 'utils/toast';

import { createEventsApi, deleteEventsApi, editEventsApi, getEventsApi } from 'apis/events';

// create Events
export const createEvents = (data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.CREATE_EVENTS_BEGIN
  });
  createEventsApi(data)
    .then((res) => {
      dispatch({
        type: actions.CREATE_EVENTS_SUCCESS
      });
      handleFunction && handleFunction();
      dispatch(getEvents());
      successToast('Events created Successfully');
    })
    .catch((error) => {
      dispatch({
        type: actions.CREATE_EVENTS_ERROR
      });
      errorToast(error);
    });
};

// get all  events
export const getEvents = (startDate, endDate, type, employeeId) => (dispatch) => {
  dispatch({
    type: actions.GET_EVENTS_BEGIN
  });
  getEventsApi(startDate, endDate, type, employeeId)
    .then((res) => {
      dispatch({
        type: actions.GET_EVENTS_SUCCESS,
        payload: res.data?.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_EVENTS_ERROR
      });
      errorToast(error);
    });
};

// edit events
export const editEvents = (id, data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.EDIT_EVENTS_BEGIN
  });
  editEventsApi(id, data)
    .then((res) => {
      dispatch({
        type: actions.EDIT_EVENTS_SUCCESS
      });
      handleFunction && handleFunction();
      successToast('Events edited Successfully');
    })
    .catch((error) => {
      dispatch({
        type: actions.EDIT_EVENTS_ERROR
      });
      errorToast(error);
    });
};

// delete events
export const deleteEvents = (id, data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.DELETE_EVENTS_BEGIN
  });
  deleteEventsApi(id)
    .then((res) => {
      dispatch({
        type: actions.DELETE_EVENTS_SUCCESS
      });
      handleFunction && handleFunction();
      successToast('Events deleted Successfully');
    })
    .catch((error) => {
      dispatch({
        type: actions.DELETE_EVENTS_ERROR
      });
      errorToast(error);
    });
};

export const addFilter = (data) => (dispatch) => {
  dispatch({
    type: actions.ADD_EVENT_FILTER,
    payload: data
  });
};
