import * as actions from './types';
const initialState = {
  statements: [],
  loading: false,
  create_loading: false,
  single_statement: null,
  single_loading: false,
  edit_loading: false,
  delete_loading: false
};

const statementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CREATE_STATEMENT_BEGIN:
      return { ...state, create_loading: true };

    case actions.CREATE_STATEMENT_SUCCESS:
      return { ...state, create_loading: false };

    case actions.CREATE_STATEMENT_ERROR:
      return { ...state, create_loading: false };

    case actions.GET_STATEMENT_BEGIN:
      return { ...state, loading: true };

    case actions.GET_STATEMENT_SUCCESS:
      return { ...state, statements: action.payload, loading: false };

    case actions.GET_STATEMENT_ERROR:
      return { ...state, loading: false };

    case actions.GET_SINGLE_STATEMENT_BEGIN:
      return { ...state, single_loading: true };

    case actions.GET_SINGLE_STATEMENT_SUCCESS:
      return { ...state, single_statement: action.payload, single_loading: false };

    case actions.GET_SINGLE_STATEMENT_ERROR:
      return { ...state, single_loading: false };

    case actions.EDIT_STATEMENT_BEGIN:
      return { ...state, edit_loading: true };

    case actions.EDIT_STATEMENT_ERROR:
    case actions.EDIT_STATEMENT_SUCCESS:
      return { ...state, edit_loading: false };

    case actions.DELETE_STATEMENT_BEGIN:
      return { ...state, delete_loading: true };

    case actions.DELETE_STATEMENT_ERROR:
    case actions.DELETE_STATEMENT_SUCCESS:
      return { ...state, delete_loading: false };

    default:
      return state;
  }
};

export default statementReducer;
