import BreadCrumb from 'components/globals/breadCrumb';
import FilterForm from './filterForm';
import MainAreaLayout from 'layouts/dashboardLayout/mainAreaLayout/MainAreaLayout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCollectivePayroll } from '../redux/actions';
import CollectiveTable from './CollectiveTable';
import { FormattedMessage } from 'react-intl';
import { changeDateFormat } from 'utils/dateUtils';

function CollectivePayroll() {
  const dispatch = useDispatch();

  const date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const { collectiveFilter } = useSelector((state) => state.payroll);
  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(firstDay);
  const [status, setStatus] = useState('');
  const [agreementId, setAgreementId] = useState('');

  useEffect(() => {
    collectiveFilter?.startDate && setStartDate(collectiveFilter?.startDate);
    collectiveFilter?.endDate && setEndDate(collectiveFilter?.endDate);
    collectiveFilter?.status && setStatus(collectiveFilter?.status);
  }, []);

  useEffect(() => {
    // console.log(new Date(startDate));
    // console.log(changeDateFormat(new Date(startDate), 'YYYY-MM-DD'));
    dispatch(
      getCollectivePayroll(
        changeDateFormat(new Date(startDate), 'YYYY-MM-DD'),
        changeDateFormat(new Date(endDate), 'YYYY-MM-DD'),
        status,
        agreementId
      )
    );
  }, [startDate, endDate, status, agreementId]);

  const handleRefetch = () => {
    startDate &&
      endDate &&
      dispatch(
        getCollectivePayroll(
          changeDateFormat(new Date(startDate), 'YYYY-MM-DD'),
          changeDateFormat(new Date(endDate), 'YYYY-MM-DD'),
          status,
          agreementId
        )
      );
  };

  return (
    <>
      <BreadCrumb
        itemName={<FormattedMessage id="Collective Payroll" defaultMessage="Collective Payroll" />}
        page={<FormattedMessage id="Collective Payroll" defaultMessage="Collective Payroll" />}
      />
      <FilterForm
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        startDate={startDate}
        endDate={endDate}
        status={status}
        setStatus={setStatus}
        setAgreementId={setAgreementId}
      />

      <MainAreaLayout>
        <CollectiveTable startDate={startDate} endDate={endDate} handleRefetch={handleRefetch} />
      </MainAreaLayout>
    </>
  );
}

export default CollectivePayroll;
