import EditIcon from '@mui/icons-material/Edit';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import CustomModal from 'components/globals/modal';
import CustomTable from 'components/globals/table';
import useToggle from 'hooks/useToggle';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { translate } from 'utils';
import TerminateContract from './employmentContract/components/TerminateContract';
import ContractEdit from './employmentContract/Edit';
import ContractView from './employmentContract/viewEmployeeContract/ContractView';
import useStyles from './styles';

export default function EmployeeContractTable({ employeeId }) {
  const typeOfContract = [
    {
      label: translate('Indefinite Period of Time'),
      value: 'indefinite'
    },
    {
      label: translate('Fixed Term'),
      value: 'fixed'
    },
    { label: translate('Trial'), value: 'trial' },
    {
      label: translate('Replacement'),
      value: 'replacement'
    }
  ];

  const columns = [
    { title: 'S.N.', type: 'Index', isSortable: true, minWidth: 20 },
    {
      title: <FormattedMessage id="Contract Number" defaultMessage="Contract Number" />,
      minWidth: 60,
      isSortable: true,
      field: (row) => <ContractNumberView row={row} />
    },
    {
      title: <FormattedMessage id="Contract Type" defaultMessage="Contract Type" />,
      minWidth: 150,
      align: 'right',
      field: (row) => <ContractName agreementId={row?.agreement_type_id} />
    },
    {
      field: 'start_date',
      title: <FormattedMessage id="Contract From" defaultMessage="Contract From" />,
      minWidth: 170,
      align: 'right'
    },
    {
      field: 'end_date',
      title: <FormattedMessage id="Contract To" defaultMessage="Contract To" />,
      minWidth: 120,
      align: 'right'
    },

    {
      title: <FormattedMessage id="Duration" defaultMessage="Duration" />,
      minWidth: 170,
      align: 'right',
      field: (row) => {
        console.log(row, 'row');
        const filteredType = typeOfContract?.find(
          (item) => item?.value == row?.type?.toLowerCase()
        );
        return filteredType?.label || '';
      }
    },

    {
      field: 'gross_salary',
      title: <FormattedMessage id="Gross" defaultMessage="Gross" />,
      minWidth: 170,
      align: 'right'
    },

    {
      field: (row) => {
        return <Actions contractId={row?.id} employeeId={employeeId} />;
      },
      title: <FormattedMessage id="Actions" defaultMessage="Actions" />,
      minWidth: 170,
      align: 'center'
    }
  ];

  const { employee_contract, employee_contract_loading } = useSelector((state) => state.contract);
  const rows = employee_contract || [];

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <CustomTable tableData={rows} tableHeads={columns} loading={employee_contract_loading} />
    </Paper>
  );
}

const Actions = ({ contractId, employeeId }) => {
  const handleClick = () => {
    openFunction();
  };
  const classes = useStyles();
  const [modalOpen, openFunction] = useToggle(false);
  const [terminateModalOpen, terminateFunction] = useToggle(false);

  return (
    <>
      <div className={classes.iconWrapper}>
        <Tooltip title="Terminate Contract">
          <IndeterminateCheckBoxIcon onClick={terminateFunction} />
        </Tooltip>
        <Tooltip title="Edit">
          <EditIcon onClick={handleClick} />
        </Tooltip>
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        headerText={<FormattedMessage id="Edit Contract" defaultMessage="Edit Contract" />}>
        <ContractEdit handleClose={openFunction} contractId={contractId} employeeId={employeeId} />
      </CustomModal>
      <CustomModal
        open={terminateModalOpen}
        handleClose={terminateFunction}
        height="auto"
        width="40rem"
        headerText={
          <FormattedMessage id="Terminate Contract" defaultMessage="Terminate Contract" />
        }>
        <TerminateContract handleClose={terminateFunction} contractId={contractId} />
      </CustomModal>
    </>
  );
};

const ContractName = ({ agreementId }) => {
  const { agreement_types } = useSelector((state) => state.contract);
  return <span>{agreement_types?.find((agreement) => agreement?.id == agreementId)?.name}</span>;
};

const ContractNumberView = ({ row }) => {
  const [modalOpen, openFunction] = useToggle(false);
  const { employees } = useSelector((state) => state.employee);
  const [name, setName] = useState('');
  const classes = useStyles();
  const handleClick = () => {
    openFunction();
  };
  useEffect(() => {
    const employeeName = employees?.find((employee) => employee?.id == row?.employee_id);
    const firstName = employeeName?.first_name ?? '';
    const secondName = employeeName?.middle_name ?? '';
    const lastName = employeeName?.last_name ?? '';
    setName(lastName + ' ' + firstName + ' ' + secondName);
  }, [employees]);
  return (
    <>
      <div className={classes.fullname} onClick={handleClick}>
        {row?.contract_number}
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="100rem"
        variant="light"
        headerText={`${translate('Details of')} ${row?.contract_number} - ${name}`}>
        <ContractView id={row?.id} employeeName={name} />
      </CustomModal>
    </>
  );
};
