import {
  addPUEApi,
  editPayrollClosingApi,
  getEzlaApi,
  getPayrollClosingApi,
  getPUEApi
} from 'apis/settings';
import { errorToast, successToast } from 'utils/toast';
import * as actions from './types';

// get payroll closing
export const getPayrollClosing = () => (dispatch) => {
  dispatch({
    type: actions.GET_COMPANY_PAYROLL_CLOSING_BEGIN
  });
  getPayrollClosingApi()
    .then((res) => {
      dispatch({
        type: actions.GET_COMPANY_PAYROLL_CLOSING_SUCCESS,
        payload: res.data?.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_COMPANY_PAYROLL_CLOSING_ERROR
      });
      errorToast(error);
    });
};

// edit payroll closing
export const editPayrollClosing = (slug, data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.EDIT_COMPANY_PAYROLL_CLOSING_BEGIN
  });
  editPayrollClosingApi(slug, data)
    .then((res) => {
      dispatch({
        type: actions.EDIT_COMPANY_PAYROLL_CLOSING_SUCCESS
      });
      handleFunction && handleFunction(res.data?.data);
      successToast('Company Payroll Closing edited Successfully');
    })
    .catch((error) => {
      dispatch({
        type: actions.EDIT_COMPANY_PAYROLL_CLOSING_ERROR
      });
      errorToast(error);
    });
};

export const getPUE = () => (dispatch) => {
  dispatch({
    type: actions.GET_PUE_BEGIN
  });
  getPUEApi()
    .then((res) => {
      dispatch({
        type: actions.GET_PUE_SUCCESS,
        payload: res.data?.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_PUE_ERROR
      });
      errorToast(error);
    });
};

// edit PUE
export const addPUE = (data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.ADD_PUE_BEGIN
  });
  addPUEApi(data)
    .then((res) => {
      dispatch({
        type: actions.ADD_PUE_SUCCESS
      });
      handleFunction && handleFunction(res.data?.data);
      successToast('PUE updated Successfully');
    })
    .catch((error) => {
      dispatch({
        type: actions.ADD_PUE_ERROR
      });
      errorToast(error);
    });
};

// get ezla data

export const getEzla = (data) => (dispatch) => {
  dispatch({
    type: actions.GET_EZLA_BEGIN
  });
  getEzlaApi(data)
    .then((res) => {
      dispatch({
        type: actions.GET_EZLA_SUCCESS,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_EZLA_ERROR
      });
      errorToast(error);
    });
};

export const clearEzla = () => (dispatch) => {
  dispatch({ type: actions.CLEAR_EZLA });
};
