import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  companyContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    background: '#f4f4f4'
  },
  NIP: {
    fontSize: '11px',
    color: 'gray',
    display: 'block',
    fontWeight: '600'
  },
  companyWrapper: {
    padding: '30px 45px',
    background: 'white',
    borderRadius: '6px',
    '& ul': {
      listStyle: 'none',
      margin: '0',
      padding: '0',
      maxHeight: '420px',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      '& div': {
        padding: '6px 30px',
        textDecoration: 'none',
        background: '#f3f3f3',
        textAlign: 'center',
        color: '#3c3c3c',
        borderRadius: '3px',
        display: 'block',
        marginBottom: '15px',
        fontSize: '14px',
        transition: 'all 0.25s ease-in',
        maxWidth: '500px',
        '&:hover': {
          background: '#496ad0',
          color: '#fff',
          cursor: 'pointer',
          '& span': {
            color: '#fff'
          }
        }
      }
    }
  },
  mainTitle: {
    color: '#496ad0',
    fontSize: '18px',
    textAlign: 'center',
    fontWeight: '500',
    marginBottom: '15px'
  },
  noCompany: {
    textAlign: 'center',
    fontSize: '14px',
    color: 'gray'
  },
  modalFooter: {
    width: '100%',
    marginTop: 'auto',
    marginLeft: 'auto',
    background: '#fff',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem 3rem',
    height: '4.9rem'
  },
  modalFooterButtons: {
    fontFamily: 'Euclid',
    ':&>*': {
      margin: '0 1rem'
    }
  },
  modalFooterCancel: {
    '&:hover': {
      background: '#ddd'
    }
  }
}));

export default useStyles;
