import { Grid } from '@mui/material';
import EmployerCost from './employee/EmployerCost';
import Overtime from './employee/Overtime';
import PayslipMain from './employee/PayslipMain';
import WorkingSettlement from './employee/WorkingSettlement';

const EmployeeContent = ({ keyData, inputData, item, overTimeData }) => {
  console.log(item, 'item');
  return (
    <Grid container spacing={3}>
      <Grid item sm={7}>
        <PayslipMain item={keyData} input={inputData} />
      </Grid>
      <Grid item sm={5}>
        <WorkingSettlement
          item={keyData}
          data={item}
          paylistId={item?.id}
          paylistDate={item?.date}
        />
        <EmployerCost item={keyData} />
        {overTimeData?.length > 0 && <Overtime data={overTimeData} />}
      </Grid>
    </Grid>
  );
};

export default EmployeeContent;
