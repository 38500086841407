import { Grid } from '@mui/material';
import React from 'react';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { translate } from 'utils';

import useStyles from './styles';

function ViewOvertime({ row }) {
  console.log(row);
  const classes = useStyles();
  const months = [
    { label: translate('January'), value: 1 },
    { label: translate('February'), value: 2 },
    { label: translate('March'), value: 3 },
    { label: translate('April'), value: 4 },
    { label: translate('May'), value: 5 },
    { label: translate('June'), value: 6 },
    { label: translate('July'), value: 7 },
    { label: translate('August'), value: 8 },
    { label: translate('September'), value: 9 },
    { label: translate('October'), value: 10 },
    { label: translate('November'), value: 11 },
    { label: translate('December'), value: 12 }
  ];
  const findMonth = useCallback(() => {
    const monthObj = months?.find((item) => item.value == row?.month);
    return monthObj?.label || '';
  }, [row?.month]);
  return (
    <div className={classes.viewRoot}>
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <ul>
            <ViewList
              label={<FormattedMessage id="Name" defaultMessage="Name" />}
              value={row?.name}
            />
            <ViewList
              label={<FormattedMessage id="Value" defaultMessage="Value" />}
              value={row?.value}
            />
            <ViewList
              label={<FormattedMessage id="Type" defaultMessage="Type" />}
              value={row?.type}
            />
          </ul>
        </Grid>
        <Grid item sm={6}>
          <ul>
            <ViewList
              label={<FormattedMessage id="Year" defaultMessage="Year" />}
              value={row?.year}
            />
            <ViewList
              label={<FormattedMessage id="Month" defaultMessage="Month" />}
              value={findMonth()}
            />
          </ul>
        </Grid>
      </Grid>
    </div>
  );
}

export default ViewOvertime;

const ViewList = ({ label, value }) => {
  const classes = useStyles();
  return (
    <li>
      <div className={classes.title}>{label}</div>
      <div className={classes.value}>{value}</div>
    </li>
  );
};
