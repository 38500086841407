// this will be the componene for filterForm
import { Autocomplete, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { translate } from 'utils';
import useStyles from './styles';

const Filterform = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  employee,
  setEmployee,
  setAgreementId
}) => {
  const classes = useStyles();
  const { agreement_types } = useSelector((state) => state.contract);
  const { employees } = useSelector((state) => state.employee);
  const handleChange = (value) => {
    setEmployee(value);
  };
  return (
    <div className={classes.root}>
      <Box display="none">
        <FormattedMessage id="Status" defaultMessage="Status" />
        <FormattedMessage id="Open" defaultMessage="Open" />
        <FormattedMessage id="Close" defaultMessage="Close" />
      </Box>
      <div className={classes.optionWrap}>
        <Box className={classes.dateWrapper}>
          <DatePicker
            views={['year', 'month']}
            label={<FormattedMessage id="Start Date" defaultMessage="Start Date" />}
            value={startDate}
            name="start_date"
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={null}
                format="YYYY-MM-DD"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
          <div className={classes.hyphen}>-</div>
          <DatePicker
            views={['year', 'month']}
            label={<FormattedMessage id="End Date" defaultMessage="End Date" />}
            value={endDate}
            name="end_date"
            onChange={(newValue) => {
              setEndDate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={null}
                format="YYYY-MM-DD"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Box>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          className={classes.employeeSearch}
          options={
            employees?.map((item) => ({
              label: item?.last_name ? item?.last_name + ' ' + item?.first_name : '',
              value: item?.id
            })) || []
          }
          sx={{ width: 200 }}
          isOptionEqualToValue={(option) => option.value}
          getOptionLabel={(option) => option.label}
          onChange={(e, data) => handleChange(data?.value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={<FormattedMessage id="Employee" defaultMessage="Employee" />}
              InputLabelProps={{ shrink: true }}
              value={employee}
            />
          )}
        />
        <select
          className={classes.select}
          onChange={(e) => {
            setAgreementId(e.target.value);
          }}>
          <option value="">{translate('Type of Contract')}</option>
          {agreement_types?.map((agreement) => (
            <option value={agreement?.id} key={agreement?.id}>
              {agreement?.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Filterform;
