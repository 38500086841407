import {
  addCompanyDetailApi,
  closeBulkPayListApi,
  getCompanyListApi,
  sendAllBulkActionApi,
  sendBulkMailApi,
  sendBulkPayoutApi
} from 'apis/company';
import {} from 'apis/employee';
import { errorToast, successToast } from 'utils/toast';
import * as actions from './types';

// get company list
export const getCompanyList = (startDate, endDate, status, agreement) => (dispatch) => {
  dispatch({
    type: actions.GET_COMPANY_LIST_BEGIN
  });
  getCompanyListApi(startDate, endDate, status, agreement)
    .then((res) => {
      dispatch({
        type: actions.GET_COMPANY_LIST_SUCCESS,
        payload: res.data.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_COMPANY_LIST_ERROR
      });
      // errorToast(error);
    });
};

export const sendBulkMail = (data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.ADD_BULK_SEND_MAIL_BEGIN
  });
  sendBulkMailApi(data)
    .then((res) => {
      dispatch({
        type: actions.ADD_BULK_SEND_MAIL_SUCCESS
      });
      successToast('Mail Sent Successfully');
      handleFunction && handleFunction();
    })
    .catch((error) => {
      dispatch({
        type: actions.ADD_BULK_SEND_MAIL_ERROR
      });
      errorToast(error);
    });
};

export const sendBulkPayout = (data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.ADD_BULK_SEND_PAYOUT_BEGIN
  });
  sendBulkPayoutApi(data)
    .then((res) => {
      dispatch({
        type: actions.ADD_BULK_SEND_PAYOUT_SUCCESS
      });
      successToast('Payout Sent Successfully');
      handleFunction && handleFunction();
    })
    .catch((error) => {
      dispatch({
        type: actions.ADD_BULK_SEND_PAYOUT_ERROR
      });
      errorToast(error);
    });
};

export const closeBulkPayList = (data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.ADD_BULK_CLOSE_PAY_LIST_BEGIN
  });
  closeBulkPayListApi(data)
    .then((res) => {
      dispatch({
        type: actions.ADD_BULK_CLOSE_PAY_LIST_SUCCESS
      });
      successToast('PayList closed  Successfully');
      handleFunction && handleFunction();
    })
    .catch((error) => {
      dispatch({
        type: actions.ADD_BULK_CLOSE_PAY_LIST_ERROR
      });
      errorToast(error);
    });
};

export const sendAllBulkAction = (data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.ADD_ALL_BULK_ACTION_BEGIN
  });
  sendAllBulkActionApi(data)
    .then((res) => {
      dispatch({
        type: actions.ADD_ALL_BULK_ACTION_BEGIN
      });
      successToast('Combined action initiated');
      handleFunction && handleFunction();
    })
    .catch((error) => {
      dispatch({
        type: actions.ADD_ALL_BULK_ACTION_BEGIN
      });
      errorToast(error);
    });
};

export const addCompanyFilter = (data) => (dispatch) => {
  dispatch({
    type: actions.ADD_COMPANY_FILTER,
    payload: data
  });
};

export const addCompanyDetail = (data) => (dispatch) => {
  addCompanyDetailApi(data)
    .then((res) => {
      dispatch({
        type: actions.ADD_COMPANY_DETAIL_SUCCESS,
        payload: res.data
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
