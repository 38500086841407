import { Grid, Skeleton } from '@mui/material';
import { Box } from '@mui/system';
import { HookFormInput } from 'components/form/hookFormInput';
import { HookFormSelect } from 'components/form/hookFormSelect';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from 'utils';
import { yearList } from 'utils/dateUtils';
import { getOvertimeAmount } from './redux/actions';

function OvertimeForm({ edit = false, employeeId }) {
  const [agreement, setAgreement] = useState([]);
  const [initial, setInitial] = useState(false);
  const { single_loading } = useSelector((state) => state.employee);
  const { employee_contract } = useSelector((state) => state.contract);
  const { amount, amount_loading } = useSelector((state) => state.overtime);
  const { watch, setValue } = useFormContext();
  const dispatch = useDispatch();
  const months = [
    { label: translate('January'), value: 1 },
    { label: translate('February'), value: 2 },
    { label: translate('March'), value: 3 },
    { label: translate('April'), value: 4 },
    { label: translate('May'), value: 5 },
    { label: translate('June'), value: 6 },
    { label: translate('July'), value: 7 },
    { label: translate('August'), value: 8 },
    { label: translate('September'), value: 9 },
    { label: translate('October'), value: 10 },
    { label: translate('November'), value: 11 },
    { label: translate('December'), value: 12 }
  ];

  useEffect(() => {
    const filterAgreement = employee_contract
      ?.filter((list) => list?.agreement_type_slug != 'contract-for-specific-work')
      ?.map((item) => ({
        label: `${item?.agreement_type_name} - ${item?.contract_number}`,
        value: item?.id
      }));

    setAgreement(filterAgreement ?? []);
  }, [employee_contract]);

  const names = [
    { name: 'Nadgodziny 50%', value: 50 },
    { name: 'Nadgodziny 100%', value: 100 },
    { name: 'godziny nocne 20%', value: 20 }
  ];

  const nameArray = [
    {
      label: <FormattedMessage id="Overtime 50%" defaultMessage="Overtime 50%" />,
      value: names[0].name
    },
    {
      label: <FormattedMessage id="Overtime 100%" defaultMessage="Overtime 100%" />,
      value: names[1].name
    },
    {
      label: <FormattedMessage id="Night hours 20%" defaultMessage="Night hours 20%" />,
      value: names[2].name
    }
  ];

  const findValue = (item) => {
    return names?.find((list) => list?.name == item)?.value;
  };
  useEffect(() => {
    const data = watch();
    if (data?.agreement_id && data?.month && data?.year && data?.name && employeeId && initial) {
      dispatch(
        getOvertimeAmount({
          employee_id: employeeId,
          agreement_id: data?.agreement_id,
          value: findValue(data?.name),
          month: data?.month,
          year: data?.year
        })
      );
    }
  }, [watch('agreement_id'), watch('month'), watch('year'), watch('name')]);

  useEffect(() => {
    amount?.overtime_amount && initial && setValue('value', amount?.overtime_amount);
    setInitial(true);
  }, [amount]);

  return (
    <Box margin={4}>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <HookFormSelect
            name="name"
            label={<FormattedMessage id="Name" defaultMessage="Name" />}
            placeholder="Overtime"
            options={nameArray}
          />
        </Grid>
        <Grid item sm={6}>
          <HookFormSelect
            name="agreement_id"
            label={<FormattedMessage id="Agreement" defaultMessage="Agreement" />}
            loading={single_loading}
            options={agreement}
            // disabled={edit || contractId}
          />
        </Grid>
        <Grid item sm={6}>
          <HookFormInput
            name="hours"
            label={<FormattedMessage id="Number of hours" defaultMessage="Number of hours" />}
            type="number"
          />
        </Grid>

        <Grid item sm={6}>
          <HookFormSelect
            name="year"
            label={<FormattedMessage id="Year" defaultMessage="Year" />}
            options={yearList}
          />
        </Grid>
        <Grid item sm={6}>
          <HookFormSelect
            name="month"
            label={<FormattedMessage id="Month" defaultMessage="Month" />}
            options={months}
          />
        </Grid>
        <Grid item sm={6}>
          {!amount_loading ? (
            <HookFormInput
              name="value"
              label={<FormattedMessage id="Value" defaultMessage="Value" />}
              type="number"
            />
          ) : (
            <Box marginTop={1}>
              <Skeleton height={50} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default OvertimeForm;
