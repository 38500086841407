/* Build in libraries */
import { Button, Card, CardContent } from '@mui/material';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import { useState } from 'react';

/* Components */
import BusinessAddress from './BusinessAddress';
import Registration from './Registration';
import UserDetails from './UserDetails';

/* Style */
import { Box } from '@mui/system';
import { HookForm } from 'components/UI/Form/HookForm';
import { HookFormProvider } from 'components/UI/Form/HookFormProvider';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { parseAddress } from 'utils';
import { addCompany } from '../redux/actions';
import Styles from '../styles';
import { validationSchema } from './validationSchema';

function CompanyForm() {
  const classes = Styles();
  const navigate = useNavigate();

  const [submitStatus, setSubmitStatus] = useState(false);
  const [backValidation, setBackValidation] = useState();
  const dispatch = useDispatch();
  const [addressError, setAddressError] = useState();

  const redirectOnSuccess = () => {
    return navigate('/accounting');
  };

  const onSubmit = (data) => {
    const finalAddress = parseAddress(data?.addresses);

    const finalData = {
      ...data,
      is_small_company: data?.is_small_company ? 1 : 0,
      addresses: finalAddress
    };
    console.log(finalData, 'error');
    if (!finalData.addresses[0]) {
      setAddressError('Home Address is required');
    } else {
      let error = 0;
      Object.entries(finalData.addresses[0]).map((item) => {
        if (!(item[0] == 'house_number' || item[0] == 'number_of_premises'))
          if (!item[1]) {
            if (item[0] != 'same_address_type') {
              setAddressError('Every Field of Home Address is required');
              error = error + 1;
            }
          }
      });

      if (error == 0) {
        setSubmitStatus(true);
        setAddressError('');
        dispatch(addCompany(finalData, redirectOnSuccess, setSubmitStatus, setBackValidation));
      }
    }
  };

  const defaultValues = {
    addresses: []
  };

  return (
    <>
      <Card className={classes.cardWrapper}>
        <CardContent>
          <HookFormProvider
            defaultValues={defaultValues}
            resolver={useYupValidationResolver(validationSchema)}>
            <HookForm onSubmit={onSubmit}>
              <Registration backValidation={backValidation} setBackValidation={setBackValidation} />
              <BusinessAddress addressError={addressError} setAddressError={setAddressError} />
              <UserDetails />
              <Box className="buttonWrapper">
                {!submitStatus ? (
                  <Button variant="contained" type="submit" disabled={submitStatus}>
                    <FormattedMessage id="Save" defaultMessage="Save" />
                  </Button>
                ) : (
                  <Button className="saveButtonLoading" type="submit" disabled={submitStatus}>
                    Saving...
                  </Button>
                )}
                <Link to="/accounting" style={{ textDecoration: 'none' }}>
                  <Button className="cancelButton">
                    <FormattedMessage id="Cancel" defaultMessage="Cancel" />
                  </Button>
                </Link>
              </Box>
            </HookForm>
          </HookFormProvider>
        </CardContent>
      </Card>
    </>
  );
}

export default CompanyForm;
