import { Box, Button, CircularProgress } from '@mui/material';
import { axiosInstance } from 'apis/_axios';
import { useEffect, useState } from 'react';

const DeclarationView = ({ id }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  let fileURL;
  useEffect(() => {
    setLoading(true);
    axiosInstance()
      .get(`/payroll/declaration-view?collective_id=${id}`, {
        responseType: 'blob'
      })
      .then(function (response) {
        const file = new Blob([response?.data], {
          type: 'application/xml'
        });
        setLoading(false);

        fileURL = URL.createObjectURL(file);
        setData(fileURL);
      })

      .catch((e) => {
        setLoading(false);
      });
  }, []);

  const handleView = () => {
    data && window.open(data);
  };

  const download = async (url, filename) => {
    const data = await fetch(url);
    const blob = await data.blob();
    const objectUrl = URL.createObjectURL(blob);

    const link = document.createElement('a');

    link.setAttribute('href', objectUrl);
    link.setAttribute('download', filename);
    link.style.display = 'none';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const handleDownload = () => {
    data && download(data, 'Declaration');
  };

  return (
    <Box paddingY={7} paddingX={4} display="flex" justifyContent="center">
      {loading ? (
        <CircularProgress size={30} />
      ) : (
        <Box display="flex" justifyContent="space-around" width="100%">
          <Button variant="contained" onClick={handleView}>
            View
          </Button>
          <Button variant="outlined" onClick={handleDownload}>
            Download
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default DeclarationView;
