import { Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { FormattedMessage } from 'react-intl';
import { translate } from 'utils';
import { yearList } from 'utils/dateUtils';

function StatementForm({ edit = false, rate, setData, data }) {
  console.log(rate, 'ratee');
  const months = [
    { label: translate('January'), value: 1 },
    { label: translate('February'), value: 2 },
    { label: translate('March'), value: 3 },
    { label: translate('April'), value: 4 },
    { label: translate('May'), value: 5 },
    { label: translate('June'), value: 6 },
    { label: translate('July'), value: 7 },
    { label: translate('August'), value: 8 },
    { label: translate('September'), value: 9 },
    { label: translate('october'), value: 10 },
    { label: translate('November'), value: 11 },
    { label: translate('December'), value: 12 }
  ];

  const handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <Box margin={4}>
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <InputLabel id="label">
            <FormattedMessage id="Year" defaultMessage="Year" />
          </InputLabel>

          <Select name="year" fullWidth onChange={handleChange} value={data?.year}>
            {yearList?.map((list) => (
              <MenuItem value={list?.value} key={list?.value}>
                {list?.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item sm={4}>
          <InputLabel id="label">
            <FormattedMessage id="Month" defaultMessage="Month" />
          </InputLabel>
          <Select name="month" fullWidth onChange={handleChange} value={data?.month}>
            {months?.map((list) => (
              <MenuItem value={list?.value} key={list?.value}>
                {list?.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item sm={4}>
          <InputLabel id="label">
            {rate == 'hourly' && <FormattedMessage id="Hour" defaultMessage="Hour" />}
            {(rate == 'monthly' || rate == 'one-time') && (
              <FormattedMessage id="Amount" defaultMessage="Amount" />
            )}
          </InputLabel>
          <TextField name="value" onChange={handleChange} value={data?.value} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default StatementForm;
