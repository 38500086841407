import { Box, Button, Grid } from '@mui/material';
import { HookFormInput } from 'components/form/hookFormInput';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useStyles from '../styles';

function ParameterForm({ handleClose }) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.formContent}>
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <HookFormInput
              name="date_s"
              label={<FormattedMessage id="Start Date" defaultMessage="Start Date" />}
              placeholder="Date applicable from"
              type="date"
            />
          </Grid>
          <Grid item sm={12}>
            <HookFormInput
              name="par_value"
              type="number"
              label={<FormattedMessage id="Value" defaultMessage="Value" />}
              placeholder="Value"
            />
          </Grid>
        </Grid>
      </div>
      <Box className={classes.modalFooter}>
        <Box className={classes.modalFooterButtons}>
          <Button sx={{ marginRight: '1rem' }} variant={'contained'} type="submit">
            {<FormattedMessage id="Save" defaultMessage="Save" />}
          </Button>
          <Button className={classes.modalFooterCancel} variant={'outlined'} onClick={handleClose}>
            {<FormattedMessage id="Cancel" defaultMessage="Cancel" />}
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default ParameterForm;
