import { Button, Grid, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { changeDateFormat } from 'utils/dateUtils';
import { modifyPayroll } from '../redux/actions';
import styles from '../styles';

function EditClosingDate({ handleClose, item, handleRefetch }) {
  const classes = styles();
  const dispatch = useDispatch();
  const { modify_payroll_loading } = useSelector((state) => state.payroll);
  const [data, setData] = useState({
    collective_id: item?.id,
    name: item?.name || '',
    payment_date: item?.payment_date || '',
    closing_date: item?.closing_date || '',
    signature: item?.signature || ''
  });

  const handleChange = (e) => {
    let result = '';
    if (e.target.type === 'date') {
      result = changeDateFormat(e.target.value, 'YYYY-MM-DD');
    } else {
      result = e.target.value;
    }
    setData((prev) => ({ ...prev, [e.target.name]: result }));
  };

  const handleSubmit = () => {
    console.log(data, 'data');
    dispatch(modifyPayroll(data, handleClose, handleRefetch));
  };
  return (
    <>
      <Box sx={{ margin: '56px 30px', background: 'transparent' }}>
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <label htmlFor="name" style={{ display: 'block' }}>
              <FormattedMessage id="Name" defaultMessage="Name" />
            </label>
            <TextField
              fullWidth
              name="name"
              type="text"
              onChange={handleChange}
              value={data?.name}
            />
          </Grid>
          <Grid item sm={6}>
            <label htmlFor="date" style={{ display: 'block' }}>
              <FormattedMessage id="Closing Date" defaultMessage="Closing Date" />
            </label>
            <TextField
              fullWidth
              type="date"
              name="closing_date"
              onChange={handleChange}
              value={data?.closing_date}
            />
          </Grid>
          <Grid item sm={6}>
            <label htmlFor="date" style={{ display: 'block' }}>
              <FormattedMessage id="Payment Date" defaultMessage="Payment Date" />
            </label>
            <TextField
              fullWidth
              type="date"
              name="payment_date"
              onChange={handleChange}
              value={data?.payment_date}
            />
          </Grid>
          <Grid item sm={6}>
            <label htmlFor="date" style={{ display: 'block' }}>
              <FormattedMessage id="Signature" defaultMessage="Signature" />
            </label>
            <TextField
              fullWidth
              type="text"
              name="signature"
              onChange={handleChange}
              value={data?.signature}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.modalFooter}>
        <Box className={classes.modalFooterButtons}>
          {modify_payroll_loading ? (
            <Button sx={{ marginRight: '1rem' }} variant={'contained'} disabled>
              {<FormattedMessage id="Saving" defaultMessage="Saving" />}
            </Button>
          ) : (
            <Button sx={{ marginRight: '1rem' }} variant={'contained'} onClick={handleSubmit}>
              {<FormattedMessage id="Save" defaultMessage="Save" />}
            </Button>
          )}
          <Button className={classes.modalFooterCancel} variant={'outlined'} onClick={handleClose}>
            {<FormattedMessage id="Cancel" defaultMessage="Cancel" />}
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default EditClosingDate;
