/* Build in libraries */
import { useEffect, useState } from 'react';

/* Third party libraries */
import { useDispatch, useSelector } from 'react-redux';

/* Components */
// import CustomTable from 'components/UI/Table';

/* Services */
import { Box } from '@mui/material';
import CustomTable from 'components/UI/Table';
import BreadCrumb from 'components/globals/breadCrumb';
import CustomModal from 'components/globals/modal';
import MainAreaLayout from 'layouts/dashboardLayout/mainAreaLayout/MainAreaLayout';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { translate } from 'utils';
import CompanyDetail from '../CompanyDetail';
import CompanyUserForm from '../CompanyForm/CompanyUserDetail';
import EditUserDetails from '../CompanyForm/EditUserDetails';
import StatusChange from '../StatusChange/Index';
import FilterForm from '../filterForm';
import { fetchCompany } from '../redux/actions';
import RemoveCompany from './RemoveCompany';

export default function CompanyTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companies, isLoading } = useSelector((state) => state.accounting);
  const [tableData, setTableData] = useState([]);
  const [openModal, setOpenModal] = useState(true);
  const [modalType, setModalType] = useState(true);
  const [detail, setDetail] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [status, setStatus] = useState('');
  const [name, setName] = useState('');
  const [taxId, setTaxId] = useState();

  const handleModalOpen = (event, type, slug) => {
    event.stopPropagation();
    const companyDetail = companies?.data?.find((data) => data?.slug === slug);
    setDetail(companyDetail);
    setModalType(type);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const refetch = () => {
    dispatch(
      fetchCompany({
        page: page,
        rowsPerPage: rowsPerPage,
        name: name,
        status: status,
        taxId: taxId
      })
    );
  };

  useEffect(() => {
    refetch();
  }, [page, rowsPerPage, status]);

  useEffect(() => {
    if (companies?.data?.length > 0) {
      const data = companies?.data?.map((data, index) => {
        return {
          name: data?.name,
          email: data?.company_detail[0]?.value,
          address: data?.address?.[0]?.city,
          tax_id: data?.tax_id,
          reg_no: data?.regon,
          slug: data?.slug,
          statusId: data?.status?.id,
          status: data?.status?.title
        };
      });
      setTableData(data);
    } else {
      setTableData([]);
    }
  }, [companies]);

  useEffect(() => {
    const Timeout = setTimeout(() => {
      dispatch(
        fetchCompany({
          page: page,
          rowsPerPage: rowsPerPage,
          name: name,
          status: status,
          taxId: taxId
        })
      );
    }, 700);
    return () => {
      clearTimeout(Timeout);
    };
  }, [name]);
  useEffect(() => {
    const Timeout = setTimeout(() => {
      dispatch(
        fetchCompany({
          page: page,
          rowsPerPage: rowsPerPage,
          name: name,
          status: status,
          taxId: taxId
        })
      );
    }, 700);
    return () => {
      clearTimeout(Timeout);
    };
  }, [taxId]);

  const tableHead = [
    { id: 'sn', label: 'S.N', minWidth: 50 },
    {
      id: 'name',
      label: <FormattedMessage id="Name" defaultMessage="Name" />,
      minWidth: 170,
      cursor: 'pointer'
    },
    { id: 'email', label: <FormattedMessage id="Email" defaultMessage="Email" />, minWidth: 170 },
    {
      id: 'address',
      label: <FormattedMessage id="Address" defaultMessage="Address" />,
      minWidth: 170
    },
    {
      id: 'tax_id',
      label: <FormattedMessage id="NIP" defaultMessage="NIP" />,
      minWidth: 170
    },
    {
      id: 'reg_no',
      label: <FormattedMessage id="Reg No." defaultMessage="Reg No." />,
      minWidth: 170
    },
    {
      id: 'status',
      label: <FormattedMessage id="Status" defaultMessage="Status" />,
      minWidth: 170
    },
    {
      id: 'actions',
      label: <FormattedMessage id="Action" defaultMessage="Action" />,
      minWidth: 170
    }
  ];

  return (
    <>
      <Box display="none">
        <FormattedMessage id="User" defaultMessage="User" />
        <FormattedMessage id="Users" defaultMessage="Users" />
        <FormattedMessage id="Detail of" defaultMessage="Detail of" />
        <FormattedMessage id="Change Status of" defaultMessage="Change Status of" />
      </Box>
      <BreadCrumb
        itemName={<FormattedMessage id="Company" defaultMessage="Company" />}
        page={<FormattedMessage id="Company" defaultMessage="Company" />}
        // handleSearch={handleSearch}
        handleButton={() => navigate('/accounting/add')}
        buttonTitle={<FormattedMessage id="Add Company" defaultMessage="Add Company" />}
      />

      <FilterForm
        setStatus={setStatus}
        status={status}
        name={name}
        setName={setName}
        taxId={taxId}
        setTaxId={setTaxId}
      />
      <MainAreaLayout>
        <CustomTable
          columns={tableHead}
          rows={tableData}
          handleModalOpen={handleModalOpen}
          handleClose={handleClose}
          loading={isLoading}
          setPage={setPage}
          page={page}
          total={companies?.meta?.total}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </MainAreaLayout>

      {modalType === 'detail' && (
        <CustomModal
          open={openModal}
          handleClose={handleClose}
          height="auto"
          width="100rem"
          variant="light"
          headerText={`${translate('Detail of')} ${detail?.name}`}>
          <CompanyDetail company={detail} />
        </CustomModal>
      )}
      {modalType === 'userDetail' && (
        <CustomModal
          open={openModal}
          handleClose={handleClose}
          height="auto"
          width="50rem"
          variant="light"
          headerText={`${translate('Update')} ${detail?.name} ${translate('User')}`}>
          <EditUserDetails handleClose={handleClose} slug={detail?.slug} />
        </CustomModal>
      )}
      {modalType === 'status' && (
        <CustomModal
          open={openModal}
          handleClose={handleClose}
          height="auto"
          width="50rem"
          variant="light"
          headerText={`${translate('Change Status of')}  ${detail?.name}`}>
          <StatusChange
            handleClose={handleClose}
            slug={detail?.slug}
            statusId={detail?.status?.id}
          />
        </CustomModal>
      )}
      {modalType === 'companyUser' && (
        <CustomModal
          open={openModal}
          handleClose={handleClose}
          height="auto"
          width="80rem"
          variant="light"
          headerText={` ${detail?.name} ${translate('Users')}`}>
          <CompanyUserForm handleClose={handleClose} slug={detail?.slug} />
        </CustomModal>
      )}
      {modalType === 'removeCompany' && (
        <CustomModal
          open={openModal}
          handleClose={handleClose}
          height="auto"
          width="50rem"
          variant="light"
          headerText={<FormattedMessage id="Remove Company" defaultMessage="Remove Company" />}>
          <RemoveCompany handleClose={handleClose} slug={detail?.slug} refetch={refetch} />
        </CustomModal>
      )}
    </>
  );
}
