import React from 'react';
import DashboardLayout from 'layouts/dashboardLayout';
import DocumentPage from 'components/locals/document/Document';

const Document = () => {
  return (
    <DashboardLayout>
      <DocumentPage />
    </DashboardLayout>
  );
};

export default Document;
