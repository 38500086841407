import * as actions from './types';
const initialState = {
  isLoading: false,
  isAuthenticated: false,
  error: '',
  companies: null,
  singleCompany: {},
  edit_admin_loading: false,
  companyAdmins: [],
  adminLoading: false,
  adminCreateLoading: false,
  changeStatusLoading: false,
  removeUserLoading: false,
  accountingProfile: null,
  accountingProfile_loading: false,
  editProfile_loading: false,
  companyStatusLoading: false,
  removeCompanyLoading: false
};

export const accountingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_ACCOUNTING_BEGINS:
      return {
        ...state,
        companies: [],
        isLoading: true
      };
    case actions.FETCH_SINGLE_COMPANY_BEGINS:
      return {
        ...state,
        singleCompany: {},
        isLoading: true
      };
    case actions.FETCH_ACCOUNTING_ERROR:
    case actions.FETCH_SINGLE_COMPANY_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case actions.FETCH_ACCOUNTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        companies: action.payload
      };

    case actions.FETCH_SINGLE_COMPANY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        singleCompany: action.payload
      };

    case actions.EDIT_ADMIN_BEGINS:
      return {
        ...state,
        edit_admin_loading: true
      };

    case actions.EDIT_ADMIN_SUCCESS:
    case actions.EDIT_ADMIN_ERROR:
      return {
        ...state,
        edit_admin_loading: false
      };

    case actions.CREATE_COMPANY_ADMIN_BEGIN:
      return { ...state, adminCreateLoading: true };

    case actions.CREATE_COMPANY_ADMIN_ERROR:
    case actions.CREATE_COMPANY_ADMIN_SUCCESS:
      return { ...state, adminCreateLoading: false };

    case actions.FETCH_COMPANY_ADMIN_BEGIN:
      return { ...state, adminLoading: true };

    case actions.FETCH_COMPANY_ADMIN_ERROR:
      return { ...state, adminLoading: false };

    case actions.FETCH_COMPANY_ADMIN_SUCCESS:
      return { ...state, adminLoading: false, companyAdmins: action.payload };

    case actions.CHANGE_ADMIN_STATUS_BEGIN:
      return {
        ...state,
        changeStatusLoading: true
      };

    case actions.CHANGE_ADMIN_STATUS_ERROR:
    case actions.CHANGE_ADMIN_STATUS_SUCCESS:
      return {
        ...state,
        changeStatusLoading: false
      };

    case actions.DELETE_ADMIN_USER_BEGIN:
      return {
        ...state,
        removeUserLoading: true
      };

    case actions.DELETE_ADMIN_USER_ERROR:
    case actions.DELETE_ADMIN_USER_SUCCESS:
      return {
        ...state,
        removeUserLoading: false
      };

    case actions.FETCH_ACCOUNTING_PROFILE_BEGINS:
      return {
        ...state,
        accountingProfile_loading: true
      };

    case actions.FETCH_ACCOUNTING_PROFILE_SUCCESS:
      return {
        ...state,
        accountingProfile_loading: false,
        accountingProfile: action.payload
      };

    case actions.FETCH_ACCOUNTING_PROFILE_ERROR:
      return {
        ...state,
        accountingProfile_loading: false
      };

    case actions.EDIT_ACCOUNTING_PROFILE_BEGINS:
      return {
        ...state,
        editProfile_loading: true
      };

    case actions.EDIT_ACCOUNTING_PROFILE_SUCCESS:
    case actions.EDIT_ACCOUNTING_PROFILE_ERROR:
      return {
        ...state,
        editProfile_loading: false
      };
    case actions.CHANGE_COMPANY_STATUS_BEGIN:
      return {
        ...state,
        companyStatusLoading: true
      };

    case actions.CHANGE_COMPANY_STATUS_ERROR:
    case actions.CHANGE_COMPANY_STATUS_SUCCESS:
      return {
        ...state,
        companyStatusLoading: false
      };

    case actions.REMOVE_COMPANY_BEGIN:
      return { ...state, removeCompanyLoading: true };

    case actions.REMOVE_COMPANY_SUCCESS:
    case actions.REMOVE_COMPANY_ERROR:
      return { ...state, removeCompanyLoading: false };
    default:
      return state;
  }
};
