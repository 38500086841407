/* Build in libraries */

/* Third party libraries */
import { Box } from '@mui/material';
import CompanyUserForm from 'components/locals/accounting/CompanyForm';

/* Components */
import AccountingLayout from 'layouts/dashboardLayout';

function AddAccounting() {
  return (
    <>
      <AccountingLayout>
        <Box pt={4} pl={4} pr={2} pb={2} sx={{ background: '#f5f5f5' }}>
          <CompanyUserForm />
        </Box>
      </AccountingLayout>
    </>
  );
}

export default AddAccounting;
