import { axiosInstance } from 'apis/_axios';
import { mimeTypes } from './mimeType';

const filePreview = async (id, extension = null) => {
  let strMimeType;
  let fileURL;

  const fileResponse = axiosInstance()
    .get(`/api/get-media/${id}`, {
      responseType: 'blob'
    })
    .then(function (response) {
      strMimeType = mimeTypes?.[extension];
      const file = new Blob([response?.data], {
        type: strMimeType
      });

      fileURL = URL.createObjectURL(file);

      if (
        extension === 'docx' ||
        extension === 'xls' ||
        extension === 'xlxs' ||
        extension === 'doc'
      ) {
        return fileURL;
      } else {
        window.open(fileURL);
      }
    })

    .catch((e) => {});
  return fileResponse;
};

export default filePreview;
