import {
  CreditCardOutlined,
  ExitToAppOutlined,
  ExpandMore,
  GroupOutlined,
  HomeOutlined,
  InsertDriveFileOutlined
} from '@mui/icons-material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import LayersIcon from '@mui/icons-material/Layers';
import ListAltIcon from '@mui/icons-material/ListAlt';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { Drawer, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkAccounting, fetchCompany, logout } from 'redux/auth/actions';
import useStyles from './styles';

const Sidebar = ({ drawerOpen }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [expand, setExpand] = useState(false);
  const [expandSetting, setExpandSetting] = useState(false);

  const { companyId, company } = useSelector((state) => state.auth);
  const { pathname } = useLocation();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  useEffect(() => {
    if (pathname == '/payroll/collective' || pathname == '/payroll/items') {
      setExpand(true);
    }
    if (
      pathname == '/parameter' ||
      pathname == '/settings' ||
      pathname == '/setup' ||
      pathname == '/calendar'
    ) {
      setExpandSetting(true);
    }
  }, [pathname]);

  useEffect(() => {
    dispatch(fetchCompany());
  }, []);

  const checkCompanyAdmin = () => {
    const data = company?.find((list) => list?.user_is_admin);
    if (data) {
      return true;
    } else {
      return false;
    }
  };

  const handleWindow = () => {
    dispatch(checkAccounting(true));
    navigate('/dashboard');
  };

  return (
    <Drawer
      sx={{
        width: '217px',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '217px',
          boxSizing: 'border-box',
          boxShadow: '0px 2px 10px 5px #f3f1f1',
          borderRight: 'none'
        }
      }}
      variant="persistent"
      anchor="left"
      open={drawerOpen}>
      {/* ---------------------- */}
      <Box className={classes.sidebarHeader}>
        {companyId?.logo?.url && (
          <Box className={classes.logoWrapper}>
            {console.log(companyId?.logo?.url, 'logo')}
            <img src={companyId?.logo?.url} />
          </Box>
        )}
        <Box className={classes.companyName}>{companyId?.name}</Box>
      </Box>
      {/* -------------------------- */}
      <Box className={classes.listItems}>
        {/* ------------------------------- */}
        <Box
          className={`${classes.listItem} ${pathname === '/dashboard' && classes.listItemActive}`}
          onClick={() => navigate('/dashboard')}>
          <HomeOutlined className={classes.listItemIcons} />
          <Typography className={classes.listItemText}>
            <FormattedMessage id="Home" defaultMessage="Home" />
          </Typography>
        </Box>
        {/* ------------------------------- */}
        <Box
          className={`${classes.listItem}  ${pathname === '/workers' && classes.listItemActive}`}
          onClick={() => navigate('/workers')}>
          <GroupOutlined className={classes.listItemIcons} />
          <Typography className={classes.listItemText}>
            {' '}
            <FormattedMessage id="Workers" defaultMessage="Workers" />
          </Typography>
        </Box>
        {/* ------------------------------- */}
        <Box className={`${classes.listItem}`} onClick={() => setExpand((prev) => !prev)}>
          <CreditCardOutlined className={classes.listItemIcons} />
          <Typography className={classes.listItemText}>
            <span>
              {' '}
              <FormattedMessage id="Payroll" defaultMessage="Payroll" />
            </span>{' '}
            <ExpandMore ml={5} />
          </Typography>
        </Box>
        {expand && (
          <Box marginLeft={2}>
            <Box
              className={`${classes.listItem}  ${
                pathname === '/payroll/collective' && classes.listItemActive
              }`}
              onClick={() => navigate('/payroll/collective')}>
              <GroupOutlined className={classes.listItemIcons} />
              <Typography className={classes.listItemText}>
                {' '}
                <FormattedMessage id="Collective" defaultMessage="Collective" />
              </Typography>
            </Box>
            <Box
              className={`${classes.listItem}  ${
                pathname === '/payroll/items' && classes.listItemActive
              }`}
              onClick={() => navigate('/payroll/items')}>
              <GroupOutlined className={classes.listItemIcons} />
              <Typography className={classes.listItemText}>
                {' '}
                <FormattedMessage id="Items" defaultMessage="Items" />
              </Typography>
            </Box>
          </Box>
        )}
        {/* ------------------------------- */}
        <Box
          className={`${classes.listItem}  ${pathname === '/events' && classes.listItemActive}`}
          onClick={() => navigate('/events')}>
          <LayersIcon className={classes.listItemIcons} />
          <Typography className={classes.listItemText}>
            {' '}
            <FormattedMessage id="Events" defaultMessage="Events" />
          </Typography>
        </Box>
        {/* ------------------------------- */}
        <Box
          className={`${classes.listItem}  ${pathname === '/contract' && classes.listItemActive}`}
          onClick={() => navigate('/contract')}>
          <InsertDriveFileOutlined className={classes.listItemIcons} />
          <Typography className={classes.listItemText}>
            {' '}
            <FormattedMessage id="Contract" defaultMessage="Contract" />
          </Typography>
        </Box>
        {/* ------------------------------- */}
        <Box className={`${classes.listItem} `}>
          <ForwardToInboxIcon className={classes.listItemIcons} />
          <Typography className={classes.listItemText}>
            {' '}
            <FormattedMessage id="Declaration" defaultMessage="Declaration" />
          </Typography>
        </Box>
        {/* ------------------------------- */}
        <Box
          className={`${classes.listItem}  ${pathname === '/document' && classes.listItemActive}`}
          onClick={() => navigate('/document')}>
          <FolderOpenIcon className={classes.listItemIcons} />
          <Typography className={classes.listItemText}>
            {' '}
            <FormattedMessage id="Document" defaultMessage="Document" />
          </Typography>
        </Box>

        {/* ------------------------------- */}

        {/* ------------------------------- */}
        <Box className={`${classes.listItem}`} onClick={() => setExpandSetting((prev) => !prev)}>
          <SettingsSuggestIcon className={classes.listItemIcons} />
          <Typography className={classes.listItemText}>
            <span>
              {' '}
              <FormattedMessage id="Settings" defaultMessage="Settings" />
            </span>{' '}
            <ExpandMore ml={5} />
          </Typography>
        </Box>
        {expandSetting && (
          <Box marginLeft={2}>
            <Box
              className={`${classes.listItem}  ${
                pathname === '/settings' && classes.listItemActive
              }`}
              onClick={() => navigate('/settings')}>
              <GroupOutlined className={classes.listItemIcons} />
              <Typography className={classes.listItemText}>
                {' '}
                <FormattedMessage id="Profile" defaultMessage="Profile" />
              </Typography>
            </Box>
            <Box
              className={`${classes.listItem}  ${
                pathname === '/parameter' && classes.listItemActive
              }`}
              onClick={() => navigate('/parameter')}>
              <ListAltIcon className={classes.listItemIcons} />
              <Typography className={classes.listItemText}>
                {' '}
                <FormattedMessage id="Parameters" defaultMessage="Parameters" />
              </Typography>
            </Box>
            <Box
              className={`${classes.listItem}  ${pathname === '/setup' && classes.listItemActive}`}
              onClick={() => navigate('/setup')}>
              <CreditCardOutlined className={classes.listItemIcons} />
              <Typography className={classes.listItemText}>
                {' '}
                <FormattedMessage id="Setup" defaultMessage="Setup" />
              </Typography>
            </Box>
            <Box
              className={`${classes.listItem}  ${
                pathname === '/calendar' && classes.listItemActive
              }`}
              onClick={() => navigate('/calendar')}>
              <CalendarMonthIcon className={classes.listItemIcons} />
              <Typography className={classes.listItemText}>
                {' '}
                <FormattedMessage id="Calendar" defaultMessage="Calendar" />
              </Typography>
            </Box>
            <Box
              className={`${classes.listItem}  ${pathname === '/ezla' && classes.listItemActive}`}
              onClick={() => navigate('/ezla')}>
              <SettingsRemoteIcon className={classes.listItemIcons} />
              <Typography className={classes.listItemText}>
                {' '}
                <FormattedMessage id="Ezla" defaultMessage="Ezla" />
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box className={classes.buttonAdmin}>
        <Box className={classes.switchButton} onClick={handleWindow}>
          <RestartAltIcon className={`${classes.listItemIcons} ${classes.logoutIcon}`} />
          <Typography className={classes.listItemText}>
            <FormattedMessage id="Go to Dashboard" defaultMessage="Go to Dashboard" />
          </Typography>
        </Box>
        {checkCompanyAdmin() && (
          <Box className={classes.companyButton} onClick={() => navigate('/companies')}>
            <KeyboardCommandKeyIcon className={`${classes.listItemIcons} ${classes.logoutIcon}`} />
            <Typography className={classes.listItemText}>
              <FormattedMessage id="Company" defaultMessage="Company" />
            </Typography>
          </Box>
        )}
        <Box className={classes.switchButton} onClick={() => navigate('/company')}>
          <RestartAltIcon className={`${classes.listItemIcons} ${classes.logoutIcon}`} />
          <Typography className={classes.listItemText}>
            <FormattedMessage id="Switch Company" defaultMessage="Switch Company" />
          </Typography>
        </Box>

        <Box className={classes.logoutButton} onClick={handleLogout}>
          <ExitToAppOutlined className={`${classes.listItemIcons} ${classes.logoutIcon}`} />
          <Typography className={classes.listItemText}>
            {' '}
            <FormattedMessage id="Logout" defaultMessage="Logout" />
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
};
export default Sidebar;
