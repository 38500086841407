import * as actions from './types';
import { errorToast, successToast } from 'utils/toast';
import {
  createDepartmentApi,
  deleteDepartmentApi,
  editDepartmentApi,
  getDepartmentApi,
  getDepartmentByIdApi
} from 'apis/department';

// create Department
export const createDepartment = (data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.CREATE_DEPARTMENT_BEGIN
  });
  createDepartmentApi(data)
    .then((res) => {
      dispatch({
        type: actions.CREATE_DEPARTMENT_SUCCESS
      });
      handleFunction && handleFunction();
      dispatch(getDepartment());
      successToast('Department created Successfully');
    })
    .catch((error) => {
      dispatch({
        type: actions.CREATE_DEPARTMENT_ERROR
      });
      errorToast(error);
    });
};

// get all department
export const getDepartment = () => (dispatch) => {
  dispatch({
    type: actions.GET_DEPARTMENT_BEGIN
  });
  getDepartmentApi()
    .then((res) => {
      dispatch({
        type: actions.GET_DEPARTMENT_SUCCESS,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_DEPARTMENT_ERROR
      });
      errorToast(error);
    });
};

// get single department
export const getDepartmentById = (id) => (dispatch) => {
  dispatch({
    type: actions.GET_SINGLE_DEPARTMENT_BEGIN
  });
  getDepartmentByIdApi(id)
    .then((res) => {
      dispatch({
        type: actions.GET_SINGLE_DEPARTMENT_SUCCESS,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_SINGLE_DEPARTMENT_ERROR
      });
      errorToast(error);
    });
};

// edit department
export const editDepartment = (id, data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.EDIT_DEPARTMENT_BEGIN
  });
  editDepartmentApi(id, data)
    .then((res) => {
      dispatch({
        type: actions.EDIT_DEPARTMENT_SUCCESS
      });
      handleFunction && handleFunction();
      successToast('Department edited Successfully');
      dispatch(getDepartment());
    })
    .catch((error) => {
      dispatch({
        type: actions.EDIT_DEPARTMENT_ERROR
      });
      errorToast(error);
    });
};

// change department status
export const deleteDepartment = (id, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.CHANGE_DEPARTMENT_BEGIN
  });
  deleteDepartmentApi(id)
    .then((res) => {
      dispatch({
        type: actions.CHANGE_DEPARTMENT_SUCCESS
      });
      handleFunction && handleFunction();
      successToast('Department deleted Successfully');
      dispatch(getDepartment());
    })
    .catch((error) => {
      dispatch({
        type: actions.CHANGE_DEPARTMENT_ERROR
      });
      errorToast(error);
    });
};

export const clearSingleDepartment = () => (dispatch) => {
  dispatch({
    type: actions.CLEAR_SINGLE_DEPARTMENT
  });
};
