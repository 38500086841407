import { Button, TextField } from '@mui/material';
import { Box } from '@mui/system';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import { FormattedMessage } from 'react-intl';
import useStyles from '../styles';

function PayslipMain({ item, input }) {
  const classes = useStyles();
  return (
    <div className={classes.payslipMain}>
      <table className={classes.table}>
        <tbody>
          <tr>
            <td colSpan={2}>
              <h3 style={{ margin: 0 }}>
                <FormattedMessage id="Gross Salary" defaultMessage="Gross Salary" />
              </h3>
            </td>
            <td style={{ fontWeight: '600' }}>
              <GrossSalary item={item} />
            </td>
          </tr>
          {item?.input_allowances?.value != 0 && (
            <tr>
              <td colSpan={2}>
                <h3 style={{ margin: 0 }}>
                  <FormattedMessage id="Total Allowance" defaultMessage="Total Allowance" />
                </h3>
              </td>
              <td>{parseFloat(item?.input_allowances?.value)?.toFixed(2)}</td>
            </tr>
          )}
          {item?.input_overtime?.value != 0 && (
            <tr>
              <td colSpan={2}>
                <h3 style={{ margin: 0 }}>
                  <FormattedMessage id="Total Overtime" defaultMessage="Total Overtime" />
                </h3>
              </td>
              <td>{parseFloat(item?.input_overtime?.value)?.toFixed(2)}</td>
            </tr>
          )}
          {item?.vacation_equivalent_pay?.value > 0 && (
            <tr>
              <td colSpan={2}>
                <h3 style={{ margin: 0 }}>
                  <FormattedMessage
                    id="Equivalent for leave"
                    defaultMessage="Equivalent for leave"
                  />
                </h3>
              </td>
              <td>{parseFloat(item?.vacation_equivalent_pay?.value)?.toFixed(2)}</td>
            </tr>
          )}
          <tr>
            <td colSpan={3}>
              <h4 style={{ background: '#d7d7d7', margin: '0' }}>
                <FormattedMessage id="Social Security" defaultMessage="Social Security" />
              </h4>
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="left">
              <FormattedMessage
                id="Social Security contribution base"
                defaultMessage="Social Security contribution base"
              />
            </td>
            <td>{parseFloat(item?.paylist_gross?.value)?.toFixed(2)}</td>
          </tr>
          <tr>
            <td className="left">
              <FormattedMessage
                id="Sickness insurance contribution"
                defaultMessage="Sickness insurance contribution"
              />
            </td>
            <td>{parseFloat(input?.sickness_insurance_employee?.value)?.toFixed(2)}%</td>
            <td>{parseFloat(item?.sickness_insurance_employee?.value)?.toFixed(2)}</td>
          </tr>
          <tr>
            <td className="left">
              <FormattedMessage
                id="Pension insurance contribution"
                defaultMessage="Pension insurance contribution"
              />
            </td>
            <td>{parseFloat(input?.pension_insurance_employee?.value)?.toFixed(2)}%</td>
            <td>{parseFloat(item?.pension_insurance_employee?.value)?.toFixed(2)}</td>
          </tr>
          <tr>
            <td className="left">
              <FormattedMessage
                id="Disability insurance contribution"
                defaultMessage="Disability insurance contribution"
              />
            </td>
            <td>{parseFloat(input?.disability_insurance_employee?.value)?.toFixed(2)}%</td>
            <td>{parseFloat(item?.disability_insurance_employee?.value)?.toFixed(2)}</td>
          </tr>
          <tr>
            <td className="left">
              <FormattedMessage id="Total" defaultMessage="Total" />
            </td>
            <td>
              {(
                parseFloat(input?.sickness_insurance_employee?.value) +
                parseFloat(input?.pension_insurance_employee?.value) +
                parseFloat(input?.disability_insurance_employee?.value)
              )?.toFixed(2)}
              %
            </td>
            <td>{parseFloat(item?.employee_total_insurance?.value)?.toFixed(2)}</td>
          </tr>
          <tr>
            <td colSpan={3}>
              <h4 style={{ background: '#d7d7d7', margin: '0' }}>
                <FormattedMessage id="NFZ" defaultMessage="NFZ" />
              </h4>
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="left">
              <FormattedMessage id="Social Security total" defaultMessage="Social Security total" />
            </td>
            <td>{parseFloat(item?.employee_total_insurance?.value)?.toFixed(2)}</td>
          </tr>
          <tr>
            <td colSpan={2} className="left">
              <FormattedMessage
                id="NFZ contribution base"
                defaultMessage=" NFZ contribution base"
              />
            </td>
            <td>{parseFloat(item?.nfz_contribution_base?.value)?.toFixed(2)}</td>
          </tr>
          <tr>
            <td className="left">
              <FormattedMessage id="To social security" defaultMessage="To social security" />
            </td>
            <td>{parseFloat(input?.health_insurance_employee?.value)?.toFixed(2)}%</td>
            <td>{parseFloat(item?.nfz_contribution?.value)?.toFixed(2)}</td>
          </tr>
          <tr>
            <td colSpan={3}>
              <h4 style={{ background: '#d7d7d7', margin: '0' }}>
                <FormattedMessage id="Tax" defaultMessage="Tax" />
              </h4>
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="left">
              <FormattedMessage id="Gross Basis" defaultMessage="Gross Basis" />
            </td>
            <td>{parseFloat(item?.tax_gross_basis?.value)?.toFixed(2)}</td>
          </tr>
          <tr>
            <td colSpan={2} className="left">
              <FormattedMessage id="Social Security" defaultMessage="Social Security" />
            </td>
            <td>{parseFloat(item?.employee_total_insurance?.value)?.toFixed(2)}</td>
          </tr>
          <tr>
            <td colSpan={2} className="left">
              <FormattedMessage id="Income costs" defaultMessage="Income costs" />
            </td>
            <td>{parseFloat(item?.cost_of_getting_income?.value)?.toFixed(2)}</td>
          </tr>
          <tr>
            <td colSpan={2} className="left">
              <FormattedMessage id="Tax Base" defaultMessage="Tax Base" />
            </td>
            <td>{parseFloat(item?.tax_base?.value)?.toFixed(2)}</td>
          </tr>
          <tr>
            <td className="left">
              <FormattedMessage id="Tax" defaultMessage="Tax" />
            </td>
            <td>{item?.tax_rate?.value}%</td>
            <td>{parseFloat(item?.tax_amount?.value)?.toFixed(2)}</td>
          </tr>
          {/* <tr>
            <td colSpan={2} className="left">
              <FormattedMessage id="Tax" defaultMessage="Tax" />
            </td>
            <td>{parseFloat(item?.tax_amount?.value)?.toFixed(2)}</td>
          </tr> */}
          {item?.free_amount?.value != 0 && (
            <tr>
              <td colSpan={2} className="left">
                <FormattedMessage id="Free amount" defaultMessage="Free amount" />
              </td>
              <td>{parseFloat(item?.free_amount?.value)?.toFixed(2)}</td>
            </tr>
          )}
          <tr>
            <td colSpan={2} className="left">
              <FormattedMessage id="Tax after rounding" defaultMessage="Tax after rounding" />
            </td>
            <td>{parseFloat(item?.tax_after_free_amount?.value)?.toFixed(2)}</td>
          </tr>
          <tr>
            <td colSpan={3}>
              <h4 style={{ background: '#d7d7d7', margin: '0' }}>
                <FormattedMessage id="Net Salary" defaultMessage="Net Salary" />
              </h4>
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="right">
              <FormattedMessage id="Gross Basis" defaultMessage="Gross Basis" />
            </td>
            <td>{parseFloat(item?.net_salary_base?.value)?.toFixed(2)}</td>
          </tr>
          <tr>
            <td colSpan={2} className="right">
              <FormattedMessage id="ZUS" defaultMessage="ZUS" />
            </td>
            <td> {parseFloat(item?.employee_total_insurance?.value)?.toFixed(2)}</td>
          </tr>
          <tr>
            <td colSpan={2} className="right">
              <FormattedMessage id="NFZ do ZUS 9%" defaultMessage="NFZ do ZUS 9%" />
            </td>
            <td> {parseFloat(item?.nfz_contribution?.value)?.toFixed(2)}</td>
          </tr>
          <tr>
            <td colSpan={2} className="right">
              <FormattedMessage id="Tax" defaultMessage="Tax" />
            </td>
            <td>{parseFloat(item?.tax_after_free_amount?.value)?.toFixed(2)}</td>
          </tr>
          <tr>
            <td colSpan={2} className="right">
              <FormattedMessage id="Net" defaultMessage="Net" />
            </td>
            <td>{parseFloat(item?.net_salary?.value)?.toFixed(2)}</td>
          </tr>
          {item?.input_deductions?.value != 0 && (
            <tr>
              <td colSpan={2} className="right">
                <FormattedMessage id="Total Deduction" defaultMessage="Total Deduction" />
              </td>
              <td>{parseFloat(item?.input_deductions?.value)?.toFixed(2)}</td>
            </tr>
          )}
          {item?.previous_payslip_deduction_amount?.value > 0 && (
            <tr>
              <td colSpan={2} className="right">
                <FormattedMessage
                  id="Deduction of the overpaid salary"
                  defaultMessage="Deduction of the overpaid salary"
                />
              </td>
              <td>{parseFloat(item?.previous_payslip_deduction_amount?.value)?.toFixed(2)}</td>
            </tr>
          )}
          {item?.previous_payslip_deduction_amount?.value < 0 && (
            <tr>
              <td colSpan={2} className="right">
                <FormattedMessage
                  id="Addition of the underpaid salary"
                  defaultMessage="Addition of the underpaid salary"
                />
              </td>
              <td>
                {Math.abs(parseFloat(item?.previous_payslip_deduction_amount?.value))?.toFixed(2)}
              </td>
            </tr>
          )}
          <tr>
            <td colSpan={2} className="right">
              <FormattedMessage id="To be paid" defaultMessage="To be paid" />
            </td>
            <td>{parseFloat(item?.salary_to_pay?.value)?.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default PayslipMain;

const GrossSalary = ({ item }) => {
  const [modalOpen, openFunction] = useToggle(false);
  const classes = useStyles();

  return (
    <>
      <div className={classes.editOption}>
        {parseFloat(item?.contract_salary?.value)?.toFixed(2)}
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="16rem"
        width="35rem"
        headerText={
          <FormattedMessage id="Update Gross Salary" defaultMessage="Update Gross Salary" />
        }>
        <Box padding={4}>
          <TextField label="Gross Salary" fullWidth InputLabelProps={{ shrink: true }} />
        </Box>
        <Box className={classes.modalFooter}>
          <Box className={classes.modalFooterButtons}>
            <Button sx={{ marginRight: '1rem' }} variant={'contained'} type="submit">
              <FormattedMessage id="Save" defaultMessage="Save" />
            </Button>
            <Button
              className={classes.modalFooterCancel}
              variant={'outlined'}
              onClick={openFunction}>
              <FormattedMessage id="Cancel" defaultMessage="Cancel" />
            </Button>
          </Box>
        </Box>
      </CustomModal>
    </>
  );
};
