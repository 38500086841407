import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { HookFormCheckbox } from 'components/form/hookFormCheckbox';
import { HookFormInput } from 'components/form/hookFormInput';
import { HookFormSelect } from 'components/form/hookFormSelect';
import React from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { translate } from 'utils';
import { getCurrentDate, getCurrentMonth, getCurrentYear, yearList } from 'utils/dateUtils';

function AllowanceForm({ edit = false, allowanceLength }) {
  const { single_loading } = useSelector((state) => state.employee);
  const { watch, setValue } = useFormContext();
  const oneTimeWatch = watch(`allowance_deductions[${allowanceLength}]`);

  useEffect(() => {
    if (oneTimeWatch?.one_time) {
      setValue(`allowance_deductions[${allowanceLength}][start_date]`, getCurrentDate());
      setValue(`allowance_deductions[${allowanceLength}][end_date]`, null);
    } else {
      setValue(`allowance_deductions[${allowanceLength}][year]`, getCurrentYear());
      setValue(`allowance_deductions[${allowanceLength}][month]`, getCurrentMonth());
    }
  }, [oneTimeWatch?.one_time]);

  const months = [
    { label: translate('January'), value: 1 },
    { label: translate('February'), value: 2 },
    { label: translate('March'), value: 3 },
    { label: translate('April'), value: 4 },
    { label: translate('May'), value: 5 },
    { label: translate('June'), value: 6 },
    { label: translate('July'), value: 7 },
    { label: translate('August'), value: 8 },
    { label: translate('September'), value: 9 },
    { label: translate('october'), value: 10 },
    { label: translate('November'), value: 11 },
    { label: translate('December'), value: 12 }
  ];
  useEffect(() => {
    if (typeof oneTimeWatch?.start_date == 'undefined') {
      setValue(`allowance_deductions[${allowanceLength}][start_date]`, getCurrentDate());
    }
    if (typeof oneTimeWatch?.is_taxable == 'undefined') {
      setValue(`allowance_deductions[${allowanceLength}][is_taxable]`, false);
    }
    if (typeof oneTimeWatch?.one_time == 'undefined') {
      setValue(`allowance_deductions[${allowanceLength}][one_time]`, false);
    }
    if (typeof oneTimeWatch?.include_socialsecurity_base == 'undefined') {
      setValue(`allowance_deductions[${allowanceLength}][include_socialsecurity_base]`, false);
    }
    if (typeof oneTimeWatch?.include_sick_base == 'undefined') {
      setValue(`allowance_deductions[${allowanceLength}][include_sick_base]`, false);
    }
    if (typeof oneTimeWatch?.include_vacation_base == 'undefined') {
      setValue(`allowance_deductions[${allowanceLength}][include_vacation_base]`, false);
    }
  }, []);
  useEffect(() => {
    if (oneTimeWatch?.type == 'allowance') {
      setValue(`allowance_deductions[${allowanceLength}][is_taxable]`, true);
      setValue(`allowance_deductions[${allowanceLength}][include_sick_base]`, true);
      setValue(`allowance_deductions[${allowanceLength}][include_vacation_base]`, true);
      setValue(`allowance_deductions[${allowanceLength}][include_socialsecurity_base]`, true);
    } else {
      setValue(`allowance_deductions[${allowanceLength}][is_taxable]`, false);
      setValue(`allowance_deductions[${allowanceLength}][include_sick_base]`, false);
      setValue(`allowance_deductions[${allowanceLength}][include_vacation_base]`, false);
      setValue(`allowance_deductions[${allowanceLength}][include_socialsecurity_base]`, false);
    }
  }, [oneTimeWatch?.type]);
  return (
    <Box margin={4}>
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <HookFormInput
            name={`allowance_deductions[${allowanceLength}][name]`}
            label={<FormattedMessage id="Name" defaultMessage="Name" />}
            placeholder="Bonus"
            loading={single_loading}
          />
        </Grid>
        <Grid item sm={4}>
          <HookFormInput
            name={`allowance_deductions[${allowanceLength}][value]`}
            label={<FormattedMessage id="Value" defaultMessage="Value" />}
            loading={single_loading}
            type="number"
          />
        </Grid>
        <Grid item sm={4}>
          <HookFormSelect
            disabled={edit}
            name={`allowance_deductions[${allowanceLength}][type]`}
            label={<FormattedMessage id="Type" defaultMessage="Type" />}
            loading={single_loading}
            options={[
              {
                label: <FormattedMessage id="Allowance" defaultMessage="Allowance" />,
                value: 'allowance'
              },
              {
                label: <FormattedMessage id="Deduction" defaultMessage="Deduction" />,
                value: 'deduction'
              }
            ]}
          />
        </Grid>

        <Grid item sm={4}>
          <Box marginTop={1.5}>
            <HookFormCheckbox
              name={`allowance_deductions[${allowanceLength}][one_time]`}
              label={<FormattedMessage id="One Time" defaultMessage="One Time" />}
              disabled={edit}
            />
          </Box>
        </Grid>
        {oneTimeWatch?.one_time ? (
          <>
            <Grid item sm={4}>
              <HookFormSelect
                name={`allowance_deductions[${allowanceLength}][year]`}
                label={<FormattedMessage id="Year" defaultMessage="Year" />}
                loading={single_loading}
                options={yearList}
              />
            </Grid>
            <Grid item sm={4}>
              <HookFormSelect
                name={`allowance_deductions[${allowanceLength}][month]`}
                label={<FormattedMessage id="Month" defaultMessage="Month" />}
                loading={single_loading}
                options={months}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item sm={4}>
              <HookFormInput
                name={`allowance_deductions[${allowanceLength}][start_date]`}
                label={<FormattedMessage id="Start Date" defaultMessage="Start Date" />}
                type="date"
                loading={single_loading}
              />
            </Grid>
            <Grid item sm={4}>
              <HookFormInput
                name={`allowance_deductions[${allowanceLength}][end_date]`}
                label={<FormattedMessage id="End Date" defaultMessage="End Date" />}
                type="date"
                loading={single_loading}
              />
            </Grid>
          </>
        )}
        {oneTimeWatch?.type == 'allowance' && (
          <>
            <Grid item sm={4}>
              <Box marginTop={2}>
                <HookFormCheckbox
                  name={`allowance_deductions[${allowanceLength}][is_taxable]`}
                  label={<FormattedMessage id="Is Taxable?" defaultMessage="Is Taxable?" />}
                />
              </Box>
            </Grid>
            <Grid item sm={4}>
              <HookFormCheckbox
                name={`allowance_deductions[${allowanceLength}][include_socialsecurity_base]`}
                label={
                  <FormattedMessage
                    id="Include Social Security Base"
                    defaultMessage="Include Social Security Base"
                  />
                }
              />
            </Grid>
            <Grid item sm={4}>
              <HookFormCheckbox
                name={`allowance_deductions[${allowanceLength}][include_sick_base]`}
                label={
                  <FormattedMessage id="Include Sick Base" defaultMessage="Include Sick Base" />
                }
              />
            </Grid>
            <Grid item sm={4}>
              <HookFormCheckbox
                name={`allowance_deductions[${allowanceLength}][include_vacation_base]`}
                label={
                  <FormattedMessage
                    id="Include Vacation Base"
                    defaultMessage="Include Vacation Base"
                  />
                }
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}

export default AllowanceForm;
