import { assignCompanyApi, deleteAssignCompanyApi, getUserCompanyApi } from 'apis/assignments';
import { getCompanyAssignmentApi } from 'apis/companyAssignments';
import { errorToast } from 'utils/toast';
import * as actions from './types';

// get all assignments
export const getAssignment = () => (dispatch) => {
  dispatch({
    type: actions.GET_COMPANY_ASSIGNMENT_BEGIN
  });
  getCompanyAssignmentApi()
    .then((res) => {
      dispatch({
        type: actions.GET_COMPANY_ASSIGNMENT_SUCCESS,
        payload: res.data?.data?.companies
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_COMPANY_ASSIGNMENT_ERROR
      });
      errorToast(error);
    });
};

// get all user company
export const getUserCompany = (userId) => (dispatch) => {
  dispatch({
    type: actions.GET_ASSIGNMENT_COMPANY_BEGIN
  });
  getUserCompanyApi(userId)
    .then((res) => {
      dispatch({
        type: actions.GET_ASSIGNMENT_COMPANY_SUCCESS,
        payload: res.data?.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_ASSIGNMENT_COMPANY_ERROR
      });
      errorToast(error);
    });
};

// assign company to user

export const assignCompany = (userId, data, handleSuccess) => (dispatch) => {
  dispatch({
    type: actions.ASSIGN_COMPANY_BEGIN
  });
  assignCompanyApi(userId, data)
    .then((res) => {
      dispatch({
        type: actions.ASSIGN_COMPANY_SUCCESS,
        payload: res.data?.data
      });
      dispatch(getUserCompany(userId));
      handleSuccess && handleSuccess();
    })
    .catch((error) => {
      dispatch({
        type: actions.ASSIGN_COMPANY_ERROR
      });
      errorToast(error);
    });
};

// delete company from user
export const deleteAssignCompany = (userId, companySlug, handleSuccess) => (dispatch) => {
  dispatch({
    type: actions.DELETE_ASSIGN_COMPANY_BEGIN
  });
  deleteAssignCompanyApi(userId, companySlug)
    .then((res) => {
      dispatch({
        type: actions.DELETE_ASSIGN_COMPANY_SUCCESS
      });
      dispatch(getUserCompany(userId));
      handleSuccess && handleSuccess();
    })
    .catch((error) => {
      dispatch({
        type: actions.DELETE_ASSIGN_COMPANY_ERROR
      });
      errorToast(error);
    });
};
