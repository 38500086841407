import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { HookFormCheckbox } from 'components/form/hookFormCheckbox';
import { HookFormInput } from 'components/form/hookFormInput';
import { HookFormSelect } from 'components/form/hookFormSelect';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { translate } from 'utils';
import { getCurrentDate, getCurrentMonth, getCurrentYear, yearList } from 'utils/dateUtils';

function AllowanceForm({ edit = false, contractId }) {
  const [agreement, setAgreement] = useState([]);
  const { single_loading } = useSelector((state) => state.employee);
  const { employee_contract } = useSelector((state) => state.contract);
  const { watch, setValue } = useFormContext();
  const oneTimeWatch = watch('one_time');
  const typeWatch = watch('type');
  const months = [
    { label: translate('January'), value: 1 },
    { label: translate('February'), value: 2 },
    { label: translate('March'), value: 3 },
    { label: translate('April'), value: 4 },
    { label: translate('May'), value: 5 },
    { label: translate('June'), value: 6 },
    { label: translate('July'), value: 7 },
    { label: translate('August'), value: 8 },
    { label: translate('September'), value: 9 },
    { label: translate('October'), value: 10 },
    { label: translate('November'), value: 11 },
    { label: translate('December'), value: 12 }
  ];

  useEffect(() => {
    const filterAgreement = employee_contract?.map((item) => ({
      label: `${item?.agreement_type_name} - ${item?.contract_number}`,
      value: item?.id
    }));
    setAgreement(filterAgreement ?? []);
  }, [employee_contract]);

  useEffect(() => {
    if (oneTimeWatch) {
      setValue('start_date', getCurrentDate());
      setValue('end_date', null);
    } else {
      setValue('year', getCurrentYear());
      setValue('month', getCurrentMonth());
    }
  }, [oneTimeWatch]);

  return (
    <Box margin={4}>
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <HookFormInput
            name="name"
            label={<FormattedMessage id="Name" defaultMessage="Name" />}
            placeholder="Bonus"
            loading={single_loading}
          />
        </Grid>
        <Grid item sm={4}>
          <HookFormInput
            name="value"
            label={<FormattedMessage id="Value" defaultMessage="Value" />}
            loading={single_loading}
            type="number"
          />
        </Grid>
        <Grid item sm={4}>
          <HookFormSelect
            disabled={edit}
            name="type"
            label={<FormattedMessage id="Type" defaultMessage="Type" />}
            loading={single_loading}
            options={[
              {
                label: <FormattedMessage id="Allowance" defaultMessage="Allowance" />,
                value: 'allowance'
              },
              {
                label: <FormattedMessage id="Deduction" defaultMessage="Deduction" />,
                value: 'deduction'
              }
            ]}
          />
        </Grid>
        <Grid item sm={8}>
          <HookFormSelect
            name="agreement_id"
            label={<FormattedMessage id="Agreement" defaultMessage="Agreement" />}
            loading={single_loading}
            options={agreement}
            disabled={edit || contractId}
          />
        </Grid>

        <Grid item sm={4}>
          <Box marginTop={1.5}>
            <HookFormCheckbox
              name="one_time"
              label={<FormattedMessage id="One Time" defaultMessage="One Time" />}
              disabled={edit}
            />
          </Box>
        </Grid>
        {oneTimeWatch ? (
          <>
            <Grid item sm={4}>
              <HookFormSelect
                name="year"
                label={<FormattedMessage id="Year" defaultMessage="Year" />}
                loading={single_loading}
                options={yearList}
              />
            </Grid>
            <Grid item sm={4}>
              <HookFormSelect
                name="month"
                label={<FormattedMessage id="Month" defaultMessage="Month" />}
                loading={single_loading}
                options={months}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item sm={4}>
              <HookFormInput
                name="start_date"
                label={<FormattedMessage id="Start Date" defaultMessage="Start Date" />}
                type="date"
                loading={single_loading}
              />
            </Grid>
            <Grid item sm={4}>
              <HookFormInput
                name="end_date"
                label={<FormattedMessage id="End Date" defaultMessage="End Date" />}
                type="date"
                loading={single_loading}
              />
            </Grid>
          </>
        )}

        {typeWatch !== 'deduction' && (
          <>
            <Grid item sm={4}>
              <Box marginTop={2}>
                <HookFormCheckbox
                  name="is_taxable"
                  label={<FormattedMessage id="Is Taxable?" defaultMessage="Is Taxable?" />}
                />
              </Box>
            </Grid>
            <Grid item sm={4}>
              <HookFormCheckbox
                name="include_socialsecurity_base"
                label={
                  <FormattedMessage
                    id="Include Social Security Base"
                    defaultMessage="Include Social Security Base"
                  />
                }
              />
            </Grid>
            <Grid item sm={4}>
              <HookFormCheckbox
                name="include_sick_base"
                label={
                  <FormattedMessage id="Include Sick Base" defaultMessage="Include Sick Base" />
                }
              />
            </Grid>
            <Grid item sm={4}>
              <HookFormCheckbox
                name="include_vacation_base"
                label={
                  <FormattedMessage
                    id="Include Vacation Base"
                    defaultMessage="Include Vacation Base"
                  />
                }
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}

export default AllowanceForm;
