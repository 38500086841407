import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
// import { getAllPayroll } from '../../redux/actions';
import Header from './Header';
import TableView from './TableView';
import TotalView from './TotalView';
import styles from './styles';
import { getAllPayrollBulk } from 'components/locals/payroll/redux/actions';

function PrintView({ handleClose, id, company_id, bulkAction = false }) {
  const dispatch = useDispatch();
  const classes = styles();
  const { allPayroll } = useSelector((state) => state.payroll);

  const printRef = useRef();

  useEffect(() => {
    bulkAction && id && dispatch(getAllPayrollBulk(id, company_id));
  }, [id]);

  const checkPageBreak = (index) => {
    if (index < 3) {
      if ((index + 1) % 2 == 0) {
        return classes.pageBreak;
      } else {
        return '';
      }
    } else {
      if ((index + 2) % 3 == 0) {
        return classes.pageBreak;
      } else {
        return '';
      }
    }
  };

  return (
    <>
      <Box margin={2} textAlign="right">
        <ReactToPrint
          trigger={() => <Button variant="contained">Print</Button>}
          content={() => printRef.current}
        />
      </Box>
      <div ref={printRef} className={classes.printDiv}>
        <Header data={allPayroll} />
        {allPayroll?.employeeData?.map((item, index) => (
          <Box marginX={3} marginY={2} key={index} className={checkPageBreak(index)}>
            <TableView data={item} index={index + 1} />
          </Box>
        ))}

        <Box marginX={3} marginY={2}>
          <TotalView data={allPayroll} />
        </Box>
      </div>
    </>
  );
}

export default PrintView;
