import { Button, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { HookForm } from 'components/form/hookForm';
import { HookFormProvider } from 'components/form/hookFormProvider';
import CancelModal from 'components/globals/modal/CancelModal';
import useToggle from 'hooks/useToggle';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import AllowanceForm from './Form';
import { editTimeRecording, getHolidayBasis } from './redux/actions';
import useStyles from './styles';

function TimeRecording({ handleClose, employeeId, row, handleRefetch }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [cancelOpen, cancelFunction] = useToggle(false);
  const { edit_loading } = useSelector((state) => state.timeRecording);

  const defaultValues = {
    name: row?.name ?? '',
    type: row?.type ?? '',
    start_date: row?.start_date ?? null,
    end_date: row?.end_date ?? null,
    hours: row?.hours ?? null,
    disease_code_id: row?.sick_leave_details?.disease_code_id ?? null,
    break_benefit_type_id: row?.sick_leave_details?.break_benefit_type_id ?? null,
    date_of_delivery: row?.sick_leave_details?.date_of_delivery ?? null,
    date_of_release: row?.sick_leave_details?.date_of_release ?? null,
    hospital_treatment: row?.sick_leave_details?.hospital_treatment ?? null,
    print_series_number: row?.sick_leave_details?.print_series_number ?? '',
    care_type: row?.sick_leave_details?.care_type ?? '',
    care_person: row?.sick_leave_details?.care_person ?? '',
    waiting_period: row?.sick_leave_details?.waiting_period ?? ''
  };

  useEffect(() => {
    if (row?.type !== 'unpaid_leave') {
      if (row?.start_date && row?.type) {
        dispatch(
          getHolidayBasis({ employee_id: employeeId, date: row?.start_date, type: row?.type })
        );
      }
    }
  }, [row?.start_date, row?.type]);

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    type: Yup.string().required('Type is required'),
    start_date: Yup.string().required('Start Date  is required'),
    end_date: Yup.string().required('End Date  is required')
  });
  const onSubmit = (data) => {
    row?.id &&
      dispatch(
        editTimeRecording(
          row?.id,
          { ...data, waiting_period: data?.waiting_period ? 1 : 0 },
          employeeId,
          handleClose,
          handleRefetch
        )
      );
  };

  return (
    <>
      <div className={classes.content}>
        <HookFormProvider
          defaultValues={defaultValues}
          resolver={useYupValidationResolver(validationSchema)}>
          <HookForm onSubmit={onSubmit}>
            <AllowanceForm edit={true} name={row?.name} />
            <Box className={classes.modalFooter}>
              <Box className={classes.modalFooterButtons}>
                {!edit_loading ? (
                  <Button sx={{ marginRight: '1rem' }} variant={'contained'} type="submit">
                    {<FormattedMessage id="Update" defaultMessage="Update" />}
                  </Button>
                ) : (
                  <Button sx={{ marginRight: '1rem' }} variant={'contained'} disabled>
                    <CircularProgress size={18} sx={{ marginRight: '1rem' }} />{' '}
                    {<FormattedMessage id="Updating" defaultMessage="Updating" />}
                  </Button>
                )}

                <Button
                  className={classes.modalFooterCancel}
                  variant={'outlined'}
                  onClick={cancelFunction}>
                  {<FormattedMessage id="Cancel" defaultMessage="Cancel" />}
                </Button>
              </Box>
            </Box>
          </HookForm>
        </HookFormProvider>
      </div>
      <CancelModal
        handleClose={handleClose}
        cancelFunction={cancelFunction}
        cancelOpen={cancelOpen}
      />
    </>
  );
}

export default TimeRecording;
