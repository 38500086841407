import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from 'utils';
import useStyles from '../styles';
import {
  addCompanyDetail,
  closeBulkPayList,
  sendAllBulkAction,
  sendBulkMail,
  sendBulkPayout
} from './redux/actions';
import TableContent from './TableContent';

export default function CompanyTable({ startDate, endDate, handleRefetch }) {
  const dispatch = useDispatch();
  const {
    listCompany_loading,
    listCompany,
    send_payout_loading,
    send_mail_loading,
    close_pay_list_loading
  } = useSelector((state) => state.companyList);
  const [ids, setIds] = useState([]);
  const classes = useStyles();
  const [allCheck, setAllCheck] = useState(false);
  const [closePayrollOpen, closePayrollFunction] = useToggle(false);
  const [sendPayoutOpen, sendPayoutFunction] = useToggle(false);
  const [sendMailOpen, sendMailFunction] = useToggle(false);
  const [allBulkActionOpen, allBulkActionFunction] = useToggle(false);

  const selectedIds = listCompany?.map((list) => list?.id);

  const columns = [
    {
      title: '',
      minWidth: 20
    },
    {
      title: <FormattedMessage id="Company" defaultMessage="Company" />,
      minWidth: 80,
      isSortable: true,
      field: 'payroll_number'
    },
    {
      title: <FormattedMessage id="Payroll number" defaultMessage="Payroll number" />,
      minWidth: 80,
      isSortable: true,
      field: 'payroll_number'
    },
    {
      title: <FormattedMessage id="Period of the list" defaultMessage="Period of the list" />,
      minWidth: 160,
      isSortable: true,
      field: 'period_list'
    },
    {
      title: <FormattedMessage id="Status" defaultMessage="Status" />,
      minWidth: 80,
      isSortable: true,
      field: 'status'
    },
    {
      title: <FormattedMessage id="Type" defaultMessage="Type" />,
      minWidth: 80,
      isSortable: true,
      field: 'type'
    },
    {
      field: 'contract_type',
      title: <FormattedMessage id="contract_type" defaultMessage="contract_type" />,
      minWidth: 100,
      align: 'left'
    },
    {
      field: 'payment_day',
      title: <FormattedMessage id="Payment Day" defaultMessage="Payment Day" />,
      minWidth: 120,
      align: 'left'
    },
    {
      field: 'paycheck',
      title: <FormattedMessage id="Paycheck" defaultMessage="Paycheck" />,
      minWidth: 100,
      align: 'left'
    },

    {
      field: 'payout',
      title: <FormattedMessage id="Payout" defaultMessage="Payout" />,
      minWidth: 80,
      align: 'left'
    },
    {
      field: 'mail_sent',
      title: <FormattedMessage id="Mail Sent" defaultMessage="Mail Sent" />,
      minWidth: 80,
      align: 'left'
    },
    {
      fieldL: 'action',
      title: <FormattedMessage id="Actions" defaultMessage="Actions" />,
      minWidth: 180,
      align: 'center'
    }
  ];

  const handleChange = (e) => {
    if (e.target.value === 'close_payroll') {
      closePayrollFunction();
    } else if (e.target.value === 'send_payouts') {
      sendPayoutFunction();
    } else if (e.target.value === 'send_email') {
      sendMailFunction();
    } else if (e.target.value === 'all_action') {
      allBulkActionFunction();
    }
  };

  const handleClosePayroll = () => {
    dispatch(
      closeBulkPayList(
        {
          collective_ids: ids
        },
        () => {
          closePayrollFunction();
          handleRefetch();
        }
      )
    );
  };

  const handleSendPayout = () => {
    dispatch(
      sendBulkPayout(
        {
          collective_ids: ids
        },
        () => {
          sendPayoutFunction();
          handleRefetch();
        }
      )
    );
  };

  const handleSendMail = () => {
    dispatch(
      sendBulkMail(
        {
          collective_ids: ids
        },
        () => {
          sendMailFunction();
          handleRefetch();
        }
      )
    );
  };

  const handleAllBulkAction = () => {
    dispatch(
      sendAllBulkAction(
        {
          collective_ids: ids
        },
        () => {
          allBulkActionFunction();
          handleRefetch();
        }
      )
    );
  };
  const handleAllCheck = (e) => {
    const selectedIds = listCompany?.map((list) => list?.id);
    setAllCheck(e.target.checked);
    if (e.target.checked) {
      setIds(selectedIds);
    } else {
      setIds([]);
    }
  };

  // useEffect(() => {

  // }, [allCheck]);

  useEffect(() => {
    const selectedIds = listCompany?.map((list) => list?.id);

    if (selectedIds?.length === ids?.length && selectedIds?.length != 0) {
      setAllCheck(true);
    } else {
      setAllCheck(false);
    }
  }, [ids]);

  useEffect(() => {
    const pusherFunction = () => {
      selectedIds && dispatch(addCompanyDetail({ collective_ids: selectedIds?.toString() }));
    };
    const timeoutFunction = setInterval(() => pusherFunction(), 30000);
    return () => clearInterval(timeoutFunction);
  }, [listCompany]);

  const closePayrollTranslated = translate('Do you want to close :count payroll?');
  const sendPayoutTranslated = translate('Do you want to send :count Payout?');
  const sendMailTranslated = translate('Do you want to send :count mail?');

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <Box display={'none'}>
          <FormattedMessage
            id="Do you want to close :count payroll?"
            defaultMessage="Do you want to close :count payroll?"
          />
          <FormattedMessage
            id="Do you want to send :count Payout?"
            defaultMessage="Do you want to send :count Payout?"
          />
          <FormattedMessage
            id="Do you want to send :count mail?"
            defaultMessage="Do you want to send :count mail?"
          />
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((item, index) =>
                index === 0 ? (
                  <TableCell key={index} sx={{ minWidth: item?.minWidth }} align={item?.align}>
                    <input
                      onChange={(e) => handleAllCheck(e)}
                      type="checkbox"
                      checked={allCheck}
                      style={{ border: 'none' }}
                      sx={{
                        '.MuiOutlinedInput-notchedOutline': { border: 'none' },
                        '.MuiOutlinedInput-root': { padding: '0' },
                        cursor: 'pointer'
                      }}
                    />
                  </TableCell>
                ) : (
                  <TableCell key={index} sx={{ minWidth: item?.minWidth }} align={item?.align}>
                    {item?.title}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!listCompany_loading ? (
              <TableContent
                columns={columns}
                handleRefetch={handleRefetch}
                setIds={setIds}
                ids={ids}
              />
            ) : (
              [...Array(8).keys()].map((index) => (
                <TableRow key={index}>
                  {columns.map((item) => (
                    <TableCell
                      key={item.field}
                      sx={{ minWidth: item?.minWidth }}
                      align={item?.align}>
                      <Skeleton height={30} />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Paper>
      <Box textAlign="right" marginTop={2}>
        <FormControl sx={{ width: 150 }}>
          <Select onChange={handleChange} value={false}>
            <MenuItem value="false">
              <FormattedMessage id="Select Actions" defaultMessage="Select Actions" />
            </MenuItem>
            <MenuItem value="close_payroll">
              {' '}
              <FormattedMessage id="Close Payroll" defaultMessage="Close Payroll" />
            </MenuItem>
            <MenuItem value="send_payouts">
              <FormattedMessage id="Send Payouts" defaultMessage="Send Payouts" />
            </MenuItem>
            <MenuItem value="send_email">
              <FormattedMessage id="Send Email" defaultMessage="Send Email" />
            </MenuItem>
            <MenuItem value="all_action">
              <FormattedMessage id="All Action" defaultMessage="All Action" />
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      <CustomModal
        open={closePayrollOpen}
        handleClose={closePayrollFunction}
        height="auto"
        width="400px"
        headerText={`Close Payroll`}>
        <Box padding={6}>
          <Typography variant="h5" textAlign="center">
            {closePayrollTranslated.replace(':count', ids?.length)}
          </Typography>
        </Box>
        <Box className={classes.modalFooter} sx={{ justifyContent: 'space-around !important' }}>
          {close_pay_list_loading ? (
            <Button variant={'contained'} disabled>
              {<FormattedMessage id="Sending" defaultMessage="Sending" />}
            </Button>
          ) : (
            <Button variant={'contained'} onClick={handleClosePayroll}>
              {<FormattedMessage id="Yes" defaultMessage="Yes" />}
            </Button>
          )}

          <Button
            className={classes.modalFooterCancel}
            variant={'outlined'}
            onClick={closePayrollFunction}>
            {<FormattedMessage id="No" defaultMessage="No" />}
          </Button>
        </Box>
      </CustomModal>
      <CustomModal
        open={sendPayoutOpen}
        handleClose={sendPayoutFunction}
        height="auto"
        width="400px"
        headerText={`Send Payout`}>
        <Box padding={6}>
          <Typography variant="h5" textAlign="center">
            {sendPayoutTranslated.replace(':count', ids?.length)}
          </Typography>
        </Box>
        <Box className={classes.modalFooter} sx={{ justifyContent: 'space-around !important' }}>
          {send_payout_loading ? (
            <Button variant={'contained'} disabled>
              {<FormattedMessage id="Sending" defaultMessage="Sending" />}
            </Button>
          ) : (
            <Button variant={'contained'} onClick={handleSendPayout}>
              {<FormattedMessage id="Yes" defaultMessage="Yes" />}
            </Button>
          )}

          <Button
            className={classes.modalFooterCancel}
            variant={'outlined'}
            onClick={sendPayoutFunction}>
            {<FormattedMessage id="No" defaultMessage="No" />}
          </Button>
        </Box>
      </CustomModal>
      <CustomModal
        open={sendMailOpen}
        handleClose={sendMailFunction}
        height="auto"
        width="400px"
        headerText={`Send Mail`}>
        <Box padding={6}>
          <Typography variant="h5" textAlign="center">
            {sendMailTranslated.replace(':count', ids?.length)}
          </Typography>
        </Box>
        <Box className={classes.modalFooter} sx={{ justifyContent: 'space-around !important' }}>
          {send_mail_loading ? (
            <Button variant={'contained'} disabled>
              {<FormattedMessage id="Sending" defaultMessage="Sending" />}
            </Button>
          ) : (
            <Button variant={'contained'} onClick={handleSendMail}>
              {<FormattedMessage id="Yes" defaultMessage="Yes" />}
            </Button>
          )}
          <Button
            className={classes.modalFooterCancel}
            variant={'outlined'}
            onClick={sendMailFunction}>
            {<FormattedMessage id="No" defaultMessage="No" />}
          </Button>
        </Box>
      </CustomModal>
      <CustomModal
        open={allBulkActionOpen}
        handleClose={allBulkActionFunction}
        height="auto"
        width="400px"
        headerText={`Send Mail`}>
        <Box padding={6}>
          <Typography variant="h5" textAlign="center">
            {
              <FormattedMessage
                id="Do you want to perform all action (closing, payouts and mail) at once?"
                defaultMessage="Do you want to perform all action (closing, payouts and mail) at once?"
              />
            }
          </Typography>
        </Box>
        <Box className={classes.modalFooter} sx={{ justifyContent: 'space-around !important' }}>
          {send_mail_loading ? (
            <Button variant={'contained'} disabled>
              {<FormattedMessage id="Sending" defaultMessage="Sending" />}
            </Button>
          ) : (
            <Button variant={'contained'} onClick={handleAllBulkAction}>
              {<FormattedMessage id="Yes" defaultMessage="Yes" />}
            </Button>
          )}
          <Button
            className={classes.modalFooterCancel}
            variant={'outlined'}
            onClick={allBulkActionFunction}>
            {<FormattedMessage id="No" defaultMessage="No" />}
          </Button>
        </Box>
      </CustomModal>
    </>
  );
}
