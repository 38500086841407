export const CREATE_DEPARTMENT_BEGIN = 'CREATE_DEPARTMENT_BEGIN';
export const CREATE_DEPARTMENT_SUCCESS = 'CREATE_DEPARTMENT_SUCCESS';
export const CREATE_DEPARTMENT_ERROR = 'CREATE_DEPARTMENT_ERROR';

export const GET_DEPARTMENT_BEGIN = 'GET_DEPARTMENT_BEGIN';
export const GET_DEPARTMENT_SUCCESS = 'GET_DEPARTMENT_SUCCESS';
export const GET_DEPARTMENT_ERROR = 'GET_DEPARTMENT_ERROR';

export const GET_SINGLE_DEPARTMENT_BEGIN = 'GET_SINGLE_DEPARTMENT_BEGIN';
export const GET_SINGLE_DEPARTMENT_SUCCESS = 'GET_SINGLE_DEPARTMENT_SUCCESS';
export const GET_SINGLE_DEPARTMENT_ERROR = 'GET_SINGLE_DEPARTMENT_ERROR';

export const EDIT_DEPARTMENT_BEGIN = 'EDIT_DEPARTMENT_BEGIN';
export const EDIT_DEPARTMENT_SUCCESS = 'EDIT_DEPARTMENT_SUCCESS';
export const EDIT_DEPARTMENT_ERROR = 'EDIT_DEPARTMENT_ERROR';

export const CHANGE_DEPARTMENT_BEGIN = 'CHANGE_DEPARTMENT_BEGIN';
export const CHANGE_DEPARTMENT_SUCCESS = 'CHANGE_DEPARTMENT_SUCCESS';
export const CHANGE_DEPARTMENT_ERROR = 'CHANGE_DEPARTMENT_ERROR';

export const CLEAR_SINGLE_DEPARTMENT = 'CLEAR_SINGLE_DEPARTMENT';
