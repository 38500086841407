import React from 'react';
import DashboardLayout from 'layouts/dashboardLayout';
import WorkersPage from 'components/locals/workers/Workers';

const Workers = () => {
  return (
    <DashboardLayout>
      <WorkersPage />
    </DashboardLayout>
  );
};

export default Workers;
