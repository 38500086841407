import * as actions from './types';
const initialState = {
  company: null,
  loading: false,
  edit_loading: false
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_COMPANY_BEGIN:
      return { ...state, loading: true };

    case actions.GET_COMPANY_SUCCESS:
      return { ...state, company: action.payload, loading: false };

    case actions.GET_COMPANY_ERROR:
      return { ...state, loading: false };

    case actions.EDIT_COMPANY_BEGIN:
      return { ...state, edit_loading: true };

    case actions.EDIT_COMPANY_ERROR:
    case actions.EDIT_COMPANY_SUCCESS:
      return { ...state, edit_loading: false };

    default:
      return state;
  }
};

export default companyReducer;
