import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    borderRadius: '0px !important',
    boxShadow: '-1px 1px 7px 0px rgb(208 200 200 / 20%) !important',
    padding: '0rem',
    '& .title': {
      fontSize: '15px',
      fontWeight: '600',
      color: theme.palette.primary.dark,
      padding: '14px 32px 12px'
    },
    '& .MuiCardContent-root': {
      padding: '0px !important'
    },
    '& .formBodyWrapper': {
      padding: '14px 35px 31px'
    },
    '& .buttonWrapper': {
      backgroundColor: theme.palette.lightGrey[1],
      padding: '13px 20px 13px',
      display: 'flex',
      justifyContent: 'flex-end',
      columnGap: '2rem',
      '& .saveButton': {
        background: theme.palette.primary.normal,
        borderRadius: '2px',
        color: '#fff',
        fontSize: '13px',
        height: '30px'
      },
      '& .saveButtonLoading': {
        background: '#b8b8b8',
        borderRadius: '2px',
        color: '#fff',
        fontSize: '13px',
        height: '30px'
      },
      '& .cancelButton': {
        border: '1px solid #9D9CAF',
        borderRadius: '2px',
        color: '#383751',
        height: '30px',
        fontSize: '13px'
      }
    }
  },
  iconWrapper: {
    '& svg': {
      marginRight: '1.5rem',
      color: theme.palette.grey[600],
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary
      }
    }
  },
  boxWrapper: {
    borderRadius: '0px !important',
    boxShadow: '0px 7px 4px 0px rgb(165 160 160 / 20%) !important',
    '& .body': {
      padding: '14px 30px 15px',
      background: theme.palette.lightGrey[1],
      '& .content': {
        background: '#fff'
      }
    },
    '& .title': {
      color: theme.palette.grey[1],
      fontSize: '12px'
    },
    '& .description': {
      color: theme.palette.primary.dark,
      fontSize: '13px'
    },
    '& .header': {
      fontSize: '15px',
      fontWeight: '600',
      color: theme.palette.primary.dark,
      padding: '8px 25px'
    },
    '& .MuiCardContent-root': {
      padding: '0px !important'
    }
  },
  modalFooter: {
    width: '100%',
    marginTop: 'auto',
    marginLeft: 'auto',
    background: '#fff',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem 3rem 2rem 3rem',
    height: '6rem'
  },
  modalFooterButtons: {
    fontFamily: 'Euclid',
    ':&>*': {
      margin: '0 1rem'
    }
  },
  modalFooterCancel: {
    '&:hover': {
      background: '#ddd'
    }
  }
}));

export default useStyles;
