import { FormattedMessage } from 'react-intl';
import useStyles from '../styles';

function EmployerCost({ item }) {
  const classes = useStyles();
  return (
    <div className={classes.employerCost}>
      <table className={classes.table}>
        <tbody>
          <tr>
            <td colSpan={2}>
              <h4 style={{ background: '#d7d7d7', margin: '0' }}>
                <FormattedMessage id="Employer Cost" defaultMessage="Employer Cost" />
              </h4>
            </td>
          </tr>
          {item?.pension_insurance_employer?.value != 0 && (
            <tr>
              <td>
                <FormattedMessage id="Pension" defaultMessage="Pension" />
              </td>
              <td>{parseFloat(item?.pension_insurance_employer?.value).toFixed(2)}</td>
            </tr>
          )}
          {item?.disability_insurance_employer?.value != 0 && (
            <tr>
              <td>
                <FormattedMessage id="Disability Pension" defaultMessage="Disability Pension" />
              </td>
              <td>{parseFloat(item?.disability_insurance_employer?.value).toFixed(2)}</td>
            </tr>
          )}
          {item?.accident_insurance_employer?.value != 0 && (
            <tr>
              <td>
                <FormattedMessage id="Accident Insurance" defaultMessage="Accident Insurance" />
              </td>
              <td>{parseFloat(item?.accident_insurance_employer?.value).toFixed(2)}</td>
            </tr>
          )}
          {item?.labor_fund_insurance_employer?.value != 0 && (
            <tr>
              <td>
                <FormattedMessage id="FP" defaultMessage="FP" />
              </td>
              <td>{parseFloat(item?.labor_fund_insurance_employer?.value).toFixed(2)}</td>
            </tr>
          )}
          {item?.fgsp_insurance_employer?.value != 0 && (
            <tr>
              <td>
                <FormattedMessage id="FGŚP" defaultMessage="FGŚP" />
              </td>
              <td>{parseFloat(item?.fgsp_insurance_employer?.value).toFixed(2)}</td>
            </tr>
          )}
          {/* {item?.fep_insurance_employer?.value != 0 && (
            <tr>
              <td>
                <FormattedMessage id="FEP" defaultMessage="FEP" />
              </td>
              <td>{parseFloat(item?.fep_insurance_employer?.value).toFixed(2)}</td>
            </tr>
          )} */}
        </tbody>
      </table>
    </div>
  );
}

export default EmployerCost;
