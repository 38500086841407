// this will be the componene for filterForm
import { FormControl, InputLabel, TextField } from '@mui/material';
import { Box } from '@mui/system';
import 'react-datepicker/dist/react-datepicker.css';
import { FormattedMessage } from 'react-intl';
import useStyles from './styles';

const FilterForm = ({ name, setName, email, setEmail }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.optionWrap}>
        <Box display="flex" justifyContent="flex-start" columnGap="15px" width={'50%'}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-label" shrink>
              <FormattedMessage id="Name" defaultMessage="Name" />
            </InputLabel>
            <TextField
              labelId="demo-simple-label"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-label" shrink>
              <FormattedMessage id="Email" defaultMessage="Email" />
            </InputLabel>
            <TextField
              labelId="demo-simple-label"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </FormControl>
        </Box>
      </div>
    </div>
  );
};

export default FilterForm;
