import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { HookFormCheckbox } from 'components/form/hookFormCheckbox';
import { HookFormInput } from 'components/form/hookFormInput';
import { HookFormRadio } from 'components/form/hookFormRadio';
import { HookFormSelect } from 'components/form/hookFormSelect';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

function TimeRecordingForm({ edit = false, name }) {
  const { single_loading } = useSelector((state) => state.employee);
  const { holiday_addons } = useSelector((state) => state.timeRecording);
  const { holiday_loading, holiday_info } = useSelector((state) => state.timeRecording);
  const [diseaseCode, setDiseaseCode] = useState([]);
  const [breakBenefit, setBreakBenefit] = useState([]);
  const { watch, setValue } = useFormContext();
  const typeWatch = watch('type');

  useEffect(() => {
    if (holiday_addons?.disease_codes) {
      const formattedHoliday = holiday_addons?.disease_codes?.map((item) => ({
        label: `${item?.code}  -   ${item?.description}`,
        value: item?.id
      }));
      setDiseaseCode(formattedHoliday);
    }
    if (holiday_addons?.break_benefit_types) {
      const formattedHoliday = holiday_addons?.break_benefit_types?.map((item) => ({
        label: item?.code + ' - ' + item?.description,
        value: item?.id
      }));
      setBreakBenefit(formattedHoliday);
    }
  }, [holiday_addons]);

  useEffect(() => {
    if (!edit) {
      if (typeWatch === 'sick_leave') {
        setValue('name', 'Chorobowe');
      } else if (typeWatch === 'vacation_leave') {
        setValue('name', 'Urlop');
      } else if (typeWatch === 'unpaid_leave') {
        setValue('name', 'Urlop bezpłatny');
      } else if (typeWatch === 'care_leave') {
        setValue('name', 'Opieka');
      }
    }
  }, [typeWatch]);
  return (
    <Box margin={4}>
      <Grid container spacing={2}>
        <Grid item sm={3}>
          <HookFormInput
            name="name"
            label={<FormattedMessage id="Name" defaultMessage="Name" />}
            placeholder="Vacation"
            loading={single_loading}
          />
        </Grid>

        <Grid item sm={3}>
          <HookFormSelect
            disabled={edit}
            name="type"
            label={<FormattedMessage id="Type" defaultMessage="Type" />}
            loading={single_loading}
            options={[
              {
                label: <FormattedMessage id="Sick Leave" defaultMessage="Sick Leave" />,
                value: 'sick_leave'
              },
              {
                label: <FormattedMessage id="Vacation Leave" defaultMessage="Vacation Leave" />,
                value: 'vacation_leave'
              },
              {
                label: <FormattedMessage id="Unpaid Leave" defaultMessage="Unpaid Leave" />,
                value: 'unpaid_leave'
              },
              {
                label: <FormattedMessage id="Care Leave" defaultMessage="Care Leave" />,
                value: 'care_leave'
              }
            ]}
          />
        </Grid>

        <Grid item sm={3}>
          <HookFormInput
            name="start_date"
            label={<FormattedMessage id="Start Date" defaultMessage="Start Date" />}
            type="date"
            loading={single_loading}
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            name="end_date"
            label={<FormattedMessage id="End Date" defaultMessage="End Date" />}
            type="date"
            loading={single_loading}
          />
        </Grid>
        {typeWatch == 'vacation_leave' && (
          <>
            <Grid item sm={3}>
              <HookFormInput
                name="hours"
                label={<FormattedMessage id="Hours" defaultMessage="Hours" />}
                type="number"
                loading={single_loading}
              />
            </Grid>
            <Grid item sm={3}>
              <HookFormInput
                name="_vacation_duration"
                label={
                  <FormattedMessage
                    id="The amount of vacation"
                    defaultMessage="The amount of vacation"
                  />
                }
                disabled
                type="number"
                loading={holiday_loading}
                placeholder={holiday_info?.total_vacation_allowed}
              />
            </Grid>
            <Grid item sm={3}>
              <HookFormInput
                name="_vacation_remaining"
                label={
                  <FormattedMessage
                    id="Remaining to be used"
                    defaultMessage="Remaining to be used"
                  />
                }
                disabled
                type="number"
                loading={holiday_loading}
                placeholder={holiday_info?.total_vacation_left}
              />
            </Grid>
          </>
        )}
        {typeWatch == 'sick_leave' || typeWatch == 'care_leave' ? (
          <>
            <Grid item sm={6}>
              <Box>
                <HookFormSelect
                  // disabled={edit}
                  name="disease_code_id"
                  label={<FormattedMessage id="Disease Code" defaultMessage="Disease Code" />}
                  loading={single_loading}
                  options={diseaseCode}
                />
              </Box>
            </Grid>
            <Grid item sm={6}>
              <HookFormSelect
                // disabled={edit}
                name="break_benefit_type_id"
                label={<FormattedMessage id="Break Benefit Type" defaultMessage="Break Benefit" />}
                loading={single_loading}
                options={breakBenefit}
              />
            </Grid>

            <Grid item sm={3}>
              <HookFormInput
                name="print_series_number"
                label={
                  <FormattedMessage id="Print Series Number" defaultMessage="Print Series Number" />
                }
                loading={single_loading}
              />
            </Grid>
            <Grid item sm={3}>
              <HookFormInput
                name="date_of_release"
                label={<FormattedMessage id="Date of release" defaultMessage="Date of release" />}
                type="date"
                loading={single_loading}
              />
            </Grid>
            <Grid item sm={3}>
              <HookFormInput
                name="date_of_delivery"
                label={<FormattedMessage id="Date of delivery" defaultMessage="Date of delivery" />}
                type="date"
                loading={single_loading}
              />
            </Grid>
            <Grid item sm={3}>
              <HookFormRadio
                loading={single_loading}
                name="hospital_treatment"
                label={
                  <FormattedMessage id="Hospital Treatment" defaultMessage="Hospital Treatment" />
                }
                options={[
                  { label: <FormattedMessage id="Yes" defaultMessage="Yes" />, value: '1' },
                  { label: <FormattedMessage id="No" defaultMessage="No" />, value: '0' }
                ]}
              />
            </Grid>
          </>
        ) : null}
        {typeWatch == 'sick_leave' && (
          <>
            <Grid item sm={3}>
              <HookFormInput
                name="_sick_duration"
                label={
                  <FormattedMessage
                    id="The amount of Sick Leave"
                    defaultMessage="The amount of Sick Leave"
                  />
                }
                disabled
                type="number"
                loading={holiday_loading}
                placeholder={holiday_info?.total_sick_leave_allowed}
              />
            </Grid>
            <Grid item sm={4}>
              <HookFormInput
                name="_sick_remaining"
                label={
                  <FormattedMessage
                    id="Number of sick days since beginning of the year"
                    defaultMessage="Number of sick days since beginning of the year"
                  />
                }
                disabled
                type="number"
                loading={holiday_loading}
                placeholder={holiday_info?.total_sick_leave_used}
              />
            </Grid>
            <Grid item sm={4}>
              <Box marginTop={1.5} marginLeft={3}>
                <HookFormCheckbox
                  name="waiting_period"
                  label={<FormattedMessage id="Waiting Period" defaultMessage="Waiting Period" />}
                />
              </Box>
            </Grid>
          </>
        )}
        {typeWatch == 'care_leave' && (
          <>
            <Grid item sm={3}>
              <HookFormSelect
                // disabled={edit}
                name="care_type"
                label={<FormattedMessage id="Care Type" defaultMessage="Care Type" />}
                loading={single_loading}
                options={[
                  {
                    label: <FormattedMessage id="Child Care" defaultMessage="Child Care" />,
                    value: 'child_care'
                  },
                  {
                    label: (
                      <FormattedMessage
                        id="Care for family member"
                        defaultMessage="Care for family member"
                      />
                    ),
                    value: 'family_care'
                  }
                ]}
              />
            </Grid>
            <Grid item sm={3}>
              <HookFormInput
                name="care_person"
                label={<FormattedMessage id="Care Person" defaultMessage="Care Person" />}
                type="text"
                loading={single_loading}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}

export default TimeRecordingForm;
