import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import useStyles from '../../styles';

function PrintDocument({ handleClose }) {
  const classes = useStyles();
  const { single_contract } = useSelector((state) => state.contract);

  const documentName = `${single_contract?.contract_number}.docx`;
  const checkValue = (value) => {
    if (value == 'null' || !value) {
      return '';
    } else return value;
  };

  const address = single_contract?.employee?.address?.[0];
  const companyAddress = single_contract?.employee?.company?.addresses?.[0];
  const idNumber = single_contract?.employee?.employee_detail?.find(
    (list) => list?.key === 'identification_number'
  )?.value;
  const getRate = () => {
    if (single_contract?.parameters?.rate == 'monthly') {
      return single_contract?.gross_salary + ' zł brutto/miesięcznie.';
    } else if (single_contract?.parameters?.rate == 'hourly') {
      return single_contract?.gross_salary + ' zł brutto za godzinę. ';
    } else {
      return '';
    }
  };

  const generate = () => {
    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),

            new Paragraph({
              text: ''
            }),
            new Paragraph({
              alignment: 'center',
              children: [
                new TextRun({
                  text: 'UMOWA ZLECENIE',
                  bold: true,
                  size: 26
                })
              ]
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: `zawarta w dniu ${single_contract?.date_of_signing} W ${companyAddress?.city} pomiędzy:`
            }),
            new Paragraph({
              text: `${single_contract?.employee?.company?.name ?? ''}, ${
                single_contract?.employee?.company?.regon ?? ''
              }, ${single_contract?.employee?.company?.tax_id ?? ''}`
            }),
            new Paragraph({
              text: `${checkValue(companyAddress?.street)} ${checkValue(
                companyAddress?.house_number
              )} ${checkValue(companyAddress?.number_of_premises)} ${checkValue(
                companyAddress?.zip
              )} ${checkValue(companyAddress?.post)} ${checkValue(
                companyAddress?.city
              )} ${checkValue(companyAddress?.country)} ${checkValue(
                companyAddress?.voivodeship
              )} ${checkValue(companyAddress?.county)} ${checkValue(companyAddress?.community)}`
            }),

            new Paragraph({
              text: `reprezentowanym przez: ${single_contract?.representative ?? ''}`
            }),
            new Paragraph({
              text: `zwanym dalej Zleceniodawcą, a:`
            }),
            new Paragraph({
              text: `${single_contract?.employee?.first_name ?? ''} ${
                single_contract?.employee?.second_name ?? ''
              } ${single_contract?.employee?.last_name ?? ''}, ${single_contract?.employee?.pesel}`
            }),
            new Paragraph({
              text: 'zamieszkałym'
            }),
            new Paragraph({
              text: `${checkValue(address?.street)} ${checkValue(
                address?.house_number
              )} ${checkValue(address?.number_of_premises)} ${checkValue(
                address?.zip
              )} ${checkValue(address?.post)} ${checkValue(address?.city)} ${checkValue(
                address?.country
              )} ${checkValue(address?.voivodeship)} ${checkValue(address?.county)} ${checkValue(
                address?.community
              )}`
            }),
            new Paragraph({
              text: `legitymującym się dowodem osobistym seria i nr ${idNumber}`
            }),
            new Paragraph({
              text: 'zwanym dalej Zleceniobiorcą.'
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              alignment: 'center',
              children: [
                new TextRun({
                  text: '§ 1',
                  bold: true,
                  size: 22
                })
              ]
            }),
            new Paragraph({
              text: '1. Na podstawie niniejszej umowy Zleceniodawca zleca, a Zleceniobiorca zobowiązuje się do wykonania następujących czynności:'
            }),
            new Paragraph({
              text: single_contract?.parameters?.terms_of_mandate
            }),
            new Paragraph({
              text: 'zwanym dalej zleceniem.'
            }),
            new Paragraph({
              text: '2. Zleceniobiorca wykona powierzone prace z dołożeniem należytej staranności.'
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              alignment: 'center',
              children: [
                new TextRun({
                  text: '§ 2',
                  bold: true,
                  size: 22
                })
              ]
            }),
            new Paragraph({
              text: `1. Za wykonanie czynności określonych w §1 Zleceniobiorca otrzyma po ich wykonaniu
              wynagrodzenie w wysokości ${getRate()}`
            }),
            new Paragraph({
              text: '2. Wynagrodzenie płatne jest na podstawie rachunku przedstawionego przez Zleceniobiorcę.'
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              alignment: 'center',
              children: [
                new TextRun({
                  text: '§ 3',
                  bold: true,
                  size: 22
                })
              ]
            }),
            new Paragraph({
              text: 'Zleceniobiorca nie może powierzyć prac wymienionych w § 1 innym osobom bez zgody Zleceniodawcy.'
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              alignment: 'center',
              children: [
                new TextRun({
                  text: '§ 4',
                  bold: true,
                  size: 22
                })
              ]
            }),
            new Paragraph({
              text: `Umowa została zawarta na czas od ${single_contract?.start_date} do ${single_contract?.end_date}.`
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              alignment: 'center',
              children: [
                new TextRun({
                  text: '§ 5',
                  bold: true,
                  size: 22
                })
              ]
            }),
            new Paragraph({
              text: ' 1. Zleceniobiorca zobowiązany jest do prowadzenia ewidencji ilości godzin wykonywanego zlecenia, która dokumentować ma faktyczny czas wykonania przez Zleceniobiorcę zlecenia.'
            }),
            new Paragraph({
              text: ' 2. Przed przystąpieniem do wykonania zlecenia, Zleceniobiorca poinformuje Zleceniodawcę o planowanej ilości godzin niezbędnej do wykonania zlecenia.'
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              alignment: 'center',
              children: [
                new TextRun({
                  text: '§ 6',
                  bold: true,
                  size: 22
                })
              ]
            }),
            new Paragraph({
              text: ' 1. Każda ze Stron może rozwiązać umowę bez podania powodu, z zachowaniem okresu wypowiedzenia, który wynosi ……………………………………………………… .'
            }),
            new Paragraph({
              text: ' 2. Jeśli Zleceniobiorca wypowie umowę bez ważnego powodu ponosi odpowiedzialność za wynikłą stąd szkodę.'
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              alignment: 'center',
              children: [
                new TextRun({
                  text: '§ 7',
                  bold: true,
                  size: 22
                })
              ]
            }),
            new Paragraph({
              text: ' Z tytułu niniejszej umowy Zleceniobiorca nie nabywa żadnych uprawnień pracowniczych.'
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              alignment: 'center',
              children: [
                new TextRun({
                  text: '§ 8',
                  bold: true,
                  size: 22
                })
              ]
            }),
            new Paragraph({
              text: 'Zmiany umowy wymagają formy pisemnej pod rygorem nieważności.'
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              alignment: 'center',
              children: [
                new TextRun({
                  text: '§ 9',
                  bold: true,
                  size: 22
                })
              ]
            }),
            new Paragraph({
              text: 'W sprawach nieuregulowanych niniejszą umową mają zastosowanie przepisy Kodeksu cywilnego.'
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              alignment: 'center',
              children: [
                new TextRun({
                  text: '§ 10',
                  bold: true,
                  size: 22
                })
              ]
            }),
            new Paragraph({
              text: ' Umowę sporządzono w dwóch jednobrzmiących egzemplarzach po jednym dla każdej ze stron.'
            }),
            new Paragraph({
              text: ''
            }),

            new Paragraph({
              text: ''
            }),

            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: '……………………………                                                               ……………………………'
            }),
            new Paragraph({
              text: '          (Zleceniodawca)                                                                                 (Zleceniobiorca) '
            })
          ]
        }
      ]
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, documentName);
    });
  };
  return (
    <div>
      <Box className={classes.modalFooter}>
        <Box className={classes.modalFooterButtons}>
          <Button
            className={classes.modalFooterCancel}
            sx={{ marginRight: '6rem' }}
            variant={'contained'}
            onClick={generate}>
            <FormattedMessage id="Export Doc" defaultMessage="Export Doc" />
          </Button>
          <Button
            className={classes.modalFooterCancel}
            sx={{ marginRight: '6rem' }}
            variant={'outlined'}
            onClick={handleClose}>
            <FormattedMessage id="Cancel" defaultMessage="Cancel" />
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default PrintDocument;
