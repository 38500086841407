// this will be the componene for filterForm
import { Box } from '@mui/system';
import React, { useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TextField } from '@mui/material';
import useStyles from './styles';
import { FormattedMessage } from 'react-intl';
import { translate } from 'utils';
import { useSelector } from 'react-redux';

// const Example = () => {
//   const [startDate, setStartDate] = useState(new Date());
//   return <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />;
// };

const FilterForm = () => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(new Date('2014-08-18T21:11:54'));
  const [endDate, setEndDate] = useState(new Date('2014-08-18T21:11:54'));
  const { agreement_types } = useSelector((state) => state.contract);

  return (
    <div className={classes.root}>
      <Box display="none">
        <FormattedMessage id="Type of Contract" defaultMessage="Type of Contract" />
      </Box>
      <div className={classes.optionWrap}>
        <Box className={classes.dateWrapper}>
          <DesktopDatePicker
            inputFormat="MM/DD/YYYY"
            value={startDate}
            onChange={(value) => setStartDate(value)}
            renderInput={(params) => <TextField {...params} />}
          />
          <div className={classes.hyphen}>-</div>
          <DesktopDatePicker
            inputFormat="MM/DD/YYYY"
            value={endDate}
            onChange={(value) => setEndDate(value)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
        <select className={classes.select}>
          <option value="">{translate('Type of Contract')}</option>
          {agreement_types?.map((item) => (
            <option value={item?.id} key={item?.id}>
              {item?.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default FilterForm;
