import { useSelector } from 'react-redux';
import AccountContainer from './AccountContainer';
import CompanyContainer from './CompanyContainer';
import useStyles from './styles';

function Dashboard() {
  const classes = useStyles();
  const { isAccounting } = useSelector((state) => state.auth);

  return (
    <div className={classes.root}>
      {!isAccounting && <CompanyContainer />}
      {isAccounting && <AccountContainer />}
    </div>
  );
}

export default Dashboard;
