import AccountingPage from 'components/locals/accounting';
import AccountingLayout from 'layouts/dashboardLayout';

const Accounting = () => {
  return (
    <AccountingLayout>
      <AccountingPage />
    </AccountingLayout>
  );
};

export default Accounting;
