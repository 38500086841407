import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useStyles from '../styles';

import { Report } from '@mui/icons-material';
import EmployeeView from 'components/locals/workers/components/viewEmployee/EmployeeView';
import { FormattedMessage } from 'react-intl';
import { translate } from 'utils';
import PayslipView from '../payslip/PayslipView';
import CorrectionModal from './CorrectionModal';

function TableContent({ fetchItemPayroll, company_id }) {
  const { itemPayrolls } = useSelector((state) => state.payroll);
  const rows = itemPayrolls?.paylists || [];
  company_id && localStorage.setItem('Temp_companyId', company_id);
  return rows?.map((item, index) => (
    <>
      <DataRow key={item?.id} index={index} item={item} fetchItemPayroll={fetchItemPayroll} />
      {item?.cancellationPayroll && (
        <DataRow
          key={item?.cancellationPayroll?.id}
          index={index}
          item={item?.cancellationPayroll}
          isChild={true}
          fetchItemPayroll={fetchItemPayroll}
        />
      )}
      {item?.corrections &&
        item?.corrections?.map((newItem) => (
          <>
            <DataRow
              key={newItem?.id}
              index={index}
              item={newItem}
              isChild={true}
              fetchItemPayroll={fetchItemPayroll}
              company_id={company_id}
            />
            {newItem?.cancellationPayroll && (
              <DataRow
                key={newItem?.cancellationPayroll?.id}
                index={index}
                item={newItem?.cancellationPayroll}
                isChild={true}
                fetchItemPayroll={fetchItemPayroll}
                company_id={company_id}
              />
            )}
          </>
        ))}
    </>
  ));
}

export default TableContent;

const DataRow = ({ item, index, isChild, fetchItemPayroll }) => {
  const { agreement_types } = useSelector((state) => state.contract);
  const [keyData, setKeyData] = useState({});

  useEffect(() => {
    setKeyData({});
    item?.outputs?.map((list) => setKeyData((keyData) => ({ ...keyData, [list.name]: list })));
  }, [item]);

  const agreementName = (id) => {
    const filteredAgreement = agreement_types?.find((list) => list.id === id);
    return filteredAgreement?.name ?? '';
  };

  return (
    <>
      <TableRow key={index}>
        <TableCell>{!isChild && index + 1}</TableCell>
        <TableCell>
          {!isChild && (
            <EmployeeViewName item={item?.employee} fetchItemPayroll={fetchItemPayroll} />
          )}
        </TableCell>
        <TableCell>{item?.type}</TableCell>
        <TableCell>{item?.paylist_code}</TableCell>
        <TableCell>{agreementName(item?.agreement?.agreement_type_id)}</TableCell>
        <TableCell>
          {keyData?.salary_to_pay?.value
            ? parseFloat(keyData?.salary_to_pay?.value)?.toFixed(2)
            : ''}
        </TableCell>
        <TableCell>
          {keyData?.employer_total_insurance?.value
            ? parseFloat(keyData?.employer_total_insurance?.value)?.toFixed(2)
            : ''}
        </TableCell>
        <TableCell>
          {keyData?.contract_salary?.value
            ? parseFloat(keyData?.contract_salary?.value)?.toFixed(2)
            : ''}
        </TableCell>
        <TableCell>
          {keyData?.tax_amount?.value
            ? parseFloat(keyData?.tax_after_free_amount?.value)?.toFixed(2)
            : ''}
        </TableCell>
        <TableCell>{item?.status}</TableCell>
        <TableCell align="left">
          <Actions item={item} />
        </TableCell>
      </TableRow>
    </>
  );
};

const Actions = ({ item }) => {
  const classes = useStyles();
  const [modalOpen, openFunction] = useToggle(false);
  const [modalCorrection, openCorrection] = useToggle(false);
  const [keyData, setKeyData] = useState({});

  const handleClick = () => {
    openFunction();
  };

  const handleCorrection = () => {
    openCorrection();
  };
  useEffect(() => {
    item?.outputs?.map((list) => setKeyData((keyData) => ({ ...keyData, [list.name]: list })));
  }, [item]);

  return (
    <>
      <div className={classes.iconWrapper}>
        <Tooltip title={<FormattedMessage id="View" defaultMessage="View" />}>
          <VisibilityIcon onClick={handleClick} />
        </Tooltip>
        {item?.status == 'CLOSED' && item?.type != 'CANCELLATION' && !item?.cancellationPayroll && (
          <Tooltip
            title={
              <FormattedMessage id="Genereate Correction" defaultMessage="Generate Correction" />
            }>
            <RefreshIcon onClick={handleCorrection} />
          </Tooltip>
        )}
        {keyData?.vacation_days?.value > 0 &&
        (keyData?.rate_per_hour?.overwrite_value == null ||
          keyData?.rate_per_hour?.overwrite_value == 0) &&
        keyData?.vacation_reference_count?.value < 3 ? (
          <Tooltip
            title={
              <Typography variant="h6">
                {
                  <FormattedMessage
                    id="Insufficient Data for Vacation Pay Calculation. You can overwrite rate per hour
                value by clicking the edit button"
                    defaultMessage="Insufficient Data for Vacation Pay Calculation. You can overwrite rate per hour
                value by clicking the edit button"
                  />
                }
              </Typography>
            }>
            <Report style={{ color: 'red', cursor: 'pointer' }} />
          </Tooltip>
        ) : null}
        {keyData?.sick_days?.value > 0 &&
        (keyData?.per_day_sick_pay?.overwrite_value == null ||
          keyData?.per_day_sick_pay?.overwrite_value == 0) &&
        keyData?.sick_reference_count?.value < 12 ? (
          <Tooltip
            title={
              <Typography variant="h6">
                {
                  <FormattedMessage
                    id="Insufficient Data for Sick Pay Calculation. You can overwrite per day sick pay value
                by clicking the edit button"
                    defaultMessage="Insufficient Data for Sick Pay Calculation. You can overwrite per day sick pay value
                by clicking the edit button"
                  />
                }
              </Typography>
            }>
            <Report style={{ color: 'red', cursor: 'pointer' }} />
          </Tooltip>
        ) : null}
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="1000px"
        headerText={<FormattedMessage id="Payslip View" defaultMessage="Payslip View" />}>
        <PayslipView item={item} />
      </CustomModal>
      <CustomModal
        open={modalCorrection}
        handleClose={openCorrection}
        height="auto"
        width="300px"
        headerText={
          <FormattedMessage id="Genereate Correction" defaultMessage="Generate Correction" />
        }>
        <CorrectionModal item={item} handleClose={openCorrection} />
      </CustomModal>
    </>
  );
};

const EmployeeViewName = ({ item, fetchItemPayroll }) => {
  const [modalOpen, openFunction] = useToggle(false);
  const classes = useStyles();
  const handleClick = () => {
    openFunction();
  };
  const joinName = () => {
    const firstName = item.first_name ?? '';
    const lastName = item.last_name ?? '';
    const middleName = item.middle_name ?? '';
    const name = lastName + ' ' + firstName + ' ' + middleName;
    return name;
  };
  const handleClose = () => {
    openFunction();
    fetchItemPayroll();
  };
  return (
    <>
      <div className={classes.fullname} onClick={handleClick}>
        {joinName()}
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={handleClose}
        height="auto"
        width="100rem"
        variant="light"
        headerText={`${translate('Details of')} ${joinName()}`}>
        <EmployeeView id={item?.id} />
      </CustomModal>
    </>
  );
};
