import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    '& svg': {
      marginRight: '1.5rem',
      color: theme.palette.grey[600],
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary
      }
    }
  },
  contentWrap: {
    padding: '2.5rem 3rem'
  },
  modalFooter: {
    width: '100%',
    marginTop: 'auto',
    marginLeft: 'auto',
    background: '#fff',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem 3rem',
    height: '4.9rem'
  },
  modalFooterButtons: {
    fontFamily: 'Euclid',
    ':&>*': {
      margin: '0 1rem'
    }
  },
  modalFooterCancel: {
    '&:hover': {
      background: '#ddd'
    }
  },

  deleteBtn: {
    color: `${theme.palette.error.main} !important`
  },

  inputText: {
    fontSize: '1.3rem',
    border: `1px solid ${theme.palette.grey[400]}`,
    padding: '0.4rem 1rem',
    width: '100%'
  }
}));

export default useStyles;
