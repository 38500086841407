import { Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import HookFormAutoComplete from 'components/form/hookFormAutoComplete';
import { HookFormCheckbox } from 'components/form/hookFormCheckbox';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getContribution } from '../../redux/actions';
import OpeningSocialContribution from './OpeningSocialContribution';
import OpeningTax from './OpeningTax';

function Zus({ employeeId, contractTypeId }) {
  const dispatch = useDispatch();
  const { insurance_code, agreement_types } = useSelector((state) => state.contract);
  const { setValue, watch } = useFormContext();

  useEffect(() => {
    const filterAgreement = agreement_types?.find((list) => list?.id == contractTypeId);

    employeeId &&
      filterAgreement?.slug &&
      dispatch(getContribution(employeeId, filterAgreement?.slug));
  }, [employeeId]);

  const own_employee = watch('parameters.own_employee');

  useEffect(() => {
    if (own_employee == 0 || !own_employee) {
      setValue('parameters.labor_fund_insurance', false);
      setValue('parameters.fgsp_insurance', false);
    }
  }, [own_employee]);
  return (
    <Grid container spacing={1}>
      <Grid item sm={12}>
        <HookFormAutoComplete
          name="parameters.insurance_code_id"
          label={
            <FormattedMessage id="Insurance title code" defaultMessage="Insurance title code" />
          }
          tooltipLabel={
            <FormattedMessage
              id="The employee's insurance title code will be used in personal monthly reports (RCA, RSA) to the Social Insurance Institution. The codes do not affect the payroll and bill calculations in the system."
              defaultMessage="The employee's insurance title code will be used in personal monthly reports (RCA, RSA) to the Social Insurance Institution. The codes do not affect the payroll and bill calculations in the system."
            />
          }
          options={insurance_code?.map((item) => ({
            label: item?.code + ' - ' + item?.title,
            value: item?.id
          }))}
        />
      </Grid>
      <Grid item sm={4}>
        <HookFormCheckbox
          name="parameters.own_employee"
          label={<FormattedMessage id="Own Employee" defaultMessage="Own Employee" />}
        />
      </Grid>

      <Grid item sm={4}>
        <HookFormCheckbox
          disabled={own_employee == 0 || !own_employee ? true : false}
          name="parameters.labor_fund_insurance"
          label={<FormattedMessage id="Labour Fund" defaultMessage="Labour Fund" />}
        />
      </Grid>
      <Grid item sm={4}>
        <HookFormCheckbox
          disabled={own_employee == 0 || !own_employee ? true : false}
          name="parameters.fgsp_insurance"
          label={<FormattedMessage id="FGSP" defaultMessage="FGSP" />}
        />
      </Grid>

      <Grid item sm={12}>
        <Paper sx={{ padding: '10px 15px', marginTop: '30px' }}>
          <Box sx={{ background: '#fff', padding: '8px 10px', marginTop: '10px' }}>
            <Typography variant="h6" fontWeight={400}>
              <FormattedMessage
                id="Information on the annual basis of contributions from the beginning of the year"
                defaultMessage="Information on the annual basis of contributions from the beginning of the year"
              />
            </Typography>
          </Box>
          <OpeningTax employeeId={employeeId} contractTypeId={contractTypeId} />
          <OpeningSocialContribution employeeId={employeeId} contractTypeId={contractTypeId} />
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Zus;
