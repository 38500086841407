import * as actions from './types';
import { errorToast, successToast } from 'utils/toast';
import {
  createAllowanceApi,
  deleteAllowanceApi,
  editAllowanceApi,
  getAllowanceApi,
  getAllowanceByIdApi
} from 'apis/allowance';

// create Allowance
export const createAllowance = (data, handleFunction, handleRefetch) => (dispatch) => {
  dispatch({
    type: actions.CREATE_ALLOWANCE_BEGIN
  });
  createAllowanceApi(data)
    .then((res) => {
      dispatch({
        type: actions.CREATE_ALLOWANCE_SUCCESS
      });
      handleFunction && handleFunction();
      handleRefetch && handleRefetch();
      dispatch(getAllowance(data?.employee_id));
      successToast('Allowance created Successfully');
    })
    .catch((error) => {
      dispatch({
        type: actions.CREATE_ALLOWANCE_ERROR
      });
      errorToast(error);
    });
};

// get all allowance
export const getAllowance = (employee_id, contractId) => (dispatch) => {
  dispatch({
    type: actions.GET_ALLOWANCE_BEGIN
  });
  getAllowanceApi(employee_id, contractId)
    .then((res) => {
      dispatch({
        type: actions.GET_ALLOWANCE_SUCCESS,
        payload: res.data.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_ALLOWANCE_ERROR
      });
      errorToast(error);
    });
};

// get single allowance
export const getAllowanceById = (id) => (dispatch) => {
  dispatch({
    type: actions.GET_SINGLE_ALLOWANCE_BEGIN
  });
  getAllowanceByIdApi(id)
    .then((res) => {
      dispatch({
        type: actions.GET_SINGLE_ALLOWANCE_SUCCESS,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_SINGLE_ALLOWANCE_ERROR
      });
      errorToast(error);
    });
};

// edit allowance
export const editAllowance =
  (id, data, employeeId, handleFunction, handleRefetch) => (dispatch) => {
    dispatch({
      type: actions.EDIT_ALLOWANCE_BEGIN
    });
    editAllowanceApi(id, data)
      .then((res) => {
        dispatch({
          type: actions.EDIT_ALLOWANCE_SUCCESS
        });
        handleFunction && handleFunction();
        handleRefetch && handleRefetch();
        successToast('Allowance edited Successfully');
        dispatch(getAllowance(employeeId));
      })
      .catch((error) => {
        dispatch({
          type: actions.EDIT_ALLOWANCE_ERROR
        });
        errorToast(error);
      });
  };

// delete allowance and deduction
export const deleteAllowance = (id, employee_id, handleSuccess) => (dispatch) => {
  dispatch({
    type: actions.DELETE_ALLOWANCE_BEGIN
  });
  deleteAllowanceApi(id)
    .then((res) => {
      dispatch({
        type: actions.DELETE_ALLOWANCE_SUCCESS,
        payload: res.data
      });
      dispatch(getAllowance(employee_id));
      successToast('Deleted Successfully');
      handleSuccess && handleSuccess();
    })
    .catch((error) => {
      dispatch({
        type: actions.DELETE_ALLOWANCE_ERROR
      });
      errorToast(error);
    });
};
