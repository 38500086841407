import { Paper } from '@mui/material';
import { Box } from '@mui/system';
import CustomTable from 'components/globals/table';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from 'utils';
import { getHolidayBasis } from './redux/actions';

function TimeTable({ employeeId }) {
  const inputs = useWatch();
  const dispatch = useDispatch();
  console.log(inputs, 'inputs');
  useEffect(() => {
    if (inputs?.type !== 'unpaid_leave') {
      if (inputs.start_date && inputs.type) {
        dispatch(
          getHolidayBasis({ employee_id: employeeId, date: inputs?.start_date, type: inputs?.type })
        );
      }
    }
  }, [inputs.start_date, inputs.type]);

  const { holiday_loading, holiday_basis } = useSelector((state) => state.timeRecording);
  const rows = holiday_basis || [];

  const months = [
    { label: translate('January'), value: 1 },
    { label: translate('February'), value: 2 },
    { label: translate('March'), value: 3 },
    { label: translate('April'), value: 4 },
    { label: translate('May'), value: 5 },
    { label: translate('June'), value: 6 },
    { label: translate('July'), value: 7 },
    { label: translate('August'), value: 8 },
    { label: translate('September'), value: 9 },
    { label: translate('October'), value: 10 },
    { label: translate('November'), value: 11 },
    { label: translate('December'), value: 12 }
  ];
  const currentMonth = (item) => {
    const filterMonth = months?.find((month) => month?.value == item?.month);
    return (
      <span>
        {filterMonth?.label} , {item?.year}
      </span>
    );
  };

  const columns = [
    { title: 'S.N.', type: 'Index', isSortable: true, minWidth: 20 },
    {
      title: <FormattedMessage id="Date" defaultMessage="Date" />,
      minWidth: 100,
      isSortable: true,
      field: (row) => currentMonth(row)
    },

    {
      field: 'value',
      title: <FormattedMessage id="Value" defaultMessage="Value" />,
      minWidth: 120,
      align: 'right'
    },
    {
      field: 'hours',
      title: <FormattedMessage id="Hours" defaultMessage="Hours" />,
      minWidth: 120,
      align: 'right'
    },
    {
      title: <FormattedMessage id="Contract Type" defaultMessage="Contract Type" />,
      minWidth: 120,
      field: (row) => row?.agreementType?.name
    }

    // {
    //   field: (row) => {
    //     return <Actions row={row} employeeId={employeeId} />;
    //   },
    //   title: <FormattedMessage id="Actions" defaultMessage="Actions" />,
    //   minWidth: 170,
    //   align: 'center'
    // }
  ];

  const getColumns = () => {
    if (inputs?.type === 'sick_leave' || inputs?.type === 'care_leave') {
      return [...columns.slice(0, 3), ...columns.slice(4)];
    } else {
      return columns.slice(0, 4);
    }
  };

  return (
    <Box paddingX={4} paddingY={2}>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <CustomTable tableData={rows} tableHeads={getColumns()} loading={holiday_loading} />
      </Paper>
    </Box>
  );
}

export default TimeTable;
