import { Alert, Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import BusinessAddressForm from './BusinessAddressForm';

function BusinessAddress({ addressError, setAddressError, company }) {
  const {
    register,
    watch,
    control,
    formState: { errors }
  } = useFormContext();
  const addressWatch = useWatch({ control, name: 'addresses[0]' });

  console.log(errors);
  useEffect(() => {
    setAddressError && setAddressError('');
  }, [addressWatch]);

  return (
    <Box>
      <Box sx={{ borderLeft: '3px solid #4C4B63' }}>
        <Typography className="title">
          {' '}
          {<FormattedMessage id="Address" defaultMessage="Address" />}
        </Typography>
      </Box>
      <Box className="formBodyWrapper">
        <Box>
          <Box mb={3} display="flex" alignItems="center" columnGap={1}>
            <Typography sx={{ fontSize: '13px' }}>
              <FormattedMessage id="Home Address" defaultMessage="Home Address" />
            </Typography>
          </Box>
          <BusinessAddressForm type="home" index={0} />
        </Box>
        {addressError && (
          <Box marginTop={2}>
            <Alert severity="error">
              <Typography fontSize={12}>{addressError}</Typography>
            </Alert>
          </Box>
        )}
        <Box
          display="flex"
          alignItems="center"
          marginBottom={4}
          marginTop={3}
          columnGap={1}
          sx={{ background: '#fff' }}>
          <input
            type="checkbox"
            value="home"
            {...register('addresses[1][same_address_type]')}
            style={{ cursor: 'pointer' }}
          />

          <Typography sx={{ fontSize: '13px' }}>
            <FormattedMessage
              id="The mailing address is the same as the company's registered address."
              defaultMessage="The mailing address is the same as the company's registered address."
            />
          </Typography>
        </Box>
        {!watch('addresses[1][same_address_type]') ? (
          <Box>
            <Box display="flex" alignItems="center" columnGap={1} marginBottom={3}>
              <Typography sx={{ fontSize: '13px' }}>
                <FormattedMessage id="Registered Address" defaultMessage="Registered Address" />
              </Typography>
            </Box>
            <BusinessAddressForm type="registered" index={1} />
          </Box>
        ) : (
          false
        )}
      </Box>
    </Box>
  );
}

export default BusinessAddress;
