import * as actions from './types';
import { errorToast } from 'utils/toast';
import {} from 'apis/employee';
import {
  closePaylistApi,
  editPayrollApi,
  generateCorrectionPayrollApi,
  getAllPayrollApi,
  getAllPayrollBulkApi,
  getCollectivePayrollApi,
  getCollectivePayrollDetailApi,
  getCollectivePayrollDetailBulkApi,
  getItemPayrollApi,
  modifyPayrollApi,
  sendPayoutMailApi,
  sendPayoutOrderApi
} from 'apis/payroll';

// get all item payroll
export const getItemPayroll = (year, month) => (dispatch) => {
  dispatch({
    type: actions.GET_ITEM_PAYROLL_BEGIN
  });
  getItemPayrollApi(year, month)
    .then((res) => {
      dispatch({
        type: actions.GET_ITEM_PAYROLL_SUCCESS,
        payload: res.data.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_ITEM_PAYROLL_ERROR
      });
      errorToast(error);
    });
};

// get all collective payroll
export const getCollectivePayroll = (startDate, endDate, status, agreement) => (dispatch) => {
  dispatch({
    type: actions.GET_COLLECTIVE_PAYROLL_BEGIN
  });
  getCollectivePayrollApi(startDate, endDate, status, agreement)
    .then((res) => {
      dispatch({
        type: actions.GET_COLLECTIVE_PAYROLL_SUCCESS,
        payload: res.data.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_COLLECTIVE_PAYROLL_ERROR
      });
      errorToast(error);
    });
};
// edit payroll
export const editPayroll = (data, year, month, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.EDIT_PAYROLL_BEGIN
  });
  editPayrollApi(data)
    .then((res) => {
      dispatch({
        type: actions.EDIT_PAYROLL_SUCCESS,
        payload: res.data.data
      });
      dispatch(getItemPayroll(year, month));
      handleFunction && handleFunction();
    })
    .catch((error) => {
      dispatch({
        type: actions.EDIT_PAYROLL_ERROR
      });
      errorToast(error);
    });
};

// modify payroll
export const modifyPayroll = (data, handleFunction, handleRefetch) => (dispatch) => {
  dispatch({
    type: actions.MODIFY_PAYROLL_BEGIN
  });
  modifyPayrollApi(data)
    .then((res) => {
      dispatch({
        type: actions.MODIFY_PAYROLL_SUCCESS,
        payload: res.data.data
      });
      handleFunction && handleFunction();
      handleRefetch && handleRefetch();
    })
    .catch((error) => {
      dispatch({
        type: actions.MODIFY_PAYROLL_ERROR
      });
      errorToast(error);
    });
};

// close paylist
export const closePaylist = (data, handleFunction, handleRefetch) => (dispatch) => {
  dispatch({
    type: actions.CLOSE_PAYLIST_BEGIN
  });
  closePaylistApi(data)
    .then((res) => {
      dispatch({
        type: actions.CLOSE_PAYLIST_SUCCESS,
        payload: res.data.data
      });
      handleFunction && handleFunction();
      handleRefetch && handleRefetch();
    })
    .catch((error) => {
      dispatch({
        type: actions.CLOSE_PAYLIST_ERROR
      });
      errorToast(error);
    });
};

// get all collective payroll
export const getAllPayroll = (id) => (dispatch) => {
  dispatch({
    type: actions.GET_ALL_PAYROLL_BEGIN
  });
  getAllPayrollApi(id)
    .then((res) => {
      dispatch({
        type: actions.GET_ALL_PAYROLL_SUCCESS,
        payload: res.data.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_ALL_PAYROLL_ERROR
      });
      errorToast(error);
    });
};

// get all collective payroll Bulk
export const getAllPayrollBulk = (id, company_id) => (dispatch) => {
  dispatch({
    type: actions.GET_ALL_PAYROLL_BEGIN
  });
  getAllPayrollBulkApi(id, company_id)
    .then((res) => {
      dispatch({
        type: actions.GET_ALL_PAYROLL_SUCCESS,
        payload: res.data.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_ALL_PAYROLL_ERROR
      });
      errorToast(error);
    });
};

// generate correction payroll
// get all collective payroll
export const generateCorrectionPayroll = (id, year, month) => (dispatch) => {
  dispatch({
    type: actions.ITEM_CORRECTION_PAYROLL_BEGIN
  });
  generateCorrectionPayrollApi(id)
    .then((res) => {
      dispatch({
        type: actions.ITEM_CORRECTION_PAYROLL_SUCCESS
      });
      dispatch(getItemPayroll(year, month));
    })
    .catch((error) => {
      dispatch({
        type: actions.ITEM_CORRECTION_PAYROLL_ERROR
      });
      errorToast(error);
    });
};

// get collective detailed-view which is same as item payroll
// get all item payroll
export const getCollectivePayrollDetail = (id) => (dispatch) => {
  dispatch({
    type: actions.GET_ITEM_PAYROLL_BEGIN
  });
  getCollectivePayrollDetailApi(id)
    .then((res) => {
      dispatch({
        type: actions.GET_COLLECTIVE_DETAIL_SUCCESS,
        payload: res.data.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_ITEM_PAYROLL_ERROR
      });
      errorToast(error);
    });
};

export const getCollectivePayrollDetailBulk = (id, company_id) => (dispatch) => {
  dispatch({
    type: actions.GET_ITEM_PAYROLL_BEGIN
  });
  getCollectivePayrollDetailBulkApi(id, company_id)
    .then((res) => {
      dispatch({
        type: actions.GET_COLLECTIVE_DETAIL_SUCCESS,
        payload: res.data.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_ITEM_PAYROLL_ERROR
      });
      errorToast(error);
    });
};

export const addItemFilter = (data) => (dispatch) => {
  dispatch({
    type: actions.ADD_ITEM_FILTER,
    payload: data
  });
};

export const addCollectiveFilter = (data) => (dispatch) => {
  dispatch({
    type: actions.ADD_COLLECTIVE_FILTER,
    payload: data
  });
};

// send Payout order
export const sendPayoutOrder = (id, handleFunction, handleRefetch) => (dispatch) => {
  dispatch({
    type: actions.PAYOUT_ORDER_BEGIN
  });
  sendPayoutOrderApi(id)
    .then((res) => {
      dispatch({
        type: actions.PAYOUT_ORDER_SUCCESS
      });
      handleFunction && handleFunction();
      handleRefetch && handleRefetch();
    })
    .catch((error) => {
      dispatch({
        type: actions.PAYOUT_ORDER_ERROR
      });
      errorToast(error);
    });
};

// send payout mail
export const sendPayoutMail = (id, handleFunction, handleRefetch) => (dispatch) => {
  dispatch({
    type: actions.SEND_PAYOUT_MAIL_BEGIN
  });
  sendPayoutMailApi({
    collective_id: id
  })
    .then((res) => {
      dispatch({
        type: actions.SEND_PAYOUT_MAIL_SUCCESS
      });
      handleFunction && handleFunction();
      handleRefetch && handleRefetch();
    })
    .catch((error) => {
      dispatch({
        type: actions.SEND_PAYOUT_MAIL_ERROR
      });
      errorToast(error);
    });
};
