import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import ContractSelect from '../../contract/ContractSelect';
import { getContract } from '../../contract/redux/actions';
import DepartmentView from '../../department/DepartmentView';
import EmployeeAdd from '../../workers/EmployeeAdd';
import { getEmployee } from '../../workers/redux/actions';
import useStyles from '../styles';

function Widgets() {
  const classes = useStyles();
  return (
    <div className={classes.widgetContainer}>
      <div className={classes.title}>
        {' '}
        <FormattedMessage id="widgets" defaultMessage="Widgets" />
      </div>
      <EmployeeModal />
      <ContractModal />
      <DepartmentModal />
    </div>
  );
}

export default Widgets;

const EmployeeModal = () => {
  const classes = useStyles();
  const [modalOpen, openFunction] = useToggle(false);

  return (
    <>
      <div className={classes.widget} onClick={openFunction}>
        <PersonAddIcon />
        <div className={classes.widgetTitle}>
          <FormattedMessage id="Add Employee" defaultMessage="Add Employee" />
        </div>
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        headerText={<FormattedMessage id="Add Employee" defaultMessage="Add Employee" />}>
        <EmployeeAdd handleClose={openFunction} />
      </CustomModal>
    </>
  );
};

const ContractModal = () => {
  const classes = useStyles();
  const [modalOpen, openFunction] = useToggle(false);
  const dispatch = useDispatch();
  const { companyId } = useSelector((state) => state.auth);

  useEffect(() => {
    if (companyId) {
      dispatch(getContract());
      dispatch(getEmployee());
    }
  }, []);

  return (
    <>
      <div className={classes.widget} onClick={openFunction}>
        <SummarizeIcon />
        <div className={classes.widgetTitle}>
          <FormattedMessage id="Add Contract" defaultMessage="Add Contract" />
        </div>
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="35rem"
        headerText={
          <FormattedMessage
            id="Select the type of contract"
            defaultMessage="Select the type of contract"
          />
        }>
        <ContractSelect handleClose={openFunction} />
      </CustomModal>
    </>
  );
};

const DepartmentModal = () => {
  const classes = useStyles();
  const [modalOpen, openFunction] = useToggle(false);

  return (
    <>
      <div className={classes.widget} onClick={openFunction}>
        <PeopleAltIcon />
        <div className={classes.widgetTitle}>
          <FormattedMessage id="Add Department" defaultMessage="Add Department" />
        </div>
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="50rem"
        headerText={<FormattedMessage id="Add Department" defaultMessage="Add Department" />}>
        <DepartmentView handleClose={openFunction} />
      </CustomModal>
    </>
  );
};
