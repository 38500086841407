import { ExitToAppOutlined, ExpandMore, GroupOutlined, HomeOutlined } from '@mui/icons-material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import ListAltIcon from '@mui/icons-material/ListAlt';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { Drawer, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { getAccountingProfile } from 'components/locals/accounting/redux/actions';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchCompany, logout } from 'redux/auth/actions';
import useStyles from './styles';

const AccountingSidebar = ({ drawerOpen }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [expand, setExpand] = useState(false);
  const [expandSetting, setExpandSetting] = useState(false);

  const { company } = useSelector((state) => state.auth);
  const { accountingProfile } = useSelector((state) => state.accounting);
  const { pathname } = useLocation();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  useEffect(() => {
    if (pathname == '/payroll/collective' || pathname == '/payroll/items') {
      setExpand(true);
    }
    if (pathname == '/parameter' || pathname == '/settings' || pathname == '/setup') {
      setExpandSetting(true);
    }
    console.log(expand, expandSetting);
  }, [pathname]);

  useEffect(() => {
    dispatch(fetchCompany());
    dispatch(getAccountingProfile());
  }, []);

  const checkCompanyAdmin = () => {
    const data = company?.find((list) => list?.user_is_admin);
    if (data) {
      return true;
    } else {
      return false;
    }
  };
  console.log(checkCompanyAdmin(), 'hi');
  return (
    <Drawer
      sx={{
        width: '217px',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '217px',
          boxSizing: 'border-box',
          boxShadow: '0px 2px 10px 5px #f3f1f1',
          borderRight: 'none'
        }
      }}
      variant="persistent"
      anchor="left"
      open={drawerOpen}>
      {/* ---------------------- */}
      <Box className={classes.sidebarHeader}>
        {accountingProfile?.logo?.url && (
          <Box className={classes.logoWrapper}>
            <img src={accountingProfile?.logo?.url} />
          </Box>
        )}
        <Box className={classes.companyName}>{accountingProfile?.name}</Box>
      </Box>
      {/* -------------------------- */}
      <Box className={classes.listItems}>
        {/* ------------------------------- */}
        <Box
          className={`${classes.listItem} ${pathname === '/dashboard' && classes.listItemActive}`}
          onClick={() => navigate('/dashboard')}>
          <HomeOutlined className={classes.listItemIcons} />
          <Typography className={classes.listItemText}>
            <FormattedMessage id="Home" defaultMessage="Home" />
          </Typography>
        </Box>
        <Box
          className={`${classes.listItem} ${pathname === '/accounting' && classes.listItemActive}`}
          onClick={() => navigate('/accounting')}>
          <AccountBalanceIcon className={classes.listItemIcons} />
          <Typography className={classes.listItemText}>
            <FormattedMessage id="Company" defaultMessage="Company" />
          </Typography>
        </Box>
        <Box
          className={`${classes.listItem} ${
            pathname === '/user-assignment' && classes.listItemActive
          }`}
          onClick={() => navigate('/user-assignment')}>
          <AssignmentTurnedInIcon className={classes.listItemIcons} />
          <Typography className={classes.listItemText}>
            <FormattedMessage id="User Assignments" defaultMessage="User Assignments" />
          </Typography>
        </Box>
        <Box
          className={`${classes.listItem} ${
            pathname === '/company-assignment' && classes.listItemActive
          }`}
          onClick={() => navigate('/company-assignment')}>
          <ListAltIcon className={classes.listItemIcons} />
          <Typography className={classes.listItemText}>
            <FormattedMessage id="Company Assignments" defaultMessage="Company Assignments" />
          </Typography>
        </Box>
        <Box className={`${classes.listItem}`} onClick={() => setExpandSetting((prev) => !prev)}>
          <SettingsSuggestIcon className={classes.listItemIcons} />
          <Typography className={classes.listItemText}>
            <span>
              {' '}
              <FormattedMessage id="Settings" defaultMessage="Settings" />
            </span>{' '}
            <ExpandMore ml={5} />
          </Typography>
        </Box>
        {expandSetting && (
          <Box marginLeft={2}>
            <Box
              className={`${classes.listItem}  ${
                pathname === '/accounting/profile' && classes.listItemActive
              }`}
              onClick={() => navigate('/accounting/profile')}>
              <GroupOutlined className={classes.listItemIcons} />
              <Typography className={classes.listItemText}>
                {' '}
                <FormattedMessage id="Profile" defaultMessage="Profile" />
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box className={classes.buttonAdmin}>
        {checkCompanyAdmin() && (
          <Box className={classes.companyButton} onClick={() => navigate('/companies')}>
            <KeyboardCommandKeyIcon className={`${classes.listItemIcons} ${classes.logoutIcon}`} />
            <Typography className={classes.listItemText}>
              <FormattedMessage id="Accounting" defaultMessage="Accounting" />
            </Typography>
          </Box>
        )}
        <Box className={classes.switchButton} onClick={() => navigate('/company')}>
          <RestartAltIcon className={`${classes.listItemIcons} ${classes.logoutIcon}`} />
          <Typography className={classes.listItemText}>
            <FormattedMessage id="Switch Company" defaultMessage="Switch Company" />
          </Typography>
        </Box>

        <Box className={classes.logoutButton} onClick={handleLogout}>
          <ExitToAppOutlined className={`${classes.listItemIcons} ${classes.logoutIcon}`} />
          <Typography className={classes.listItemText}>
            {' '}
            <FormattedMessage id="Logout" defaultMessage="Logout" />
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
};
export default AccountingSidebar;
