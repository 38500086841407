import Paper from '@mui/material/Paper';
import CustomModal from 'components/globals/modal';
import CustomTable from 'components/globals/table';
import useToggle from 'hooks/useToggle';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';

import ApartmentIcon from '@mui/icons-material/Apartment';
import { Box, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { translate } from 'utils';
import CompanyList from './CompanyList';
import { getAssignment } from './redux/actions';
export default function AssignmentTable({ name, email }) {
  const { assignments, loading } = useSelector((state) => state.assignment);
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  const columns = [
    { title: 'S.N.', type: 'Index', isSortable: true, minWidth: 20 },

    {
      title: <FormattedMessage id="Name" defaultMessage="Name" />,
      minWidth: 100,
      isSortable: true,
      field: 'name'
    },
    {
      field: 'email',
      title: <FormattedMessage id="Email" defaultMessage="Email" />,
      minWidth: 170,
      align: 'right'
    },
    {
      field: (row) => {
        return row?.company?.map((list) => (
          <p className={classes.companyList} key={list?.id}>
            {list?.name}
          </p>
        ));
      },
      title: <FormattedMessage id="Companies" defaultMessage="Companies" />,
      minWidth: 120,
      align: 'right'
    },

    {
      field: (row) => {
        return row?.company?.reduce((a, b) => b?.employment_contract_count + a, 0);
      },
      title: <FormattedMessage id="Employment Contract" defaultMessage="Employment Contract" />,
      minWidth: 170,
      align: 'right'
    },
    {
      field: (row) => {
        return row?.company?.reduce((a, b) => b?.mandate_contract_count + a, 0);
      },
      title: <FormattedMessage id="Contract of Mandate" defaultMessage="Contract of Mandate" />,
      minWidth: 170,
      align: 'right'
    },
    {
      field: (row) => {
        return row?.company?.reduce((a, b) => b?.specific_contract_count + a, 0);
      },
      title: (
        <FormattedMessage
          id="Contract for Specific Work"
          defaultMessage="Contract for Specific Work"
        />
      ),
      minWidth: 170,
      align: 'right'
    },

    {
      field: (row) => {
        return <Actions row={row} />;
      },
      title: <FormattedMessage id="Actions" defaultMessage="Actions" />,
      minWidth: 180,
      align: 'center'
    }
  ];

  useEffect(() => {
    const filteredData = assignments?.filter((list) => {
      if (
        list?.name?.toLowerCase()?.includes(name?.toLowerCase()) &&
        list?.email?.toLowerCase()?.includes(email?.toLowerCase())
      ) {
        return list;
      }
    });
    filteredData && setRows(filteredData);
  }, [assignments, name, email]);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <CustomTable tableData={rows} tableHeads={columns} loading={loading} />
    </Paper>
  );
}

const Actions = ({ row }) => {
  const classes = useStyles();
  const [modalOpen, openFunction] = useToggle(false);
  const dispatch = useDispatch();

  const handleClick = () => {
    openFunction();
  };

  const handleClose = () => {
    dispatch(getAssignment());
    openFunction();
  };

  return (
    <>
      <Box display="none">
        <FormattedMessage id="Company List of" defaultMessage="Company List of" />
      </Box>
      <div className={classes.iconWrapper}>
        <Tooltip title={<FormattedMessage id="Edit Company" defaultMessage="Edit Company" />}>
          <ApartmentIcon onClick={handleClick} />
        </Tooltip>
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={handleClose}
        height="auto"
        width="80rem"
        headerText={translate('Company List of') + ' ' + row?.name}>
        <CompanyList userId={row?.id} />
      </CustomModal>
    </>
  );
};
