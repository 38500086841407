/* Build in libraries */

/* Third party libraries */
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { HookFormCheckbox } from 'components/UI/Form/HookFormCheckbox/Index';
import { HookFormInput } from 'components/UI/Form/HookFormInput';
import { FormattedMessage } from 'react-intl';

function CompanyUserForm() {
  return (
    <>
      <Box>
        <Box className="formBodyWrapper">
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <HookFormInput
                name="name"
                label={<FormattedMessage id="Full Name" defaultMessage="Full Name" />}
                placeholder="John Does"
              />
            </Grid>
            <Grid item sm={6}>
              <HookFormInput
                name="email"
                label={<FormattedMessage id="Email" defaultMessage="Email" />}
                placeholder="john.doe@gmail.com"
              />
            </Grid>
            <Grid item sm={6}>
              <HookFormCheckbox
                name="is_admin"
                type="checkbox"
                label={<FormattedMessage id="Is Admin" defaultMessage="Is Admin" />}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default CompanyUserForm;
