import BreadCrumb from 'components/globals/breadCrumb';
import MainAreaLayout from 'layouts/dashboardLayout/mainAreaLayout/MainAreaLayout';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { changeDateFormat } from 'utils/dateUtils';
import CollectiveTable from './CompanyTable';
import FilterForm from './filterForm';
import { getCompanyList } from './redux/actions';

function Companies() {
  const dispatch = useDispatch();

  const date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const { companyFilter } = useSelector((state) => state.companyList);
  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(firstDay);
  const [status, setStatus] = useState('');
  const [paymentDate, setPayment] = useState(changeDateFormat(date, 'YYYY-MM-DD'));
  const [payout, setPayout] = useState('');
  const [mailSent, setMailSent] = useState('');
  const [selectedCompany, setSelectedCompany] = useState('');
  const [agreementId, setAgreementId] = useState('');

  useEffect(() => {
    companyFilter?.startDate && setStartDate(companyFilter?.startDate);
    companyFilter?.endDate && setEndDate(companyFilter?.endDate);
    companyFilter?.status && setStatus(companyFilter?.status);
    companyFilter?.paymentDate && setPayment(companyFilter?.paymentDate);
    companyFilter?.payout && setPayout(companyFilter?.payout);
    companyFilter?.mailSent && setMailSent(companyFilter?.mailSent);
    companyFilter?.selectedCompany && setSelectedCompany(companyFilter?.selectedCompany);
  }, []);

  useEffect(() => {
    const data = {
      start_date: changeDateFormat(new Date(startDate), 'YYYY-MM-DD'),
      end_date: changeDateFormat(new Date(endDate), 'YYYY-MM-DD'),
      agreement_type_id: agreementId,
      status: status,
      payouts: payout,
      mail_sent: mailSent,
      company_id: selectedCompany
    };
    dispatch(getCompanyList(data));
  }, [startDate, endDate, status, agreementId, status, payout, mailSent, selectedCompany]);

  const handleRefetch = () => {
    const data = {
      start_date: changeDateFormat(new Date(startDate), 'YYYY-MM-DD'),
      end_date: changeDateFormat(new Date(endDate), 'YYYY-MM-DD'),
      agreementId: agreementId,
      status: status,
      payouts: payout,
      mail_sent: mailSent,
      company_id: selectedCompany
    };
    startDate && endDate && dispatch(getCompanyList(data));
  };

  return (
    <>
      <BreadCrumb
        itemName={<FormattedMessage id="Company" defaultMessage="Company" />}
        page={<FormattedMessage id="Company" defaultMessage="Company" />}
      />
      <FilterForm
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        startDate={startDate}
        endDate={endDate}
        status={status}
        setStatus={setStatus}
        setAgreementId={setAgreementId}
        paymentDate={paymentDate}
        setPayment={setPayment}
        payout={payout}
        setPayout={setPayout}
        agreementId={agreementId}
        mailSent={mailSent}
        setMailSent={setMailSent}
        selectedCompany={selectedCompany}
        setSelectedCompany={setSelectedCompany}
      />

      <MainAreaLayout>
        <CollectiveTable startDate={startDate} endDate={endDate} handleRefetch={handleRefetch} />
      </MainAreaLayout>
    </>
  );
}

export default Companies;
