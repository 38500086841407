import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
// import { downloadEmployeeSample } from 'apis/employee';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { uploadEmployeeList } from '../redux/actions';

const ImportEmployee = () => {
  const [modalOpen, openFunction] = useToggle(false);
  const { uploading } = useSelector((state) => state.employee);
  const dispatch = useDispatch();
  const [file, setFile] = useState();

  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };

  function downloadFile(url, filename) {
    var link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleDownload = () => {
    // alert('download');
    downloadFile('/sample.xlsx', 'sample.xlsx');
    // downloadEmployeeSample();
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('excel', file);
    file && dispatch(uploadEmployeeList(formData, openFunction));
  };

  return (
    <>
      <Button
        variant="contained"
        color="btnColor"
        onClick={openFunction}
        sx={{ display: 'flex', columnGap: '10px' }}>
        <CloudUploadIcon />
        <FormattedMessage id="Import" defaultMessage="Import" />
      </Button>

      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="50rem"
        headerText={<FormattedMessage id="Import Employee" defaultMessage="Import Employee" />}>
        <Box margin={3}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5">
              <FormattedMessage id="Download Sample" defaultMessage="Download Sample" />
            </Typography>
            <Button variant="contained" onClick={handleDownload}>
              Download
            </Button>
          </Box>
          <Box marginTop={5}>
            <Typography variant="h5">
              <FormattedMessage id="Upload Employee List" defaultMessage="Upload Employee List" />
            </Typography>
            <Box marginTop={2} display="flex" justifyContent="space-between">
              <TextField type="file" onChange={handleFile} />{' '}
              {uploading ? (
                <Button variant="contained" disabled>
                  <Box display="flex" columnGap={1}>
                    <CircularProgress size={18} /> Uploading
                  </Box>
                </Button>
              ) : (
                <Button variant="contained" onClick={handleSubmit}>
                  Upload
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </CustomModal>
    </>
  );
};

export default ImportEmployee;
