import { Box } from '@mui/system';
import useToggle from 'hooks/useToggle';
import Drawer from 'layouts/dashboardLayout/sidebar';
import { useSelector } from 'react-redux';
import CustomAppBar from './appbar';
import AccountingSidebar from './sidebar/AccountingSidebar';

const DasboardLayout = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useToggle(true);
  const { user, isAccounting } = useSelector((state) => state.auth);
  const commonStyles = {
    width: '100%'
  };
  const customStyles = {
    openState: {
      ...commonStyles,
      marginTop: '6.5rem !important',
      transition: 'all .3s ease'
    },
    closedState: {
      ...commonStyles,
      marginTop: '6.1rem !important',
      marginLeft: '-210px',
      transition: 'all .3s ease'
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CustomAppBar drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />

      {user?.accounting_office && isAccounting ? (
        <AccountingSidebar drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      ) : (
        <Drawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      )}

      <Box sx={drawerOpen ? customStyles.openState : customStyles.closedState}>{children}</Box>
    </Box>
  );
};

export default DasboardLayout;
