import { axiosInstance } from 'apis/_axios';

// get payroll
export const getItemPayrollApi = (year, month) => {
  return axiosInstance().get(`/payroll/monthly?year=${year}&month=${month}`);
};

// edit payroll
export const editPayrollApi = (data) => {
  return axiosInstance().post(`/payroll/modify`, data);
};

// modify payroll
export const modifyPayrollApi = (data) => {
  return axiosInstance().post(`/payroll/modify-group`, data);
};

// get collective payroll
export const getCollectivePayrollApi = (start_date, end_date, status, agreementId) => {
  const payrollStatus = status ? `&status=${status}` : '';
  const agreementTypeId = agreementId ? `&agreement_type_id=${agreementId}` : '';
  return axiosInstance().get(
    `/payroll/collective/filter?start_date=${start_date}&end_date=${end_date}${payrollStatus}${agreementTypeId}`
  );
};

// CLOSE paylist
export const closePaylistApi = (data) => {
  return axiosInstance().post(`/payroll/close-general`, data);
};

// get all collective payroll
export const getAllPayrollApi = (id) => {
  return axiosInstance().get(`/payroll/collective/print?collective_id=${id}`);
};
// get all collective payroll
export const getAllPayrollBulkApi = (id, company_id) => {
  return axiosInstance().get(`/bulk_action/collective/print`, {
    params: { collective_id: id, company_id: company_id }
  });
};

// generate correction payroll api
export const generateCorrectionPayrollApi = (id) => {
  return axiosInstance().post(`/payroll/generate-correction`, { paylist_id: id });
};

// get details of collective payroll
export const getCollectivePayrollDetailApi = (id) => {
  return axiosInstance().get(`/payroll/collective/details?collective_id=${id}`);
};
// bulk collective payroll
export const getCollectivePayrollDetailBulkApi = (id, company_id) => {
  return axiosInstance().get(`/bulk_action/collective/details`, {
    params: {
      collective_id: id,
      company_id: company_id
    }
  });
};

// send payout order
export const sendPayoutOrderApi = (id) => {
  return axiosInstance().post(`/payouts/collective/${id}`);
};

// send payout mail
export const sendPayoutMailApi = (data) => {
  return axiosInstance().post(`/payouts/mail`, data);
};
