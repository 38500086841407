import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { translate } from 'utils';
import useStyles from './styles';

function ViewAllowance({ row }) {
  const months = [
    { label: translate('January'), value: 1 },
    { label: translate('February'), value: 2 },
    { label: translate('March'), value: 3 },
    { label: translate('April'), value: 4 },
    { label: translate('May'), value: 5 },
    { label: translate('June'), value: 6 },
    { label: translate('July'), value: 7 },
    { label: translate('August'), value: 8 },
    { label: translate('September'), value: 9 },
    { label: translate('October'), value: 10 },
    { label: translate('November'), value: 11 },
    { label: translate('December'), value: 12 }
  ];
  const classes = useStyles();
  const findMonth = useCallback(() => {
    const monthObj = months?.find((item) => item.value == row?.month);
    return monthObj?.label || '';
  }, [row?.month]);
  const capitalize = (item) => {
    const data = item?.split(' ');
    const changedData = data?.map((list) => list[0].toUpperCase() + list.slice(1, list.length));
    const joinedData = changedData.join(' ');
    return translate(joinedData);
  };
  console.log(row, 'rowss');
  return (
    <div className={classes.viewRoot}>
      <Box display="none">
        <FormattedMessage id="Employee Contract" defaultMessage="Employee Contract" />
      </Box>
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <ul>
            <ViewList
              label={<FormattedMessage id="Name" defaultMessage="Name" />}
              value={row?.name}
            />
            <ViewList
              label={<FormattedMessage id="Contract" defaultMessage="Contract" />}
              value={translate('Employee Contract') + ' - ' + row?.contract_number}
            />
            <ViewList
              label={<FormattedMessage id="Value" defaultMessage="Value" />}
              value={row?.value}
            />
            <ViewList
              label={<FormattedMessage id="Type" defaultMessage="Type" />}
              value={capitalize(row?.type)}
            />
            <ViewList
              label={<FormattedMessage id="One Time" defaultMessage="One Time" />}
              value={row?.one_time ? translate('Yes') : translate('No')}
            />
            {row?.one_time ? (
              <>
                <ViewList
                  label={<FormattedMessage id="Year" defaultMessage="Year" />}
                  value={row?.year}
                />
                <ViewList
                  label={<FormattedMessage id="Month" defaultMessage="Month" />}
                  value={findMonth()}
                />
              </>
            ) : (
              <>
                <ViewList
                  label={<FormattedMessage id="Start Date" defaultMessage="Start Date" />}
                  value={row?.start_date}
                />
                <ViewList
                  label={<FormattedMessage id="End Date" defaultMessage="End Date" />}
                  value={row?.end_date}
                />
              </>
            )}
          </ul>
        </Grid>
        <Grid item sm={6}>
          {row?.type != 'deduction' && (
            <ul>
              <ViewList
                label={<FormattedMessage id="Is Taxable?" defaultMessage="Is Taxable?" />}
                value={row?.is_taxable ? translate('Yes') : translate('No')}
              />
              <ViewList
                label={
                  <FormattedMessage
                    id="Include Social Security Base"
                    defaultMessage="Include Social Security Base"
                  />
                }
                value={row?.include_socialsecurity_base ? translate('Yes') : translate('No')}
              />
              <ViewList
                label={
                  <FormattedMessage id="Include Sick Base" defaultMessage="Include Sick Base" />
                }
                value={row?.include_sick_base ? translate('Yes') : translate('No')}
              />
              <ViewList
                label={
                  <FormattedMessage
                    id="Include Vaccation Base"
                    defaultMessage="Incclude Vaccation Base"
                  />
                }
                value={row?.include_vacation_base ? translate('Yes') : translate('No')}
              />
            </ul>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default ViewAllowance;

const ViewList = ({ label, value }) => {
  const classes = useStyles();
  return (
    <li>
      <div className={classes.title}>{label}</div>
      <div className={classes.value}>{value}</div>
    </li>
  );
};
