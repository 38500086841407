import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PrintIcon from '@mui/icons-material/Print';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CircularProgress, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CustomModal from 'components/globals/modal';
import CollectiveView from 'components/locals/payroll/collectivePayroll/CollectiveView';
import useToggle from 'hooks/useToggle';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { checkAccounting, setCompanyId } from 'redux/auth/actions';
import { translate } from 'utils';
import { changeDateFormat } from 'utils/dateUtils';
import useStyles from '../styles';
import PayoutTable from './components/PayoutTable';
import PrintView from './components/PrintView';

function TableContent({ handleRefetch, setIds, ids }) {
  const { listCompany } = useSelector((state) => state.companyList);
  const rows = listCompany || [];
  return rows?.map((item, index) => (
    <DataRow
      key={item?.id}
      index={index}
      item={item}
      handleRefetch={handleRefetch}
      setIds={setIds}
      ids={ids}
    />
  ));
}

export default TableContent;

const DataRow = ({ item, index, handleRefetch, setIds, ids }) => {
  const [modalOpen, openFunction] = useToggle(false);
  const [checkState, setCheck] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { company } = useSelector((state) => state.auth);
  const months = [
    { label: translate('January'), value: 1 },
    { label: translate('February'), value: 2 },
    { label: translate('March'), value: 3 },
    { label: translate('April'), value: 4 },
    { label: translate('May'), value: 5 },
    { label: translate('June'), value: 6 },
    { label: translate('July'), value: 7 },
    { label: translate('August'), value: 8 },
    { label: translate('September'), value: 9 },
    { label: translate('October'), value: 10 },
    { label: translate('November'), value: 11 },
    { label: translate('December'), value: 12 }
  ];
  const currentMonth = months?.find((month) => month?.value == item?.month);
  const handleChange = (e) => {
    if (e.target.checked) {
      setIds((prev) => [...prev, item?.id]);
    } else {
      setIds((prev) => prev.filter((list) => list != item?.id));
    }
  };
  const check = () => {
    if (ids?.includes(item?.id)) {
      return setCheck(true);
    }
    return setCheck(false);
  };
  const payoutSent = (item) => {
    if (item?.status === 'CLOSED' && item?.type == 'GENERAL') {
      return item?.payouts?.length > 0 ? true : false;
    }
    return '-';
  };
  const mailSent = (item) => {
    if (item?.status == 'CLOSED' && item?.type == 'GENERAL') {
      if (item?.payouts?.length > 0) {
        return item?.payouts?.find((item) => item?.mail_sent == 0) ? false : true;
      }
      return false;
    }
    return '-';
  };
  useEffect(() => {
    check();
  }, [ids?.length]);

  const checkQueueAction = (type, queueActions, status = '') => {
    const handleAction = () => {
      const filteredQueue = queueActions?.find((item) => item?.action_type === type);
      if (filteredQueue?.status === 'processing') {
        return (
          <span>
            <CircularProgress size={16} />
          </span>
        );
      } else if (filteredQueue?.status === 'done') {
        return false;
      } else if (filteredQueue?.status === 'error') {
        return (
          <span>
            <Tooltip title={filteredQueue?.meta_data?.exception}>
              <ReportProblemIcon style={{ color: 'red', cursor: 'pointer' }} />
            </Tooltip>
          </span>
        );
      }
    };
    if (type === 'close' && status == 'CLOSED') {
      return false;
    } else if (type == 'mail' && mailSent(item)) {
      return false;
    } else if (type == 'payout' && payoutSent(item)) {
      return false;
    } else {
      return handleAction();
    }
  };

  const handleNewPage = () => {
    const companyId = company?.find((list) => list?.id === item?.company_id);
    dispatch(checkAccounting(false));
    companyId && localStorage.setItem('companyId', JSON.stringify(companyId));
    companyId && dispatch(setCompanyId(companyId));
    navigate('/dashboard');
  };
  return (
    <>
      <TableRow key={index}>
        <TableCell>
          <input
            onChange={(e) => handleChange(e)}
            type="checkbox"
            checked={checkState}
            style={{ border: 'none' }}
            sx={{
              '.MuiOutlinedInput-notchedOutline': { border: 'none' },
              '.MuiOutlinedInput-root': { padding: '0' },
              cursor: 'pointer'
            }}
          />
        </TableCell>
        <TableCell onClick={handleNewPage}>
          <Box sx={{ cursor: 'pointer', '&:hover': { color: '#2c67cf' } }}>
            {item?.company_name}
          </Box>
        </TableCell>

        <TableCell>
          <Box sx={{ cursor: 'pointer' }} onClick={openFunction}>
            {item?.name}
          </Box>
        </TableCell>
        <TableCell>
          {currentMonth?.label}, {item?.year}
        </TableCell>
        <TableCell>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Box marginRight={1}>{item?.status}</Box>
            {checkQueueAction('close', item?.queueActions, item?.status)}
          </Box>
        </TableCell>
        <TableCell>{item?.type}</TableCell>
        <TableCell>{item?.agreement_type_name}</TableCell>
        <TableCell>{item?.payment_date}</TableCell>
        <TableCell>{item?.paycheck.toFixed(2)}</TableCell>
        <TableCell>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Box marginRight={1}>
              {payoutSent(item) == '-' ? (
                <span>-</span>
              ) : payoutSent(item) ? (
                <CheckCircleIcon style={{ color: '#4caf50', fontSize: '20px' }} />
              ) : (
                <CancelIcon style={{ color: '#e85247', fontSize: '20px' }} />
              )}
            </Box>
            {checkQueueAction('payout', item?.queueActions)}
          </Box>
        </TableCell>
        <TableCell align="center">
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Box marginRight={1}>
              {mailSent(item) == '-' ? (
                <span>-</span>
              ) : mailSent(item) ? (
                <CheckCircleIcon style={{ color: '#4caf50', fontSize: '20px' }} />
              ) : (
                <CancelIcon style={{ color: '#e85247', fontSize: '20px' }} />
              )}
            </Box>
            {checkQueueAction('mail', item?.queueActions)}
          </Box>
        </TableCell>
        <TableCell align="center">
          <Actions item={item} handleRefetch={handleRefetch} />
        </TableCell>
      </TableRow>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="400px"
        headerText={`Payroll of ${item?.agreement_type_name} - ${item?.name}`}>
        <Box padding={5}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5">Closing Date</Typography>
            <Typography variant="h5">{item?.closing_date}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5">Payment Date</Typography>
            <Typography variant="h5">{item?.payment_date}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5">Signature</Typography>
            <Typography variant="h5">{item?.signature}</Typography>
          </Box>
        </Box>
      </CustomModal>
    </>
  );
};

const Actions = ({ item, startDate, endDate, handleRefetch }) => {
  const classes = useStyles();
  const [modalOpen, openFunction] = useToggle(false);
  const [printOpen, openPrintFunction] = useToggle(false);
  const [viewOpen, openViewFunction] = useToggle(false);

  const handleClick = () => {
    openFunction();
  };

  const handlePrint = () => {
    openPrintFunction();
  };
  const handleClose = () => {
    localStorage.removeItem('Temp_companyId');
    openFunction();
  };

  return (
    <>
      <div className={classes.iconWrapper}>
        <Tooltip title={<FormattedMessage id="View" defaultMessage="View" />}>
          <VisibilityIcon onClick={handleClick} />
        </Tooltip>
        <Tooltip title={<FormattedMessage id="Print" defaultMessage="Print" />}>
          <PrintIcon onClick={handlePrint} />
        </Tooltip>

        {item?.status === 'CLOSED' && item?.type == 'GENERAL' && (
          <>
            {item?.payouts?.length > 0 && (
              <Tooltip title={<FormattedMessage id="View Payouts" defaultMessage="View Payouts" />}>
                <LocalAtmIcon onClick={openViewFunction} />
              </Tooltip>
            )}
          </>
        )}
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={handleClose}
        height="auto"
        width="1000px"
        headerText={`Payroll of ${item?.agreement_type_name} - ${item?.name}`}>
        <CollectiveView
          year={changeDateFormat(item?.year, 'YYYY')}
          month={parseInt(changeDateFormat(item?.year, 'MM'))}
          collectiveId={item?.id}
          bulkAction={true}
          company_id={item?.company_id}
        />
      </CustomModal>

      <CustomModal
        open={printOpen}
        handleClose={openPrintFunction}
        height="auto"
        width="1150px"
        headerText={`Print ${item?.agreement_type_name} - ${item?.name}`}>
        <PrintView
          handleClose={openPrintFunction}
          id={item?.id}
          bulkAction={true}
          company_id={item?.company_id}
        />
      </CustomModal>

      <CustomModal
        open={viewOpen}
        handleClose={openViewFunction}
        height="auto"
        width="1100px"
        headerText={`View Payout -  ${item?.name}`}>
        <PayoutTable payouts={item?.payouts} />
      </CustomModal>
    </>
  );
};
