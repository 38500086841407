import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import MailIcon from '@mui/icons-material/Mail';
import PrintIcon from '@mui/icons-material/Print';
import ReceiptIcon from '@mui/icons-material/Receipt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, CircularProgress, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from 'utils';
import { changeDateFormat } from 'utils/dateUtils';
import { closePaylist, sendPayoutMail, sendPayoutOrder } from '../redux/actions';
import useStyles from '../styles';
import CollectiveView from './CollectiveView';
import EditClosingDate from './EditClosingDate';
import DeclarationView from './components/DeclarationView';
import PayoutTable from './components/PayoutTable';
import PrintView from './components/PrintView';

function TableContent({ handleRefetch }) {
  const { collectivePayrolls } = useSelector((state) => state.payroll);
  const rows = collectivePayrolls || [];
  return rows?.map((item, index) => (
    <DataRow key={item?.id} index={index} item={item} handleRefetch={handleRefetch} />
  ));
}

export default TableContent;

const DataRow = ({ item, index, handleRefetch }) => {
  const [modalOpen, openFunction] = useToggle(false);
  const months = [
    { label: translate('January'), value: 1 },
    { label: translate('February'), value: 2 },
    { label: translate('March'), value: 3 },
    { label: translate('April'), value: 4 },
    { label: translate('May'), value: 5 },
    { label: translate('June'), value: 6 },
    { label: translate('July'), value: 7 },
    { label: translate('August'), value: 8 },
    { label: translate('September'), value: 9 },
    { label: translate('October'), value: 10 },
    { label: translate('November'), value: 11 },
    { label: translate('December'), value: 12 }
  ];
  const currentMonth = months?.find((month) => month?.value == item?.month);
  return (
    <>
      <TableRow key={index}>
        <TableCell>{index + 1}</TableCell>
        <TableCell>
          <Box sx={{ cursor: 'pointer' }} onClick={openFunction}>
            {item?.name}
          </Box>
        </TableCell>
        <TableCell>
          {currentMonth?.label}, {item?.year}
        </TableCell>
        <TableCell>{item?.status}</TableCell>
        <TableCell>{item?.type}</TableCell>
        <TableCell>{item?.agreement_type_name}</TableCell>
        <TableCell>{item?.paycheck.toFixed(2)}</TableCell>
        <TableCell>{item?.employer_cost.toFixed(2)}</TableCell>
        <TableCell>{item?.base_salary.toFixed(2)}</TableCell>
        <TableCell>{item?.tax_amount.toFixed(2)}</TableCell>
        <TableCell>{item?.payment_date}</TableCell>
        <TableCell align="center">
          <Actions item={item} handleRefetch={handleRefetch} />
        </TableCell>
      </TableRow>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="400px"
        headerText={`${translate('Payroll of')} ${item?.agreement_type_name} - ${item?.name}`}>
        <Box padding={5}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5">
              <FormattedMessage id="Closing Date" defaultMessage="Closing Date" />{' '}
            </Typography>
            <Typography variant="h5">{item?.closing_date}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5">
              <FormattedMessage id="Payment Date" defaultMessage="Payment Date" />
            </Typography>
            <Typography variant="h5">{item?.payment_date}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5">
              <FormattedMessage id="Signature" defaultMessage="Signature" />
            </Typography>
            <Typography variant="h5">{item?.signature}</Typography>
          </Box>
        </Box>
      </CustomModal>
    </>
  );
};

const Actions = ({ item, startDate, endDate, handleRefetch }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalOpen, openFunction] = useToggle(false);
  const [editOpen, openEditFunction] = useToggle(false);
  const [closeOpen, openCloseFunction] = useToggle(false);
  const [printOpen, openPrintFunction] = useToggle(false);
  const [payoutOpen, openPayoutFunction] = useToggle(false);
  const [mailOpen, openMailFunction] = useToggle(false);
  const [viewOpen, openViewFunction] = useToggle(false);
  const [declarationOpen, openDeclarationFunction] = useToggle(false);
  const { payoutLoading, mail_loading, close_paylist_loading } = useSelector(
    (state) => state.payroll
  );

  const handleClick = () => {
    openFunction();
  };

  const handlePrint = () => {
    openPrintFunction();
  };

  const handleClosePaylist = () => {
    dispatch(
      closePaylist(
        {
          collective_id: item?.id
        },

        openCloseFunction,
        handleRefetch
      )
    );
  };

  const handlePayout = () => {
    dispatch(sendPayoutOrder(item?.id, openPayoutFunction, handleRefetch));
  };

  const handleSendMail = () => {
    dispatch(sendPayoutMail(item?.id, openMailFunction, handleRefetch));
  };

  return (
    <>
      <div className={classes.iconWrapper}>
        <Tooltip title={<FormattedMessage id="View" defaultMessage="View" />}>
          <VisibilityIcon onClick={handleClick} />
        </Tooltip>
        <Tooltip title={<FormattedMessage id="Print" defaultMessage="Print" />}>
          <PrintIcon onClick={handlePrint} />
        </Tooltip>

        {item?.status !== 'CLOSED' && (
          <>
            <Tooltip
              title={<FormattedMessage id="Edit Closing Date" defaultMessage="Edit Closing Date" />}
              onClick={openEditFunction}>
              <EventAvailableIcon />
            </Tooltip>
            <Tooltip
              title={<FormattedMessage id="Close Playlist" defaultMessage="Close Playlist" />}
              onClick={openCloseFunction}>
              <DoDisturbOnIcon />
            </Tooltip>
          </>
        )}
        {item?.status === 'CLOSED' && item?.type == 'GENERAL' && (
          <>
            {item?.payouts?.length > 0 && item?.payouts?.find((item) => item?.mail_sent == 0) && (
              <Tooltip title={<FormattedMessage id="Send Mail" defaultMessage="Send Mail" />}>
                <MailIcon onClick={openMailFunction} />
              </Tooltip>
            )}
            {item?.payouts?.length > 0 ? (
              <Tooltip title={<FormattedMessage id="View Payouts" defaultMessage="View Payouts" />}>
                <LocalAtmIcon onClick={openViewFunction} />
              </Tooltip>
            ) : (
              <Tooltip title={<FormattedMessage id="Payout Order" defaultMessage="Payout Order" />}>
                <AccountBalanceIcon onClick={openPayoutFunction} />
              </Tooltip>
            )}
            <Tooltip title={<FormattedMessage id="Declaration" defaultMessage="Declaration" />}>
              <ReceiptIcon onClick={openDeclarationFunction} />
            </Tooltip>
          </>
        )}
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="1000px"
        headerText={`${translate('Payroll of')} ${item?.agreement_type_name} - ${item?.name}`}>
        <CollectiveView
          year={changeDateFormat(item?.year, 'YYYY')}
          month={parseInt(changeDateFormat(item?.year, 'MM'))}
          collectiveId={item?.id}
        />
      </CustomModal>
      <CustomModal
        open={editOpen}
        handleClose={openEditFunction}
        height="auto"
        width="500px"
        headerText={`${translate('Edit Closing date of')} ${item?.agreement_type_name} - ${
          item?.name
        }`}>
        <EditClosingDate handleClose={openEditFunction} item={item} handleRefetch={handleRefetch} />
      </CustomModal>
      <CustomModal
        open={closeOpen}
        handleClose={openCloseFunction}
        height="auto"
        width="400px"
        headerText={`Close paylist -  ${item?.name}`}>
        <Box padding={5}>
          <Typography variant="h5" textAlign="center">
            {
              <FormattedMessage
                id="Do you want to Close paylist?"
                defaultMessage="Do you want to Close paylist?"
              />
            }
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-around" paddingBottom={3}>
          {close_paylist_loading ? (
            <Button variant={'contained'} disabled>
              <FormattedMessage id="Closing" defaultMessage="Closing" />
              <Box marginLeft={2}>
                <CircularProgress size={18} />
              </Box>
            </Button>
          ) : (
            <Button variant={'contained'} onClick={handleClosePaylist}>
              {<FormattedMessage id="Yes" defaultMessage="Yes" />}
            </Button>
          )}

          <Button
            className={classes.modalFooterCancel}
            variant={'outlined'}
            disabled={close_paylist_loading}
            onClick={openCloseFunction}>
            {<FormattedMessage id="No" defaultMessage="No" />}
          </Button>
        </Box>
      </CustomModal>
      <CustomModal
        open={printOpen}
        handleClose={openPrintFunction}
        height="auto"
        width="1150px"
        headerText={`${translate('Print')} ${item?.agreement_type_name} - ${item?.name}`}>
        <PrintView handleClose={openPrintFunction} id={item?.id} />
      </CustomModal>

      <CustomModal
        open={declarationOpen}
        handleClose={openDeclarationFunction}
        height="auto"
        width="350px"
        headerText={<FormattedMessage id="Declaration" defaultMessage="Declaration" />}>
        <DeclarationView id={item?.id} />
      </CustomModal>
      <CustomModal
        open={payoutOpen}
        handleClose={openPayoutFunction}
        height="auto"
        width="400px"
        headerText={`${translate('Payout order')} -  ${item?.name}`}>
        <Box padding={6}>
          <Typography variant="h5" textAlign="center">
            {
              <FormattedMessage
                id="Do you want to order payment of the list?"
                defaultMessage="Do you want to order payment of the list?"
              />
            }
          </Typography>
        </Box>
        <Box className={classes.modalFooter} sx={{ justifyContent: 'space-around !important' }}>
          {!payoutLoading ? (
            <Button variant={'contained'} onClick={handlePayout}>
              {<FormattedMessage id="Yes" defaultMessage="Yes" />}
            </Button>
          ) : (
            <Button variant={'contained'} disabled>
              {<FormattedMessage id="Submitting" defaultMessage="Submitting" />}
            </Button>
          )}
          <Button
            className={classes.modalFooterCancel}
            variant={'outlined'}
            onClick={openPayoutFunction}>
            {<FormattedMessage id="No" defaultMessage="No" />}
          </Button>
        </Box>
      </CustomModal>
      <CustomModal
        open={mailOpen}
        handleClose={openMailFunction}
        height="auto"
        width="400px"
        headerText={`${translate('Send Mail')}`}>
        <Box padding={6}>
          <Typography variant="h5" textAlign="center">
            {
              <FormattedMessage
                id="Do you want to send payroll mail?"
                defaultMessage="Do you want to send payroll mail?"
              />
            }
          </Typography>
        </Box>
        <Box className={classes.modalFooter} sx={{ justifyContent: 'space-around !important' }}>
          {mail_loading ? (
            <Button variant={'contained'} disabled>
              {<FormattedMessage id="Sending" defaultMessage="Sending" />}
            </Button>
          ) : (
            <Button variant={'contained'} onClick={handleSendMail}>
              {<FormattedMessage id="Yes" defaultMessage="Yes" />}
            </Button>
          )}

          <Button
            className={classes.modalFooterCancel}
            variant={'outlined'}
            onClick={openMailFunction}>
            {<FormattedMessage id="No" defaultMessage="No" />}
          </Button>
        </Box>
      </CustomModal>
      <CustomModal
        open={viewOpen}
        handleClose={openViewFunction}
        height="auto"
        width="1100px"
        headerText={`${translate('View Payout')} -  ${item?.name}`}>
        <PayoutTable payouts={item?.payouts} />
      </CustomModal>
    </>
  );
};
