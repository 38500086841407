import { Grid } from '@mui/material';
import { HookFormInput } from 'components/UI/Form/HookFormInput';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

const BusinessAddressForm = ({ index, type }) => {
  const { register } = useFormContext();

  return (
    <>
      <Grid container spacing={3}>
        <input type="hidden" {...register(`addresses[${index}][type]`)} defaultValue={type} />

        <Grid item sm={3}>
          <HookFormInput
            name={`addresses[${index}].country`}
            required={true}
            label={<FormattedMessage id="Country" defaultMessage="Country" />}
            placeholder="Poland"
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            required={true}
            name={`addresses[${index}].street`}
            label={<FormattedMessage id="Street" defaultMessage="Street" />}
            placeholder="848 St road"
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            name={`addresses[${index}].house_number`}
            label={<FormattedMessage id="House Number" defaultMessage="House Number" />}
            placeholder="848 St road"
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            name={`addresses[${index}].number_of_premises`}
            label={<FormattedMessage id="No. of premises" defaultMessage="No. of premises" />}
            placeholder="848 St road"
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            required={true}
            name={`addresses[${index}].zip`}
            label={<FormattedMessage id="Zip code" defaultMessage="Zip code" />}
            placeholder="001354"
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            required={true}
            name={`addresses[${index}].post`}
            label={<FormattedMessage id="Post" defaultMessage="Post" />}
            placeholder="595959"
          />
        </Grid>

        <Grid item sm={3}>
          <HookFormInput
            required={true}
            name={`addresses[${index}].community`}
            label={<FormattedMessage id="Town" defaultMessage="Town" />}
            placeholder="Bagbazar"
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            required={true}
            name={`addresses[${index}].county`}
            label={<FormattedMessage id="District" defaultMessage="District" />}
            placeholder="abc"
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            required={true}
            name={`addresses[${index}].volvodeship`}
            label={<FormattedMessage id="Volvodeship" defaultMessage="Volvodeship" />}
            placeholder="xyz"
          />
        </Grid>
        <Grid item sm={3}>
          <HookFormInput
            required={true}
            name={`addresses[${index}].city`}
            label={<FormattedMessage id="Commune" defaultMessage="Commune" />}
            placeholder="Kathmandu"
          />
        </Grid>
      </Grid>
    </>
  );
};
export default BusinessAddressForm;
