import { axiosInstance } from 'apis/_axios';

export const addCompanyApi = (data) => {
  return axiosInstance().post('/accounting/company', data);
};

export const fetchCompanyApi = (data) => {
  const page = data?.page + 1 ?? 1;
  const rowsPerPage = data?.rowsPerPage ?? 10;
  const name = data?.name ? `&name=${data?.name}` : '';
  const taxId = data?.taxId ? `&tax_id=${data?.taxId}` : '';
  const status = data?.status ? `&status=${data?.status}` : '';
  return axiosInstance().get(
    `/accounting/company?page=${page}&limit=${rowsPerPage}${name}${status}${taxId}`
  );
};

export const editCompanyApi = (slug, data) => {
  return axiosInstance().put(`/accounting/company/${slug}`, data);
};

export const fetchSingleCompanyApi = (slug) => {
  return axiosInstance().get(`/accounting/company/${slug}`);
};

export const updateCompanyAdminApi = (company_id, data) => {
  return axiosInstance().post(`/accounting/company/${company_id}/admin`, data);
};

export const getCompanyAdminApi = (slug) => {
  return axiosInstance().get(`/accounting/company-admin?company_slug=${slug}`);
};

export const addCompanyAdminApi = (data) => {
  return axiosInstance().post(`/accounting/company-admin`, data);
};

export const changeUserStatusApi = (data) => {
  return axiosInstance().patch(`/accounting/company-admin/change-status`, data);
};

export const removeUserApi = (data) => {
  return axiosInstance().patch(`/accounting/company-admin/remove`, data);
};

export const removeCompanyApi = (data) => {
  return axiosInstance().patch(`/accounting/company-assign-remove`, data);
};

export const getAccountingProfileApi = () => {
  return axiosInstance().get(`/accounting/my-accounting-office`);
};

export const editAccountingProfileApi = (data) => {
  return axiosInstance().post(`/accounting/my-accounting-office/update`, data);
};

export const changeCompanyStatusApi = (slug, data) => {
  return axiosInstance().patch(`/accounting/company/${slug}/change-active`, data);
};
