import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContractById } from '../../redux/actions';

import useStyles from '../../styles';
import PrintDocument from './PrintDocument';

function PrintSpecificContract({ contractId, handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { single_contract } = useSelector((state) => state.contract);

  useEffect(() => {
    contractId && dispatch(getContractById(contractId));
  }, [contractId]);

  const address = single_contract?.employee?.address?.[0];
  const companyAddress = single_contract?.employee?.company?.addresses?.[0];
  const idNumber = single_contract?.employee?.employee_detail?.find(
    (list) => list?.key === 'identification_number'
  )?.value;

  const checkValue = (value) => {
    if (value == 'null' || !value) {
      return '';
    } else return value;
  };

  return (
    <>
      <div className={classes.printContractSection}>
        <Box textAlign="center" margin={5}>
          <Typography variant="h5" className={classes.bold}>
            {'UMOWA O DZIEŁO' || single_contract?.agreement_type_name}
          </Typography>
        </Box>
        <Typography variant="body1">
          zawarta w dniu {single_contract?.date_of_signing} W {companyAddress?.city} pomiędzy:
          prowadzącym/ą działalność gospodarczą pod nazwą{' '}
          {single_contract?.employee?.company?.name ?? ''} wpisanym/ą do rejestru przedsiębiorców
          pod numerem {single_contract?.employee?.company?.krs ?? ''} , zsiedzibą{' '}
          {checkValue(companyAddress?.street)} {checkValue(companyAddress?.house_number)}{' '}
          {checkValue(companyAddress?.number_of_premises)} {checkValue(companyAddress?.zip)}{' '}
          {checkValue(companyAddress?.post)} {checkValue(companyAddress?.city)}{' '}
          {checkValue(companyAddress?.country)} {checkValue(companyAddress?.voivodeship)}{' '}
          {checkValue(companyAddress?.county)} {checkValue(companyAddress?.community)}{' '}
          reprezentowaną przez: {single_contract?.representative ?? ''} NIP:{' '}
          {single_contract?.employee?.company?.tax_id} zwanym/ą wdalszej części umowy Zamawiającym,
        </Typography>
        <br />
        <Typography variant="body1">
          a {single_contract?.employee?.first_name ?? ''}{' '}
          {single_contract?.employee?.second_name ?? ''}
          {single_contract?.employee?.last_name ?? ''}, {single_contract?.employee?.pesel ?? ''}{' '}
          zamieszkałym {checkValue(address?.street)} {checkValue(address?.house_number)}{' '}
          {checkValue(address?.number_of_premises)} {checkValue(address?.zip)}{' '}
          {checkValue(address?.post)} {checkValue(address?.city)} {checkValue(address?.country)}{' '}
          {checkValue(address?.voivodeship)} {checkValue(address?.county)}{' '}
          {checkValue(address?.community)} legitymującym się dokumentem {idNumber || ''}, zwanym w
          dalszej części umowyWykonawcą o treści następującej:
        </Typography>

        <br />
        <br />
        <Typography textAlign="center">§ 1</Typography>
        <Typography variant="h5">
          Zamawiający powierza wykonanie, a Wykonawca zobowiązuje się wykonać dziełopolegające
          <br />
          {single_contract?.parameters?.work_description}
        </Typography>
        <br />
        <br />
        <Typography textAlign="center">§ 2</Typography>
        <Typography>
          Wykonawca zobowiązuje się wykonać dzieło w terminie ustalonym przez Strony, tj. oddnia{' '}
          {single_contract?.start_date} do dnia {single_contract?.end_date}.
        </Typography>

        <br />
        <br />
        <Typography textAlign="center">§ 3</Typography>
        <Typography>
          3Za wykonanie dzieła jego Wykonawca otrzyma wynagrodzenie w wysokości{' '}
          {single_contract?.gross_salary} brutto.
        </Typography>
        <br />
        <br />
        <Typography textAlign="center">§ 4</Typography>
        <Typography>
          Wynagrodzenie wypłacone będzie na podstawie rachunku po dokonaniu odbioru dziełaprzez
          Zamawiającego.
        </Typography>
        <br />
        <br />
        <Typography textAlign="center">§ 5</Typography>
        <Typography>
          W sprawach nieuregulowanych umową mają zastosowanie odpowiednie przepisy Kodeksucywilnego.
        </Typography>
        <br />
        <br />
        <Typography textAlign="center">§ 6</Typography>
        <Typography>Zmiany umowy wymagają formy pisemnej, pod rygorem nieważności.</Typography>

        <br />
        <br />
        <Typography textAlign="center">§ 7</Typography>
        <Typography>
          Wszelkie ewentualne spory wynikające z niniejszej umowy lub jej dotyczące będąrozstrzygane
          przez właściwy rzeczowo Sąd - Sąd Rejonowy dla Warszawy-Woli wWarszawie; 01-473 Warszawa;
          ul. Kocjana 3
        </Typography>
        <br />
        <br />
        <Typography textAlign="center">§ 8</Typography>

        <Typography>
          Umowa została sporządzona w 2 (słownie: dwóch ) jednobrzmiących egzemplarzach,pojednymdla
          każdej ze stron.
        </Typography>
        <br />
        <br />

        <Box display="flex" justifyContent="space-between" marginTop={14}>
          <div className={classes.signatureWrapper}>
            <div>
              ..............................................................................
            </div>
            <Typography variant="h6" fontWeight={300}>
              zamawiający
            </Typography>
          </div>
          <div className={classes.signatureWrapper}>
            <div>
              ..............................................................................
            </div>
            <Typography variant="h6" fontWeight={300}>
              wykonawca
            </Typography>
          </div>
        </Box>
      </div>
      <PrintDocument handleClose={handleClose} />
    </>
  );
}

export default PrintSpecificContract;
