import * as React from 'react';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import { Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import TableContent from './TableContent';
import { FormattedMessage } from 'react-intl';

const columns = [
  {
    title: <FormattedMessage id="S.N." defaultMessage="S.N." />,
    type: 'Index',
    isSortable: true,
    minWidth: 20
  },
  {
    title: <FormattedMessage id="Payroll number" defaultMessage="Payroll number" />,
    minWidth: 80,
    isSortable: true,
    field: 'payroll_number'
  },
  {
    title: <FormattedMessage id="Period of the list" defaultMessage="Period of the list" />,
    minWidth: 160,
    isSortable: true,
    field: 'period_list'
  },
  {
    title: <FormattedMessage id="Status" defaultMessage="Status" />,
    minWidth: 80,
    isSortable: true,
    field: 'status'
  },
  {
    title: <FormattedMessage id="Type" defaultMessage="Type" />,
    minWidth: 80,
    isSortable: true,
    field: 'type'
  },
  {
    field: 'contract_type',
    title: <FormattedMessage id="contract_type" defaultMessage="contract_type" />,
    minWidth: 100,
    align: 'left'
  },
  {
    field: 'paycheck',
    title: <FormattedMessage id="Paycheck" defaultMessage="Paycheck" />,
    minWidth: 100,
    align: 'left'
  },
  {
    field: 'expense',
    title: <FormattedMessage id="Social Security" defaultMessage="Social Security" />,
    minWidth: 120,
    align: 'left'
  },
  {
    field: 'base_salary',
    title: <FormattedMessage id="Base Salary" defaultMessage="Base Salary" />,
    minWidth: 120,
    align: 'left'
  },
  {
    field: 'tax_amount',
    title: <FormattedMessage id="Tax Amount" defaultMessage="Tax Amount" />,
    minWidth: 100,
    align: 'left'
  },
  {
    field: 'payment_day',
    title: <FormattedMessage id="Payment Day" defaultMessage="Payment Day" />,
    minWidth: 100,
    align: 'left'
  },
  {
    fieldL: 'action',
    title: <FormattedMessage id="Actions" defaultMessage="Actions" />,
    minWidth: 180,
    align: 'center'
  }
];

export default function CollectiveTable({ startDate, endDate, handleRefetch }) {
  const { collective_loading } = useSelector((state) => state.payroll);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((item, index) => (
              <TableCell key={index} sx={{ minWidth: item?.minWidth }} align={item?.align}>
                {item?.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!collective_loading ? (
            <TableContent columns={columns} handleRefetch={handleRefetch} />
          ) : (
            [...Array(8).keys()].map((index) => (
              <TableRow key={index}>
                {columns.map((item) => (
                  <TableCell key={item.field} sx={{ minWidth: item?.minWidth }} align={item?.align}>
                    <Skeleton height={30} />
                  </TableCell>
                ))}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </Paper>
  );
}
