import { axiosInstance } from 'apis/_axios';

// create department
export const createDepartmentApi = (data) => {
  return axiosInstance().post('/department', data);
};

// get all departments
export const getDepartmentApi = () => {
  return axiosInstance().get('/all-department', {
    params: {
      paginate: 0,
      limit: null
    }
  });
};

// getDepartment By Id
export const getDepartmentByIdApi = (id) => {
  return axiosInstance().get(`/department/${id}`);
};

// edit Department
export const editDepartmentApi = (id, data) => {
  return axiosInstance().post(`/department/${id}`, data);
};

// delete department
export const deleteDepartmentApi = (id) => {
  return axiosInstance().delete(`/department/${id}`);
};
