import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from 'utils';
import { changeUserStatus, getCompanyAdmin, removeUser } from '../../redux/actions';
import useStyles from '../../styles';
import EditUserDetails from './EditUserForm';

function CompanyUserDetail({ slug }) {
  const dispatch = useDispatch();
  const { companyAdmins, adminLoading } = useSelector((state) => state.accounting);
  useEffect(() => {
    slug && dispatch(getCompanyAdmin(slug));
  }, [slug]);

  return !adminLoading ? (
    <div>
      <EditUserDetails slug={slug} />
      <Box sx={{ margin: '30px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.N.</TableCell>
              <TableCell>
                <FormattedMessage id="Name" defaultMessage="Name" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="Email" defaultMessage="Email" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="Status" defaultMessage="Status" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="Admin" defaultMessage="Admin" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="Actions" defaultMessage="Actions" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companyAdmins?.map((list, index) => (
              <TableRow key={list?.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{list?.name}</TableCell>
                <TableCell style={{ textTransform: 'none' }}>{list?.email}</TableCell>
                <TableCell>
                  {list?.status == 1 ? (
                    <FormattedMessage id="Active" defaultMessage="Active" />
                  ) : (
                    <FormattedMessage id="Inactive" defaultMessage="Inactive" />
                  )}
                </TableCell>
                <TableCell>
                  {list?.is_admin == 1 ? (
                    <FormattedMessage id="Yes" defaultMessage="Yes" />
                  ) : (
                    <FormattedMessage id="No" defaultMessage="No" />
                  )}
                </TableCell>
                <TableCell>
                  <Actions list={list} slug={slug} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </div>
  ) : (
    <Box textAlign="center" margin={10}>
      <CircularProgress />
    </Box>
  );
}

export default CompanyUserDetail;

const Actions = ({ list, slug }) => {
  const classes = useStyles();
  const [openModal, openFunction] = useToggle(false);
  const [deleteModal, deleteFunction] = useToggle(false);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({ is_admin: list?.is_admin, status: list?.status });
  const { changeStatusLoading, removeUserLoading } = useSelector((state) => state.accounting);

  console.log(userData, 'userdata');

  const handleChange = (e) => {
    setUserData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleStatus = () => {
    dispatch(changeUserStatus({ company_slug: slug, user_id: list?.id, ...userData }));
  };

  const handleDelete = () => {
    dispatch(removeUser({ company_slug: slug, user_id: list?.id }));
  };
  console.log(list, 'list');
  return (
    <>
      <Box display="none">
        <FormattedMessage id="Yes" defaultMessage="Yes" />
        <FormattedMessage id="No" defaultMessage="No" />
        <FormattedMessage id="Inactive" defaultMessage="Inactive" />
        <FormattedMessage id="Active" defaultMessage="Active" />
      </Box>
      <div className={classes.iconWrapper}>
        <Tooltip title={<FormattedMessage id="Change Status" defaultMessage="Change Status" />}>
          <EditIcon onClick={openFunction} />
        </Tooltip>
        <Tooltip title={<FormattedMessage id="Remove User" defaultMessage="Remove User" />}>
          <Delete onClick={deleteFunction} />
        </Tooltip>
        <CustomModal
          open={openModal}
          handleClose={openFunction}
          height="auto"
          width="40rem"
          variant="light"
          headerText={`Edit User`}>
          <Box margin={3}>
            <Grid container spacing={2}>
              <Grid item>
                <Typography variant="h6" sx={{ display: 'inline-block' }}>
                  <FormattedMessage id="Is Admin" defaultMessage="Is Admin" />
                </Typography>
                <select
                  onChange={handleChange}
                  style={{ padding: '5px 10px', border: 'none', marginLeft: '15px' }}
                  name="is_admin"
                  value={userData?.is_admin}>
                  <option value="1">{translate('Yes')}</option>
                  <option value="0">{translate('No')}</option>
                </select>
              </Grid>
              <Grid item>
                <Typography variant="h6" sx={{ display: 'inline-block' }}>
                  <FormattedMessage id="Status" defaultMessage="Status" />
                </Typography>
                <select
                  value={userData?.status}
                  onChange={handleChange}
                  style={{ padding: '5px 10px', border: 'none', marginLeft: '15px' }}
                  name="status">
                  <option value="1">{translate('Active')}</option>
                  <option value="0">{translate('Inactive')}</option>
                </select>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.modalFooter}>
            <Box className={classes.modalFooterButtons}>
              {!changeStatusLoading ? (
                <Button sx={{ marginRight: '1rem' }} variant={'contained'} onClick={handleStatus}>
                  <FormattedMessage id="Yes" defaultMessage="Yes" />
                </Button>
              ) : (
                <Button sx={{ marginRight: 'rem' }} variant={'contained'} disabled>
                  <CircularProgress size={18} sx={{ marginRight: '1rem' }} /> Yes
                </Button>
              )}

              <Button
                className={classes.modalFooterCancel}
                sx={{ background: '#fff', color: '#000' }}
                variant={'contained'}
                onClick={openFunction}>
                <FormattedMessage id="No" defaultMessage="No" />
              </Button>
            </Box>
          </Box>
        </CustomModal>
        <CustomModal
          open={deleteModal}
          handleClose={deleteFunction}
          height="auto"
          width="40rem"
          variant="light"
          headerText={`Remove User`}>
          <Box textAlign="center" margin={5}>
            <Typography variant="h5">
              <FormattedMessage
                id="Are you sure want to remove this user?"
                defaultMessage="Are you sure want to remove this user?"
              />
            </Typography>
          </Box>
          <Box className={classes.modalFooter}>
            <Box className={classes.modalFooterButtons}>
              {!removeUserLoading ? (
                <Button sx={{ marginRight: '1rem' }} variant={'contained'} onClick={handleDelete}>
                  <FormattedMessage id="Yes" defaultMessage="Yes" />
                </Button>
              ) : (
                <Button sx={{ marginRight: 'rem' }} variant={'contained'} disabled>
                  <CircularProgress size={18} sx={{ marginRight: '1rem' }} />{' '}
                  <FormattedMessage id="Yes" defaultMessage="Yes" />
                </Button>
              )}

              <Button
                className={classes.modalFooterCancel}
                sx={{ background: '#fff', color: '#000' }}
                variant={'contained'}
                onClick={deleteFunction}>
                <FormattedMessage id="No" defaultMessage="No" />
              </Button>
            </Box>
          </Box>
        </CustomModal>
      </div>
    </>
  );
};
