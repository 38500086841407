import { Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import useStyles from './styles';

export default function ViewStatement({ row }) {
  const classes = useStyles();
  console.log(row);
  return (
    <div className={classes.viewRoot}>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <ul>
            <ViewList
              label={<FormattedMessage id="Employee" defaultMessage="Employee" />}
              value={row?.employee?.first_name + ' ' + row?.employee?.last_name}
            />
            <ViewList
              label={<FormattedMessage id="Year" defaultMessage="Year" />}
              value={row?.year}
            />
            <ViewList
              label={<FormattedMessage id="Month" defaultMessage="Month" />}
              value={row?.month}
            />
            <ViewList
              label={<FormattedMessage id="Value" defaultMessage="Value" />}
              value={row?.value}
            />
          </ul>
        </Grid>
      </Grid>
    </div>
  );
}

const ViewList = ({ label, value }) => {
  const classes = useStyles();
  return (
    <li>
      <div className={classes.title}>{label}</div>
      <div className={classes.value}>{value}</div>
    </li>
  );
};
