export const CREATE_PARAMETER_BEGIN = 'CREATE_PARAMETER_BEGIN';
export const CREATE_PARAMETER_SUCCESS = 'CREATE_PARAMETER_SUCCESS';
export const CREATE_PARAMETER_ERROR = 'CREATE_PARAMETER_ERROR';

export const GET_PARAMETER_BEGIN = 'GET_PARAMETER_BEGIN';
export const GET_PARAMETER_SUCCESS = 'GET_PARAMETER_SUCCESS';
export const GET_PARAMETER_ERROR = 'GET_PARAMETER_ERROR';

export const GET_SINGLE_PARAMETER_BEGIN = 'GET_SINGLE_PARAMETER_BEGIN';
export const GET_SINGLE_PARAMETER_SUCCESS = 'GET_SINGLE_PARAMETER_SUCCESS';
export const GET_SINGLE_PARAMETER_ERROR = 'GET_SINGLE_PARAMETER_ERROR';

export const EDIT_PARAMETER_BEGIN = 'EDIT_PARAMETER_BEGIN';
export const EDIT_PARAMETER_SUCCESS = 'EDIT_PARAMETER_SUCCESS';
export const EDIT_PARAMETER_ERROR = 'EDIT_PARAMETER_ERROR';

export const CHANGE_PARAMETER_BEGIN = 'CHANGE_PARAMETER_BEGIN';
export const CHANGE_PARAMETER_SUCCESS = 'CHANGE_PARAMETER_SUCCESS';
export const CHANGE_PARAMETER_ERROR = 'CHANGE_PARAMETER_ERROR';

export const CLEAR_SINGLE_PARAMETER = 'CLEAR_SINGLE_PARAMETER';

export const GET_PARAMETER_BYNAME_BEGIN = 'GET_PARAMETER_BYNAME_BEGIN';
export const GET_PARAMETER_BYNAME_SUCCESS = 'GET_PARAMETER_BYNAME_SUCCESS';
export const GET_PARAMETER_BYNAME_ERROR = 'GET_PARAMETER_BYNAME_ERROR';

export const GET_ADMINPARAMETER_BEGIN = 'GET_ADMINPARAMETER_BEGIN';
export const GET_ADMINPARAMETER_SUCCESS = 'GET_ADMINPARAMETER_SUCCESS';
export const GET_ADMINPARAMETER_ERROR = 'GET_ADMINPARAMETER_ERROR';

export const CLEAR_PARAMETER_BYNAME = 'CLEAR_PARAMETER_BYNAME';
