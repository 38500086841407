import { axiosInstance } from 'apis/_axios';

// get all assignments
export const getCompanyAssignmentApi = () => {
  return axiosInstance().get('accounting/company-user-assigned');
};

// GET all user company
export const getUserCompanyApi = (userId) => {
  return axiosInstance().get(`/accounting/user-assigned/${userId}`);
};

// assign company to user
export const assignCompanyApi = (userId, data) => {
  return axiosInstance().post(`/accounting/user-assigned/${userId}`, data);
};

// delete company from user
export const deleteAssignCompanyApi = (userId, companySlug) => {
  return axiosInstance().delete(`/accounting/user-assigned/${userId}/${companySlug}`);
};
