import { Grid } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { translate } from 'utils';
import { changeDateFormat } from 'utils/dateUtils';
import useStyles from './styles';

function Header({ item, keyData }) {
  const classes = useStyles();
  const months = [
    { label: translate('January'), value: 1 },
    { label: translate('February'), value: 2 },
    { label: translate('March'), value: 3 },
    { label: translate('April'), value: 4 },
    { label: translate('May'), value: 5 },
    { label: translate('June'), value: 6 },
    { label: translate('July'), value: 7 },
    { label: translate('August'), value: 8 },
    { label: translate('September'), value: 9 },
    { label: translate('october'), value: 10 },
    { label: translate('November'), value: 11 },
    { label: translate('December'), value: 12 }
  ];
  const getDate = () => {
    const currentMonth = changeDateFormat(item?.date, 'MM');
    const currentObj = months?.find((month) => month.value == currentMonth);
    return currentObj?.label + ' ' + changeDateFormat(item?.date, 'YYYY');
  };
  const { nhf } = useSelector((state) => state.employee);
  const { companyId } = useSelector((state) => state.auth);
  const { insurance_code } = useSelector((state) => state.contract);
  const { departments } = useSelector((state) => state.department);
  // const { insurance_code } = useSelector((state) => state.contract);

  const joinName = () => {
    const firstName = item?.employee?.first_name ?? '';
    const lastName = item?.employee?.last_name ?? '';
    const middleName = item?.employee?.middle_name ?? '';
    const name = lastName + ' ' + firstName + ' ' + middleName;
    return name;
  };
  const employee = item?.employee;
  // const outputs = item?.outputs;
  const employeeDetails = item?.employee?.employee_detail?.reduce(
    (finalObject, singleItem) =>
      Object.assign(finalObject, { [singleItem?.key]: singleItem?.value }),
    {}
  );
  const agreement = item?.agreement;
  // const employeeInsuranceCode = insurance_code?.find(
  //   (item) => item?.id == item?.agreement?.parameter?.insurance_code_id
  // );
  const employeeNfz = nhf?.find((item) => item?.id == employeeDetails?.national_health_fund);
  const address = companyId?.addresses?.[0];

  const getInsuranceCode = () => {
    const code = item?.recurringInputs?.find((list) => list?.name == 'insurance_code_id');
    const filterCode = insurance_code?.find((list) => list?.id == code?.value);
    return filterCode?.code ?? '';
  };
  console.log(agreement?.parameters, 'add');
  const getDepartmentName = () => {
    const filteredDepartment = departments?.find(
      (list) => list?.id == item?.agreement?.department_id
    );
    return filteredDepartment?.title ?? '';
  };
  return (
    <div className={classes.header}>
      <h1>
        <FormattedMessage id="Payslip" defaultMessage="Payslip" />
      </h1>
      <h2>{companyId?.name}</h2>
      <h3>
        {address?.street} {address?.city} {address?.county} {address?.country}, {address?.post}
      </h3>
      <h2>
        <FormattedMessage id="Pay Slip for the Month" defaultMessage="Pay Slip for the Month" /> :{' '}
        {getDate()}
      </h2>

      <Grid container spacing={3}>
        <Grid item sm={4}>
          <ul>
            <ViewList
              label={<FormattedMessage id="Employee Name" defaultMessage="Employee Name" />}
              value={joinName()}
            />
            <ViewList
              label={<FormattedMessage id="PESEL" defaultMessage="PESEL" />}
              value={employee?.pesel}
            />
            <ViewList
              label={<FormattedMessage id="Working Hours" defaultMessage="Working Hours" />}
              value={keyData?.working_hours?.value}
            />
            <ViewList
              label={<FormattedMessage id="Income costs" defaultMessage="Income costs" />}
              value={keyData?.cost_of_getting_income?.value}
            />
          </ul>
        </Grid>
        <Grid item sm={4}>
          <ul>
            <ViewList
              label={<FormattedMessage id="Position" defaultMessage="Position" />}
              value={agreement?.position}
            />
            <ViewList
              label={
                <FormattedMessage id="Insurance title code" defaultMessage="Insurance title code" />
              }
              value={getInsuranceCode()}
            />
            <ViewList
              label={<FormattedMessage id="NIP" defaultMessage="NIP" />}
              value={employee?.tax_id}
            />

            <ViewList
              label={<FormattedMessage id="Tax allowance" defaultMessage="Tax allowance" />}
              value={parseInt(keyData?.free_amount?.value)?.toFixed(2)}
            />
          </ul>
        </Grid>
        <Grid item sm={4}>
          <ul>
            <ViewList
              label={
                <FormattedMessage id="Organizational Unit" defaultMessage="Organizational Unit" />
              }
              value={getDepartmentName()}
            />
            <ViewList
              label={<FormattedMessage id="NFZ branch" defaultMessage="NFZ branch" />}
              value={employeeNfz?.title}
            />
            <ViewList
              label={<FormattedMessage id="Shared taxation" defaultMessage="Shared taxation" />}
              value={
                agreement?.parameters?.shared_taxation == 1 ? translate('Yes') : translate('No')
              }
            />
          </ul>
        </Grid>
      </Grid>
    </div>
  );
}

export default Header;

const ViewList = ({ label, value }) => {
  const classes = useStyles();
  return (
    <li>
      <div className={classes.title}>{label}</div>
      <div className={classes.value}>{value}</div>
    </li>
  );
};
