import BreadCrumb from 'components/globals/breadCrumb';
import MainAreaLayout from 'layouts/dashboardLayout/mainAreaLayout/MainAreaLayout';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import AssignmentTable from './AssignmentTable';
import FilterForm from './filterForm';
import { getAssignment } from './redux/actions';

function CompanyAssignmentPage() {
  const dispatch = useDispatch();
  const [nip, setNIP] = useState('');
  const [name, setName] = useState('');
  useEffect(() => {
    dispatch(getAssignment());
  }, []);

  return (
    <>
      <BreadCrumb
        itemName={
          <FormattedMessage id="Company Assignments" defaultMessage="Company Assignments" />
        }
        page={<FormattedMessage id="Company Assignments" defaultMessage="Company Assignments" />}
      />
      <FilterForm name={name} setName={setName} nip={nip} setNIP={setNIP} />
      <MainAreaLayout>
        <AssignmentTable name={name} nip={nip} />
      </MainAreaLayout>
    </>
  );
}

export default CompanyAssignmentPage;
