import { Box, Button } from '@mui/material';
import { singleParameterParser } from 'helpers';
import React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { upsertParameter } from '../redux/actions';
import useStyles from '../styles';

function EditParameter({ handleClose, parameter }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(parameter?.par_value);

  const handleSet = (e) => {
    setValue(e.target.value);
  };
  console.log(parameter);
  const handleSubmit = () => {
    const finalData = singleParameterParser({
      id: parameter?.id,
      par_name: parameter?.par_name,
      date_s: parameter.date_s,
      par_type: '',
      par_value: value
    });
    dispatch(upsertParameter(finalData, handleClose));
  };

  return (
    <>
      <div className={classes.editRoot}>
        <ul>
          <li>
            <div className={classes.label}>
              {<FormattedMessage id="Start Date" defaultMessage="Start Date" />}
            </div>
            <div className={classes.inputText}>{parameter?.date_s}</div>
          </li>
          <li>
            <div className={classes.label}>
              {<FormattedMessage id="Value" defaultMessage="Value" />}
            </div>
            <input type="number" onChange={handleSet} value={value} />
          </li>
        </ul>
      </div>
      <Box className={classes.modalFooter}>
        <Box className={classes.modalFooterButtons}>
          <Button sx={{ marginRight: '1rem' }} variant={'contained'} onClick={handleSubmit}>
            {<FormattedMessage id="Update" defaultMessage="Update" />}
          </Button>
          <Button className={classes.modalFooterCancel} variant={'outlined'} onClick={handleClose}>
            {<FormattedMessage id="Cancel" defaultMessage="Cancel" />}
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default EditParameter;
