import { Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import HookFormAutoComplete from 'components/form/hookFormAutoComplete';
import { HookFormCheckbox } from 'components/form/hookFormCheckbox';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getContribution } from '../../redux/actions';
import OpeningSocialContribution from './OpeningSocialContribution';
import OpeningTax from './OpeningTax';

function Zus({ employeeId, contractTypeId }) {
  const dispatch = useDispatch();
  const { occupation_code, insurance_code, agreement_types } = useSelector(
    (state) => state.contract
  );

  useEffect(() => {
    const filterAgreement = agreement_types?.find((list) => list?.id == contractTypeId);

    employeeId &&
      filterAgreement?.slug &&
      dispatch(getContribution(employeeId, filterAgreement?.slug));
  }, [employeeId]);
  return (
    <Grid container spacing={1}>
      <Grid item sm={12}>
        <HookFormAutoComplete
          name="parameters.insurance_code_id"
          label={
            <FormattedMessage id="Insurance title code" defaultMessage="Insurance title code" />
          }
          tooltipLabel={
            <FormattedMessage
              id="The employee's insurance title code will be used in personal monthly reports (RCA, RSA) to the Social Insurance Institution. The codes do not affect the payroll and bill calculations in the system."
              defaultMessage="The employee's insurance title code will be used in personal monthly reports (RCA, RSA) to the Social Insurance Institution. The codes do not affect the payroll and bill calculations in the system."
            />
          }
          options={insurance_code?.map((item) => ({
            label: item?.code + ' - ' + item?.title,
            value: item?.id
          }))}
        />
      </Grid>
      <Grid item sm={4}>
        <HookFormCheckbox
          name="parameters.labor_fund_insurance_employer"
          label={<FormattedMessage id="Labour Fund" defaultMessage="Labour Fund" />}
        />
      </Grid>
      <Grid item sm={4}>
        <HookFormCheckbox
          name="parameters.fgsp_insurance_employer"
          label={<FormattedMessage id="FGSP" defaultMessage="FGSP" />}
        />
      </Grid>
      <Grid item sm={4}>
        <HookFormCheckbox
          name="parameters.fep_insurance_employer"
          label={<FormattedMessage id="Bridge Pension Fund" defaultMessage="Bridge Pension Fund" />}
        />
      </Grid>

      <Grid item sm={12}>
        <HookFormAutoComplete
          name="parameters.occupation_code_id"
          label={<FormattedMessage id="Occupation code" defaultMessage="Occupation code" />}
          tooltipLabel={
            <FormattedMessage
              id="From May 16, 2021, when registering a person employed for insurance, the profession code must be provided on the ZUS ZUA or ZUS ZZA declaration. The job code finder is here."
              defaultMessage="From May 16, 2021, when registering a person employed for insurance, the profession code must be provided on the ZUS ZUA or ZUS ZZA declaration. The job code finder is here."
            />
          }
          options={occupation_code?.map((item) => ({ label: item?.title, value: item?.id }))}
        />
      </Grid>
      <Grid item sm={12}>
        <Paper sx={{ padding: '10px 15px', marginTop: '30px' }}>
          <Box sx={{ background: '#fff', padding: '8px 10px', marginTop: '10px' }}>
            <Typography variant="h6" fontWeight={400}>
              <FormattedMessage
                id="Information on the annual basis of contributions from the beginning of the year"
                defaultMessage="Information on the annual basis of contributions from the beginning of the year"
              />
            </Typography>
          </Box>
          <OpeningTax employeeId={employeeId} contractTypeId={contractTypeId} />
          <OpeningSocialContribution employeeId={employeeId} contractTypeId={contractTypeId} />
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Zus;
