import CompanyAssignmentPage from 'components/locals/companyAssignment';
import DashboardLayout from 'layouts/dashboardLayout';

const CompanyAssignment = () => {
  return (
    <DashboardLayout>
      <CompanyAssignmentPage />
    </DashboardLayout>
  );
};

export default CompanyAssignment;
