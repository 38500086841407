import * as actions from './types';
const initialState = {
  events: [],
  events_loading: false,
  add_events_loading: false,
  edit_events_loading: false,
  delete_events_loading: false,
  filters: {}
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CREATE_EVENTS_BEGIN:
      return { ...state, add_events_loading: true };

    case actions.CREATE_EVENTS_SUCCESS:
    case actions.CREATE_EVENTS_ERROR:
      return { ...state, add_events_loading: false };

    case actions.GET_EVENTS_BEGIN:
      return { ...state, events_loading: true };

    case actions.GET_EVENTS_SUCCESS:
      return { ...state, events: action.payload, events_loading: false };

    case actions.GET_EVENTS_ERROR:
      return { ...state, events_loading: false };

    case actions.EDIT_EVENTS_BEGIN:
      return { ...state, edit_events_loading: true };

    case actions.EDIT_EVENTS_ERROR:
    case actions.EDIT_EVENTS_SUCCESS:
      return { ...state, edit_events_loading: false };

    case actions.DELETE_EVENTS_BEGIN:
      return { ...state, delete_events_loading: true };

    case actions.DELETE_EVENTS_ERROR:
    case actions.DELETE_EVENTS_SUCCESS:
      return { ...state, delete_events_loading: false };

    case actions.ADD_EVENT_FILTER:
      return {
        ...state,
        filters: { ...state.filters, [action.payload?.key]: action.payload.value }
      };

    default:
      return state;
  }
};

export default eventReducer;
