import { Button, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { HookForm } from 'components/form/hookForm';
import { HookFormProvider } from 'components/form/hookFormProvider';
import React from 'react';
import useStyles from './styles';
import * as Yup from 'yup';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import { useDispatch, useSelector } from 'react-redux';
import useToggle from 'hooks/useToggle';
import CancelModal from 'components/globals/modal/CancelModal';
import AllowanceForm from './Form';
import { editAllowance } from './redux/actions';
import { FormattedMessage } from 'react-intl';
import { changeDateFormat } from 'utils/dateUtils';

function EditAllowance({ handleClose, employeeId, row, handleRefetch }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [cancelOpen, cancelFunction] = useToggle(false);
  const { edit_loading } = useSelector((state) => state.allowance);

  const defaultValues = {
    name: row?.name ?? '',
    value: row?.value ?? '',
    type: row?.type ?? 'allowance',
    agreement_id: row?.agreement_id ?? '',
    one_time: row?.one_time ?? true,
    is_taxable: row?.is_taxable ?? false,
    include_socialsecurity_base: row?.include_socialsecurity_base ?? false,
    include_sick_base: row?.include_sick_base ?? false,
    include_vacation_base: row?.include_vacation_base ?? false,
    start_date: row?.start_date ?? null,
    end_date: row?.end_date ?? null,
    year: row?.year ?? null,
    month: row?.month ?? null
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    value: Yup.number().required('Value is required'),
    type: Yup.string().required('Type is required'),
    agreement_id: Yup.number().required('Agreement field is required'),
    one_time: Yup.boolean().required('One Time field is required'),
    year: Yup.string()
      .nullable()
      .notRequired()
      .when('one_time', (started, yup) =>
        started ? yup.required('Year is required') : yup.notRequired()
      ),
    month: Yup.string()
      .nullable()
      .notRequired()
      .when('one_time', (started, yup) =>
        started ? yup.required('Month is required') : yup.notRequired()
      ),
    start_date: Yup.date()
      .nullable()
      .notRequired()
      .when('one_time', (started, yup) =>
        !started ? yup.required('Start Date is required') : yup.notRequired()
      ),
    end_date: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('start_date', (started, yup) =>
        started ? yup.min(started, 'End date cannot be before start date') : yup.notRequired()
      )
      .default(undefined)
  });

  const onSubmit = (data) => {
    const newData = {
      ...data,
      start_date: data?.start_date ? changeDateFormat(new Date(data?.start_date)) : null,
      end_date: data?.end_date ? changeDateFormat(new Date(data?.end_date)) : null
    };
    row?.id && dispatch(editAllowance(row?.id, newData, employeeId, handleClose, handleRefetch));
  };

  return (
    <>
      <div className={classes.content}>
        <HookFormProvider
          defaultValues={defaultValues}
          resolver={useYupValidationResolver(validationSchema)}>
          <HookForm onSubmit={onSubmit}>
            <AllowanceForm edit={true} />
            <Box className={classes.modalFooter} justifyContent="flex-end">
              <Box className={classes.modalFooterButtons}>
                {!edit_loading ? (
                  <Button sx={{ marginRight: '1rem' }} variant={'contained'} type="submit">
                    {<FormattedMessage id="Update" defaultMessage="Update" />}
                  </Button>
                ) : (
                  <Button sx={{ marginRight: '1rem' }} variant={'contained'} disabled>
                    <CircularProgress size={18} sx={{ marginRight: '1rem' }} />{' '}
                    {<FormattedMessage id="Updating" defaultMessage="Updating" />}
                  </Button>
                )}

                <Button
                  className={classes.modalFooterCancel}
                  variant={'outlined'}
                  onClick={cancelFunction}>
                  {<FormattedMessage id="Cancel" defaultMessage="Cancel" />}
                </Button>
              </Box>
            </Box>
          </HookForm>
        </HookFormProvider>
      </div>
      <CancelModal
        handleClose={handleClose}
        cancelFunction={cancelFunction}
        cancelOpen={cancelOpen}
      />
    </>
  );
}

export default EditAllowance;
