import EditIcon from '@mui/icons-material/Edit';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import PrintIcon from '@mui/icons-material/Print';
import { Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import CustomModal from 'components/globals/modal';
import CustomTable from 'components/globals/table';
import useToggle from 'hooks/useToggle';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { translate } from 'utils';
import EmployeeView from '../workers/components/viewEmployee/EmployeeView';
import PrintContract from './employmentContract/components/PrintContract';
import TerminateContract from './employmentContract/components/TerminateContract';
import TerminateMandateContract from './mandateContract/components/TerminateContract';
import TerminateSpecificContract from './specificContract/components/TerminateContract';

import ContractEdit from './employmentContract/Edit';
import ContractView from './employmentContract/viewEmployeeContract/ContractView';
import PrintMandateContract from './mandateContract/components/PrintContract';
import MandateContractEdit from './mandateContract/Edit';
import MandateContractView from './mandateContract/viewMandate/ContractView';
import PrintSpecificContract from './specificContract/components/PrintContract';
import SpecificContractEdit from './specificContract/Edit';
import SpecificContractView from './specificContract/viewSpecific/ContractView';
import useStyles from './styles';

export default function ContractTable({ refetch }) {
  const { contracts, loading } = useSelector((state) => state.contract);

  const typeOfContract = [
    {
      label: translate('Indefinite Period of Time'),
      value: 'indefinite'
    },
    {
      label: translate('Fixed Term'),
      value: 'fixed'
    },
    { label: translate('Trial'), value: 'trial' },
    {
      label: translate('Replacement'),
      value: 'replacement'
    }
  ];

  const columns = [
    { title: 'S.N.', type: 'Index', isSortable: true, minWidth: 20 },
    {
      title: <FormattedMessage id="Contract Number" defaultMessage="Contract Number" />,
      minWidth: 60,
      isSortable: true,
      field: (row) => <ContractNumberView row={row} />
    },
    {
      title: <FormattedMessage id="Employee" defaultMessage="Employee" />,
      minWidth: 100,
      isSortable: true,
      field: (row) => <EmployeeViewName row={row} />
    },
    {
      title: <FormattedMessage id="Contract Type" defaultMessage="Contract Type" />,
      minWidth: 150,
      align: 'right',
      field: (row) => <ContractName agreementId={row?.agreement_type_id} />
    },
    {
      field: 'start_date',
      title: <FormattedMessage id="Contract From" defaultMessage="Contract From" />,
      minWidth: 170,
      align: 'right'
    },
    {
      field: 'end_date',
      title: <FormattedMessage id="Contract To" defaultMessage="Contract To" />,
      minWidth: 120,
      align: 'right'
    },

    {
      field: (row) => {
        const filteredType = typeOfContract?.find(
          (item) => item?.value == row?.type?.toLowerCase()
        );
        return filteredType?.label || '';
      },
      title: <FormattedMessage id="Duration" defaultMessage="Duration" />,
      minWidth: 170,
      align: 'right'
    },

    {
      field: 'gross_salary',
      title: <FormattedMessage id="Gross" defaultMessage="Gross" />,
      minWidth: 170,
      align: 'right'
    },

    {
      field: (row) => {
        return (
          <Actions
            contractId={row?.id}
            endDate={row?.end_date}
            slug={row?.agreement_type_slug}
            refetch={refetch}
          />
        );
      },
      title: <FormattedMessage id="Actions" defaultMessage="Actions" />,
      minWidth: 180,
      align: 'center'
    }
  ];
  const rows = contracts || [];

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <CustomTable tableData={rows} tableHeads={columns} loading={loading} />
    </Paper>
  );
}

const Actions = ({ contractId, endDate, slug, refetch }) => {
  const handleClick = () => {
    openFunction();
  };
  const classes = useStyles();
  const [modalOpen, openFunction] = useToggle(false);
  const [terminateModalOpen, terminateFunction] = useToggle(false);
  const [printOpen, printFunction] = useToggle(false);

  return (
    <>
      <div className={classes.iconWrapper}>
        {
          <Tooltip
            title={
              <FormattedMessage id="Terminate Contract" defaultMessage="Terminate Contract" />
            }>
            <IndeterminateCheckBoxIcon onClick={terminateFunction} />
          </Tooltip>
        }
        <Tooltip title={<FormattedMessage id="Edit" defaultMessage="Edit" />}>
          <EditIcon onClick={handleClick} />
        </Tooltip>
        <Tooltip title={<FormattedMessage id="Print" defaultMessage="Print" />}>
          <PrintIcon onClick={printFunction} />
        </Tooltip>
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="125rem"
        headerText={<FormattedMessage id="Edit Contract" defaultMessage="Edit Contract" />}>
        {slug == 'employment-contract' && (
          <ContractEdit handleClose={openFunction} contractId={contractId} refetch={refetch} />
        )}
        {slug == 'contract-of-mandate' && (
          <MandateContractEdit
            handleClose={openFunction}
            contractId={contractId}
            refetch={refetch}
          />
        )}
        {slug == 'contract-for-specific-work' && (
          <SpecificContractEdit
            handleClose={openFunction}
            contractId={contractId}
            refetch={refetch}
          />
        )}
      </CustomModal>
      <CustomModal
        open={terminateModalOpen}
        handleClose={terminateFunction}
        height="auto"
        width="40rem"
        headerText={
          <FormattedMessage id="Terminate Contract" defaultMessage="Terminate Contract" />
        }>
        {slug == 'employment-contract' && (
          <TerminateContract
            handleClose={terminateFunction}
            contractId={contractId}
            refetch={refetch}
          />
        )}
        {slug == 'contract-of-mandate' && (
          <TerminateMandateContract
            handleClose={terminateFunction}
            contractId={contractId}
            refetch={refetch}
          />
        )}
        {slug == 'contract-for-specific-work' && (
          <TerminateSpecificContract
            handleClose={terminateFunction}
            contractId={contractId}
            refetch={refetch}
          />
        )}
      </CustomModal>
      <CustomModal
        open={printOpen}
        handleClose={printFunction}
        height="auto"
        width="100rem"
        headerText={<FormattedMessage id="Print Contract" defaultMessage="Print Contract" />}>
        {slug == 'employment-contract' && (
          <PrintContract handleClose={printFunction} contractId={contractId} />
        )}
        {slug == 'contract-of-mandate' && (
          <PrintMandateContract handleClose={printFunction} contractId={contractId} />
        )}
        {slug == 'contract-for-specific-work' && (
          <PrintSpecificContract handleClose={printFunction} contractId={contractId} />
        )}
      </CustomModal>
    </>
  );
};

const EmployeeViewName = ({ row }) => {
  const [modalOpen, openFunction] = useToggle(false);
  const { employees } = useSelector((state) => state.employee);
  const [name, setName] = useState('');
  const classes = useStyles();
  const handleClick = () => {
    openFunction();
  };
  useEffect(() => {
    const employeeName = employees?.find((employee) => employee?.id == row?.employee_id);
    const firstName = employeeName?.first_name ?? '';
    const secondName = employeeName?.middle_name ?? '';
    const lastName = employeeName?.last_name ?? '';
    setName(lastName + ' ' + firstName + ' ' + secondName);
  }, [employees]);
  return (
    <>
      <div className={classes.fullname} onClick={handleClick}>
        {name}
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="100rem"
        variant="light"
        headerText={translate('Details of') + ' ' + name}>
        <EmployeeView id={row?.employee_id} />
      </CustomModal>
    </>
  );
};

const ContractName = ({ agreementId }) => {
  const { agreement_types } = useSelector((state) => state.contract);
  return <span>{agreement_types?.find((agreement) => agreement?.id == agreementId)?.name}</span>;
};

const ContractNumberView = ({ row }) => {
  const [modalOpen, openFunction] = useToggle(false);
  const { employees } = useSelector((state) => state.employee);
  const [name, setName] = useState('');
  // const contractYear = new Date(row?.date_of_signing);
  // const contractNumber = row?.id + '/' + contractYear.getFullYear();
  const contractNumber = row?.contract_number;
  const classes = useStyles();
  const handleClick = () => {
    openFunction();
  };
  useEffect(() => {
    const employeeName = employees?.find((employee) => employee?.id == row?.employee_id);
    const firstName = employeeName?.first_name ?? '';
    const secondName = employeeName?.middle_name ?? '';
    const lastName = employeeName?.last_name ?? '';
    setName(lastName + ' ' + firstName + ' ' + secondName);
  }, [employees]);
  return (
    <>
      <div className={classes.fullname} onClick={handleClick}>
        {contractNumber}
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="100rem"
        variant="light"
        headerText={`${translate('Details of')} ${contractNumber} - ${name}`}>
        {row?.agreement_type_slug == 'contract-of-mandate' && (
          <MandateContractView id={row?.id} employeeName={name} />
        )}

        {row?.agreement_type_slug == 'employment-contract' && (
          <ContractView id={row?.id} employeeName={name} />
        )}
        {row?.agreement_type_slug == 'contract-for-specific-work' && (
          <SpecificContractView id={row?.id} employeeName={name} />
        )}
      </CustomModal>
    </>
  );
};
