import React from 'react';
import DashboardLayout from 'layouts/dashboardLayout';
import DashboardPage from 'components/locals/dashboard';

const Dashboard = () => {
  return (
    <DashboardLayout>
      <DashboardPage />
    </DashboardLayout>
  );
};

export default Dashboard;
