import React from 'react';
import DashboardLayout from 'layouts/dashboardLayout';
import ParameterPage from 'components/locals/parameter/Parameter';

const Parameter = () => {
  return (
    <DashboardLayout>
      <ParameterPage />
    </DashboardLayout>
  );
};

export default Parameter;
