import { Box } from '@mui/material';
import ExcelExport from 'components/globals/excelExport';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { changeDateFormat } from 'utils/dateUtils';
import ItemTable from '../itemPayroll/ItemTable';
import { getCollectivePayrollDetail, getCollectivePayrollDetailBulk } from '../redux/actions';

function CollectiveView({ year, month, company_id, collectiveId, bulkAction = false }) {
  const dispatch = useDispatch();
  const { itemPayrolls } = useSelector((state) => state.payroll);
  const [excelData, setExcelData] = useState();
  useEffect(() => {
    if (bulkAction) {
      collectiveId && dispatch(getCollectivePayrollDetailBulk(collectiveId, company_id));
    } else {
      collectiveId && dispatch(getCollectivePayrollDetail(collectiveId));
    }
  }, [collectiveId]);

  console.log(year, month, 'hello');

  const getDate = () => {
    const date = itemPayrolls?.paylists?.[0]?.date;
    return changeDateFormat(date, 'MMM, YYYY');
  };

  useEffect(() => {
    const filterData = itemPayrolls?.paylists?.map((list) => {
      console.log(list);

      let output;
      let input;
      list?.outputs?.map((item) => {
        output = { ...output, [item.name]: item };
      });
      list?.inputs?.map((item) => {
        input = { ...input, [item.name]: item };
      });
      console.log(input, 'input');
      console.log(output, 'output');
      const getName = (name) => {
        const firstName = name?.first_name ?? '';
        const middleName = name?.middle_name ?? '';
        const lastName = name?.last_name ?? '';
        const fullName = firstName + ' ' + middleName + ' ' + lastName;
        return fullName ?? '';
      };
      const row = {
        'Employee Name': getName(list?.employee),
        ['Pesel']: list?.employee?.pesel,
        'Working Hours': output?.working_hours?.value,
        'Income Costs': parseFloat(output?.cost_of_getting_income?.value)?.toFixed(2),
        'Tax Allowance': parseFloat(input?.tax_allowance?.value)?.toFixed(2),
        'Shared Taxation': list?.agreement?.parameters?.shared_taxation == 1 ? 'Yes' : 'No',
        'Gross Salary': parseFloat(output?.contract_salary?.value)?.toFixed(2),
        'Total Allowance': parseFloat(output?.input_allowances?.value)?.toFixed(2),
        'Total Overtime': parseFloat(output?.input_overtime?.value)?.toFixed(2),
        'Social Security Contribution Base': parseFloat(output?.paylist_gross?.value)?.toFixed(2),
        [`Sickness insurance contribution (${input?.sickness_insurance_employee?.value} %)`]:
          parseFloat(output?.sickness_insurance_employee?.value)?.toFixed(2),
        [`Pension insurance contribution (${input?.pension_insurance_employee?.value} %)`]:
          parseFloat(output?.pension_insurance_employee?.value)?.toFixed(2),
        [`Disability insurance contribution (${input?.disability_insurance_employee?.value} %)`]:
          parseFloat(output?.disability_insurance_employee?.value)?.toFixed(2),
        'Social Security Total': parseFloat(output?.employee_total_insurance?.value)?.toFixed(2),
        'Number of working days in current month(days)': output?.working_days?.value,
        'Number of working days in current mont(hours)': parseFloat(
          output?.working_hours?.value
        ).toFixed(2),
        'Number of vacation days': output?.vacation_days?.value,
        'Number of vacation hours': parseFloat(output?.vacation_hours?.value).toFixed(2),
        'Remaining vacation to be used': output?.vacation_days_remaining?.value || 0,
        'Vacation Pay': parseFloat(output?.vacation_pay?.value).toFixed(2),
        'Number of sick days': output?.sick_days?.value,
        'Number of care leave days': output?.sick_days_for_care?.value,
        'Number of sick days since beginning of year':
          output?.sick_days_since_beginning_of_year?.value,
        'Sick Pay': parseFloat(output?.sick_pay?.value).toFixed(2),
        'Care Pay': parseFloat(output?.care_pay?.value || 0).toFixed(2),
        'Social Security Total Amount': parseFloat(
          output?.employee_total_insurance?.value
        )?.toFixed(2),
        'NFZ contribution base': output?.nfz_contribution_base?.value,
        [`To Social security (${parseFloat(input?.health_insurance_employee?.value)?.toFixed(
          2
        )} %)`]: parseFloat(output?.nfz_contribution?.value)?.toFixed(2),
        'Gross Basis': parseFloat(output?.tax_gross_basis?.value)?.toFixed(2),
        'Social Security': parseFloat(output?.employee_total_insurance?.value)?.toFixed(2),
        'Income costs': parseFloat(output?.cost_of_getting_income?.value)?.toFixed(2),
        'Tax Base': parseFloat(output?.tax_base?.value)?.toFixed(2),
        [`Tax`]: parseFloat(output?.tax_amount?.value)?.toFixed(2),
        'Free amount': parseFloat(output?.free_amount?.value)?.toFixed(2),
        'Tax after rounding': parseFloat(output?.tax_after_free_amount?.value)?.toFixed(2),
        'Gross Basis.': parseFloat(output?.net_salary_base?.value)?.toFixed(2),
        ['ZUS']: parseFloat(output?.employee_total_insurance?.value)?.toFixed(2),
        'NFZ so ZUS 9%': parseFloat(output?.nfz_contribution?.value)?.toFixed(2),
        ['Tax Amount']: parseFloat(output?.tax_after_free_amount?.value)?.toFixed(2),
        ['Net']: parseFloat(output?.net_salary?.value)?.toFixed(2),
        'Total Deduction': parseFloat(output?.input_deductions?.value)?.toFixed(2),
        'To be paid': parseFloat(output?.salary_to_pay?.value)?.toFixed(2),
        'Pension Insurance': parseFloat(output?.pension_insurance_employer?.value).toFixed(2),
        'Disability Insurance': parseFloat(output?.disability_insurance_employer?.value).toFixed(2),
        'Accident Insurance': parseFloat(output?.accident_insurance_employer?.value).toFixed(2),
        ['FP']: parseFloat(output?.labor_fund_insurance_employer?.value).toFixed(2),
        ['FGSP']: parseFloat(output?.fgsp_insurance_employer?.value).toFixed(2),
        ['FEP']: parseFloat(output?.fep_insurance_employer?.value).toFixed(2)
      };
      return row;
    });
    setExcelData(filterData);
  }, [itemPayrolls]);

  return (
    <Box padding={2}>
      <Box display="none">
        <FormattedMessage id="Payroll of" defaultMessage="Payroll of" />
        <FormattedMessage id="View Payout" defaultMessage="View Payout" />
        <FormattedMessage id="Payout order" defaultMessage="Payout order" />
        <FormattedMessage id="Edit Closing date of" defaultMessage="Edit Closing date of" />
      </Box>
      <ItemTable company_id={company_id} />
      <ExcelExport
        excelData={excelData}
        fileName="EmployeeProfile"
        fileTitle={`Employee Payroll - ${getDate()} `}
      />
    </Box>
  );
}

export default CollectiveView;
