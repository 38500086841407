import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import CustomModal from 'components/globals/modal';
import CustomTable from 'components/globals/table';
import useToggle from 'hooks/useToggle';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from 'utils';
import { changeDateFormat } from 'utils/dateUtils';
import { removeSocialSecurityLimit, updateSocialSecurityLimit } from '../../redux/actions';
import { default as useStyle, default as useStyles } from '../../styles';
import OpeningEdit from './Edit';

function OpeningView({ year, handleClose, employeeId, contractTypeId }) {
  const classes = useStyle();
  const { contributions } = useSelector((state) => state.contract);
  const [yearData, setYearData] = useState([]);
  const [otherDetail, setOtherDetail] = useState();
  const months = [
    { label: translate('January'), value: 1 },
    { label: translate('February'), value: 2 },
    { label: translate('March'), value: 3 },
    { label: translate('April'), value: 4 },
    { label: translate('May'), value: 5 },
    { label: translate('June'), value: 6 },
    { label: translate('July'), value: 7 },
    { label: translate('August'), value: 8 },
    { label: translate('September'), value: 9 },
    { label: translate('October'), value: 10 },
    { label: translate('November'), value: 11 },
    { label: translate('December'), value: 12 }
  ];

  const columns = [
    {
      title: <FormattedMessage id="S.N." defaultMessage="S.N." />,
      type: 'Index',
      isSortable: true,
      minWidth: 20
    },

    {
      field: (row) => months.find((item) => item.value == parseInt(row?.month))?.label,
      title: <FormattedMessage id="Month" defaultMessage="Month" />,
      minWidth: 170,
      align: 'right'
    },
    {
      field: 'social_contribution_base',
      title: <FormattedMessage id="Social Contribution" defaultMessage="Social Contribution" />,
      minWidth: 80,
      align: 'right'
    },
    {
      field: (row) => {
        return (
          <Actions
            year={row?.year}
            employeeId={employeeId}
            month={row?.month}
            contractTypeId={contractTypeId}
          />
        );
      },
      title: <FormattedMessage id="Actions" defaultMessage="Actions" />,
      minWidth: 170,
      align: 'center'
    }
  ];

  useEffect(() => {
    if (contributions?.social_security?.length > 0) {
      const groupByYear = contributions?.social_security?.reduce((group, item) => {
        group[item.year] = group[item.year] ?? [];
        group[item.year].push(item);
        return group;
      }, {});
      setYearData(groupByYear[year]);
    }
    const filterResult = contributions?.limit_exceed?.find((list) => list?.year == year);
    setOtherDetail(filterResult);
  }, [contributions]);
  return (
    <div className={classes.content}>
      <Box>
        <CustomTable tableData={yearData} tableHeads={columns} />
      </Box>
      <Paper>
        <Box
          paddingY={2}
          marginTop={4}
          marginX={4}
          display="flex"
          justifyContent="space-between"
          alignItems="center">
          <Box display="flex" justifyContent="space-between" alignItems="center" columnGap={1}>
            <Typography variant="h6">{translate('Date Exceeded')}:</Typography>
            <Typography variant="h6" fontWeight={400}>
              {otherDetail?.exceed_date ?? ''}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" columnGap={1}>
            <Typography variant="h6">{translate('Submitted By')}:</Typography>
            <Typography variant="h6" fontWeight={400}>
              {otherDetail?.submitted_by ?? ''}
            </Typography>
          </Box>
          <Box display="flex" columnGap={1}>
            <EditButton
              otherDetail={otherDetail}
              employeeId={employeeId}
              year={year}
              contractTypeId={contractTypeId}
            />
            <DeleteButton
              otherDetail={otherDetail}
              employeeId={employeeId}
              contractTypeId={contractTypeId}
            />
          </Box>
        </Box>
      </Paper>
    </div>
  );
}

export default OpeningView;

const Actions = ({ year, employeeId, month, contractTypeId }) => {
  const handleClick = () => {
    openFunction();
  };
  const classes = useStyles();
  const [modalOpen, openFunction] = useToggle(false);

  return (
    <>
      <div className={classes.iconWrapper}>
        <EditIcon onClick={handleClick} />
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        width="50rem"
        height="auto"
        headerText="The basis for assessing contributions from other jobs">
        <OpeningEdit
          year={year}
          employeeId={employeeId}
          handleClose={handleClick}
          month={month}
          contractTypeId={contractTypeId}
        />
      </CustomModal>
    </>
  );
};

const EditButton = ({ year, employeeId, contractTypeId, otherDetail }) => {
  const [modalOpen, openFunction] = useToggle(false);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    exceed_date: moment().year(year),
    submitted_by: ''
  });
  const { agreement_types, security_limit_loading } = useSelector((state) => state.contract);

  const filterAgreement = agreement_types?.find((list) => list?.id == contractTypeId);

  const handleClick = () => {
    openFunction();
  };

  const handleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = () => {
    if (data?.exceed_date && data?.submitted_by) {
      dispatch(
        updateSocialSecurityLimit({
          ...data,
          exceed_date: changeDateFormat(new Date(data?.exceed_date)),
          employee_id: employeeId,
          agreement_type: filterAgreement?.slug
        })
      );
    }
  };

  const disableYear = (date) => {
    const newDate = changeDateFormat(new Date(date), 'YYYY');
    return newDate != year;
  };

  useEffect(() => {
    if (otherDetail?.exceed_date && otherDetail?.submitted_by) {
      setData({
        exceed_date: otherDetail?.exceed_date,
        submitted_by: otherDetail?.submitted_by
      });
    }
  }, [otherDetail]);

  return (
    <>
      <Button variant="contained" onClick={handleClick}>
        <EditIcon />
      </Button>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        width="50rem"
        height="auto"
        headerText={
          <FormattedMessage
            id="Update Social Security Limit"
            defaultMessage="Update Social Security Limit"
          />
        }>
        <Box padding={4} display="flex" justifyContent="space-between" columnGap={2}>
          <FormControl fullWidth>
            <label htmlFor="">Exceed Date</label>
            <DatePicker
              name="exceed_date"
              value={data?.exceed_date}
              shouldDisableDate={disableYear}
              onChange={(newValue) => setData((prev) => ({ ...prev, exceed_date: newValue }))}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormControl>
          <FormControl fullWidth>
            <label htmlFor="">Submitted By</label>
            <Select
              value={data?.submitted_by}
              name="submitted_by"
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}>
              <MenuItem value="ZUS">{translate('ZUS')}</MenuItem>
              <MenuItem value="Payer">{translate('Payer')}</MenuItem>
              <MenuItem value="Insured">{translate('Insured')}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box padding={4} display="flex" justifyContent="flex-end">
          {security_limit_loading ? (
            <Button variant="contained" disabled>
              <CircularProgress size={18} /> Updating
            </Button>
          ) : (
            <Button variant="contained" onClick={handleSubmit}>
              Update
            </Button>
          )}
        </Box>
      </CustomModal>
    </>
  );
};

const DeleteButton = ({ employeeId, otherDetail, contractTypeId }) => {
  const [modalOpen, openFunction] = useToggle(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClick = () => {
    openFunction();
  };

  const { remove_security_limit_loading, agreement_types } = useSelector((state) => state.contract);

  const filterAgreement = agreement_types?.find((list) => list?.id == contractTypeId);

  const handleDelete = () => {
    if (otherDetail?.id) {
      dispatch(
        removeSocialSecurityLimit(
          { employee_id: employeeId, limit_id: otherDetail?.id },
          filterAgreement?.slug
        )
      );
    }
  };
  return (
    <>
      <Button variant="contained" sx={{ background: '#f44e4e' }} onClick={handleClick}>
        <DeleteIcon />
      </Button>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        width="50rem"
        height="auto"
        headerText={
          <FormattedMessage
            id="Remove Social Security Limit"
            defaultMessage="Remove Social Security Limit"
          />
        }>
        <Box padding={5}>
          <Typography variant="h5" textAlign="center">
            {
              <FormattedMessage
                id="Are you sure to delete it?"
                defaultMessage="Are you sure to delete it?"
              />
            }
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-around"
          columnGap={5}
          className={classes.modalFooter}>
          <>
            {remove_security_limit_loading ? (
              <Button variant={'contained'} disabled>
                <CircularProgress size={18} /> <FormattedMessage id="Yes" defaultMessage="Yes" />
              </Button>
            ) : (
              <Button variant={'contained'} onClick={handleDelete}>
                {<FormattedMessage id="Yes" defaultMessage="Yes" />}
              </Button>
            )}
          </>

          <Button className={classes.modalFooterCancel} variant={'outlined'} onClick={handleClick}>
            {<FormattedMessage id="No" defaultMessage="No" />}
          </Button>
        </Box>
      </CustomModal>
    </>
  );
};
