import { Button, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { HookForm } from 'components/form/hookForm';
import { HookFormProvider } from 'components/form/hookFormProvider';
import CancelModal from 'components/globals/modal/CancelModal';
import useToggle from 'hooks/useToggle';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import AllowanceForm from './Form';
import { clearOvertime, editOvertime } from './redux/actions';
import useStyles from './styles';

function Overtime({ handleClose, employeeId, row, handleRefetch }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [cancelOpen, cancelFunction] = useToggle(false);
  const { edit_loading, amount_loading } = useSelector((state) => state.overtime);

  const defaultValues = {
    name: row?.name ?? '',
    value: row?.value ?? '',
    year: row?.year ?? '',
    month: row?.month ?? '',
    hours: row?.hours ?? '',
    agreement_id: row?.agreement_id ?? ''
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    hours: Yup.string().required('Hours is required'),
    value: Yup.string().required('Overtime value is required'),
    year: Yup.string().required('Year  is required'),
    month: Yup.string().required('Month  is required')
  });
  const onSubmit = (data) => {
    row?.id && dispatch(editOvertime(row?.id, data, employeeId, handleClose, handleRefetch));
  };

  useEffect(() => {
    dispatch(clearOvertime());
  }, []);

  return (
    <>
      <div className={classes.content}>
        <HookFormProvider
          defaultValues={defaultValues}
          resolver={useYupValidationResolver(validationSchema)}>
          <HookForm onSubmit={onSubmit}>
            <AllowanceForm edit={true} employeeId={employeeId} />
            <Box className={classes.modalFooter} justifyContent="flex-end">
              <Box className={classes.modalFooterButtons}>
                {!edit_loading ? (
                  <Button
                    sx={{ marginRight: '1rem' }}
                    variant={'contained'}
                    type="submit"
                    disabled={amount_loading}>
                    Edit
                  </Button>
                ) : (
                  <Button sx={{ marginRight: '1rem' }} variant={'contained'} disabled>
                    <CircularProgress size={18} sx={{ marginRight: '1rem' }} /> Editing
                  </Button>
                )}

                <Button
                  className={classes.modalFooterCancel}
                  variant={'outlined'}
                  onClick={cancelFunction}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </HookForm>
        </HookFormProvider>
      </div>
      <CancelModal
        handleClose={handleClose}
        cancelFunction={cancelFunction}
        cancelOpen={cancelOpen}
      />
    </>
  );
}

export default Overtime;
