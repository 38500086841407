/* eslint-disable */
import { accountingReducer } from 'components/locals/accounting/redux/reducers';
import assignmentReducer from 'components/locals/assignment/redux/reducers';
import { calendarReducer } from 'components/locals/calendar/redux/reducers';
import companyListReducer from 'components/locals/company/companies/redux/reducers';
import companyAssignmentReducer from 'components/locals/companyAssignment/redux/reducers';
import statementReducer from 'components/locals/contract/common/statement/redux/reducers';
import contractReducer from 'components/locals/contract/redux/reducers';
import departmentReducer from 'components/locals/department/redux/reducers';
import documentReducer from 'components/locals/document/redux/reducers';
import eventReducer from 'components/locals/events/redux/reducers';
import parameterReducer from 'components/locals/parameter/redux/reducers';
import payrollReducer from 'components/locals/payroll/redux/reducers';
import companyReducer from 'components/locals/settings/redux/reducers';
import setupReducer from 'components/locals/setup/redux/reducers';
import allowanceReducer from 'components/locals/workers/components/allowance/redux/reducers';
import overtimeReducer from 'components/locals/workers/components/overtime/redux/reducers';
import timeRecordingReducer from 'components/locals/workers/components/timeRecording/redux/reducers';
import employeeReducer from 'components/locals/workers/redux/reducers';
import { combineReducers } from 'redux';
import authReducer from './auth/reducers';

export default combineReducers({
  auth: authReducer,
  employee: employeeReducer,
  department: departmentReducer,
  parameter: parameterReducer,
  document: documentReducer,
  contract: contractReducer,
  payroll: payrollReducer,
  company: companyReducer,
  allowance: allowanceReducer,
  timeRecording: timeRecordingReducer,
  overtime: overtimeReducer,
  events: eventReducer,
  setup: setupReducer,
  companyList: companyListReducer,
  accounting: accountingReducer,
  statement: statementReducer,
  assignment: assignmentReducer,
  companyAssignment: companyAssignmentReducer,
  calendar: calendarReducer
});
