import Paper from '@mui/material/Paper';
import CustomModal from 'components/globals/modal';
import CustomTable from 'components/globals/table';
import useToggle from 'hooks/useToggle';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';

import ApartmentIcon from '@mui/icons-material/Apartment';
import { Box, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkAccounting, setCompanyId } from 'redux/auth/actions';
import { translate } from 'utils';
import CompanyUserDetail from '../accounting/CompanyForm/CompanyUserDetail';
import { getAssignment } from './redux/actions';

export default function AssignmentTable({ name, nip }) {
  const { assignments, loading } = useSelector((state) => state.companyAssignment);
  const { company } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  const handleNewPage = (company_id) => {
    const companyId = company?.find((list) => list?.id === company_id);
    dispatch(checkAccounting(false));
    companyId && localStorage.setItem('companyId', JSON.stringify(companyId));
    companyId && dispatch(setCompanyId(companyId));
    navigate('/dashboard');
  };

  const columns = [
    { title: 'S.N.', type: 'Index', isSortable: true, minWidth: 20 },
    {
      title: <FormattedMessage id="Company" defaultMessage="Company" />,
      minWidth: 100,
      isSortable: true,
      field: (row) => {
        return (
          <div onClick={() => handleNewPage(row?.id)} className={classes.companyName}>
            <div>{row?.name}</div>
            <div className={classes.nip}>NIP: {row?.tax_id}</div>
          </div>
        );
      }
    },

    {
      field: (row) => {
        return row?.company_admins?.map((list) => (
          <p className={classes.companyList} key={list?.id}>
            {list?.name}
          </p>
        ));
      },
      title: <FormattedMessage id="Name" defaultMessage="Name" />,
      minWidth: 120,
      align: 'right'
    },
    {
      field: (row) => {
        return row?.company_admins?.map((list) => (
          <p className={classes.companyList} key={list?.id}>
            {list?.email}
          </p>
        ));
      },
      title: <FormattedMessage id="Email" defaultMessage="Email" />,
      minWidth: 120,
      align: 'right'
    },

    {
      field: 'employment_contract_count',
      title: <FormattedMessage id="Employment Contract" defaultMessage="Employment Contract" />,
      minWidth: 170,
      align: 'right'
    },
    {
      field: 'mandate_contract_count',
      title: <FormattedMessage id="Contract of Mandate" defaultMessage="Contract of Mandate" />,
      minWidth: 170,
      align: 'right'
    },
    {
      field: 'specific_contract_count',
      title: (
        <FormattedMessage
          id="Contract for Specific Work"
          defaultMessage="Contract for Specific Work"
        />
      ),
      minWidth: 170,
      align: 'right'
    },

    {
      field: (row) => {
        return <Actions row={row} />;
      },
      title: <FormattedMessage id="Actions" defaultMessage="Actions" />,
      minWidth: 180,
      align: 'center'
    }
  ];

  useEffect(() => {
    const filteredData = assignments?.filter((list) => {
      if (
        list?.name?.toLowerCase()?.includes(name?.toLowerCase()) &&
        list?.tax_id?.toString()?.includes(nip)
      ) {
        return list;
      }
    });
    filteredData && setRows(filteredData);
  }, [assignments, name, nip]);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <CustomTable tableData={rows} tableHeads={columns} loading={loading} />
    </Paper>
  );
}

const Actions = ({ row }) => {
  const classes = useStyles();
  const [modalOpen, openFunction] = useToggle(false);
  const dispatch = useDispatch();

  const handleClick = () => {
    openFunction();
  };

  const handleClose = () => {
    dispatch(getAssignment());
    openFunction();
  };

  return (
    <>
      <Box display="none">
        <FormattedMessage id="User List of" defaultMessage="User List of" />
      </Box>
      <div className={classes.iconWrapper}>
        <Tooltip
          title={
            <FormattedMessage
              id="Assign user to the company"
              defaultMessage="Assign user to the company"
            />
          }>
          <ApartmentIcon onClick={handleClick} />
        </Tooltip>
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={handleClose}
        height="auto"
        width="80rem"
        headerText={translate('User List of') + ' ' + row?.name}>
        <CompanyUserDetail handleClose={handleClose} slug={row?.slug} />
      </CustomModal>
    </>
  );
};
