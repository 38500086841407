import { Button, CircularProgress, MenuItem, TextField } from '@mui/material';
import { Box } from '@mui/system';
import useStyles from './styles';

import CancelModal from 'components/globals/modal/CancelModal';
import StatementForm from 'components/locals/assignment/common/statement/Form';
import { createStatement } from 'components/locals/assignment/common/statement/redux/actions';
import useToggle from 'hooks/useToggle';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

function AddStatement({ handleClose, handleRefetch }) {
  const classes = useStyles();
  const [contractId, setContractId] = useState();
  const [cancelOpen, cancelFunction] = useToggle(false);
  const { employee_contract } = useSelector((state) => state.contract);

  const handleChange = (e) => {
    setContractId(e.target.value);
  };

  return (
    <>
      <div className={classes.content}>
        <Box width="300px" margin={3}>
          <TextField
            select
            label="Select Contract"
            fullWidth
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}>
            {employee_contract
              ?.filter(
                (list) =>
                  list?.agreement_type_slug == 'contract-of-mandate' ||
                  list?.agreement_type_slug == 'contract-for-specific-work'
              )
              ?.map((contract) => (
                <MenuItem
                  key={contract?.id}
                  value={
                    contract?.id
                  }>{`${contract?.contract_number}-${contract?.agreement_type_name}`}</MenuItem>
              ))}
          </TextField>
        </Box>
        {contractId && (
          <Statement
            cancelFunction={cancelFunction}
            contractId={contractId}
            handleClose={handleClose}
            handleRefetch={handleRefetch}
          />
        )}
      </div>
      <CancelModal
        handleClose={handleClose}
        cancelFunction={cancelFunction}
        cancelOpen={cancelOpen}
      />
    </>
  );
}

export default AddStatement;

const Statement = ({ contractId, cancelFunction, handleClose, handleRefetch }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { create_loading } = useSelector((state) => state.statement);
  const [data, setData] = useState({ year: '', month: '', value: '', agreement_id: '' });
  const { employee_contract } = useSelector((state) => state.contract);

  const handleSuccess = () => {
    handleClose();
    handleRefetch();
  };

  const rate = employee_contract?.find((item) => item?.id == contractId)?.parameters?.rate;
  const handleSubmit = () => {
    dispatch(createStatement({ rate: rate, ...data, agreement_id: contractId }, handleSuccess));
  };

  return (
    <>
      <StatementForm rate={rate} setData={setData} data={data} />
      <Box className={classes.modalFooter}>
        <Box className={classes.modalFooterButtons}>
          {!create_loading ? (
            <Button sx={{ marginRight: '1rem' }} variant={'contained'} onClick={handleSubmit}>
              <FormattedMessage id="Save" defaultMessage="Save" />
            </Button>
          ) : (
            <Button sx={{ marginRight: '1rem' }} variant={'contained'} disabled>
              <CircularProgress size={20} />
            </Button>
          )}

          <Button
            className={classes.modalFooterCancel}
            variant={'outlined'}
            onClick={cancelFunction}>
            <FormattedMessage id="Cancel" defaultMessage="Cancel" />
          </Button>
        </Box>
      </Box>
    </>
  );
};
