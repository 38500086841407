/* Build in libraries */
import { Button, Card, CardContent } from '@mui/material';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import { useEffect, useState } from 'react';

/* Components */
import BusinessAddress from '../BusinessAddress';
import Registration from '../Registration';

/* Style */
import { Box } from '@mui/system';
import { HookForm } from 'components/UI/Form/HookForm';
import { HookFormProvider } from 'components/UI/Form/HookFormProvider';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { editCompany, fetchSingleCompany } from '../../redux/actions';
import Styles from '../../styles';

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  short_name: Yup.string().required('Short name is required'),
  tax_id: Yup.string().required('Tax id is required'),
  regon: Yup.string().required('Registration no. is required'),
  phone_number: Yup.string().typeError('Must be a number').required('Phone number is required'),
  addresses: Yup.array(),
  company_detail_email: Yup.string().email().required('Company email is required,')
});

function EditCompany() {
  const location = useLocation();
  const navigate = useNavigate();
  const [submitStatus, setSubmitStatus] = useState(false);
  const { singleCompany } = useSelector((state) => state.accounting);
  const {
    setValue,
    formState: { errors }
  } = useFormContext();
  const dispatch = useDispatch();
  const locationArray = location.pathname.split('/');
  const slug = locationArray.slice(-1);

  useEffect(() => {
    dispatch(fetchSingleCompany(slug));
  }, []);
  console.log(errors, 'error');
  const [backValidation, setBackValidation] = useState();

  useEffect(() => {
    if (Object.keys(singleCompany)?.length > 0) {
      setValue('addresses[0]', singleCompany?.addresses[0]);
      setValue('addresses[1]', singleCompany?.addresses[1]);
      setValue('name', singleCompany?.name);
      setValue('short_name', singleCompany?.short_name);
      setValue('tax_id', singleCompany?.tax_id);
      setValue('regon', singleCompany?.regon);
      setValue('bdo', singleCompany?.bdo);
      setValue('ceidg', singleCompany?.ceidg);
      setValue('krs', singleCompany?.krs);
      setValue('pkd', singleCompany?.pkd);
      setValue('is_small_company', singleCompany?.is_small_company);
      setValue('phone_number', singleCompany?.phone_number);
      setValue('company_detail_email', singleCompany?.company_detail[0]?.value);
    }
  }, [singleCompany]);

  console.log(backValidation);

  const [addressError, setAddressError] = useState();

  const redirectOnSuccess = () => {
    return navigate('/accounting');
  };

  const onSubmit = (data) => {
    const updatedAddress = data?.addresses.map((item) => {
      const updatedCountryValue = item?.country?.value;
      return { ...item, country: updatedCountryValue };
    });

    const finalData = {
      ...data,
      is_small_company: data?.is_small_company ? 1 : 0,
      addresses: updatedAddress
    };
    console.log(finalData, 'hello');
    if (!finalData.addresses[0]) {
      setAddressError('Home Address is required');
    } else {
      let error = 0;
      // Object.entries(finalData.addresses[0]).map((item) => {
      //   if (!item[1]) {
      //     if (item[0] != 'same_address_type') {
      //       setAddressError('Every Field of Home Address is required');
      //       error = error + 1;
      //     }
      //   }
      // });

      console.log(error, 'hello');
      if (error == 0) {
        setSubmitStatus(true);
        setAddressError('');
        dispatch(
          editCompany(finalData, slug, redirectOnSuccess, setSubmitStatus, setBackValidation)
        );
      }
    }
  };

  return (
    <>
      <HookForm onSubmit={onSubmit}>
        <Registration setBackValidation={setBackValidation} backValidation={setBackValidation} />
        <BusinessAddress addressError={addressError} setAddressError={setAddressError} />
        {/* <UserDetails /> */}
        <Box className="buttonWrapper">
          <Button variant="contained" type="submit" disabled={submitStatus}>
            {submitStatus ? 'Saving' : 'Save'}
          </Button>
          <Link to="/accounting" style={{ textDecoration: 'none' }}>
            <Button className="cancelButton">Cancel</Button>
          </Link>
        </Box>
      </HookForm>
    </>
  );
}

function EditCompanyForm() {
  const classes = Styles();

  return (
    <>
      <Card className={classes.cardWrapper}>
        <CardContent>
          <HookFormProvider resolver={useYupValidationResolver(validationSchema)}>
            <EditCompany />
          </HookFormProvider>
        </CardContent>
      </Card>
    </>
  );
}

export default EditCompanyForm;
