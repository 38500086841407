import EzlaPage from 'components/locals/ezla';
import DashboardLayout from 'layouts/dashboardLayout';

const Ezla = () => {
  return (
    <DashboardLayout>
      <EzlaPage />
    </DashboardLayout>
  );
};

export default Ezla;
