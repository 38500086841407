import BreadCrumb from 'components/globals/breadCrumb';
import MainAreaLayout from 'layouts/dashboardLayout/mainAreaLayout/MainAreaLayout';
import React, { useEffect, useState } from 'react';
import EventsTable from './EventTable';
import { useDispatch, useSelector } from 'react-redux';
import { getEvents } from './redux/actions';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import Filterform from './components/FilterForm';
import { changeDateFormat } from 'utils/dateUtils';
import useToggle from 'hooks/useToggle';
import CustomModal from 'components/globals/modal';
import EventCenter from './EventCenter';

function Events() {
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.events);
  const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
  const endOfMonth = moment().clone().endOf('month').format('YYYY-MM-DD');
  const [modalOpen, openFunction] = useToggle(false);

  const [startDate, setStartDate] = useState(startOfMonth);
  const [endDate, setEndDate] = useState(endOfMonth);
  const [type, setType] = useState('');
  const [employeeId, setEmployeeId] = useState('');

  useEffect(() => {
    filters?.startDate && setStartDate(filters?.startDate);
    filters?.endDate && setEndDate(filters?.endDate);
    filters?.type && setType(filters?.type);
  }, []);
  const handleRefetch = () => {
    const sDate = changeDateFormat(new Date(startDate), 'YYYY-MM-DD');
    const eDate = changeDateFormat(new Date(endDate), 'YYYY-MM-DD');
    startDate && endDate && dispatch(getEvents(sDate, eDate, type, employeeId));
  };
  useEffect(() => {
    const sDate = changeDateFormat(new Date(startDate), 'YYYY-MM-DD');
    const eDate = changeDateFormat(new Date(endDate), 'YYYY-MM-DD');
    startDate && endDate && dispatch(getEvents(sDate, eDate, type, employeeId));
    // dispatch()
    // dispatch(getEmployee());
  }, [startDate, endDate, type, employeeId]);

  return (
    <>
      <BreadCrumb
        itemName={<FormattedMessage id="Events" defaultMessage="Events" />}
        page={<FormattedMessage id="Events" defaultMessage="Events" />}
        // handleSearch={handleSearch}
        handleButton={openFunction}
        buttonTitle={<FormattedMessage id="Add Events" defaultMessage="Add Events" />}
      />
      <Filterform
        setStartDate={setStartDate}
        startDate={startDate}
        setEndDate={setEndDate}
        endDate={endDate}
        setType={setType}
        type={type}
        employeeId={employeeId}
        setEmployeeId={setEmployeeId}
      />
      <MainAreaLayout>
        <EventsTable handleRefetch={handleRefetch} />
      </MainAreaLayout>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="95rem"
        headerText={<FormattedMessage id="Create Event" defaultMessage="Create Event" />}>
        <EventCenter handleClose={openFunction} handleRefetch={handleRefetch} />
      </CustomModal>
    </>
  );
}

export default Events;
