import { Button, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { HookForm } from 'components/form/hookForm';
import { HookFormProvider } from 'components/form/hookFormProvider';
import CancelModal from 'components/globals/modal/CancelModal';
import { parseAddress } from 'helpers';
import useToggle from 'hooks/useToggle';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import EmployeeForm from './EmployeeForm';
import { clearSingleEmployee, createEmployee, getNHF } from './redux/actions';
import useStyles from './styles';

function EmployeeAdd({ handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { create_loading } = useSelector((state) => state.employee);

  const defaultValues = {
    first_name: '',
    middle_name: '',
    last_name: '',
    gender: '',
    pesel: '',
    tax_id: '',
    email: '',
    employee_detail: {}
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required(
      <FormattedMessage id="First name is required" defaultMessage="First name is required" />
    ),
    last_name: Yup.string().required(
      <FormattedMessage id="Last name is required" defaultMessage="Last name is required" />
    ),
    gender: Yup.string().required(
      <FormattedMessage id="Gender is required" defaultMessage="Gender is required" />
    ),
    pesel: Yup.mixed().when('employee_detail.foreigner', {
      is: (data) => data,
      then: Yup.string().nullable().notRequired(),
      otherwise: Yup.string()
        .typeError(
          <FormattedMessage id="Pesel must be Number" defaultMessage="Pesel must be Number" />
        )
        .required(<FormattedMessage id="Pesel is required" defaultMessage="Pesel is required" />)
        .test('len', 'Must be exactly 11 digits', (val) => val.length === 11)
    }),
    email: Yup.string()
      .email()
      .required(<FormattedMessage id="Email is required" defaultMessage="Email is required" />),
    address: Yup.array(),
    bank_name: Yup.string().required(
      <FormattedMessage id="Bank name is required" defaultMessage="Bank name is required" />
    ),
    account_number: Yup.string().required(
      <FormattedMessage
        id="Bank account number is required"
        defaultMessage="Bank account number is required"
      />
    ),
    employee_detail: Yup.object().shape({
      identification_type: Yup.string().when('foreigner', {
        is: (data) => data == true,
        then: Yup.string().required(
          <FormattedMessage
            id="Identification type is required"
            defaultMessage="Identification type is required"
          />
        ),
        otherwise: Yup.string().nullable().notRequired()
      }),
      identification_number: Yup.string().when('foreigner', {
        is: (data) => data == true,
        then: Yup.string().required(
          <FormattedMessage
            id="Identification number is required"
            defaultMessage="Identification number is required"
          />
        ),
        otherwise: Yup.string().nullable().notRequired()
      })
    })
  });

  const onSubmit = (data) => {
    const finalAddress = parseAddress(data?.address);

    const finalData = {
      ...data,
      employeed_from: new Date(),
      company_id: user?.company?.id,
      address: finalAddress,
      employee_detail: {
        ...data?.employee_detail,
        foreigner:
          data?.employee_detail?.foreigner == true || data?.employee_detail?.foreigner == '1'
            ? 1
            : 0
      }
    };

    dispatch(createEmployee(finalData, handleClose));
  };

  useEffect(() => {
    dispatch(clearSingleEmployee());
    dispatch(getNHF());
  }, []);

  const [cancelOpen, cancelFunction] = useToggle(false);

  return (
    <>
      <div className={classes.content}>
        <HookFormProvider
          defaultValues={defaultValues}
          resolver={useYupValidationResolver(validationSchema)}>
          <HookForm onSubmit={onSubmit}>
            <EmployeeForm action="register" />
            <Box className={classes.modalFooter}>
              <Box className={classes.modalFooterButtons}>
                {!create_loading ? (
                  <Button sx={{ marginRight: '1rem' }} variant={'contained'} type="submit">
                    <FormattedMessage id="Save" defaultMessage="Save" />
                  </Button>
                ) : (
                  <Button sx={{ marginRight: '1rem' }} variant={'contained'} disabled>
                    <CircularProgress size={18} sx={{ marginRight: '1rem' }} /> Saving
                  </Button>
                )}

                <Button
                  className={classes.modalFooterCancel}
                  variant={'outlined'}
                  onClick={cancelFunction}>
                  <FormattedMessage id="Cancel" defaultMessage="Cancel" />
                </Button>
              </Box>
            </Box>
          </HookForm>
        </HookFormProvider>
      </div>
      <CancelModal
        handleClose={handleClose}
        cancelFunction={cancelFunction}
        cancelOpen={cancelOpen}
      />
    </>
  );
}

export default EmployeeAdd;
