// this will be the componene for filterForm
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { Box } from '@mui/system';
import 'react-datepicker/dist/react-datepicker.css';
import { FormattedMessage } from 'react-intl';
import { translate } from 'utils';
import useStyles from './styles';

const FilterForm = ({ status, setStatus, name, setName, taxId, setTaxId }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box display="none">
        <FormattedMessage id="Status" defaultMessage="Status" />
        <FormattedMessage id="Active" defaultMessage="Active" />
        <FormattedMessage id="Inactive" defaultMessage="Inactive" />
      </Box>
      <div className={classes.optionWrap}>
        <Box display="flex" justifyContent="flex-start" columnGap="15px" width={'50%'}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-label" shrink>
              <FormattedMessage id="Name" defaultMessage="Name" />
            </InputLabel>
            <TextField
              labelId="demo-simple-label"
              value={name}
              onChange={(e) => {
                // dispatch(addCompanyFilter({ key: 'name', value: e.target.value }));
                setName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-label" shrink>
              <FormattedMessage id="NIP" defaultMessage="NIP" />
            </InputLabel>
            <TextField
              labelId="demo-simple-label"
              value={taxId}
              onChange={(e) => {
                // dispatch(addCompanyFilter({ key: 'name', value: e.target.value }));
                setTaxId(e.target.value);
              }}
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-label" shrink>
              <FormattedMessage id="Status" defaultMessage="Status" />
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={status}
              defaultValue=""
              input={<OutlinedInput notched label="Status" />}
              onChange={(e) => {
                // dispatch(addCompanyFilter({ key: 'status', value: e.target.value }));
                setStatus(e.target.value);
              }}>
              <MenuItem value="">
                <FormattedMessage id="None" defaultMessage="None" />
              </MenuItem>
              <MenuItem value="1">{translate('Active')}</MenuItem>
              <MenuItem value="0">{translate('Inactive')}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </div>
    </div>
  );
};

export default FilterForm;
