import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import HookFormAutoComplete from 'components/form/hookFormAutoComplete';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { translate } from 'utils';

function IncomeTax() {
  const [rates, setRates] = useState();

  const { tax_rates } = useSelector((state) => state.contract);

  const translateAutomatic = translate('Automatic');
  const translateLack = translate('Lack');
  useEffect(() => {
    const taxes = tax_rates?.map((item) => ({
      label: `${item}%`,
      value: item
    }));
    setRates([
      { label: translateAutomatic, value: null },
      ...taxes,
      { label: translateLack, value: 0 }
    ]);
  }, [tax_rates]);

  return (
    <>
      <Box display="none">
        <FormattedMessage id="Lack" defaultMessage="Lack" />
        <FormattedMessage id="Yes" defaultMessage="Yes" />
        <FormattedMessage id="No" defaultMessage="No" />
        <FormattedMessage id="Single" defaultMessage="Single" />
        <FormattedMessage id="Double" defaultMessage="Double" />
        <FormattedMessage id="Usually" defaultMessage="Usually" />
        <FormattedMessage id="Elevated" defaultMessage="Elevated" />
        <FormattedMessage id="Automatic" defaultMessage="Automatic" />

        <FormattedMessage id="Not" defaultMessage="Not" />
        <FormattedMessage
          id="Automatic (youth release)"
          defaultMessage="Automatic (youth release)"
        />
        <FormattedMessage id="Yes (youth exemption)" defaultMessage="Yes (youth exemption)" />
        <FormattedMessage id="Relief for parents(4+)" defaultMessage="Relief for parents(4+)" />
        <FormattedMessage id="Working pensioner" defaultMessage="Working pensioner" />
        <FormattedMessage
          id="Exemption for returnees from abroad"
          defaultMessage="Exemption for returnees from abroad"
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <HookFormAutoComplete
            name="parameters.tax_relief"
            label={<FormattedMessage id="Tax Relief" defaultMessage="Tax Relief" />}
            tooltipLabel="If the employee is still employed in another company and there is already an amount reducing the tax taken into account when calculating advances for income tax , select the none option .
            The employee is obliged to submit the PIT-2 form to the employer, in which he declares that the payer will deduct the tax-reducing amount from the advances.
            If the taxpayer submits to the payer a declaration of intention to jointly tax the spouses, his advances may be reduced by a double tax-reducing amount, provided that the spouse or child does not receive any income (except for survivor's pension)."
            options={[
              { label: translate('Lack'), value: 'lack' },
              { label: translate('Single'), value: 'single' },
              { label: translate('1/2 Tax Relief'), value: 'half' },
              { label: translate('1/3 Tax Relief'), value: 'one_third' }
            ]}
          />
        </Grid>
        <Grid item sm={6}>
          <HookFormAutoComplete
            name="parameters.cost_of_getting_income"
            label={
              <FormattedMessage
                id="Cost of getting income"
                defaultMessage="Cost of getting income"
              />
            }
            tooltipLabel="Increased costs may be applied when the place of work is located outside the place of residence of the employee.            "
            options={[
              { label: translate('Lack'), value: 'lack' },
              { label: translate('Standard'), value: 'standard' },
              { label: translate('Copyright'), value: 'copyright' }
            ]}
          />
        </Grid>
        <Grid item sm={6}>
          {rates && (
            <HookFormAutoComplete
              name="parameters.tax_rate"
              label={<FormattedMessage id="Tax rate" defaultMessage="Tax rate" />}
              placeholder="passport"
              options={rates}
            />
          )}
        </Grid>
        <Grid item sm={6}>
          <HookFormAutoComplete
            name="parameters.pit_exemption"
            label={<FormattedMessage id="PIT exemption" defaultMessage="PIT exemption" />}
            placeholder="passport"
            options={[
              { label: translate('Not'), value: null },
              { label: translate('Automatic (youth release)'), value: 'automatic' },
              { label: translate('Yes (youth exemption)'), value: 'exemption' },
              { label: translate('Relief for parents(4+)'), value: 'relief' },
              { label: translate('Working pensioner'), value: 'working_pensioner' },
              {
                label: translate('Exemption for returnees from abroad'),
                value: 'returnees_from_abroad'
              }
            ]}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default IncomeTax;
