import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCompanyInfo } from 'redux/auth/actions';
import Employee from './components/Employee';
import Widgets from './components/Widgets';

const CompanyContainer = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCompanyInfo());
  }, []);
  return (
    <>
      <Widgets />
      <Employee />
    </>
  );
};

export default CompanyContainer;
