import { Box, CircularProgress, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

const JsonViewer = () => {
  const { ezla, ezla_loading } = useSelector((state) => state.setup);

  return (
    <div>
      <Box marginBottom={4} marginTop={8}>
        <Typography variant="h4" textAlign="center">
          Ezla Json Data
        </Typography>
      </Box>
      {ezla_loading ? (
        <Box margin={10} textAlign="center">
          <CircularProgress size={24} />
          <Box>Loading...</Box>
        </Box>
      ) : (
        <pre style={{ fontSize: '16px' }}>{ezla && JSON.stringify(ezla, null, 8)}</pre>
      )}
    </div>
  );
};

export default JsonViewer;
