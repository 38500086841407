import { Button, CircularProgress, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { checkAccounting, fetchCompany, setCompanyId } from 'redux/auth/actions';
import useStyles from './styles';

function Company() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { company, companyLoading, user } = useSelector((state) => state.auth);
  const [filterCompany, setFilterCompany] = useState([]);

  const navigate = useNavigate();
  const handleClick = (data) => {
    localStorage.setItem('companyId', JSON.stringify(data));
    dispatch(setCompanyId(data));
    dispatch(checkAccounting(false));
    navigate('/dashboard');
  };

  const handleNavigate = () => {
    dispatch(checkAccounting(true));
    navigate('/dashboard');
  };
  useEffect(() => {
    dispatch(fetchCompany());
  }, []);

  useEffect(() => {
    setFilterCompany(company);
  }, [company]);

  const handleChange = (e) => {
    const newCompany = company
      ?.map((list) => {
        const value = e.target.value.toLowerCase();
        if (
          list?.name?.toLowerCase()?.includes(value) ||
          list?.tax_id?.toString()?.includes(e.target.value)
        ) {
          return list;
        } else {
          return null;
        }
      })
      .filter((item) => item);

    setFilterCompany(newCompany);
  };
  console.log(filterCompany);

  return (
    <div className={classes.companyContainer}>
      <div className={classes.companyWrapper}>
        <div className="search-company">
          <Box marginBottom={2}>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              onChange={handleChange}
              label={<FormattedMessage id="Search Company" defaultMessage="Search Company" />}
            />
          </Box>
          <div className={classes.mainTitle}>
            <FormattedMessage id="Select your Company" defaultMessage="Select your Company" />
          </div>
        </div>
        {!companyLoading ? (
          filterCompany?.length > 0 ? (
            <ul>
              {filterCompany?.map((list) => (
                <li key={list?.id}>
                  <div onClick={() => handleClick(list)}>
                    <span>{list?.name}</span>
                    <span className={classes.NIP}>NIP: {list?.tax_id}</span>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className={classes.noCompany}>
              <FormattedMessage id="No Company Found" defaultMessage="No Company Found" />
            </div>
          )
        ) : (
          <Box sx={{ textAlign: 'center', margin: '30px' }}>
            <CircularProgress />
          </Box>
        )}
        {user?.accounting_office && (
          <Box marginTop={5}>
            <Button variant="contained" fullWidth onClick={handleNavigate}>
              Go to Dashboard
            </Button>
          </Box>
        )}
      </div>
    </div>
  );
}

export default Company;
