import { axiosInstance } from 'apis/_axios';

// get employee by id
export const getCompanyListApi = (data) => {
  return axiosInstance().get(`/bulk_action/collective`, { params: data });
};

export const closeBulkPayListApi = (data) => {
  return axiosInstance().post(`/bulk_action/close_paylist`, data);
};

export const sendBulkMailApi = (data) => {
  return axiosInstance().post(`bulk_action/send_mail`, data);
};

export const sendBulkPayoutApi = (data) => {
  return axiosInstance().post(`bulk_action/send_payouts`, data);
};

export const sendAllBulkActionApi = (data) => {
  return axiosInstance().post(`bulk_action/all-actions`, data);
};

export const addCompanyDetailApi = (data) => {
  return axiosInstance().get(`/bulk_action/action_data`, { params: data });
};
