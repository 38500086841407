import { Box, Typography } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import AddressData from './AddressData';

function AddressContainer() {
  const { register, watch } = useFormContext();

  return (
    <>
      <Box
        mt={3}
        mb={3}
        display="flex"
        alignItems="center"
        columnGap={1}
        padding="10px"
        sx={{ background: '#fff' }}>
        <input
          type="checkbox"
          value="home"
          {...register('address[1][same_address_type]')}
          style={{ cursor: 'pointer' }}
        />

        <Typography sx={{ fontSize: '13px' }}>
          {
            <FormattedMessage
              id="The registered address is same as the home address"
              defaultMessage="The registered address is same as the home address"
            />
          }
          :{' '}
        </Typography>
      </Box>
      {!watch('address[1][same_address_type]') ? (
        <>
          <Box mt={3} mb={3} display="flex" alignItems="center" columnGap={1}>
            <Typography sx={{ fontSize: '13px' }}>
              {<FormattedMessage id="Registered Address" defaultMessage="Registered Address" />}:
            </Typography>
          </Box>
          <AddressData type="registered" index={1} />
        </>
      ) : (
        false
      )}
      <Box
        mt={3}
        mb={3}
        display="flex"
        alignItems="center"
        columnGap={1}
        padding="10px"
        sx={{ background: '#fff' }}>
        <input
          type="checkbox"
          value="home"
          {...register('address[2][same_address_type]')}
          style={{ cursor: 'pointer' }}
        />
        <Typography sx={{ fontSize: '13px' }}>
          {
            <FormattedMessage
              id="The mailing address is same as the home address"
              defaultMessage="The mailing address is same as the home address"
            />
          }{' '}
        </Typography>
      </Box>
      {!watch('address[2][same_address_type]') ? (
        <>
          <Box mt={3} mb={3} display="flex" alignItems="center" columnGap={1}>
            <Typography sx={{ fontSize: '13px' }}>
              {<FormattedMessage id="Mailing Address" defaultMessage="Mailing Address" />}:
            </Typography>
          </Box>
          <AddressData type="mailing" index={2} />
        </>
      ) : (
        false
      )}
    </>
  );
}

export default AddressContainer;
