import { Button, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { HookForm } from 'components/UI/Form/HookForm';
import { HookFormInput } from 'components/UI/Form/HookFormInput';
import { HookFormProvider } from 'components/UI/Form/HookFormProvider';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { changeDateFormat } from 'utils/dateUtils';
import * as yup from 'yup';
import { storeHoliday } from './redux/actions';
import { useStyles } from './styles';

function AddCalendar({ openFunction, currentDate }) {
  const { isLoading } = useSelector((state) => state.calendar);
  const classes = useStyles();
  const validationSchema = yup.object({
    closing_date: yup.date().required(),
    title: yup.string().required()
  });
  const dispatch = useDispatch();

  const defaultValues = {
    closing_date: '',
    title: ''
  };

  const onSubmit = (data) => {
    dispatch(
      storeHoliday(
        { ...data, closing_date: changeDateFormat(data?.closing_date, 'YYYY-MM-DD') },
        openFunction,
        currentDate?.getFullYear()
      )
    );
  };

  return (
    <div className={classes.cardWrapper}>
      <HookFormProvider
        defaultValues={defaultValues}
        resolver={useYupValidationResolver(validationSchema)}>
        <HookForm onSubmit={onSubmit}>
          <FormContent />
          <Box className="buttonWrapper">
            <Button color="primary" variant="contained" type="submit" disabled={isLoading}>
              {isLoading ? (
                <FormattedMessage id="Saving" defaultMessage="Saving" />
              ) : (
                <FormattedMessage id="Save" defaultMessage="Save" />
              )}
            </Button>

            <Button className="cancelButton" onClick={openFunction}>
              <FormattedMessage id="Cancel" defaultMessage="Cancel" />
            </Button>
          </Box>
        </HookForm>
      </HookFormProvider>
    </div>
  );
}

export default AddCalendar;

const FormContent = () => {
  return (
    <Box marginX={4} marginY={2}>
      <Grid container spacing={1}>
        <Grid item sm={12}>
          <HookFormInput
            name="closing_date"
            type="date"
            label={<FormattedMessage id="Date" defaultMessage="Date" />}
          />
        </Grid>
        <Grid item sm={12}>
          <HookFormInput
            name="title"
            label={<FormattedMessage id="Holiday Title" defaultMessage="Holiday Title" />}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
