import { Skeleton, Stack } from '@mui/material';
import { Box } from '@mui/system';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from 'utils';
import EmploymentContractAdd from './employmentContract/Add';
import MandateContractAdd from './mandateContract/Add';
import { clearCalculateContract, clearContribution } from './redux/actions';
import SpecificContractAdd from './specificContract/Add';
import useStyles from './styles';

function ContractSelect({ handleClose, employeeId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalOpen, openFunction] = useToggle(false);
  const [title, setTitle] = useState('');
  const [contractId, setContractId] = useState();
  const [slug, setSlug] = useState();
  const { agreement_types, agreement_type_loading } = useSelector((state) => state.contract);

  const handleClick = (header, id, slug) => {
    setContractId(id);
    setTitle(header);
    openFunction();
    setSlug(slug);
  };

  useEffect(() => {
    dispatch(clearCalculateContract());
    dispatch(clearContribution());
  }, []);

  return (
    <>
      <Box display="none">
        <FormattedMessage id="Add a new" defaultMessage="Add a new" />
      </Box>
      <div className={classes.contractSelectWrapper}>
        {!agreement_type_loading ? (
          <ul>
            {agreement_types?.map((agreement) => (
              <li
                onClick={() => handleClick(`${agreement?.name}`, agreement?.id, agreement?.slug)}
                key={agreement?.id}>
                {agreement?.name}
              </li>
            ))}
          </ul>
        ) : (
          <Stack spacing={0}>
            <Skeleton height={32} animation="wave" />
            <Skeleton height={32} animation="wave" />
          </Stack>
        )}
      </div>

      <CustomModal
        open={modalOpen}
        handleClose={handleClose}
        height="auto"
        width="1100px"
        headerText={translate('Add a new') + ' ' + title}
        variant="light">
        {slug === 'employment-contract' && (
          <EmploymentContractAdd
            handleClose={handleClose}
            contractId={contractId}
            selectedEmployeeId={employeeId}
          />
        )}
        {slug === 'contract-of-mandate' && (
          <MandateContractAdd
            handleClose={handleClose}
            contractId={contractId}
            selectedEmployeeId={employeeId}
          />
        )}
        {slug === 'contract-for-specific-work' && (
          <SpecificContractAdd
            handleClose={handleClose}
            contractId={contractId}
            selectedEmployeeId={employeeId}
          />
        )}
      </CustomModal>
    </>
  );
}

export default ContractSelect;
