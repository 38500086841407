import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    padding: '0rem',
    '& .title': {
      fontSize: '15px',
      fontWeight: '600',
      color: theme.palette.primary.dark,
      padding: '8px 32px'
    },
    '& .MuiCardContent-root': {
      padding: '0px !important'
    },
    '& .formBodyWrapper': {
      padding: '14px 35px 31px'
    },
    '& .buttonWrapper': {
      backgroundColor: theme.palette.lightGrey[1],
      padding: '13px 20px 13px',
      display: 'flex',
      justifyContent: 'flex-end',
      columnGap: '2rem',
      '& .saveButton': {
        background: theme.palette.primary.normal,
        borderRadius: '2px',
        color: '#fff',
        fontSize: '13px',
        height: '30px'
      },
      '& .cancelButton': {
        border: '1px solid #9D9CAF',
        borderRadius: '2px',
        color: '#383751',
        height: '30px',
        fontSize: '13px'
      }
    }
  },
  boxWrapper: {
    borderRadius: '0px !important',
    boxShadow: '0px 7px 4px 0px rgb(165 160 160 / 20%) !important',
    '& .body': {
      padding: '14px 30px 15px',
      background: theme.palette.lightGrey[1],
      '& .content': {
        background: '#fff'
      }
    },
    '& .title': {
      color: theme.palette.grey[1],
      fontSize: '12px'
    },
    '& .description': {
      color: theme.palette.primary.dark,
      fontSize: '13px'
    },
    '& .header': {
      fontSize: '15px',
      fontWeight: '600',
      color: theme.palette.primary.dark,
      padding: '8px 25px'
    },
    '& .MuiCardContent-root': {
      padding: '0px !important'
    }
  },
  logoWrapper: {
    margin: '2rem',
    textAlign: 'center',
    cursor: 'pointer',
    '& img': {
      height: '180px',
      boxShadow: '3px 4px 10px #ecebeb',
      borderRadius: '4px',
      width: '180px',
      objectFit: 'cover',
      overflow: 'hidden'
    }
  },
  updateLogo: {
    height: '180px',
    boxShadow: '3px 4px 10px #ecebeb',
    borderRadius: '4px',
    width: '180px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '22px',
    fontWeight: '600'
  }
}));

export default useStyles;
