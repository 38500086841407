export const GET_ITEM_PAYROLL_BEGIN = 'GET_ITEM_PAYROLL_BEGIN';
export const GET_ITEM_PAYROLL_SUCCESS = 'GET_ITEM_PAYROLL_SUCCESS';
export const GET_ITEM_PAYROLL_ERROR = 'GET_ITEM_PAYROLL_ERROR';

export const GET_COLLECTIVE_PAYROLL_BEGIN = 'GET_COLLECTIVE_PAYROLL_BEGIN';
export const GET_COLLECTIVE_PAYROLL_SUCCESS = 'GET_COLLECTIVE_PAYROLL_SUCCESS';
export const GET_COLLECTIVE_PAYROLL_ERROR = 'GET_COLLECTIVE_PAYROLL_ERROR';

export const GET_COLLECTIVE_DETAIL_SUCCESS = 'GET_COLLECTIVE_DETAIL_SUCCESS';

export const EDIT_PAYROLL_BEGIN = 'EDIT_PAYROLL_BEGIN';
export const EDIT_PAYROLL_SUCCESS = 'EDIT_PAYROLL_SUCCESS';
export const EDIT_PAYROLL_ERROR = 'EDIT_PAYROLL_ERROR';

export const ITEM_CORRECTION_PAYROLL_BEGIN = 'ITEM_CORRECTION_PAYROLL_BEGIN';
export const ITEM_CORRECTION_PAYROLL_SUCCESS = 'ITEM_CORRECTION_PAYROLL_SUCCESS';
export const ITEM_CORRECTION_PAYROLL_ERROR = 'ITEM_CORRECTION_PAYROLL_ERROR';

export const COLLECTIVE_CORRECTION_PAYROLL_BEGIN = 'COLLECTIVE_CORRECTION_PAYROLL_BEGIN';
export const COLLECTIVE_CORRECTION_PAYROLL_SUCCESS = 'COLLECTIVE_CORRECTION_PAYROLL_SUCCESS';
export const COLLECTIVE_CORRECTION_PAYROLL_ERROR = 'COLLECTIVE_CORRECTION_PAYROLL_ERROR';

export const CLOSE_PAYLIST_BEGIN = 'CLOSE_PAYLIST_BEGIN';
export const CLOSE_PAYLIST_SUCCESS = 'CLOSE_PAYLIST_SUCCESS';
export const CLOSE_PAYLIST_ERROR = 'CLOSE_PAYLIST_ERROR';

export const MODIFY_PAYROLL_BEGIN = 'MODIFY_PAYROLL_BEGIN';
export const MODIFY_PAYROLL_SUCCESS = 'MODIFY_PAYROLL_SUCCESS';
export const MODIFY_PAYROLL_ERROR = 'MODIFY_PAYROLL_ERROR';

export const GET_ALL_PAYROLL_BEGIN = 'GET_ALL_PAYROLL_BEGIN';
export const GET_ALL_PAYROLL_SUCCESS = 'GET_ALL_PAYROLL_SUCCESS';
export const GET_ALL_PAYROLL_ERROR = 'GET_ALL_PAYROLL_ERROR';

export const ADD_ITEM_FILTER = 'ADD_ITEM_FILTER';
export const ADD_COLLECTIVE_FILTER = 'ADD_COLLECTIVE_FILTER';

export const PAYOUT_ORDER_BEGIN = 'PAYOUT_ORDER_BEGIN';
export const PAYOUT_ORDER_SUCCESS = 'PAYOUT_ORDER_SUCCESS';
export const PAYOUT_ORDER_ERROR = 'PAYOUT_ORDER_ERROR';

export const SEND_PAYOUT_MAIL_BEGIN = 'SEND_PAYOUT_MAIL_BEGIN';
export const SEND_PAYOUT_MAIL_SUCCESS = 'SEND_PAYOUT_MAIL_SUCCESS';
export const SEND_PAYOUT_MAIL_ERROR = 'SEND_PAYOUT_MAIL_ERROR';
