import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import AddStatement from './AddStatement';
import { getStatement } from './redux/actions';
import StatementTable from './StatementTable';

function Statement({ contractId }) {
  const [modalOpen, openFunction] = useToggle(false);
  const { watch } = useFormContext();
  const dispatch = useDispatch();
  const watchRate = watch('parameters.rate');

  const handleOpenFunction = () => {
    openFunction();
  };

  useEffect(() => {
    dispatch(getStatement(contractId));
  }, [contractId]);

  return (
    <>
      <Box marginBottom={2}>
        <Button variant="contained" onClick={handleOpenFunction}>
          <AddIcon />
          {<FormattedMessage id="Add" defaultMessage="Add" />}
        </Button>
      </Box>
      <StatementTable />
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        headerText={<FormattedMessage id="Add Statement" defaultMessage="Add Statement" />}>
        <AddStatement handleClose={openFunction} contractId={contractId} rate={watchRate} />
      </CustomModal>
    </>
  );
}

export default Statement;
