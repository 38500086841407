import BreadCrumb from 'components/globals/breadCrumb';
import FilterForm from './filterForm';
import MainAreaLayout from 'layouts/dashboardLayout/mainAreaLayout/MainAreaLayout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getItemPayroll } from '../redux/actions';
import ItemTable from './ItemTable';
import { FormattedMessage } from 'react-intl';

function ItemPayroll() {
  const dispatch = useDispatch();
  const handleSearch = () => {
    console.log('searched');
  };
  const date = new Date();
  const { itemFilter } = useSelector((state) => state.payroll);
  const [year, setYear] = useState(date.getFullYear());
  const [month, setMonth] = useState(date.getMonth() + 1);
  const fetchItemPayroll = () => {
    dispatch(getItemPayroll(year, month));
  };
  useEffect(() => {
    itemFilter?.year && setYear(itemFilter?.year);
    itemFilter?.month && setMonth(itemFilter?.month);
  }, []);
  useEffect(() => {
    fetchItemPayroll();
  }, [year, month]);

  return (
    <>
      <BreadCrumb
        itemName={<FormattedMessage id="Item Payroll" defaultMessage="Item Payroll" />}
        page={<FormattedMessage id="Item Payroll" defaultMessage="Item Payroll" />}
        handleSearch={handleSearch}
      />
      <FilterForm setMonth={setMonth} setYear={setYear} year={year} month={month} />
      <MainAreaLayout>
        <ItemTable fetchItemPayroll={fetchItemPayroll} />
      </MainAreaLayout>
    </>
  );
}

export default ItemPayroll;
