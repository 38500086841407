import * as React from 'react';
import Paper from '@mui/material/Paper';
import CustomTable from 'components/globals/table';
import { FormattedMessage } from 'react-intl';
import { changeDateFormat } from 'utils/dateUtils';
import moment from 'moment';

export default function PayoutTable({ payouts }) {
  const columns = [
    { title: 'S.N.', type: 'Index', isSortable: true, minWidth: 20 },
    {
      field: (row) => {
        const firstName = row?.employee?.first_name ?? '';
        const middleName = row?.employee?.middle_name ?? '';
        const lastName = row?.employee?.last_name ?? '';
        return firstName + ' ' + middleName + ' ' + lastName;
      },
      title: <FormattedMessage id="Employee" defaultMessage="Employee" />,
      width: 100,
      align: 'right'
    },
    {
      title: <FormattedMessage id="Contract Number" defaultMessage="Contract Number" />,
      minWidth: 100,
      isSortable: true,
      field: (row) => {
        return row?.paylist?.agreement?.contract_number ?? '';
      }
    },

    {
      field: (row) => {
        return row?.paylist?.paylist_code ?? '';
      },
      title: <FormattedMessage id="Payroll Number" defaultMessage="Payroll Number" />,
      minWidth: 120,
      align: 'right'
    },

    {
      field: (row) => {
        if (row?.period) {
          const period = changeDateFormat(moment(row?.period), 'YYYY-MM');
          return period;
        } else {
          return '';
        }
      },
      title: <FormattedMessage id="Period of the list" defaultMessage="Period of the list" />,
      minWidth: 100,
      align: 'right'
    },
    {
      field: 'amount',
      title: <FormattedMessage id="Paycheck" defaultMessage="Paycheck" />,
      minWidth: 100,
      align: 'right'
    },
    {
      field: 'payment_date',
      title: <FormattedMessage id="Payment Day" defaultMessage="Payment Day" />,
      minWidth: 200,
      align: 'right'
    },
    {
      field: 'bank_name',
      title: <FormattedMessage id="Bank Name" defaultMessage="Bank Name" />,
      minWidth: 120,
      align: 'right'
    },
    {
      field: 'account_number',
      title: <FormattedMessage id="Account Number" defaultMessage="Account Number" />,
      minWidth: 120,
      align: 'right'
    }
  ];

  const rows = payouts || [];

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <CustomTable tableData={rows} tableHeads={columns} />
    </Paper>
  );
}
