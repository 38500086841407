import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  name: Yup.string().required(
    <FormattedMessage id="Name is required" defaultMessage="Name is required" />
  ),
  short_name: Yup.string().required(
    <FormattedMessage id="Short name is required" defaultMessage="Short name is required" />
  ),
  tax_id: Yup.string().required(
    <FormattedMessage id="Tax id is required" defaultMessage="Tax id is required" />
  ),
  regon: Yup.string().required(
    <FormattedMessage
      id="Registration no. is required"
      defaultMessage="Registration no. is required"
    />
  ),

  phone_number: Yup.string()
    .typeError('Must be a number')
    .required(
      <FormattedMessage id="Phone number is required" defaultMessage="Phone number is required" />
    ),
  addresses: Yup.array(),
  user_name: Yup.string().required(
    <FormattedMessage id="User Name is required" defaultMessage="User Name is required" />
  ),
  user_email: Yup.string()
    .email()
    .required(
      <FormattedMessage id="User Email is required" defaultMessage="User Email is required" />
    ),
  company_detail_email: Yup.string()
    .email()
    .required(
      <FormattedMessage id="Company email is required" defaultMessage="Company email is required" />
    )
});
