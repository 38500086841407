import { Grid } from '@mui/material';
import { HookFormInput } from 'components/form/hookFormInput';
import React from 'react';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

function AddressData({ index, type, setAddressError }) {
  const { register, control } = useFormContext();

  const data = useWatch({ control, name: 'address[0]' });
  useEffect(() => {
    setAddressError && setAddressError('');
  }, [data]);

  return (
    <Grid container spacing={2}>
      <input type="hidden" {...register(`address[${index}][type]`)} defaultValue={type} />
      <Grid item sm={6}>
        <HookFormInput
          name={`address[${index}][street]`}
          label={<FormattedMessage id="Street" defaultMessage="Street" />}
          placeholder="Wrocławska"
        />
      </Grid>
      <Grid item sm={2}>
        <HookFormInput
          name={`address[${index}][house_number]`}
          label={<FormattedMessage id="House Number" defaultMessage="House Number" />}
          placeholder="33F"
        />
      </Grid>
      <Grid item sm={2}>
        <HookFormInput
          name={`address[${index}][number_of_premises]`}
          label={<FormattedMessage id="No. of premises" defaultMessage="No. of premises" />}
          placeholder="5"
        />
      </Grid>
      <Grid item sm={2}>
        <HookFormInput
          name={`address[${index}][zip]`}
          label={<FormattedMessage id="Zip Code" defaultMessage="Zip code" />}
          placeholder="00-012"
        />
      </Grid>
      {/* <Grid item sm={4}>
        <HookFormInput name="full_address" label="Full Address" placeholder="Albert Flores" />
      </Grid> */}
      <Grid item sm={3}>
        <HookFormInput
          name={`address[${index}][post]`}
          label={<FormattedMessage id="Post" defaultMessage="Post" />}
          placeholder="Sandomierz"
        />
      </Grid>
      <Grid item sm={3}>
        <HookFormInput
          name={`address[${index}][city]`}
          label={<FormattedMessage id="City" defaultMessage="City" />}
          placeholder="Snadomierz"
        />
      </Grid>
      <Grid item sm={3}>
        <HookFormInput
          name={`address[${index}][country]`}
          label={<FormattedMessage id="Country" defaultMessage="Country" />}
          placeholder="Polska"
        />
      </Grid>
      <Grid item sm={3}>
        <HookFormInput
          name={`address[${index}][voivodeship]`}
          label={<FormattedMessage id="Voivodeship" defaultMessage="Voivodeship" />}
          placeholder="Świętokrzyskie"
        />
      </Grid>
      <Grid item sm={3}>
        <HookFormInput
          name={`address[${index}][county]`}
          label={<FormattedMessage id="District" defaultMessage="District" />}
          placeholder="Sandomierski"
        />
      </Grid>
      <Grid item sm={3}>
        <HookFormInput
          name={`address[${index}][community]`}
          label={<FormattedMessage id="Commune" defaultMessage="Commune" />}
          placeholder="Sandomierz"
        />
      </Grid>
    </Grid>
  );
}

export default AddressData;
