import {
  createOvertimeApi,
  deleteOvertimeApi,
  editOvertimeApi,
  getOvertimeAmountApi,
  getOvertimeApi
} from 'apis/overtime';
import { errorToast, successToast } from 'utils/toast';
import * as actions from './types';

// create Overtime
export const createOvertime = (data, handleFunction, handleRefetch) => (dispatch) => {
  dispatch({
    type: actions.CREATE_OVERTIME_BEGIN
  });
  createOvertimeApi(data)
    .then((res) => {
      dispatch({
        type: actions.CREATE_OVERTIME_SUCCESS
      });
      handleFunction && handleFunction();
      handleRefetch && handleRefetch();
      dispatch(getOvertime(data?.employee_id));
      successToast('Overtime created Successfully');
    })
    .catch((error) => {
      dispatch({
        type: actions.CREATE_OVERTIME_ERROR
      });
      errorToast(error);
    });
};

// get all overtime
export const getOvertime = (employee_id) => (dispatch) => {
  dispatch({
    type: actions.GET_OVERTIME_BEGIN
  });
  getOvertimeApi(employee_id)
    .then((res) => {
      dispatch({
        type: actions.GET_OVERTIME_SUCCESS,
        payload: res.data.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_OVERTIME_ERROR
      });
      errorToast(error);
    });
};

// edit overtime
export const editOvertime = (id, data, employeeId, handleFunction, handleRefetch) => (dispatch) => {
  dispatch({
    type: actions.EDIT_OVERTIME_BEGIN
  });
  editOvertimeApi(id, data)
    .then((res) => {
      dispatch({
        type: actions.EDIT_OVERTIME_SUCCESS
      });
      handleFunction && handleFunction();
      handleRefetch && handleRefetch();
      successToast('Overtime edited Successfully');
      dispatch(getOvertime(employeeId));
    })
    .catch((error) => {
      dispatch({
        type: actions.EDIT_OVERTIME_ERROR
      });
      errorToast(error);
    });
};

// delete overtime
export const deleteOvertime = (id, employeeId, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.DELETE_OVERTIME_BEGIN
  });
  deleteOvertimeApi(id)
    .then((res) => {
      dispatch({
        type: actions.DELETE_OVERTIME_SUCCESS
      });
      handleFunction && handleFunction();
      successToast('Overtime deleted Successfully');
      dispatch(getOvertime(employeeId));
    })
    .catch((error) => {
      dispatch({
        type: actions.DELETE_OVERTIME_ERROR
      });
      errorToast(error);
    });
};

// get overtime calculated amount
export const getOvertimeAmount = (data) => (dispatch) => {
  dispatch({
    type: actions.GET_OVERTIME_AMOUNT_BEGIN
  });
  getOvertimeAmountApi(data)
    .then((res) => {
      dispatch({
        type: actions.GET_OVERTIME_AMOUNT_SUCCESS,
        payload: res.data.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_OVERTIME_AMOUNT_ERROR
      });
      errorToast(error);
    });
};

// clear overtime
export const clearOvertime = () => (dispatch) => {
  dispatch({ type: actions.OVERTIME_AMOUNT_CLEAR });
};
