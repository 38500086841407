import Delete from '@mui/icons-material/Delete';
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAssignCompany, getUserCompany } from '../redux/actions';
import useStyles from '../styles';
import EditUserDetails from './EditUserForm';

function CompanyList({ userId }) {
  const dispatch = useDispatch();
  const { user_company, user_company_loading } = useSelector((state) => state.assignment);
  useEffect(() => {
    userId && dispatch(getUserCompany(userId));
  }, [userId]);

  return !user_company_loading ? (
    <div>
      <EditUserDetails userId={userId} />
      <Box sx={{ margin: '30px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>S.N.</TableCell>
              <TableCell>
                <FormattedMessage id="Name" defaultMessage="Name" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="Email" defaultMessage="Email" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="Status" defaultMessage="Status" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="Admin" defaultMessage="Admin" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="Actions" defaultMessage="Actions" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {user_company?.user?.company?.map((list, index) => (
              <TableRow key={list?.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{list?.name}</TableCell>
                <TableCell style={{ textTransform: 'none' }}>{list?.email}</TableCell>
                <TableCell>{list?.status == 1 ? 'Active' : 'Inactive'}</TableCell>
                <TableCell>{list?.is_admin == 1 ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  <Actions slug={list?.slug} userId={userId} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </div>
  ) : (
    <Box textAlign="center" margin={10}>
      <CircularProgress />
    </Box>
  );
}

export default CompanyList;

const Actions = ({ userId, slug }) => {
  const classes = useStyles();
  const [deleteModal, deleteFunction] = useToggle(false);
  const dispatch = useDispatch();
  const { delete_assign_company_loading } = useSelector((state) => state.assignment);

  const handleDelete = () => {
    dispatch(deleteAssignCompany(userId, slug));
  };
  return (
    <div className={classes.iconWrapper}>
      <Tooltip title="Remove User">
        <Delete onClick={deleteFunction} />
      </Tooltip>

      <CustomModal
        open={deleteModal}
        handleClose={deleteFunction}
        height="auto"
        width="40rem"
        variant="light"
        headerText={`Remove Company`}>
        <Box textAlign="center" margin={5}>
          <Typography variant="h5">
            <FormattedMessage
              id="Are you sure want to remove this company?"
              defaultMessage="Are you sure want to remove this company?"
            />
          </Typography>
        </Box>
        <Box className={classes.modalFooter}>
          <Box className={classes.modalFooterButtons}>
            {!delete_assign_company_loading ? (
              <Button sx={{ marginRight: '1rem' }} variant={'contained'} onClick={handleDelete}>
                <FormattedMessage id="Yes" defaultMessage="Yes" />
              </Button>
            ) : (
              <Button sx={{ marginRight: 'rem' }} variant={'contained'} disabled>
                <CircularProgress size={18} sx={{ marginRight: '1rem' }} />{' '}
                <FormattedMessage id="Yes" defaultMessage="Yes" />
              </Button>
            )}

            <Button
              className={classes.modalFooterCancel}
              sx={{ background: '#fff', color: '#000' }}
              variant={'contained'}
              onClick={deleteFunction}>
              <FormattedMessage id="No" defaultMessage="No" />
            </Button>
          </Box>
        </Box>
      </CustomModal>
    </div>
  );
};
