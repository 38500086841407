import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    '& svg': {
      marginRight: '1.5rem',
      color: theme.palette.grey[600],
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary
      }
    }
  },
  modalFooter: {
    width: '100%',
    marginTop: 'auto',
    marginLeft: 'auto',
    background: '#fff',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem 3rem',
    height: '4.9rem'
  },
  modalFooterButtons: {
    fontFamily: 'Euclid',
    ':&>*': {
      margin: '0 1rem'
    }
  },
  modalFooterCancel: {
    '&:hover': {
      background: '#ddd'
    }
  },
  contentWrap: {
    padding: '0rem 1.5rem'
  },
  rootTab: {
    background: '#fff',
    padding: '0 3.5rem',
    minHeight: '3.8rem',
    '& button': {
      minHeight: '3.8rem',
      fontSize: '1.2rem',
      textTransform: 'capitalize',
      fontWeight: 600,
      padding: '0',
      marginRight: '5rem',
      letterSpacing: '0.6px'
    }
  },
  viewRootTab: {
    background: '#fff',
    padding: '0 3.5rem',
    minHeight: '3.8rem',
    '& button': {
      minHeight: '3.8rem',
      fontSize: '1.2rem',
      textTransform: 'capitalize',
      fontWeight: 600,
      padding: '0',
      marginRight: '2rem',
      letterSpacing: '0.6px'
    }
  },
  fullname: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  departmentBtn: {
    marginTop: '2rem',
    '& button': {
      padding: '0.7rem 0',
      minWidth: '4rem'
    }
  }
}));

export default useStyles;
