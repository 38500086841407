import { Box, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import * as locales from '@mui/material/locale';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  getAllAgreementType,
  getInsuranceCode,
  getOccupationCode,
  getTaxRate,
  getTerminationData
} from 'components/locals/contract/redux/actions';
import { getDepartment } from 'components/locals/department/redux/actions';
import { getEmployee, getNHF, getTaxOffice } from 'components/locals/workers/redux/actions';
import 'dayjs/locale/en';
import 'dayjs/locale/pl';
import 'index.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  checkAccounting,
  fetchLanguage,
  getCompanyId,
  setCompanyId,
  setGlobalUser
} from 'redux/auth/actions';
import Store from 'redux/store';
import RouteList from 'routes';
import theme from 'themes';
import { isLoggedIn } from 'utils';

import { getHolidayAddons } from 'components/locals/workers/components/timeRecording/redux/actions';
// import { streams } from 'helpers';

if (localStorage.getItem('HRM_USER')) {
  Store.dispatch(setGlobalUser());
}

export default function App() {
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const { language, companyId } = useSelector((state) => state.auth);
  const localCompanyId = getCompanyId();

  useEffect(() => {
    if (isLoggedIn() && companyId) {
      dispatch(fetchLanguage());
      dispatch(getDepartment());
      dispatch(getEmployee());
      dispatch(getTaxOffice());
      dispatch(getNHF());
      dispatch(getOccupationCode());
      dispatch(getInsuranceCode());
      dispatch(getAllAgreementType());
      dispatch(getTerminationData());
      dispatch(getTaxRate());
      dispatch(getHolidayAddons());
    }
  }, [dispatch, isLoggedIn(), companyId]);

  useEffect(() => {
    if (isLoggedIn() && companyId) {
      dispatch(getAllAgreementType());
    }
  }, [language, companyId]);

  useEffect(() => {
    fetch('./translations.json')
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        setData(data);
      })
      .catch(function (err) {
        console.log(err, ' error');
      });
    if (localCompanyId) {
      dispatch(setCompanyId(JSON.parse(localCompanyId)));
    }
    const accounting = localStorage.getItem('isAccounting');
    dispatch(checkAccounting(JSON.parse(accounting)));
  }, []);

  const getLanguage = useCallback(() => {
    if (language === 'pl') {
      return 'plPL';
    } else {
      return 'enUS';
    }
  }, [language]);

  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[getLanguage()]),
    [language, theme]
  );
  // useEffect(() => {
  //   // streams();
  //   localStorage.removeItem('Temp_companyId');
  //   if (window.name.length > 0) {
  //     localStorage.setItem('NewCompany', window.name);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (window.name !== 'Initiator' && window.name) {
  //     const companyId = company?.find((item) => item.id == window.name);
  //     companyId && dispatch(setCompanyId(companyId));
  //     companyId && localStorage.setItem('companyId', JSON.stringify(companyId));
  //   }
  // }, [company]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language}>
      <ThemeProvider theme={themeWithLocale}>
        <CssBaseline />
        <IntlProvider locale={language} messages={data?.[language]}>
          <div>
            <RouteList />
            <ToastContainer autoClose={4000} limit={3} draggablePercent={50} />
            <Box display="none">
              <FormattedMessage id="January" defaultMessage="January" />
              <FormattedMessage id="February" defaultMessage="February" />
              <FormattedMessage id="March" defaultMessage="March" />
              <FormattedMessage id="April" defaultMessage="April" />
              <FormattedMessage id="May" defaultMessage="May" />
              <FormattedMessage id="June" defaultMessage="June" />
              <FormattedMessage id="July" defaultMessage="July" />
              <FormattedMessage id="August" defaultMessage="August" />
              <FormattedMessage id="September" defaultMessage="September" />
              <FormattedMessage id="October" defaultMessage="October" />
              <FormattedMessage id="November" defaultMessage="November" />
              <FormattedMessage id="December" defaultMessage="December" />
            </Box>
          </div>
        </IntlProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
