import React from 'react';
import DashboardLayout from 'layouts/dashboardLayout';
import SetupPage from 'components/locals/setup';

const Setup = () => {
  return (
    <DashboardLayout>
      <SetupPage />
    </DashboardLayout>
  );
};

export default Setup;
