import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  topBar: {
    background: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px',
    borderRadius: '3px',

    '& svg': {
      fontSize: '30px'
    }
  },
  cpTitle: {
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'flex-start'
  },
  month: {
    fontSize: '14px',
    marginRight: '5px'
  },
  year: {
    fontSize: '14px'
  },
  table: {
    borderCollapse: 'collapse',
    border: '1px solid #eeeeee',
    width: '100%',
    '& thead': {
      background: '#d7d8da',
      fontSize: '13px',
      fontWeight: 400,
      color: '#535151',
      letterSpacing: '0.8px'
    },
    '& th': {
      padding: '10px',
      border: '1px solid #eeeeee',
      width: '100px'
    },
    '& td': {
      height: '100px',
      background: '#fff',
      textAlign: 'center',
      fontSize: '26px',
      border: '1px solid #eeeeee'
    }
  },
  date: {
    fontSize: '32px !important',
    position: 'relative',
    color: '#656565',
    '&.holiday': {
      color: '#f45f5f',
      fontWeight: 600,
      fontSize: '36px !important'
    }
  },
  eventWrap: {
    position: 'absolute',
    top: '10px',
    right: '15px',
    fontSize: '18px',
    cursor: 'pointer',
    '& svg': {
      fontSize: '20px'
    }
  },
  deleteEventWrap: {
    position: 'absolute',
    bottom: '0px',
    right: '15px',
    fontSize: '18px',
    cursor: 'pointer',
    color: 'red',
    '& svg': {
      fontSize: '20px'
    }
  },
  cardWrapper: {
    borderRadius: '0px !important',
    boxShadow: '-1px 1px 7px 0px rgb(208 200 200 / 20%) !important',
    padding: '0rem',
    '& .title': {
      fontSize: '15px',
      fontWeight: '600',
      color: theme.palette.primary.dark,
      padding: '14px 32px 12px'
    },
    '& .MuiCardContent-root': {
      padding: '0px !important'
    },
    '& .formBodyWrapper': {
      padding: '14px 35px 31px'
    },
    '& .buttonWrapper': {
      backgroundColor: theme.palette.lightGrey[1],
      padding: '13px 20px 13px',
      display: 'flex',
      justifyContent: 'flex-end',
      columnGap: '2rem',
      '& .saveButton': {
        background: theme.palette.primary.normal,
        borderRadius: '2px',
        color: '#fff',
        fontSize: '13px',
        height: '30px'
      },
      '& .cancelButton': {
        border: '1px solid #9D9CAF',
        borderRadius: '2px',
        color: '#383751',
        height: '30px',
        fontSize: '13px'
      }
    }
  }
}));
