import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { HookFormCheckbox } from 'components/form/hookFormCheckbox';
import { HookFormInput } from 'components/form/hookFormInput';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import Logo from './Logo';

function Registration({ company, type }) {
  const { setValue } = useFormContext();
  useEffect(() => {
    console.log(company, 'company');
    if (company) {
      if (Object.keys(company)?.length > 0) {
        setValue('addresses[0]', company?.addresses[0]);
        setValue('addresses[1]', company?.addresses[1]);
        setValue('name', company?.name);
        setValue('short_name', company?.short_name);
        setValue('tax_id', company?.tax_id);
        setValue('regon', company?.regon);
        setValue('bdo', company?.bdo);
        setValue('pkd', company?.pkd);
        setValue('krs', company?.krs);
        setValue('ceidg', company?.ceidg);
        setValue('is_small_company', company?.is_small_company);
        setValue('phone_number', company?.phone_number);
        if (type == 'account') {
          setValue('email', company?.email);
        } else {
          setValue('company_detail_email', company?.company_detail?.[0]?.value);
        }
      }
    }
  }, [company]);
  return (
    <>
      <Box>
        <Box sx={{ borderLeft: '3px solid #4C4B63' }}>
          <Typography className="title">Profile</Typography>
        </Box>
        <Box className="formBodyWrapper">
          <Grid container spacing={3}>
            <Grid item sm={8}>
              <Grid container spacing={3}>
                <Grid item sm={6}>
                  <HookFormInput
                    required={true}
                    name="name"
                    label={<FormattedMessage id="Company Name" defaultMessage="Company Name" />}
                    placeholder="Scodus Innovations"
                  />
                </Grid>
                <Grid item sm={6}>
                  <HookFormInput
                    required={true}
                    name="short_name"
                    label={<FormattedMessage id="Short Name" defaultMessage="Short Name" />}
                    placeholder="Scodus"
                  />
                </Grid>
                <Grid item sm={6}>
                  {type == 'account' ? (
                    <HookFormInput
                      required={true}
                      name="email"
                      label={<FormattedMessage id="Email" defaultMessage="Email" />}
                      type="email"
                      placeholder="sarao@gmail.com"
                    />
                  ) : (
                    <HookFormInput
                      required={true}
                      name="company_detail_email"
                      label={<FormattedMessage id="Email" defaultMessage="Email" />}
                      type="email"
                      placeholder="sarao@gmail.com"
                    />
                  )}
                </Grid>
                <Grid item sm={6}>
                  <HookFormInput
                    required={true}
                    name="tax_id"
                    label={<FormattedMessage id="Tax Id" defaultMessage="Tax Id" />}
                    type="number"
                    placeholder="848-095-1234"
                  />
                </Grid>
                <Grid item sm={6}>
                  <HookFormInput
                    name="regon"
                    required={true}
                    label={<FormattedMessage id="Reg. No" defaultMessage="Reg. No" />}
                    placeholder="148-225-1354"
                    tooltipLabel={
                      <FormattedMessage
                        id="Unique number-9 digits"
                        defaultMessage="Unique nuber-9 digits"
                      />
                    }
                  />
                </Grid>

                <Grid item sm={6}>
                  <HookFormInput
                    name="krs"
                    label={<FormattedMessage id="KRS" defaultMessage="KRS" />}
                    placeholder="848-095-1234"
                    required
                    tooltipLabel={
                      <FormattedMessage
                        id="Unique String Nullable"
                        defaultMessage="Unique String Nullable"
                      />
                    }
                  />
                </Grid>
                <Grid item sm={6}>
                  <HookFormInput
                    type="number"
                    name="phone_number"
                    label={<FormattedMessage id="Phone Number" defaultMessage="Phone Number" />}
                    required={true}
                    placeholder="74747843"
                  />
                </Grid>
                <Grid item sm={6}>
                  <HookFormInput
                    name="bdo"
                    label={<FormattedMessage id="BDO" defaultMessage="BDO" />}
                    placeholder="848-095-1234"
                    tooltipLabel={
                      <FormattedMessage
                        id="Unique String Nullable"
                        defaultMessage="Unique String Nullable"
                      />
                    }
                  />
                </Grid>

                <Grid item sm={6}>
                  <HookFormInput
                    name="pkd"
                    label={<FormattedMessage id="PKD" defaultMessage="PKD" />}
                    placeholder="848-095-1234"
                    tooltipLabel={
                      <FormattedMessage
                        id="Unique String Nullable"
                        defaultMessage="Unique String Nullable"
                      />
                    }
                  />
                </Grid>
                {type !== 'account' && (
                  <Grid item sm={6}>
                    <HookFormInput
                      name="ceidg"
                      label={<FormattedMessage id="CEIDG" defaultMessage="CEIDG" />}
                      placeholder="848-095-1234"
                      tooltipLabel={
                        <FormattedMessage
                          id="Unique String Nullable"
                          defaultMessage="Unique String Nullable"
                        />
                      }
                    />
                  </Grid>
                )}
                {type !== 'account' && (
                  <Grid item sm={6}>
                    <Box marginTop={1.5} marginLeft={2}>
                      <HookFormCheckbox
                        name="is_small_company"
                        label={
                          <FormattedMessage id="Small Company" defaultMessage="Small Company" />
                        }
                        tooltipLabel={
                          <FormattedMessage
                            id="A contributor reporting no more than 20 insured persons to sickness insurance"
                            defaultMessage="A contributor reporting no more than 20 insured persons to sickness insurance"
                          />
                        }
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item sm={4}>
              <Logo company={company} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default Registration;
