export const ADD_BULK_CLOSE_PAY_LIST_BEGIN = 'ADD_BULK_CLOSE_PAY_LIST_BEGIN';
export const ADD_BULK_CLOSE_PAY_LIST_SUCCESS = 'ADD_BULK_CLOSE_PAY_LIST_SUCCESS';
export const ADD_BULK_CLOSE_PAY_LIST_ERROR = 'ADD_BULK_CLOSE_PAY_LIST_ERROR';

export const ADD_BULK_SEND_PAYOUT_BEGIN = 'ADD_BULK_SEND_PAYOUT_BEGIN';
export const ADD_BULK_SEND_PAYOUT_SUCCESS = 'ADD_BULK_SEND_PAYOUT_SUCCESS';
export const ADD_BULK_SEND_PAYOUT_ERROR = 'ADD_BULK_SEND_PAYOUT_ERROR';

export const ADD_BULK_SEND_MAIL_BEGIN = 'ADD_BULK_SEND_MAIL_BEGIN';
export const ADD_BULK_SEND_MAIL_SUCCESS = 'ADD_BULK_SEND_MAIL_SUCCESS';
export const ADD_BULK_SEND_MAIL_ERROR = 'ADD_BULK_SEND_MAIL_ERROR';

export const ADD_ALL_BULK_ACTION_BEGIN = 'ADD_ALL_BULK_ACTION_BEGIN';
export const ADD_ALL_BULK_ACTION_SUCCESS = 'ADD_ALL_BULK_ACTION_SUCCESS';
export const ADD_ALL_BULK_ACTION_ERROR = 'ADD_ALL_BULK_ACTION_ERROR';

export const GET_COMPANY_LIST_BEGIN = 'GET_COMPANY_LIST_BEGIN';
export const GET_COMPANY_LIST_SUCCESS = 'GET_COMPANY_LIST_SUCCESS';
export const GET_COMPANY_LIST_ERROR = 'GET_COMPANY_LIST_ERROR';

export const ADD_COMPANY_DETAIL_SUCCESS = 'ADD_COMPANY_DETAIL_SUCCESS';

export const ADD_COMPANY_FILTER = 'ADD_ITEM_FILTER';
