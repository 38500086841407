import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import useStyles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { addFilter } from '../redux/actions';

function EmployeeSelect({ handleEmployeeId, customError, setCustomError, employeeId }) {
  const classes = useStyles();
  const { employees } = useSelector((state) => state.employee);

  const handleChange = (data) => {
    handleEmployeeId(data);
  };
  const dispatch = useDispatch();

  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        className={classes.employeeSearch}
        options={
          employees?.map((item) => ({
            label: item?.last_name ? item?.last_name + ' ' + item?.first_name : '',
            value: item?.id
          })) || []
        }
        sx={{ width: 300 }}
        isOptionEqualToValue={(option) => option.value}
        getOptionLabel={(option) => option.label}
        onChange={(e, data) => {
          dispatch(addFilter({ key: 'employeeId', value: data?.value }));
          handleChange(data?.value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={<FormattedMessage id="Employee" defaultMessage="Employee" />}
            InputLabelProps={{ shrink: true }}
            value={employeeId}
            error={Boolean(customError)}
            helperText={customError}
          />
        )}
      />
    </>
  );
}

export default EmployeeSelect;
