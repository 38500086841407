import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  fileWrapper: {
    width: '110px',
    height: '110px',
    padding: '0.5rem',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    flexDirection: 'column',
    flexWrap: 'wrap',
    display: 'inline-flex',
    position: 'relative',
    '&:hover': {
      background: '#edeef1'
    },
    '& img': {
      color: theme.palette.grey[600],
      cursor: 'pointer',
      height: '40px',
      width: '40px',
      '&:hover': {
        color: theme.palette.primary
      }
    },
    '& .options': {
      position: 'absolute',
      top: '0',
      right: '0',
      display: 'none'
    },
    '&:hover .options': {
      display: 'block'
    }
  },
  item: {
    fontSize: '1.4rem',
    display: 'flex',
    '& .label': {
      width: '8rem'
    }
  },
  name: {
    fontSize: '1.3rem',
    width: '100px',
    height: '30px',
    overflow: 'hidden',
    lineHeight: '1.6rem',
    textAlign: 'center',
    marginTop: '1rem',
    cursor: 'pointer'
  },
  inputWrap: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',

    '& input': {
      width: '100%',
      fontSize: '1.2rem',
      border: '1px solid #e0e0e0',
      padding: '2px 5px'
    }
  },
  save: {
    border: 'none',
    fontSize: '1.1rem',
    padding: '2px 3px',
    marginTop: '0.5rem',
    background: theme.palette.success.main,
    color: theme.palette.common.white,
    borderRadius: '3px',
    cursor: 'pointer',
    width: '3rem',
    margin: '0 auto'
  },
  cancel: {
    border: 'none',
    fontSize: '1.1rem',
    padding: '3px 3px 2px 3px',
    marginTop: '0.5rem',
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    borderRadius: '3px',
    cursor: 'pointer',
    width: '3rem',
    margin: '0 auto'
  },
  breadcrumbItem: {
    fontSize: '12px !important',
    color: '#4E8AF4',
    textDecoration: 'none'
  },
  breadcrumbItemActive: {
    fontSize: '13px !important',
    color: theme.palette.grey[6],
    textDecoration: 'none',
    cursor: 'pointer'
  },
  modalFooter: {
    width: '100%',
    marginTop: 'auto',
    marginLeft: 'auto',
    background: '#fff',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem 3rem',
    height: '4.9rem'
  },
  modalFooterButtons: {
    fontFamily: 'Euclid',
    ':&>*': {
      margin: '0 1rem'
    }
  },
  modalFooterCancel: {
    '&:hover': {
      background: '#ddd'
    }
  },
  buttonWrap: {
    '& button': {
      marginLeft: '2rem'
    },
    '& svg': {
      marginRight: '0.5rem'
    }
  },
  input: {
    fontSize: '1.2rem',
    border: '1px solid #e0e0e0',
    padding: '6px 5px',
    minWidth: '100%'
  },
  createWrap: {
    margin: '3rem',
    marginTop: '5rem',
    textAlign: 'center',

    '& .label': {
      fontSize: '1.4rem'
    }
  },
  noFilesWrap: {
    minHeight: '60vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  fileImage: {
    height: '100px',
    width: '100px',
    overflow: 'hidden',
    padding: '1rem',
    borderRadius: '50%',
    background: '#d5dae5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '2rem',
    '& img': {
      objectFit: 'contain',
      margin: '2rem'
    }
  },
  fileTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold'
  },
  instruction: {
    fontSize: '1.2rem',
    color: theme.palette.grey[500]
  },
  documentPanel: {
    position: 'relative',
    minHeight: '80vh'
  },
  loader: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'radial-gradient(#2b74f517,#a2c4ff0d, transparent)'
  }
}));

export default useStyles;
