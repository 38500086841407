import { Grid } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { translate } from 'utils';
import { changeDateFormat } from 'utils/dateUtils';
import useStyles from './styles';

function Header({ data }) {
  const classes = useStyles();
  const { companyId } = useSelector((state) => state.auth);
  const address = companyId?.addresses?.[0];

  const months = [
    { label: translate('January'), value: 1 },
    { label: translate('February'), value: 2 },
    { label: translate('March'), value: 3 },
    { label: translate('April'), value: 4 },
    { label: translate('May'), value: 5 },
    { label: translate('June'), value: 6 },
    { label: translate('July'), value: 7 },
    { label: translate('August'), value: 8 },
    { label: translate('September'), value: 9 },
    { label: translate('October'), value: 10 },
    { label: translate('November'), value: 11 },
    { label: translate('December'), value: 12 }
  ];
  const getPayrollDate = () => {
    const month = changeDateFormat(data?.closing_date, 'MM');
    const filterMonth = months?.find((list) => list?.value == month);
    return filterMonth?.label ?? '';
  };
  return (
    <div className={classes.header}>
      <h1 style={{ marginTop: '10px' }}>
        <FormattedMessage id="Collective Payslip" defaultMessage="Collective Payslip" />
      </h1>
      <h2>{companyId?.name}</h2>
      <h3>
        {address?.street} {address?.city} {address?.county} {address?.country}, {address?.post}
      </h3>

      <Grid container spacing={3}>
        <Grid item sm={2}></Grid>
        <Grid item sm={3}>
          <ul>
            <ViewList
              label={<FormattedMessage id="Payroll number" defaultMessage="Payroll number" />}
              value={data?.paylist_code}
            />
            <ViewList
              label={<FormattedMessage id="Pay date" defaultMessage="Pay date" />}
              value={data?.payment_date}
            />
          </ul>
        </Grid>
        <Grid item sm={2}></Grid>
        <Grid item sm={3}>
          <ul>
            <ViewList
              label={
                <FormattedMessage
                  id="Pay slip for the month"
                  defaultMessage="Pay slip for the month"
                />
              }
              value={getPayrollDate()}
            />
            <ViewList
              label={
                <FormattedMessage id="Title of the Payroll" defaultMessage="Title of the Payroll" />
              }
              value=""
            />
          </ul>
        </Grid>
      </Grid>
    </div>
  );
}

export default Header;

const ViewList = ({ label, value }) => {
  const classes = useStyles();
  return (
    <li>
      <div className={classes.title}>{label}</div>
      <div className={classes.value}>{value}</div>
    </li>
  );
};
