import Login from 'pages/login';
import Forgot from 'pages/forgot';
import ResetPassword from 'pages/reset';
import VerifyEmail from 'pages/verify';

const AuthRoutes = [
  {
    path: '/login',
    component: <Login />
  },
  { path: '/forgot-password', component: <Forgot /> },
  { path: '/reset-password', component: <ResetPassword /> },
  { path: '/verify-mail', component: <VerifyEmail /> }
];

export default AuthRoutes;
