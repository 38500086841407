import * as actions from './types';
import { errorToast, successToast } from 'utils/toast';
import {
  createParameterApi,
  deleteParameterApi,
  editParameterApi,
  getAdminParameterApi,
  getParameterApi,
  getParameterByIdApi,
  getParameterByNameApi
} from 'apis/parameter';

// create Parameter
export const upsertParameter = (data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.CREATE_PARAMETER_BEGIN
  });
  createParameterApi({ parameter: [data] })
    .then((res) => {
      dispatch({
        type: actions.CREATE_PARAMETER_SUCCESS
      });
      handleFunction && handleFunction();
      dispatch(getParameterByName(data?.par_name));
      dispatch(getParameter());
      successToast('Parameter created Successfully');
    })
    .catch((error) => {
      dispatch({
        type: actions.CREATE_PARAMETER_ERROR
      });
      errorToast(error);
    });
};

// get all parameter
export const getParameter = () => (dispatch) => {
  dispatch({
    type: actions.GET_PARAMETER_BEGIN
  });
  getParameterApi()
    .then((res) => {
      dispatch({
        type: actions.GET_PARAMETER_SUCCESS,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_PARAMETER_ERROR
      });
      errorToast(error);
    });
};

//get paramters created by super-admin
export const getAdminParameters = () => (dispatch) => {
  dispatch({
    type: actions.GET_ADMINPARAMETER_BEGIN
  });
  getAdminParameterApi()
    .then((res) => {
      dispatch({
        type: actions.GET_ADMINPARAMETER_SUCCESS,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_ADMINPARAMETER_ERROR
      });
      errorToast(error);
    });
};

export const getParameterById = (id) => (dispatch) => {
  dispatch({
    type: actions.GET_SINGLE_PARAMETER_BEGIN
  });
  getParameterByIdApi(id)
    .then((res) => {
      dispatch({
        type: actions.GET_SINGLE_PARAMETER_SUCCESS,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_SINGLE_PARAMETER_ERROR
      });
      errorToast(error);
    });
};

// get single parameter type for multiple dates
export const getParameterByName = (name) => (dispatch) => {
  dispatch({
    type: actions.GET_PARAMETER_BYNAME_BEGIN
  });
  getParameterByNameApi(name)
    .then((res) => {
      dispatch({
        type: actions.GET_PARAMETER_BYNAME_SUCCESS,
        payload: res.data?.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_PARAMETER_BYNAME_ERROR
      });
      errorToast(error);
    });
};

// edit parameter
export const editParameter = (id, data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.EDIT_PARAMETER_BEGIN
  });
  editParameterApi(id, data)
    .then((res) => {
      dispatch({
        type: actions.EDIT_PARAMETER_SUCCESS
      });
      handleFunction && handleFunction();
      successToast('Parameter edited Successfully');
      dispatch(getParameter());
    })
    .catch((error) => {
      dispatch({
        type: actions.EDIT_PARAMETER_ERROR
      });
      errorToast(error);
    });
};

// change parameter status
export const deleteParameter = (data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.CHANGE_PARAMETER_BEGIN
  });
  deleteParameterApi(data.id)
    .then((res) => {
      dispatch({
        type: actions.CHANGE_PARAMETER_SUCCESS
      });
      handleFunction && handleFunction();
      dispatch(getParameterByName(data?.name));
      dispatch(getParameter());
      successToast('Parameter deleted Successfully');
    })
    .catch((error) => {
      dispatch({
        type: actions.CHANGE_PARAMETER_ERROR
      });
      errorToast(error);
    });
};

export const clearSingleParameter = () => (dispatch) => {
  dispatch({
    type: actions.CLEAR_SINGLE_PARAMETER
  });
};

export const clearParameterByName = () => (dispatch) => {
  dispatch({
    type: actions.CLEAR_PARAMETER_BYNAME
  });
};
