import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import HookFormAutoComplete from 'components/form/hookFormAutoComplete';
import { HookFormInput } from 'components/form/hookFormInput';
import { FormattedMessage } from 'react-intl';
import { translate } from 'utils';
import { changeDateFormat } from 'utils/dateUtils';

function DetailedInfo() {
  return (
    <>
      <Box display="none">
        <FormattedMessage id="Passport" defaultMessage="Passport" />
        <FormattedMessage id="Citizenship" defaultMessage="Citizenship" />
        <FormattedMessage id="Driving License" defaultMessage="Driving License" />
      </Box>
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <HookFormInput
            name="employee_detail[family_name]"
            label={<FormattedMessage id="Family Name" defaultMessage="Family Name" />}
            placeholder="Simmons"
          />
        </Grid>
        {/* <Grid item sm={4}>
          <HookFormInput
            name="employee_detail[father_name]"
            label={<FormattedMessage id="Father's name" defaultMessage="Father's name" />}
            placeholder="Brooklyn Simsons"
          />
        </Grid>
        <Grid item sm={4}>
          <HookFormInput
            name="employee_detail[mother_name]"
            label={<FormattedMessage id="Mother's Name" defaultMessage="Mother's Name" />}
            placeholder="Jane Simmons"
          />
        </Grid> */}
        <Grid item sm={4}>
          <HookFormInput
            name="employee_detail[citizenship]"
            label={<FormattedMessage id="Citizenship" defaultMessage="Citizenship" />}
            placeholder="Polish"
          />
        </Grid>
        <Grid item sm={4}>
          <HookFormInput
            name="employee_detail[pob]"
            label={<FormattedMessage id="Birthplace" defaultMessage="Birthplace" />}
            placeholder="Napervilla"
          />
        </Grid>
        <Grid item sm={4}>
          <HookFormInput
            name="employee_detail[date_of_birth]"
            label={<FormattedMessage id="Date of birth" defaultMessage="Date of birth" />}
            placeholder="10/28/12"
            type="date"
            inputProps={{ max: changeDateFormat(new Date(), 'YYYY-MM-DD') }}
          />
        </Grid>
        <Grid item sm={4}>
          <HookFormInput
            name="employee_detail[id_number]"
            label={<FormattedMessage id="Id Number" defaultMessage="Id Number" />}
            placeholder="1232344545"
          />
        </Grid>
        <Grid item sm={4}></Grid>
        <Grid item sm={4}>
          <HookFormAutoComplete
            name="employee_detail.identification_type"
            label={
              <FormattedMessage
                id="Another type of identification"
                defaultMessage="Another type of identification"
              />
            }
            placeholder="passport"
            tooltipLabel={
              <FormattedMessage
                id="Any Verified Identification"
                defaultMessage="Any Verified Identification"
              />
            }
            options={[
              {
                label: translate('Passport'),
                value: 'passport'
              },
              {
                label: translate('Citizenship'),
                value: 'citizenship'
              },
              {
                label: translate('Driving License'),
                value: 'driving_license'
              }
            ]}
          />
        </Grid>

        <Grid item sm={4}>
          <HookFormInput
            name="employee_detail.identification_number"
            label={
              <FormattedMessage id="Identification Number" defaultMessage="Identification Number" />
            }
            placeholder="1232344545"
          />
        </Grid>
        <Grid item sm={4}>
          <HookFormInput
            name="employee_detail[state_issued_document]"
            label={
              <FormattedMessage id="State Issued Document" defaultMessage="State Issued Document" />
            }
            placeholder=""
            tooltipLabel={
              <FormattedMessage id="State Issued Document" defaultMessage="State Issued Document" />
            }
          />
        </Grid>

        {/* <Grid item sm={4}>
          <Grid container spacing={1}>
            <Grid item sm={10}>
              <HookFormAutoComplete
                name="department_id"
                label={<FormattedMessage id="Department of" defaultMessage="Department of" />}
                placeholder="IT"
                tooltipLabel={
                  <FormattedMessage id="Add Department" defaultMessage="Add Department" />
                }
                options={departments?.map((item) => ({
                  label: item.title,
                  value: item?.id
                }))}
              />
            </Grid>
            <Grid item sm={2}>
              <Box className={classes.departmentBtn} onClick={openFunction}>
                <Tooltip
                  title={
                    <Typography variant="body1">
                      <FormattedMessage id="Add Department" defaultMessage="Add Department" />
                    </Typography>
                  }
                  placement="top">
                  <Button variant="outlined" color="btnColor">
                    <AddIcon />
                  </Button>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
      {/* <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="50rem"
        headerText="Add Department">
        <DepartmentView handleClose={openFunction} />
      </CustomModal> */}
    </>
  );
}

export default DetailedInfo;
