import {
  changeEmployeeStatusApi,
  createEmployeeApi,
  editEmployeeApi,
  getEmployeeApi,
  getEmployeeByIdApi,
  getNHFApi,
  getPayoutByEmployeeIdApi,
  getTaxOfficeApi,
  uploadEmployeeListApi
} from 'apis/employee';
import { errorToast, successToast } from 'utils/toast';
import * as actions from './types';

// create Employee
export const createEmployee = (data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.CREATE_EMPLOYEE_BEGIN
  });
  createEmployeeApi(data)
    .then((res) => {
      dispatch({
        type: actions.CREATE_EMPLOYEE_SUCCESS
      });
      handleFunction && handleFunction();
      dispatch(getPaginatedEmployees(0));
      dispatch(getEmployee());
      successToast('Employee created Successfully');
    })
    .catch((error) => {
      dispatch({
        type: actions.CREATE_EMPLOYEE_ERROR
      });
      errorToast(error);
    });
};

// get all employee
export const getEmployee = () => (dispatch) => {
  dispatch({
    type: actions.GET_EMPLOYEE_BEGIN
  });
  getEmployeeApi()
    .then((res) => {
      dispatch({
        type: actions.GET_EMPLOYEE_SUCCESS,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_EMPLOYEE_ERROR
      });
      errorToast(error);
    });
};

// get paginated employees
export const getPaginatedEmployees = (parameter) => (dispatch) => {
  dispatch({
    type: actions.GET_EMPLOYEE_PAGINATED_BEGIN
  });
  getEmployeeApi(parameter)
    .then((res) => {
      dispatch({
        type: actions.GET_EMPLOYEE_PAGINATED_SUCCESS,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_EMPLOYEE_PAGINATED_ERROR
      });
      errorToast(error);
    });
};

// get single employee
export const getEmployeeById = (id) => (dispatch) => {
  dispatch({
    type: actions.GET_SINGLE_EMPLOYEE_BEGIN
  });
  getEmployeeByIdApi(id)
    .then((res) => {
      dispatch({
        type: actions.GET_SINGLE_EMPLOYEE_SUCCESS,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_SINGLE_EMPLOYEE_ERROR
      });
      errorToast(error);
    });
};

// edit employee
export const editEmployee = (id, data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.EDIT_EMPLOYEE_BEGIN
  });
  editEmployeeApi(id, data)
    .then((res) => {
      dispatch({
        type: actions.EDIT_EMPLOYEE_SUCCESS
      });
      handleFunction && handleFunction();
      successToast('Employee edited Successfully');
      dispatch(getPaginatedEmployees(0));
      dispatch(getEmployee());
    })
    .catch((error) => {
      dispatch({
        type: actions.EDIT_EMPLOYEE_ERROR
      });
      errorToast(error);
    });
};

// change employee status
export const changeEmployeeStatus = (id, data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.CHANGE_EMPLOYEE_BEGIN
  });
  changeEmployeeStatusApi(id)
    .then((res) => {
      dispatch({
        type: actions.CHANGE_EMPLOYEE_SUCCESS
      });
      handleFunction && handleFunction();
      successToast('Employee status changed Successfully');
    })
    .catch((error) => {
      dispatch({
        type: actions.CHANGE_EMPLOYEE_ERROR
      });
      errorToast(error);
    });
};

export const clearSingleEmployee = () => (dispatch) => {
  dispatch({
    type: actions.CLEAR_SINGLE_EMPLOYEE
  });
};

// get all tax offices
export const getTaxOffice = () => (dispatch) => {
  dispatch({
    type: actions.GET_TAX_OFFICE_BEGIN
  });
  getTaxOfficeApi()
    .then((res) => {
      dispatch({
        type: actions.GET_TAX_OFFICE_SUCCESS,
        payload: res.data.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_TAX_OFFICE_ERROR
      });
      errorToast(error);
    });
};

// get NHF
export const getNHF = () => (dispatch) => {
  dispatch({
    type: actions.GET_NHF_BEGIN
  });
  getNHFApi()
    .then((res) => {
      dispatch({
        type: actions.GET_NHF_SUCCESS,
        payload: res.data.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_NHF_ERROR
      });
      errorToast(error);
    });
};

// get payout by employee id
export const getPayoutByEmployeeId = (employeeId) => (dispatch) => {
  dispatch({
    type: actions.GET_PAYOUT_BEGIN
  });
  getPayoutByEmployeeIdApi(employeeId)
    .then((res) => {
      dispatch({
        type: actions.GET_PAYOUT_SUCCESS,
        payload: res.data?.data?.payouts
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_PAYOUT_ERROR
      });
      errorToast(error);
    });
};

// upload employee list
export const uploadEmployeeList = (data, handleSuccess) => (dispatch) => {
  dispatch({ type: actions.UPLOAD_EMPLOYEE_LIST_BEGIN });
  uploadEmployeeListApi(data)
    .then((res) => {
      dispatch({ type: actions.UPLOAD_EMPLOYEE_LIST_SUCCESS });
      dispatch(getPaginatedEmployees(0));
      handleSuccess && handleSuccess();
    })
    .catch((error) => {
      dispatch({ type: actions.UPLOAD_EMPLOYEE_LIST_ERROR });
      errorToast(error);
    });
};
