import { axiosInstance } from 'apis/_axios';

// create overtime
export const createOvertimeApi = (data) => {
  return axiosInstance().post('/actions/overtime-amount', data);
};

// get all overtime
export const getOvertimeApi = (employee_id) => {
  return axiosInstance().get(`/actions/overtime-amount?employee_id=${employee_id}`);
};

// edit overtime
export const editOvertimeApi = (id, data) => {
  return axiosInstance().put(`/actions/overtime-amount/${id}`, data);
};

// delete overtime
export const deleteOvertimeApi = (id) => {
  return axiosInstance().delete(`/actions/overtime-amount/${id}`);
};

// get overtime calculated amount
export const getOvertimeAmountApi = (data) => {
  return axiosInstance().get('/actions/overtime-amount-calulate', { params: data });
};
