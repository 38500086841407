import { MoreVertOutlined } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Button, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import filePreview from 'utils/filePreview';
import document from '../../../../assets/images/document.svg';
import file from '../../../../assets/images/file.svg';
import image from '../../../../assets/images/image.svg';
import pdf from '../../../../assets/images/pdf.svg';
import useStyles from '../styles';

function FileWrapper({ name, id, handleUrl, type = '', fileType = 'folder', extension, url }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteModalOpen, openFunctionDelete] = useToggle(false);
  const open = Boolean(anchorEl);
  const [value, setValue] = useState('');
  const [edit, setEdit] = useState('');

  const checkFileImage = () => {
    if (fileType == 'file') {
      if (extension == 'pdf') {
        return pdf;
      } else if (
        extension == 'jpg' ||
        extension == 'svg' ||
        extension == 'png' ||
        extension == 'jpeg' ||
        extension == 'webp'
      ) {
        return image;
      } else {
        return document;
      }
    } else return file;
  };

  const handleActionButtonClick = (event, data) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick = () => {
    if (fileType == 'folder') {
      handleUrl({ name: name, id: id, type: type });
    } else {
      filePreview(id, extension);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClicked = () => {
    // onEdit(actionSelectedData);
    setEdit(true);
    setValue(name);
    handleClose();
  };

  const handleDownloadClicked = () => {
    handleClose();
    alert('Handle Download Clicked');
  };

  const handleDeleteClicked = () => {
    // onDelete(actionSelectedData);
    openFunctionDelete();
    handleClose();
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleCancel = () => {
    setValue('');
    setEdit(false);
  };

  const handleSave = () => {};

  const handleConfirmDelete = () => {};
  return (
    <div className={classes.fileWrapper}>
      <img
        src={checkFileImage()}
        alt="Employee File"
        className={classes.image}
        onClick={handleClick}
      />
      {edit ? (
        <div className={classes.inputWrap}>
          <input type="text" value={value} onChange={handleChange} />
          <Box display="flex" justiyContent="center">
            <button className={classes.save} onClick={handleSave}>
              <DoneIcon />
            </button>
            <button className={classes.cancel} onClick={handleCancel}>
              <CancelIcon />
            </button>
          </Box>
        </div>
      ) : (
        <div className={classes.name} onClick={handleClick}>
          {name}
        </div>
      )}
      <IconButton onClick={(event) => handleActionButtonClick(event, 'data')} className="options">
        <MoreVertOutlined />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <MenuItem onClick={handleEditClicked} className={classes.item}>
          <div className="label">
            <FormattedMessage id="Rename" defaultMessage="Rename" />
          </div>{' '}
          <EditIcon sx={{ color: '#4E8AF4' }} />
        </MenuItem>
        {fileType == 'file' && (
          <MenuItem onClick={handleDownloadClicked} className={classes.item}>
            <div className="label">
              <FormattedMessage id="Download" defaultMessage="Download" />
            </div>{' '}
            <FileDownloadIcon sx={{ color: '#4caf50' }} />
          </MenuItem>
        )}
        <MenuItem onClick={handleDeleteClicked} className={classes.item}>
          <div className="label">
            <FormattedMessage id="Delete" defaultMessage="Delete" />
          </div>{' '}
          <DeleteOutlineIcon sx={{ color: 'red' }} />
        </MenuItem>
      </Menu>
      <CustomModal
        open={deleteModalOpen}
        handleClose={openFunctionDelete}
        height="20rem"
        width="40rem"
        headerText="Delete Folder">
        <Box textAlign="center" marginTop={5} marginBottom={3}>
          <Typography variant="h5">
            <FormattedMessage
              id="Are you sure want to delete this Folder ?"
              defaultMessage="Are you sure want to delete this Folder ?"
            />
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-around">
          <Button variant={'contained'} onClick={handleConfirmDelete}>
            <FormattedMessage id="Yes" defaultMessage="Yes" />
          </Button>
          <Button
            className={classes.modalFooterCancel}
            variant={'outlined'}
            onClick={openFunctionDelete}>
            <FormattedMessage id="No" defaultMessage="No" />
          </Button>
        </Box>
      </CustomModal>
    </div>
  );
}

export default FileWrapper;
