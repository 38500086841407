import { Button, CircularProgress, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers';
import CustomModal from 'components/globals/modal';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from 'utils';
import { changeDateFormat } from 'utils/dateUtils';
import { updateHolidayBase } from './redux/actions';
import useStyles from './styles';

function AddHolidayBase({ handleClose, employeeId, modalOpen }) {
  const newDate = new Date();
  const [date, setDate] = useState(newDate);
  const [hours, setHours] = useState();
  const [value, setValue] = useState();
  const [agreementType, setAgreementType] = useState();
  const classes = useStyles();
  const { watch } = useFormContext();
  const type = watch('type');
  const startDate = watch('start_date');
  const dispatch = useDispatch();
  const { update_holiday_loading } = useSelector((state) => state.timeRecording);
  const { agreement_types } = useSelector((state) => state.contract);

  const handleSubmit = () => {
    if (type == 'sick_leave' || type == 'care_leave') {
      console.log(agreementType);
      if (date && value && agreementType) {
        const year = changeDateFormat(date.$d, 'YYYY');
        const month = changeDateFormat(date.$d, 'MM');
        const getDate = changeDateFormat(startDate, 'YYYY-MM-DD');
        dispatch(
          updateHolidayBase(
            {
              employee_id: employeeId,
              type: 'sick_leave',
              year: year,
              month: parseInt(month),
              value: value,
              agreement_type: agreementType
            },
            getDate,

            handleClose
          )
        );
      }
    }
    if (type == 'vacation_leave') {
      if (date && hours && value) {
        const year = changeDateFormat(date.$d, 'YYYY');
        const month = changeDateFormat(date.$d, 'MM');
        const getDate = changeDateFormat(startDate, 'YYYY-MM-DD');
        dispatch(
          updateHolidayBase(
            {
              employee_id: employeeId,
              type: type,
              year: year,
              month: parseInt(month),
              value: value,
              hours: hours
            },
            getDate,
            handleClose
          )
        );
      }
    }
  };
  const checkHeader = () => {
    if (type === 'sick_leave' || type === 'care_leave') {
      return translate('Add Sick Base');
    } else {
      return translate('Add Vacation Base');
    }
  };

  const handleAgreementTypeChange = (event) => {
    setAgreementType(event.target.value);
  };
  return (
    <>
      <Box display="none">
        <FormattedMessage id="Add Sick Base" defaultMessage="Add Sick Base" />
        <FormattedMessage id="Add Vacation Base" defaultMessage="Add Vacation Base" />
      </Box>
      <CustomModal
        open={modalOpen}
        handleClose={handleClose}
        height="auto"
        width="30rem"
        headerText={checkHeader()}>
        <Box marginX={4} marginY={4}>
          <DatePicker
            views={['year', 'month']}
            label={<FormattedMessage id="Date" defaultMessage="Date" />}
            value={date}
            onChange={(newValue) => {
              setDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} helperText={null} fullWidth />}
          />
          <Box
            display="flex"
            alignItems="center"
            columnGap={0.5}
            sx={{ paddingBottom: '4px', marginTop: '15px' }}>
            <InputLabel>
              <FormattedMessage id="Value" defaultMessage="Value" />
            </InputLabel>
          </Box>
          <TextField type="number" fullWidth onChange={(e) => setValue(e.target.value)} required />
          {type == 'vacation_leave' && (
            <>
              <Box
                display="flex"
                alignItems="center"
                columnGap={0.5}
                sx={{ paddingBottom: '4px', marginTop: '15px' }}>
                <InputLabel>
                  <FormattedMessage id="Hours" defaultMessage="Hours" />
                </InputLabel>
              </Box>
              <TextField
                type="number"
                fullWidth
                onChange={(e) => setHours(e.target.value)}
                required
              />
            </>
          )}
          {type != 'vacation_leave' && (
            <>
              <Box
                display="flex"
                alignItems="center"
                columnGap={0.5}
                sx={{ paddingBottom: '4px', marginTop: '15px' }}>
                <InputLabel>
                  <FormattedMessage id="Contract Type" defaultMessage="Contract Type" />
                </InputLabel>
              </Box>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={agreementType}
                onChange={handleAgreementTypeChange}>
                <MenuItem value="">
                  <em>
                    <FormattedMessage id="Contract Type" defaultMessage="Contract Type" />
                  </em>
                </MenuItem>
                {agreement_types?.map((item) => (
                  <MenuItem key={item?.id} value={item?.slug}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        </Box>
        <Box className={classes.modalFooter} justifyContent="space-around">
          <Box className={classes.modalFooterButtons}>
            {!update_holiday_loading ? (
              <Button sx={{ marginRight: '1rem' }} variant={'contained'} onClick={handleSubmit}>
                {<FormattedMessage id="Save" defaultMessage="Save" />}
              </Button>
            ) : (
              <Button sx={{ marginRight: '1rem' }} variant={'contained'} disabled>
                <CircularProgress size={18} sx={{ marginRight: '1rem' }} /> Saving
              </Button>
            )}
            <Button
              className={classes.modalFooterCancel}
              variant={'outlined'}
              onClick={handleClose}>
              {<FormattedMessage id="Cancel" defaultMessage="Cancel" />}
            </Button>
          </Box>
        </Box>
      </CustomModal>
    </>
  );
}

export default AddHolidayBase;
