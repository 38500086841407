import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { Button, CircularProgress, Menu, MenuItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import moment from 'moment';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { changeDateFormat, checkEqualDate } from 'utils/dateUtils';
import { deleteHoliday } from './redux/actions';
import { useStyles } from './styles';

function CalendarTable({ monthlyCalendar, monthlyClosing = [], currentDate }) {
  const classes = useStyles();
  const [modalOpen, openFunction] = useToggle(false);
  const [deleteId, setDeleteId] = useState();
  const dispatch = useDispatch();

  const { delete_loading } = useSelector((state) => state.calendar);

  const getAddedDate = (day) => {
    let selectedDate = moment(currentDate);
    selectedDate.date(day);
    return changeDateFormat(selectedDate);
  };
  const checkDate = (date) => {
    let holidayData = {
      title: '',
      id: null
    };
    if (monthlyClosing?.length > 0) {
      monthlyClosing.map((item) => {
        if (checkEqualDate(item?.closing_date, date)) {
          holidayData.title = item?.title;
          holidayData.id = item?.id;
        }
      });
    }
    return holidayData;
  };

  const handleDeleteAction = (id) => {
    openFunction();
    setDeleteId(id);
  };

  const handleDelete = () => {
    deleteId && dispatch(deleteHoliday(deleteId, openFunction, currentDate?.getFullYear()));
  };

  const checkHoliday = (index, date) => {
    const checkData = checkDate(date);
    const holiday = index > 4 || checkData?.title ? 'holiday' : '';
    return { day: holiday, title: checkData?.title, id: checkData?.id };
  };

  return (
    <Box marginTop={2}>
      <table className={classes.table} border>
        <thead>
          <th>
            <FormattedMessage id="Monday" defaultMessage="Monday" />
          </th>
          <th>
            <FormattedMessage id="Tuesday" defaultMessage="Tuesday" />
          </th>
          <th>
            <FormattedMessage id="Wednesday" defaultMessage="Wednesday" />
          </th>
          <th>
            <FormattedMessage id="Thursday" defaultMessage="Tuesday" />
          </th>
          <th>
            <FormattedMessage id="Friday" defaultMessage="Friday" />
          </th>
          <th>
            <FormattedMessage id="Saturday" defaultMessage="Saturday" />
          </th>
          <th>
            <FormattedMessage id="Sunday" defaultMessage="Sunday" />
          </th>
        </thead>
        <tbody>
          {monthlyCalendar?.map((item, index) => (
            <tr key={index}>
              {item?.map((list, ind) => {
                const check = checkHoliday(ind, list?.date);

                return (
                  <td className={`${classes.date} ${check?.day}`} key={ind}>
                    {list?.day > 0 ? list?.day : ''}
                    {check?.title && (
                      <div className={classes.deleteEventWrap}>
                        <DeleteIcon onClick={() => handleDeleteAction(getAddedDate(list?.day))} />
                      </div>
                    )}
                    <InfoCard title={check?.title} index={ind} />
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="35rem"
        headerText={<FormattedMessage id="Deleting Holiday" defaultMessage="Deleting Holiday" />}>
        <div className={classes.cardWrapper}>
          <Box marginTop={3} display="flex" justifyContent="center" marginBottom={3}>
            <Typography variant="h5">
              <FormattedMessage
                id="Are you sure want to delete this holiday?"
                defaultMessage="Are you sure want to delete this holiday?"
              />
            </Typography>
          </Box>
          <Box className="buttonWrapper">
            {!delete_loading ? (
              <Button color="primary" variant="contained" onClick={handleDelete}>
                <FormattedMessage id="Confirm" defaultMessage="Confirm" />
              </Button>
            ) : (
              <Button color="primary" variant="contained" disabled>
                <CircularProgress size={18} />{' '}
                <Box marginLeft={2}>
                  <FormattedMessage id="Deleting" defaultMessage="Deleting" />
                  ...
                </Box>
              </Button>
            )}

            <Button onClick={openFunction}>
              {' '}
              <FormattedMessage id="Cancel" defaultMessage="Cancel" />
            </Button>
          </Box>
        </div>
      </CustomModal>
    </Box>
  );
}

export default CalendarTable;

const InfoCard = ({ title, index }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleActionButtonClick = (event, data) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {title && (
        <div
          className={classes.eventWrap}
          onClick={(event) => handleActionButtonClick(event, index)}>
          <InfoIcon />
        </div>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '&  .MuiPaper-root': { border: '1px solid #f2ecec' },
          '& .MuiMenuItem-root': { fontSize: '11px' }
        }}
        elevation={0}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <MenuItem>{title}</MenuItem>
      </Menu>
    </>
  );
};
