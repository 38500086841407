import { Grid } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import useStyles from '../../styles';
import CircularProgress from '@mui/material/CircularProgress';
import { FormattedMessage } from 'react-intl';

function BasicView() {
  const classes = useStyles();
  const { calculated_agreement, calculated_agreement_loading } = useSelector(
    (state) => state.contract
  );
  return (
    <div className={classes.calculatedView}>
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <table className={classes.table}>
            <tbody>
              <tr>
                <td className={classes.title}>
                  {<FormattedMessage id="Net Salary" defaultMessage="Net Salary" />}
                </td>
                <td className={classes.value}>{calculated_agreement?.net_salary?.toFixed(2)}</td>
              </tr>
              <tr>
                <td className={classes.title}>
                  {<FormattedMessage id="Gross Salary" defaultMessage="Gross Salary" />}
                </td>
                <td className={classes.value}>{calculated_agreement?.gross_salary?.toFixed(2)}</td>
              </tr>
              <tr>
                <td className={classes.title}>
                  {<FormattedMessage id="Total Salary Cost" defaultMessage="Total Salary Cost" />}
                </td>
                <td className={classes.value}>
                  {calculated_agreement?.total_salary_cost?.toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
          <table className={classes.table}>
            <tbody>
              <tr>
                <td className={classes.title}>
                  {
                    <FormattedMessage
                      id="The basic of health insurance"
                      defaultMessage="The basic of health insurance"
                    />
                  }
                </td>
                <td className={classes.value}>
                  {calculated_agreement?.social_security_base?.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td className={classes.title}>
                  {
                    <FormattedMessage
                      id="Cost of getting income"
                      defaultMessage="Cost of getting income"
                    />
                  }
                </td>
                <td className={classes.value}>
                  {calculated_agreement?.cost_of_getting_income?.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td className={classes.title}>
                  {<FormattedMessage id="The tax base" defaultMessage="The tax base" />}
                </td>
                <td className={classes.value}>
                  {calculated_agreement?.tax_base < 0
                    ? 0
                    : calculated_agreement?.tax_base?.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td className={classes.title}>
                  {<FormattedMessage id="Tax" defaultMessage="Tax" />} (
                  {calculated_agreement?.tax_rate}%)
                </td>
                <td className={classes.value}>
                  {calculated_agreement?.tax_after_free_amount
                    ? Math.round(calculated_agreement?.tax_after_free_amount).toFixed(2)
                    : 0}
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
        <Grid item sm={6}>
          <table className={classes.table}>
            <tbody>
              <tr>
                <td className={classes.title}>
                  {
                    <FormattedMessage
                      id="Pension Insurance (employee)"
                      defaultMessage="Pension Insurance (employee)"
                    />
                  }
                </td>
                <td className={classes.value}>
                  {calculated_agreement?.pension_insurance_employee?.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td className={classes.title}>
                  {
                    <FormattedMessage
                      id="Disability Insurance (employee)"
                      defaultMessage="Disability Insurance (employee)"
                    />
                  }
                </td>
                <td className={classes.value}>
                  {calculated_agreement?.disability_insurance_employee?.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td className={classes.title}>
                  {
                    <FormattedMessage
                      id="Sickness Insurance (employee)"
                      defaultMessage="Sickness Insurance (employee)"
                    />
                  }
                </td>
                <td className={classes.value}>
                  {calculated_agreement?.sickness_insurance_employee?.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td className={classes.title}>
                  {<FormattedMessage id="Health Insurance" defaultMessage="Health Insurance" />}
                </td>
                <td className={classes.value}>
                  {calculated_agreement?.health_insurance_employee?.toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
          <table className={classes.table}>
            <tbody>
              <tr>
                <td className={classes.title}>
                  {
                    <FormattedMessage
                      id="Pension Insurance (employer)"
                      defaultMessage="Pension Insurance (employer)"
                    />
                  }
                </td>
                <td className={classes.value}>
                  {calculated_agreement?.pension_insurance_employer?.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td className={classes.title}>
                  {
                    <FormattedMessage
                      id="Disability Insurance (employer)"
                      defaultMessage="Disability Insurance (employer)"
                    />
                  }
                </td>
                <td className={classes.value}>
                  {calculated_agreement?.disability_insurance_employer?.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td className={classes.title}>
                  {
                    <FormattedMessage
                      id="Accident Insurance (employer)"
                      defaultMessage="Accident Insurance (employer)"
                    />
                  }
                </td>
                <td className={classes.value}>
                  {calculated_agreement?.accident_insurance_employer?.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td className={classes.title}>
                  {
                    <FormattedMessage
                      id="Insurance for the labor fund (employer)"
                      defaultMessage="Insurance for the labor fund (employer)"
                    />
                  }
                </td>
                <td className={classes.value}>
                  {calculated_agreement?.labor_fund_insurance_employer?.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td className={classes.title}>
                  {
                    <FormattedMessage
                      id="Insurance for the FGSP (employer)"
                      defaultMessage="Insurance for the FGSP (employer)"
                    />
                  }
                </td>
                <td className={classes.value}>
                  {calculated_agreement?.fgsp_insurance_employer?.toFixed(2)}
                </td>
              </tr>
              <tr>
                <td className={classes.title}>
                  {
                    <FormattedMessage
                      id="FEP Insurance (employer)"
                      defaultMessage="FEP Insurance (employer)"
                    />
                  }
                </td>
                <td className={classes.value}>
                  {calculated_agreement?.fep_insurance_employer?.toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      </Grid>
      {calculated_agreement_loading && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

export default BasicView;
