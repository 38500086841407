import * as actions from './types';
const initialState = {
  employee_document: [],
  employee_loading: false,
  other_document: [],
  other_loading: false,
  single_folder: null,
  single_folder_loading: false,
  create_loading: false,
  upload_loading: false
};

const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_ALL_EMPLOYEE_FOLDER_SUCCESS:
      return { ...state, employee_document: action.payload, employee_loading: false };

    case actions.GET_ALL_EMPLOYEE_FOLDER_BEGIN:
      return { ...state, employee_loading: true };

    case actions.GET_ALL_EMPLOYEE_FOLDER_ERROR:
      return { ...state, employee_loading: false };

    case actions.GET_OTHER_FOLDER_SUCCESS:
      return { ...state, other_document: action.payload?.data, other_loading: false };

    case actions.GET_OTHER_FOLDER_BEGIN:
      return { ...state, other_loading: true };

    case actions.GET_OTHER_FOLDER_ERROR:
      return { ...state, other_loading: false };

    case actions.GET_SINGLE_FOLDER_SUCCESS:
      return { ...state, single_folder: action.payload?.data, single_folder_loading: false };

    case actions.GET_SINGLE_FOLDER_BEGIN:
      return { ...state, single_folder_loading: true, single_folder: null };

    case actions.GET_SINGLE_FOLDER_ERROR:
      return { ...state, single_folder_loading: false };

    case actions.CREATE_FOLDER_SUCCESS:
    case actions.CREATE_FOLDER_ERROR:
      return { ...state, create_loading: false };

    case actions.CREATE_FOLDER_BEGIN:
      return { ...state, create_loading: true };

    case actions.UPLOAD_FILE_SUCCESS:
    case actions.UPLOAD_FILE_ERROR:
      return { ...state, upload_loading: false };

    case actions.UPLOAD_FILE_BEGIN:
      return { ...state, upload_loading: true };

    default:
      return state;
  }
};

export default documentReducer;
