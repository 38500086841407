import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import TimeRecordingTable from './TimeRecordingTable';
import { Box } from '@mui/system';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import AddAllowance from './AddTimeRecording';
import { useDispatch } from 'react-redux';
import { getEmployeeContract } from 'components/locals/contract/redux/actions';
import { getTimeRecording } from './redux/actions';
import { FormattedMessage } from 'react-intl';

function TimeRecording({ employeeId }) {
  const [modalOpen, openFunction] = useToggle(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEmployeeContract(employeeId));
    dispatch(getTimeRecording(employeeId));
  }, []);

  return (
    <>
      <Box marginBottom={2}>
        <Button variant="contained" onClick={openFunction}>
          <AddIcon />
          {<FormattedMessage id="Add" defaultMessage="Add" />}
        </Button>
      </Box>
      <TimeRecordingTable employeeId={employeeId} />
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="1000px"
        headerText={
          <FormattedMessage id="Add Time Recording" defaultMessage="Add Time Recording" />
        }>
        <AddAllowance handleClose={openFunction} employeeId={employeeId} />
      </CustomModal>
    </>
  );
}

export default TimeRecording;
