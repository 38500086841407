import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import HookFormAutoComplete from 'components/form/hookFormAutoComplete';
import { HookFormInput } from 'components/form/hookFormInput';
import { HookFormSelect } from 'components/form/hookFormSelect';
import CustomModal from 'components/globals/modal';
import DepartmentView from 'components/locals/department/DepartmentView';
import useToggle from 'hooks/useToggle';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { translate } from 'utils';
import { changeDateFormat } from 'utils/dateUtils';
import useStyles from '../../styles';

function BasicDetail({ contractId }) {
  const { single_loading } = useSelector((state) => state.employee);
  const { single_loading: department_loading } = useSelector((state) => state.contract);
  const [modalOpen, openFunction] = useToggle(false);
  const classes = useStyles();
  const { watch, setValue } = useFormContext();
  const [checkType, setCheckType] = useState(false);
  const contractType = watch('type');
  useEffect(() => {
    if (contractType == 'indefinite') {
      setCheckType(true);
      setValue('end_date', null);
    } else {
      setCheckType(false);
    }
  }, [contractType]);
  return (
    <Grid container spacing={2}>
      <Box display="none">
        <FormattedMessage
          id="Indefinite Period of Time"
          defaultMessage="Indefinite Period of Time"
        />
        <FormattedMessage id="Fixed Term" defaultMessage="Fixed Term" />
        <FormattedMessage id="Trial" defaultMessage="Trial" />
        <FormattedMessage id="Replacement" defaultMessage="Replacement" />
      </Box>
      <Grid item sm={4}>
        <HookFormInput
          loading={single_loading}
          name="type_gross"
          label={<FormattedMessage id="Salary Type" defaultMessage="Salary Type" />}
          defaultValue="Gross"
          disabled
        />
      </Grid>
      <Grid item sm={4}>
        <HookFormInput
          name="gross_salary"
          label={<FormattedMessage id="Amount" defaultMessage="Amount" />}
          type="number"
          placeholder="3000"
          loading={single_loading}
        />
      </Grid>
      <Grid item sm={4}>
        <HookFormAutoComplete
          loading={single_loading}
          name="type"
          label={<FormattedMessage id="Type of Contract" defaultMessage="Type of Contract" />}
          options={[
            {
              label: translate('Indefinite Period of Time'),
              value: 'indefinite'
            },
            {
              label: translate('Fixed Term'),
              value: 'fixed'
            },
            { label: translate('Trial'), value: 'trial' },
            {
              label: translate('Replacement'),
              value: 'replacement'
            }
          ]}
        />
      </Grid>
      <Grid item sm={2}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <div>
            <HookFormInput
              name="parameters.work_hour_numerator"
              label={<FormattedMessage id="Working Hours" defaultMessage="Working" />}
              placeholder="5"
              loading={single_loading}
            />
          </div>
          <div className={classes.slash}>/</div>
          <div>
            <Box marginTop={2.2} width="80px">
              <HookFormInput
                name="parameters.work_hour_denominator"
                placeholder="12"
                label=" "
                loading={single_loading}
              />
            </Box>
          </div>
        </Box>
      </Grid>

      <Grid item sm={2}></Grid>
      <Grid item sm={4}>
        <HookFormInput
          name="date_of_signing"
          type="date"
          label={<FormattedMessage id="Date of Signing" defaultMessage="Date of Signing" />}
          loading={single_loading}
          inputProps={{ max: changeDateFormat(new Date(), 'YYYY-MM-DD') }}
          disabled={contractId ? false : true}
        />
      </Grid>
      <Grid item sm={4}>
        <HookFormInput
          name="position"
          label={<FormattedMessage id="Position" defaultMessage="Position" />}
          placeholder="Director"
          loading={single_loading}
        />
      </Grid>
      <Grid item sm={4}>
        <HookFormInput
          name="start_date"
          type="date"
          label={<FormattedMessage id="Start Date" defaultMessage="Start Date" />}
          loading={single_loading}
          disabled={contractId ? false : true}
        />
      </Grid>

      <Grid item sm={4}>
        {checkType ? null : (
          <HookFormInput
            name="end_date"
            type="date"
            label={<FormattedMessage id="End Date" defaultMessage="End Date" />}
            loading={single_loading}
          />
        )}
      </Grid>

      <Grid item sm={4}>
        {!department_loading && <Department openFunction={openFunction} />}
      </Grid>

      <Grid item sm={4}>
        <HookFormInput
          name="parameters.place_of_work"
          label={<FormattedMessage id="Place of Work" defaultMessage="Place of Work" />}
          placeholder=""
          loading={single_loading}
        />
      </Grid>

      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="50rem"
        headerText="Add Department">
        <DepartmentView handleClose={openFunction} />
      </CustomModal>
    </Grid>
  );
}

export default BasicDetail;

const Department = ({ openFunction }) => {
  const classes = useStyles();
  const { departments } = useSelector((state) => state.department);
  return (
    <Grid container spacing={1}>
      <Grid item sm={10}>
        <HookFormSelect
          name="department_id"
          label={<FormattedMessage id="Department of" defaultMessage="Department of" />}
          placeholder="IT"
          tooltipLabel={<FormattedMessage id="Add Department" defaultMessage="Add Department" />}
          options={departments?.map((item) => ({
            label: item.title,
            value: item?.id
          }))}
        />
      </Grid>
      <Grid item sm={2}>
        <Box className={classes.departmentBtn} onClick={openFunction}>
          <Tooltip
            title={
              <Typography variant="body1">
                <FormattedMessage id="Add Department" defaultMessage="Add Department" />
              </Typography>
            }
            placement="top">
            <Button variant="outlined" color="btnColor">
              <AddIcon />
            </Button>
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
  );
};
