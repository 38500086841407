import * as actions from './types';
const initialState = {
  overtime: [],
  loading: false,
  create_loading: false,
  edit_loading: false,
  delete_loading: false,
  amount: null,
  amount_loading: false
};

const overtimeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CREATE_OVERTIME_BEGIN:
      return { ...state, create_loading: true };

    case actions.CREATE_OVERTIME_SUCCESS:
      return { ...state, create_loading: false };

    case actions.CREATE_OVERTIME_ERROR:
      return { ...state, create_loading: false };

    case actions.GET_OVERTIME_BEGIN:
      return { ...state, loading: true };

    case actions.GET_OVERTIME_SUCCESS:
      return { ...state, overtime: action.payload, loading: false };

    case actions.GET_OVERTIME_ERROR:
      return { ...state, loading: false };

    case actions.EDIT_OVERTIME_BEGIN:
      return { ...state, edit_loading: true };

    case actions.GET_OVERTIME_AMOUNT_BEGIN:
      return { ...state, amount_loading: true };

    case actions.GET_OVERTIME_AMOUNT_SUCCESS:
      return { ...state, amount: action.payload, amount_loading: false };

    case actions.GET_OVERTIME_AMOUNT_ERROR:
      return { ...state, amount_loading: false };

    case actions.EDIT_OVERTIME_ERROR:
    case actions.EDIT_OVERTIME_SUCCESS:
      return { ...state, edit_loading: false };

    case actions.DELETE_OVERTIME_BEGIN:
      return { ...state, delete_loading: true };

    case actions.DELETE_OVERTIME_ERROR:
    case actions.DELETE_OVERTIME_SUCCESS:
      return { ...state, delete_loading: false };

    case actions.OVERTIME_AMOUNT_CLEAR:
      return { ...state, amount: null };

    default:
      return state;
  }
};

export default overtimeReducer;
