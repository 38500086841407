import { Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import useStyles from './styles';
import ViewTable from './ViewTable';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import { createDepartment } from './redux/actions';
import { FormattedMessage } from 'react-intl';

function DepartmentView({ handleClose }) {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleClear = () => {
    setValue('');
  };

  const handleSubmit = () => {
    console.log(value);
    if (value) {
      dispatch(createDepartment({ title: value, status_id: 1 }, handleClear));
    }
  };
  return (
    <div className={classes.contentWrap}>
      <Box marginBottom={2}>
        <Grid
          spacing={6}
          container
          justifyContent="space-between"
          direction="row"
          alignItems="center">
          <Grid item sm={8}>
            <input
              type="text"
              value={value}
              onChange={handleChange}
              className={classes.inputText}
              autoFocus={true}
            />
          </Grid>
          <Grid item sm={4}>
            <Button variant="contained" fullWidth onClick={handleSubmit}>
              <AddIcon /> {<FormattedMessage id="Add" defaultMessage="Add" />}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <ViewTable />
    </div>
  );
}

export default DepartmentView;
