import { Button, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { HookForm } from 'components/form/hookForm';
import { HookFormProvider } from 'components/form/hookFormProvider';
import React from 'react';
import useStyles from './styles';
import * as Yup from 'yup';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import { useDispatch, useSelector } from 'react-redux';
import useToggle from 'hooks/useToggle';
import CancelModal from 'components/globals/modal/CancelModal';
import AllowanceForm from './Form';
import { createAllowance } from './redux/actions';
import { FormattedMessage } from 'react-intl';
import { getCurrentDate, getCurrentMonth, getCurrentYear } from 'utils/dateUtils';

function AddAllowance({ handleClose, employeeId, contractId, handleRefetch }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { create_loading } = useSelector((state) => state.allowance);

  const defaultValues = {
    name: '',
    value: '',
    type: 'allowance',
    agreement_id: contractId ?? '',
    one_time: true,
    is_taxable: true,
    include_socialsecurity_base: true,
    include_sick_base: true,
    include_vacation_base: true,
    start_date: getCurrentDate(),
    end_date: null,
    year: getCurrentYear(),
    month: getCurrentMonth()
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    value: Yup.string().required('Value is required'),
    type: Yup.string().required('Type is required'),
    agreement_id: Yup.string().required('Agreement field is required'),
    one_time: Yup.boolean().required('One Time field is required'),
    year: Yup.string()
      .nullable()
      .notRequired()
      .when('one_time', (started, yup) =>
        started ? yup.required('Year is required') : yup.notRequired()
      ),
    month: Yup.string()
      .nullable()
      .notRequired()
      .when('one_time', (started, yup) =>
        started ? yup.required('Month is required') : yup.notRequired()
      ),
    start_date: Yup.date()
      .nullable()
      .notRequired()
      .when('one_time', (started, yup) =>
        !started ? yup.required('Start Date is required') : yup.notRequired()
      ),
    end_date: Yup.date()
      .nullable()
      .notRequired()
      .when('start_date', (started, yup) =>
        started ? yup.min(started, 'End date cannot be before start date') : yup.notRequired()
      )
  });

  const onSubmit = (data) => {
    const finalData = {
      ...data,
      employee_id: employeeId
    };
    dispatch(createAllowance(finalData, handleClose, handleRefetch));
  };

  const [cancelOpen, cancelFunction] = useToggle(false);

  return (
    <>
      <div className={classes.content}>
        <HookFormProvider
          defaultValues={defaultValues}
          resolver={useYupValidationResolver(validationSchema)}>
          <HookForm onSubmit={onSubmit}>
            <AllowanceForm contractId={contractId} />
            <Box className={classes.modalFooter} justifyContent="flex-end">
              <Box className={classes.modalFooterButtons}>
                {!create_loading ? (
                  <Button sx={{ marginRight: '1rem' }} variant={'contained'} type="submit">
                    <FormattedMessage id="Save" defaultMessage="Save" />
                  </Button>
                ) : (
                  <Button sx={{ marginRight: '1rem' }} variant={'contained'} disabled>
                    <CircularProgress size={18} sx={{ marginRight: '1rem' }} />{' '}
                    <FormattedMessage id="Saving" defaultMessage="Saving" />
                  </Button>
                )}

                <Button
                  className={classes.modalFooterCancel}
                  variant={'outlined'}
                  onClick={cancelFunction}>
                  <FormattedMessage id="Cancel" defaultMessage="Cancel" />
                </Button>
              </Box>
            </Box>
          </HookForm>
        </HookFormProvider>
      </div>
      <CancelModal
        handleClose={handleClose}
        cancelFunction={cancelFunction}
        cancelOpen={cancelOpen}
      />
    </>
  );
}

export default AddAllowance;
