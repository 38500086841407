import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateContribution } from '../../redux/actions';
import useStyles from '../../styles';
import OpeningForm from './Form';

function OpeningEdit({ handleClose, employeeId, year, month, contractTypeId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { contributions, agreement_types } = useSelector((state) => state.contract);
  const [error, setError] = useState('');

  const filterAgreement = agreement_types?.find((list) => list?.id == contractTypeId);
  const [data, setData] = useState({
    employee_id: employeeId,
    year: year,
    month: month,
    tax_base: ''
  });

  const onSubmit = () => {
    if (!data.year) {
      setError('Year is required');
    } else if (!data.month) {
      setError('Month is required');
    } else {
      dispatch(
        updateContribution(
          {
            ...data,
            employee_id: employeeId,
            month: parseInt(data.month),
            agreement_type: filterAgreement?.slug
          },
          handleClose
        )
      );
    }
  };

  useEffect(() => {
    if (contributions?.openingBalances?.length > 0) {
      const groupByYear = contributions?.openingBalances?.reduce((group, item) => {
        group[item.year] = group[item.year] ?? [];
        group[item.year].push(item);
        return group;
      }, {});
      const monthData = groupByYear[year]?.find((item) => parseInt(item.month) == parseInt(month));
      setData({
        ...data,
        social_contribution_base: monthData?.social_contribution_base || '',
        tax_base: monthData?.tax_base || ''
      });
    }
  }, [contributions]);

  return (
    <>
      <div className={classes.content}>
        <OpeningForm setError={setError} setData={setData} data={data} error={error} disabled />
        <Box className={classes.modalFooter}>
          <Box className={classes.modalFooterButtons}>
            <Button sx={{ marginRight: '1rem' }} variant={'contained'} onClick={onSubmit}>
              Save
            </Button>
            <Button
              className={classes.modalFooterCancel}
              variant={'outlined'}
              onClick={handleClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </div>
    </>
  );
}

export default OpeningEdit;
