import * as actions from './types';
const initialState = {
  employees: [],
  loading: false,
  create_loading: false,
  single_employee: null,
  single_loading: false,
  edit_loading: false,
  change_status_loading: false,
  employees_paginated: [],
  paginated_loading: false,
  tax_offices: [],
  tax_office_loading: false,
  nhf: [],
  nhf_loading: false,
  payout_loading: false,
  payouts: [],
  uploading: false
};

const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CREATE_EMPLOYEE_BEGIN:
      return { ...state, create_loading: true };

    case actions.CREATE_EMPLOYEE_SUCCESS:
      return { ...state, create_loading: false };

    case actions.CREATE_EMPLOYEE_ERROR:
      return { ...state, create_loading: false };

    case actions.GET_EMPLOYEE_BEGIN:
      return { ...state, loading: true };

    case actions.GET_EMPLOYEE_SUCCESS:
      return { ...state, employees: action.payload, loading: false };

    case actions.GET_EMPLOYEE_ERROR:
      return { ...state, loading: false };

    case actions.GET_EMPLOYEE_PAGINATED_BEGIN:
      return { ...state, paginated_loading: true };

    case actions.GET_EMPLOYEE_PAGINATED_SUCCESS:
      return { ...state, employees_paginated: action.payload, paginated_loading: false };

    case actions.GET_EMPLOYEE_PAGINATED_ERROR:
      return { ...state, paginated_loading: false };

    case actions.GET_SINGLE_EMPLOYEE_BEGIN:
      return { ...state, single_loading: true };

    case actions.GET_SINGLE_EMPLOYEE_SUCCESS:
      return { ...state, single_employee: action.payload, single_loading: false };

    case actions.GET_SINGLE_EMPLOYEE_ERROR:
      return { ...state, single_loading: false };

    case actions.EDIT_EMPLOYEE_BEGIN:
      return { ...state, edit_loading: true };

    case actions.EDIT_EMPLOYEE_ERROR:
    case actions.EDIT_EMPLOYEE_SUCCESS:
      return { ...state, edit_loading: false };

    case actions.CHANGE_EMPLOYEE_BEGIN:
      return { ...state, change_status_loading: true };

    case actions.CHANGE_EMPLOYEE_ERROR:
    case actions.CHANGE_EMPLOYEE_SUCCESS:
      return { ...state, change_status_loading: false };

    case actions.CLEAR_SINGLE_EMPLOYEE:
      return { ...state, single_employee: null };

    case actions.GET_TAX_OFFICE_BEGIN:
      return { ...state, tax_office_loading: true };

    case actions.GET_TAX_OFFICE_SUCCESS:
      return { ...state, tax_offices: action.payload, tax_office_loading: false };

    case actions.GET_TAX_OFFICE_ERROR:
      return { ...state, tax_office_loading: false };

    case actions.GET_NHF_BEGIN:
      return { ...state, nhf_loading: true };

    case actions.GET_NHF_SUCCESS:
      return { ...state, nhf: action.payload, nhf_loading: false };

    case actions.GET_NHF_ERROR:
      return { ...state, nhf_loading: false };

    case actions.GET_PAYOUT_BEGIN:
      return { ...state, payout_loading: true, payouts: [] };

    case actions.GET_PAYOUT_SUCCESS:
      return { ...state, payouts: action.payload, payout_loading: false };

    case actions.GET_PAYOUT_ERROR:
      return { ...state, payout_loading: false };

    case actions.UPLOAD_EMPLOYEE_LIST_BEGIN:
      return { ...state, uploading: true };

    case actions.UPLOAD_EMPLOYEE_LIST_ERROR:
    case actions.UPLOAD_EMPLOYEE_LIST_SUCCESS:
      return { ...state, uploading: false };

    default:
      return state;
  }
};

export default employeeReducer;
