/* Build in libraries */

/* Third party libraries */
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

/* Components */
import { HookFormInput } from 'components/UI/Form/HookFormInput';
import { FormattedMessage } from 'react-intl';

function UserDetails({ showTitle = true }) {
  return (
    <>
      <Box>
        {showTitle && (
          <Box>
            <Typography className="title">
              <FormattedMessage id="User Details" defaultMessage="User Details" />
            </Typography>
          </Box>
        )}

        <Box className="formBodyWrapper">
          <Grid container spacing={3}>
            <Grid item sm={4}>
              <HookFormInput
                name="user_name"
                label={<FormattedMessage id="Full Name" defaultMessage="Full Name" />}
                placeholder="John Doe"
              />
            </Grid>
            <Grid item sm={4}>
              <HookFormInput
                name="user_email"
                label={<FormattedMessage id="Email Address" defaultMessage="Email Address" />}
                placeholder="singh@gmail.com"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default UserDetails;
