import BreadCrumb from 'components/globals/breadCrumb';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import MainAreaLayout from 'layouts/dashboardLayout/mainAreaLayout/MainAreaLayout';
import { FormattedMessage } from 'react-intl';
import EmployeeAdd from './EmployeeAdd';
import EmployeeTable from './components/EmployeeTable';
import FilterForm from './components/filterForm';

function Workers() {
  const [modalOpen, openFunction] = useToggle(false);

  return (
    <>
      <BreadCrumb
        type="worker"
        itemName={<FormattedMessage id="workers" defaultMessage="Workers" />}
        page={<FormattedMessage id="workers" defaultMessage="Workers" />}
        handleButton={openFunction}
        buttonTitle={<FormattedMessage id="Add Employee" defaultMessage="Add Employee" />}
      />
      <FilterForm />
      <MainAreaLayout>
        <EmployeeTable />
      </MainAreaLayout>

      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        headerText={<FormattedMessage id="Add Employee" defaultMessage="Add Employee" />}>
        <EmployeeAdd handleClose={openFunction} />
      </CustomModal>
    </>
  );
}

export default Workers;
