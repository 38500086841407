import { Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import useStyles from './styles';

export default function ViewContractTermination({ row }) {
  const classes = useStyles();
  console.log(row);
  return (
    <div className={classes.viewRoot}>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <ul>
            <ViewList
              label={<FormattedMessage id="Employee" defaultMessage="Employee" />}
              value={row?.employee?.first_name + ' ' + row?.employee?.last_name}
            />
            <ViewList
              label={<FormattedMessage id="Type of Contract" defaultMessage="Type of Contract" />}
              value={row?.agreement_type_name}
            />
            <ViewList
              label={<FormattedMessage id="Contract" defaultMessage="Contract" />}
              value={row?.contract_number}
            />
            <ViewList
              label={<FormattedMessage id="Date" defaultMessage="Date" />}
              value={row?.termination_details?.date}
            />
            <ViewList
              label={
                <FormattedMessage
                  id="Number of vacation days"
                  defaultMessage="Number of vacation days"
                />
              }
              value={row?.termination_details?.vacation_days}
            />
          </ul>
        </Grid>
      </Grid>
    </div>
  );
}

const ViewList = ({ label, value }) => {
  const classes = useStyles();
  return (
    <li>
      <div className={classes.title}>{label}</div>
      <div className={classes.value}>{value}</div>
    </li>
  );
};
