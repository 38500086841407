import * as actions from './types';
const initialState = {
  timeRecording: [],
  loading: false,
  create_loading: false,
  edit_loading: false,
  delete_loading: false,
  holiday_loading: false,
  holiday_basis: [],
  holiday_info: [],
  update_holiday_loading: false,
  holiday_addons: [],
  holiday_addons_loading: false
};

const timeRecordingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CREATE_TIME_RECORDING_BEGIN:
      return { ...state, create_loading: true };

    case actions.CREATE_TIME_RECORDING_SUCCESS:
      return { ...state, create_loading: false };

    case actions.CREATE_TIME_RECORDING_ERROR:
      return { ...state, create_loading: false };

    case actions.GET_TIME_RECORDING_BEGIN:
      return { ...state, loading: true };

    case actions.GET_TIME_RECORDING_SUCCESS:
      return { ...state, timeRecording: action.payload, loading: false };

    case actions.GET_TIME_RECORDING_ERROR:
      return { ...state, loading: false };

    case actions.EDIT_TIME_RECORDING_BEGIN:
      return { ...state, edit_loading: true };

    case actions.EDIT_TIME_RECORDING_ERROR:
    case actions.EDIT_TIME_RECORDING_SUCCESS:
      return { ...state, edit_loading: false };

    case actions.DELETE_TIME_RECORDING_BEGIN:
      return { ...state, delete_loading: true };

    case actions.DELETE_TIME_RECORDING_ERROR:
    case actions.DELETE_TIME_RECORDING_SUCCESS:
      return { ...state, delete_loading: false };

    case actions.GET_HOLIDAY_BASIS_BEGIN:
      return { ...state, holiday_loading: true, holiday_basis: [], holiday_info: [] };

    case actions.GET_HOLIDAY_BASIS_SUCCESS:
      return {
        ...state,
        holiday_basis: action.payload?.bases,
        holiday_info: action.payload?.holiday_info,
        holiday_loading: false
      };

    case actions.GET_HOLIDAY_BASIS_ERROR:
      return { ...state, holiday_loading: false };

    case actions.UPDATE_HOLIDAY_BASIS_BEGIN:
      return { ...state, update_holiday_loading: true };

    case actions.UPDATE_HOLIDAY_BASIS_ERROR:
    case actions.UPDATE_HOLIDAY_BASIS_SUCCESS:
      return { ...state, update_holiday_loading: false };

    case actions.GET_HOLIDAY_ADDON_BEGIN:
      return { ...state, holiday_addons_loading: true };

    case actions.GET_HOLIDAY_ADDON_SUCCESS:
      return { ...state, holiday_addons: action.payload, holiday_addons_loading: false };

    case actions.GET_HOLIDAY_ADDON_ERROR:
      return { ...state, holiday_addons_loading: false };
    default:
      return state;
  }
};

export default timeRecordingReducer;
