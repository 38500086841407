import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import useStyles from '../../styles';

function PrintDocument({ handleClose }) {
  const classes = useStyles();
  const { single_contract } = useSelector((state) => state.contract);

  const documentName = `${single_contract?.contract_number}.docx`;
  const checkValue = (value) => {
    if (value == 'null' || !value) {
      return '';
    } else return value;
  };

  const address = single_contract?.employee?.address?.[0];
  const companyAddress = single_contract?.employee?.company?.addresses?.[0];
  const idNumber = single_contract?.employee?.employee_detail?.find(
    (list) => list?.key === 'identification_number'
  )?.value;

  const generate = () => {
    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              text: ''
            }),

            new Paragraph({
              text: ''
            }),
            new Paragraph({
              alignment: 'center',
              children: [
                new TextRun({
                  text: 'UMOWA O DZIEŁO',
                  bold: true,
                  size: 26
                })
              ]
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: `zawarta w dniu ${single_contract?.date_of_signing} W ${
                companyAddress?.city
              } pomiędzy: prowadzącym/ą działalność gospodarczą pod nazwą ${
                single_contract?.employee?.company?.name ?? ''
              } wpisanym/ą do rejestru przedsiębiorców pod numerem ${
                single_contract?.employee?.company?.krs ?? ''
              } , zsiedzibą ${checkValue(companyAddress?.street)} ${checkValue(
                companyAddress?.house_number
              )} ${checkValue(companyAddress?.number_of_premises)} ${checkValue(
                companyAddress?.zip
              )} ${checkValue(companyAddress?.post)} ${checkValue(
                companyAddress?.city
              )} ${checkValue(companyAddress?.country)} ${
                checkValue(companyAddress?.voivodeship) ?? ''
              } ${checkValue(companyAddress?.county)} ${checkValue(
                companyAddress?.community
              )} reprezentowaną przez: ${single_contract?.representative ?? ''} NIP: ${
                single_contract?.employee?.company?.tax_id
              } zwanym/ą wdalszej części umowy Zamawiającym,`
            }),
            new Paragraph({
              text: ''
            }),

            new Paragraph({
              text: `a ${single_contract?.employee?.first_name ?? ''} ${
                single_contract?.employee?.second_name ?? ''
              } ${single_contract?.employee?.last_name ?? ''}, ${
                single_contract?.employee?.pesel ?? ''
              } zamieszkałym ${checkValue(address?.street)} ${checkValue(
                address?.house_number
              )} ${checkValue(address?.number_of_premises)} ${checkValue(address?.zip)}${checkValue(
                address?.post
              )} ${checkValue(address?.city)} ${checkValue(address?.country)} ${checkValue(
                address?.voivodeship
              )} ${checkValue(address?.county)} ${checkValue(
                address?.community
              )} legitymującym się dokumentem ${
                idNumber || ''
              }, zwanym w dalszej części umowyWykonawcą o treści następującej:`
            }),

            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              alignment: 'center',
              children: [
                new TextRun({
                  text: '§ 1',
                  bold: true,
                  size: 22
                })
              ]
            }),
            new Paragraph({
              text: 'Zamawiający powierza wykonanie, a Wykonawca zobowiązuje się wykonać dziełopolegające'
            }),
            new Paragraph({
              text: single_contract?.parameters?.work_description
            }),

            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              alignment: 'center',
              children: [
                new TextRun({
                  text: '§ 2',
                  bold: true,
                  size: 22
                })
              ]
            }),
            new Paragraph({
              text: ` Wykonawca zobowiązuje się wykonać dzieło w terminie ustalonym przez Strony, tj. oddnia ${single_contract?.start_date} do dnia ${single_contract?.end_date}.`
            }),

            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              alignment: 'center',
              children: [
                new TextRun({
                  text: '§ 3',
                  bold: true,
                  size: 22
                })
              ]
            }),
            new Paragraph({
              text: `3Za wykonanie dzieła jego Wykonawca otrzyma wynagrodzenie w wysokości ${single_contract?.gross_salary} brutto.`
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              alignment: 'center',
              children: [
                new TextRun({
                  text: '§ 4',
                  bold: true,
                  size: 22
                })
              ]
            }),
            new Paragraph({
              text: `Wynagrodzenie wypłacone będzie na podstawie rachunku po dokonaniu odbioru dziełaprzez
              Zamawiającego.`
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              alignment: 'center',
              children: [
                new TextRun({
                  text: '§ 5',
                  bold: true,
                  size: 22
                })
              ]
            }),
            new Paragraph({
              text: 'W sprawach nieuregulowanych umową mają zastosowanie odpowiednie przepisy Kodeksucywilnego.'
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              alignment: 'center',
              children: [
                new TextRun({
                  text: '§ 6',
                  bold: true,
                  size: 22
                })
              ]
            }),
            new Paragraph({
              text: 'Zmiany umowy wymagają formy pisemnej, pod rygorem nieważności.'
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              alignment: 'center',
              children: [
                new TextRun({
                  text: '§ 7',
                  bold: true,
                  size: 22
                })
              ]
            }),
            new Paragraph({
              text: `Wszelkie ewentualne spory wynikające z niniejszej umowy lub jej dotyczące będąrozstrzygane przez właściwy rzeczowo Sąd - Sąd Rejonowy dla Warszawy-Woli wWarszawie; 01-473 Warszawa; ul. Kocjana 3`
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              alignment: 'center',
              children: [
                new TextRun({
                  text: '§ 8',
                  bold: true,
                  size: 22
                })
              ]
            }),
            new Paragraph({
              text: `Umowa została sporządzona w 2 (słownie: dwóch ) jednobrzmiących egzemplarzach,pojednymdla
              każdej ze stron.`
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),

            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: ''
            }),
            new Paragraph({
              text: '……………………………                                                                        ……………………………'
            }),
            new Paragraph({
              text: '          zamawiający                                                                                                  wykonawca '
            })
          ]
        }
      ]
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, documentName);
    });
  };
  return (
    <div>
      <Box className={classes.modalFooter}>
        <Box className={classes.modalFooterButtons}>
          <Button
            className={classes.modalFooterCancel}
            sx={{ marginRight: '6rem' }}
            variant={'contained'}
            onClick={generate}>
            <FormattedMessage id="Export Doc" defaultMessage="Export Doc" />
          </Button>
          <Button
            className={classes.modalFooterCancel}
            sx={{ marginRight: '6rem' }}
            variant={'outlined'}
            onClick={handleClose}>
            <FormattedMessage id="Cancel" defaultMessage="Cancel" />
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default PrintDocument;
