import { Box, Button, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile } from '../redux/actions';
import useStyles from '../styles';

function UploadFile({ uploadFunction, url }) {
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [error, setError] = useState({
    file: '',
    fileName: ''
  });
  const { upload_loading } = useSelector((state) => state.document);

  const handleSuccess = () => {
    uploadFunction();
    setFile(null);
    setFileName('');
  };

  const handleUpload = () => {
    setError({ file: '', fileName: '' });
    if (!file) {
      setError((prev) => ({ ...prev, file: 'Upload a file' }));
    }

    if (!fileName) {
      setError((prev) => ({ ...prev, fileName: 'Give a file name' }));
    }

    if (file && fileName) {
      dispatch(
        uploadFile(url.at(-1)?.id, { file: file, name: fileName, _method: 'put' }, handleSuccess)
      );
    }
  };

  const handleFileName = (e) => {
    setFileName(e.target.value);
    setError((prev) => ({ ...prev, fileName: '' }));
  };

  const handleFile = (e) => {
    setFile(e.target.files[0]);
    e.target.files[0]?.name.split('.')[0]
      ? setFileName(e.target.files[0]?.name.split('.')[0])
      : setFileName('');
    setError((prev) => ({ ...prev, file: '' }));
  };
  return (
    <div className={classes.createWrap}>
      <Box marginBottom={2}>
        <input type="file" onChange={handleFile} />
        {error?.file && <Box style={{ color: 'red', marginTop: '5px' }}>{error?.file} </Box>}
      </Box>
      <input
        type="text"
        className={classes.input}
        placeholder="File Name"
        value={fileName}
        onChange={handleFileName}
      />
      {error?.fileName && <Box style={{ color: 'red', marginTop: '5px' }}>{error?.fileName} </Box>}

      <Box marginTop={3}>
        {!upload_loading ? (
          <Button variant="contained" onClick={handleUpload}>
            <FormattedMessage id="Upload" defaultMessage="Upload" />
          </Button>
        ) : (
          <Button variant="contained" disabled>
            <CircularProgress size={18} sx={{ marginRight: '10px' }} />
            <FormattedMessage id="Uploading" defaultMessage="Uploading" />
          </Button>
        )}
      </Box>
    </div>
  );
}

export default UploadFile;
