import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { generateCorrectionPayroll } from '../redux/actions';
import useStyles from './styles';

export default function CorrectionModal({ item, handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { generate_correction_loading } = useSelector((state) => state.payroll);
  console.log(item);
  const handleGenerateCorrection = () => {
    const date = new Date(item?.date);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    dispatch(generateCorrectionPayroll(item?.id, year, month));
  };
  return (
    <>
      <Box padding={5}>
        <Typography variant="h5" textAlign="center">
          {
            <FormattedMessage
              id="Are you sure want to generate correction payroll?"
              defaultMessage="Are you sure want to generate correction payroll?"
            />
          }
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-around" className={classes.modalFooter}>
        <>
          {generate_correction_loading ? (
            <Button variant={'contained'} disabled>
              <CircularProgress size={18} /> <FormattedMessage id="Yes" defaultMessage="Yes" />
            </Button>
          ) : (
            <Button variant={'contained'} onClick={handleGenerateCorrection}>
              {<FormattedMessage id="Yes" defaultMessage="Yes" />}
            </Button>
          )}
        </>

        <Button className={classes.modalFooterCancel} variant={'outlined'} onClick={handleClose}>
          {<FormattedMessage id="No" defaultMessage="No" />}
        </Button>
      </Box>
    </>
  );
}
