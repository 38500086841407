import { Box, Grid } from '@mui/material';
import { HookFormCheckbox } from 'components/form/hookFormCheckbox';
import { HookFormInput } from 'components/form/hookFormInput';
import { HookFormRadio } from 'components/form/hookFormRadio';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

function BasicDetail() {
  const { single_loading } = useSelector((state) => state.employee);
  return (
    <Grid container spacing={2}>
      <Grid item sm={4}>
        <HookFormInput
          name="first_name"
          label={<FormattedMessage id="First Name" defaultMessage="First Name" />}
          placeholder="Albert "
          loading={single_loading}
        />
      </Grid>
      <Grid item sm={4}>
        <HookFormInput
          name="last_name"
          label={<FormattedMessage id="Last Name" defaultMessage="Last Name" />}
          placeholder="Jones"
          loading={single_loading}
        />
      </Grid>
      <Grid item sm={4}>
        <HookFormInput
          name="middle_name"
          label={<FormattedMessage id="Second Name" defaultMessage="Second Name" />}
          placeholder="Flores"
          loading={single_loading}
        />
      </Grid>
      <Grid item sm={4}>
        <Box marginTop={2}>
          <HookFormCheckbox
            name="employee_detail[foreigner]"
            type="checkbox"
            label={<FormattedMessage id="Foreigner" defaultMessage="Foreigner" />}
            loading={single_loading}
          />
        </Box>
      </Grid>
      <Grid item sm={4}>
        <HookFormInput
          name="pesel"
          type="number"
          label={<FormattedMessage id="PESEL" defaultMessage="PESEL" />}
          placeholder="234523"
          loading={single_loading}
        />
      </Grid>

      <Grid item sm={4}>
        <HookFormInput
          name="tax_id"
          type="number"
          label={
            <FormattedMessage
              id="NIP (tax identification number)"
              defaultMessage="NIP (tax identification number)"
            />
          }
          placeholder="0224344543"
          loading={single_loading}
        />
      </Grid>
      <Grid item sm={4}>
        <HookFormRadio
          loading={single_loading}
          name="gender"
          label={<FormattedMessage id="Gender" defaultMessage="Gender" />}
          options={[
            { label: <FormattedMessage id="Female" defaultMessage="Female" />, value: 'female' },
            { label: <FormattedMessage id="Male" defaultMessage="Male" />, value: 'male' }
          ]}
          placeholder="148-225-1354"
          tooltipLabel={
            <FormattedMessage id="Unique number-9 digits" defaultMessage="Unique number-9 digits" />
          }
        />
      </Grid>
      <Grid item sm={4}>
        <HookFormInput
          name="email"
          label={<FormattedMessage id="Email Address" defaultMessage="Email Address" />}
          type="email"
          placeholder="alma.lawson@example.com"
          loading={single_loading}
        />
      </Grid>
      <Grid item sm={4}>
        <HookFormInput
          name="employee_detail[phone]"
          label={<FormattedMessage id="Phone Number" defaultMessage="Phone Number" />}
          placeholder="(977)32344545"
        />
      </Grid>
      <Grid item sm={6}>
        <HookFormInput
          name="bank_name"
          label={<FormattedMessage id="Bank Name" defaultMessage="Bank Name" />}
          placeholder="Global Bank"
        />
      </Grid>
      <Grid item sm={6}>
        <HookFormInput
          name="account_number"
          label={<FormattedMessage id="Account Number" defaultMessage="Account Number" />}
          placeholder="1232344545"
        />
      </Grid>
    </Grid>
  );
}

export default BasicDetail;
