import * as React from 'react';
import Paper from '@mui/material/Paper';

import { Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import TableContent from './TableContent';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

export default function ItemTable({ fetchItemPayroll, company_id }) {
  const columns = [
    {
      title: <FormattedMessage id="S.N." defaultMessage="S.N." />,
      type: 'Index',
      isSortable: true,
      minWidth: 20
    },
    {
      title: <FormattedMessage id="Employee" defaultMessage="Employee" />,
      minWidth: 200,
      field: 'employee',
      align: 'left'
    },
    {
      field: 'type',
      title: <FormattedMessage id="Type" defaultMessage="Type" />,
      minWidth: '100',
      align: 'left'
    },
    {
      field: 'payroll',
      title: <FormattedMessage id="Payroll" defaultMessage="Payroll" />,
      minWidth: 100,
      align: 'left'
    },
    {
      field: 'agreement',
      title: <FormattedMessage id="Agreement" defaultMessage="Agreement" />,
      minWidth: 160,
      align: 'left'
    },

    {
      field: 'paycheck',
      title: <FormattedMessage id="Paycheck" defaultMessage="Paycheck" />,
      minWidth: 90,
      align: 'left'
    },
    {
      field: 'expense',
      title: <FormattedMessage id="Social Security" defaultMessage="Social Security" />,
      minWidth: 120,
      align: 'left'
    },
    {
      field: 'base_salary',
      title: <FormattedMessage id="Base Salary" defaultMessage="Base Salary" />,
      minWidth: 120,
      align: 'left'
    },
    {
      field: 'tax_amount',
      title: <FormattedMessage id="Tax Amount" defaultMessage="Tax Amount" />,
      minWidth: 100,
      align: 'left'
    },
    {
      field: 'status',
      title: <FormattedMessage id="Status" defaultMessage="Status" />,
      minWidth: '100',
      align: 'left'
    },
    {
      field: 'action',
      title: <FormattedMessage id="Actions" defaultMessage="Actions" />,
      minWidth: 150,
      align: 'left'
    }
  ];
  const { item_loading } = useSelector((state) => state.payroll);

  return (
    <Paper sx={{ width: '100%', overflow: 'scroll' }}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((item, index) => (
              <TableCell key={index} sx={{ minWidth: item?.minWidth }} align={item?.align}>
                {item?.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!item_loading ? (
            <TableContent
              columns={columns}
              fetchItemPayroll={fetchItemPayroll}
              company_id={company_id}
            />
          ) : (
            [...Array(8).keys()].map((index) => (
              <TableRow key={index}>
                {columns.map((item) => (
                  <TableCell key={item.field} sx={{ minWidth: item?.minWidth }} align={item?.align}>
                    <Skeleton height={30} />
                  </TableCell>
                ))}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </Paper>
  );
}
