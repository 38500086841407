export const CREATE_OVERTIME_BEGIN = 'CREATE_OVERTIME_BEGIN';
export const CREATE_OVERTIME_SUCCESS = 'CREATE_OVERTIME_SUCCESS';
export const CREATE_OVERTIME_ERROR = 'CREATE_OVERTIME_ERROR';

export const GET_OVERTIME_BEGIN = 'GET_OVERTIME_BEGIN';
export const GET_OVERTIME_SUCCESS = 'GET_OVERTIME_SUCCESS';
export const GET_OVERTIME_ERROR = 'GET_OVERTIME_ERROR';

export const EDIT_OVERTIME_BEGIN = 'EDIT_OVERTIME_BEGIN';
export const EDIT_OVERTIME_SUCCESS = 'EDIT_OVERTIME_SUCCESS';
export const EDIT_OVERTIME_ERROR = 'EDIT_OVERTIME_ERROR';

export const DELETE_OVERTIME_BEGIN = 'DELETE_OVERTIME_BEGIN';
export const DELETE_OVERTIME_SUCCESS = 'DELETE_OVERTIME_SUCCESS';
export const DELETE_OVERTIME_ERROR = 'DELETE_OVERTIME_ERROR';

export const GET_OVERTIME_AMOUNT_BEGIN = 'GET_OVERTIME_AMOUNT_BEGIN';
export const GET_OVERTIME_AMOUNT_SUCCESS = 'GET_OVERTIME_AMOUNT_SUCCESS';
export const GET_OVERTIME_AMOUNT_ERROR = 'GET_OVERTIME_AMOUNT_ERROR';

export const OVERTIME_AMOUNT_CLEAR = 'OVERTIME_AMOUNT_CLEAR';
