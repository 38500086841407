import { Box, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import AddressContainer from './components/AddressContainer';
import AddressData from './components/AddressData';
import BasicDetail from './components/BasicDetail';
import DetailedInfo from './components/DetailedInfo';
import Zus from './components/Zus';
import useStyles from './styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function EmployeeForm({ action }) {
  const classes = useStyles();
  const [value, setVal] = React.useState(0);
  const { single_employee } = useSelector((state) => state.employee);

  const handleChange = (event, newValue) => {
    setVal(newValue);
  };

  const {
    setValue,
    reset,
    watch,
    formState: { errors }
  } = useFormContext();

  useEffect(() => {
    reset();
    setValue('first_name', single_employee?.first_name || '');
    setValue('last_name', single_employee?.last_name || '');
    setValue('middle_name', single_employee?.middle_name || '');
    setValue('gender', single_employee?.gender || '');
    setValue('pesel', single_employee?.pesel);
    setValue('tax_id', single_employee?.tax_id || '');
    setValue('email', single_employee?.email || '');
    setValue('department_id', single_employee?.department?.id || null);
    setValue('bank_name', single_employee?.bank_name || '');
    setValue('account_number', single_employee?.account_number || '');

    single_employee?.employee_detail?.map((item) => {
      setValue(`employee_detail[${item.key}]`, item.value);
    });

    setValue('address', single_employee?.address);

    // setValue('address', single_employee?.address || []);
  }, [single_employee]);

  const checkError = () => {
    if (
      errors['employee_detail.identification_number'] ||
      errors['employee_detail.identification_type']
    ) {
      return { color: 'red' };
    }
  };

  console.log(watch(), 'value)');

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className={classes.rootTab}>
          <Tab
            label={<FormattedMessage id="Basic Detail" defaultMessage="Basic Detail" />}
            {...a11yProps(0)}
          />
          <Tab
            label={
              <span style={checkError()}>
                <FormattedMessage id="Detailed Info" defaultMessage="Detailed Info" />
              </span>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={<FormattedMessage id="Address Data" defaultMessage="Address Data" />}
            {...a11yProps(2)}
          />
          <Tab
            label={<FormattedMessage id="ZUS and US" defaultMessage="ZUS and US" />}
            {...a11yProps(3)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className={classes.contentWrap}>
          <BasicDetail />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className={classes.contentWrap}>
          <DetailedInfo />
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className={classes.contentWrap}>
          <Box mb={3} display="flex" alignItems="center" columnGap={1}>
            <Typography sx={{ fontSize: '13px' }}>
              <FormattedMessage id="Home Address" defaultMessage="Home Address" />
            </Typography>
          </Box>
          <AddressData type="home" index={0} />

          <AddressContainer />
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div className={classes.contentWrap}>
          <Zus />
        </div>
      </TabPanel>
    </>
  );
}

export default EmployeeForm;
