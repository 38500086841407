import moment from 'moment';
import { FormattedMessage } from 'react-intl';

export const parseAddress = (addressData = [], remove, removeKey) => {
  const finalAddress = addressData.map((address, index) => {
    if (index == 0) {
      return { ...address, same_address_type: '' };
    } else if (address.same_address_type) {
      return {
        type: address.type,
        same_address_type: address.same_address_type
      };
    } else {
      return address;
    }
  });
  return finalAddress;
};

export const singleParameterParser = (data) => {
  let type = '';
  let val = data.par_value;
  if (isNaN(val)) {
    type = 'string';
  } else {
    val = Number(val);
    const totalDecimals = countDecimals(val);
    if (totalDecimals == 0) {
      type = 'integer';
    } else if (totalDecimals > 0 && totalDecimals <= 2) {
      type = 'float';
    } else {
      type = 'double';
    }
  }
  return {
    id: data?.id || null,
    par_name: data.par_name,
    date_s: data.date_s ? moment(data?.date_s).format('YYYY-MM-DD') : '',
    par_type: type,
    par_value: val
  };
};

const countDecimals = (value) => {
  if (Math.floor(value) === value) return 0;
  return value.toString().split('.')[1].length || 0;
};

export const ViewFormattedMessage = ({ value = 'any', addon = '' }) => {
  return (
    <>
      <FormattedMessage id={value} defaultMessage={value} /> {addon}
    </>
  );
};

export const viewFormattedMessage = (key, addon = '') => {
  return (
    <>
      <FormattedMessage id={key} defaultMessage={key} /> {addon}
    </>
  );
};

export const streams = () => {
  const eventSource = new EventSource('http://192.168.1.86:80/bulk_action/action-stream');

  eventSource.onmessage = function (event) {
    const data = JSON.parse(event.data);
    // if (data.time) {
    //   document.getElementById('time').innerHTML = data.time;
    // }
    // const newElement = document.createElement('li');
    // const eventList = document.getElementById('list');

    // newElement.textContent = 'message: ' + event.data;
    // eventList.appendChild(newElement);
    console.log(data, 'streamdata');
    return data;
  };
};
