import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './styles.js';
function TableView({ data, index }) {
  const classes = styles();
  return (
    <div>
      <Table className={classes.tableRoot}>
        <TableBody>
          <TableRow>
            <TableCell>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{index}</TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableCell>
            <TableCell>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2} width="300px">
                      {data?.last_name ?? ''} {data?.first_name ?? ''}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="Gross Salary" defaultMessage="Gross Salary" />
                    </TableCell>
                    <TableCell>{data?.contract_salary}</TableCell>
                  </TableRow>
                  {data?.input_allowances != 0 && (
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id="Total Allowance" defaultMessage="Total Allowance" />
                      </TableCell>
                      <TableCell>{data?.input_allowances}</TableCell>
                    </TableRow>
                  )}
                  {data?.input_overtime != 0 && (
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id="Total Overtime" defaultMessage="Total Overtime" />
                      </TableCell>
                      <TableCell>{data?.input_overtime}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableCell>
            <TableCell>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <FormattedMessage id="Bases" defaultMessage="Bases" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage
                        id="Social security contribution base"
                        defaultMessage="Social security contribution base"
                      />
                    </TableCell>
                    <TableCell>{data?.nfz_contribution_base?.toFixed(2)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="Gross Basis" defaultMessage="Gross Basis" />
                    </TableCell>
                    <TableCell>{data?.net_salary_base?.toFixed(2)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="Tax Base" defaultMessage="Tax Base" />
                    </TableCell>
                    <TableCell>{data?.tax_base?.toFixed(2)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableCell>
            <TableCell>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <FormattedMessage id="Social Security" defaultMessage="Social Security" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.sickness_insurance_employee != 0 && (
                    <TableRow>
                      <TableCell>
                        <FormattedMessage
                          id="Sickness insurance contribution"
                          defaultMessage="Sickness insurance contribution"
                        />
                      </TableCell>
                      <TableCell>{data?.sickness_insurance_employee?.toFixed(2)}</TableCell>
                    </TableRow>
                  )}
                  {data?.pension_insurance_employee != 0 && (
                    <TableRow>
                      <TableCell>
                        <FormattedMessage
                          id="Pension insurance contribution"
                          defaultMessage="Pension insurance contribution"
                        />
                      </TableCell>
                      <TableCell>{data?.pension_insurance_employee?.toFixed(2)}</TableCell>
                    </TableRow>
                  )}
                  {data?.disability_insurance_employee != 0 && (
                    <TableRow>
                      <TableCell>
                        <FormattedMessage
                          id="Disability insurance contribution"
                          defaultMessage="Disability insurance contribution"
                        />
                      </TableCell>
                      <TableCell>{data?.disability_insurance_employee?.toFixed(2)}</TableCell>
                    </TableRow>
                  )}
                  {data?.employee_total_insurance != 0 && (
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id="Total" defaultMessage="Total" />
                      </TableCell>
                      <TableCell>{data?.employee_total_insurance?.toFixed(2)}</TableCell>
                    </TableRow>
                  )}
                  {data?.nfz_contribution != 0 && (
                    <TableRow>
                      <TableCell>
                        <FormattedMessage
                          id="To social security"
                          defaultMessage="To social security"
                        />
                      </TableCell>
                      <TableCell>{data?.nfz_contribution?.toFixed(2)}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableCell>
            <TableCell>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <FormattedMessage id="Employer Cost" defaultMessage="Employer Cost" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.pension_insurance_employer != 0 && (
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id="Pension" defaultMessage="Pension" />
                      </TableCell>
                      <TableCell>{data?.pension_insurance_employer?.toFixed(2)}</TableCell>
                    </TableRow>
                  )}
                  {data?.disability_insurance_employer != 0 && (
                    <TableRow>
                      <TableCell>
                        <FormattedMessage
                          id="Disability Pension"
                          defaultMessage="Disability Pension"
                        />
                      </TableCell>
                      <TableCell>{data?.disability_insurance_employer?.toFixed(2)}</TableCell>
                    </TableRow>
                  )}
                  {data?.accident_insurance_employer != 0 && (
                    <TableRow>
                      <TableCell>
                        <FormattedMessage
                          id="Accident insurance"
                          defaultMessage="Accident insurance"
                        />
                      </TableCell>
                      <TableCell>{data?.accident_insurance_employer?.toFixed(2)}</TableCell>
                    </TableRow>
                  )}
                  {data?.labor_fund_insurance_employer != 0 && (
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id="FP" defaultMessage="FP" />
                      </TableCell>
                      <TableCell>{data?.labor_fund_insurance_employer?.toFixed(2)}</TableCell>
                    </TableRow>
                  )}
                  {data?.fgsp_insurance_employer != 0 && (
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id="FGSP" defaultMessage="FGSP" />
                      </TableCell>
                      <TableCell>{data?.fgsp_insurance_employer?.toFixed(2)}</TableCell>
                    </TableRow>
                  )}
                  {data?.fep_insurance_employer != 0 && (
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id="FEP" defaultMessage="FEP" />
                      </TableCell>
                      <TableCell>{data?.fep_insurance_employer?.toFixed(2)}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableCell>
            <TableCell>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <FormattedMessage id="Tax" defaultMessage="Tax" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="Free amount" defaultMessage="Free amount" />
                    </TableCell>
                    <TableCell>{data?.free_amount?.toFixed(2)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage
                        id="To social security"
                        defaultMessage="To social security"
                      />
                    </TableCell>
                    <TableCell>{data?.nfz_contribution?.toFixed(2)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="Income costs" defaultMessage="Income costs" />
                    </TableCell>
                    <TableCell>{data?.cost_of_getting_income?.toFixed(2)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage
                        id="Tax after rounding"
                        defaultMessage="Tax after rounding"
                      />
                    </TableCell>
                    <TableCell>{data?.tax_after_free_amount?.toFixed(2)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableCell>
            <TableCell>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <FormattedMessage id="Summary" defaultMessage="Summary" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="Gross Basis" defaultMessage="Gross Basis" />
                    </TableCell>
                    <TableCell>{data?.net_salary_base?.toFixed(2)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="Net" defaultMessage="Net" />
                    </TableCell>
                    <TableCell>{data?.net_salary?.toFixed(2)}</TableCell>
                  </TableRow>
                  {data?.input_deductions != 0 && (
                    <TableRow>
                      <TableCell>
                        <FormattedMessage id="Total deduction" defaultMessage="Total deduction" />
                      </TableCell>
                      <TableCell>{data?.input_deductions?.toFixed(2)}</TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="To be paid" defaultMessage="To be paid" />
                    </TableCell>
                    <TableCell>{data?.salary_to_pay?.toFixed(2)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}

export default TableView;
