import { Button } from '@mui/material';
import { Box } from '@mui/system';
import CustomModal from 'components/globals/modal';
import ContractSelect from 'components/locals/contract/ContractSelect';
import EmployeeContractTable from 'components/locals/contract/EmployeeContractTable';
import { getEmployeeContract } from 'components/locals/contract/redux/actions';
import useToggle from 'hooks/useToggle';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';

function EmployeeContract({ employeeId }) {
  const dispatch = useDispatch();
  const [modalOpen, openFunction] = useToggle(false);

  useEffect(() => {
    employeeId && dispatch(getEmployeeContract(employeeId));
  }, []);

  return (
    <>
      <Box marginBottom={2}>
        <Button onClick={openFunction} variant="contained">
          <AddIcon /> {<FormattedMessage id="Add" defaultMessage="Add" />}{' '}
        </Button>
      </Box>
      <EmployeeContractTable employeeId={employeeId} />
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="35rem"
        headerText={
          <FormattedMessage
            id="Select the type of contract"
            defaultMessage="Select the type of contract"
          />
        }>
        <ContractSelect handleClose={openFunction} employeeId={employeeId} />
      </CustomModal>
    </>
  );
}

export default EmployeeContract;
