// this will be the componene for filterForm
import { Box } from '@mui/system';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import useStyles from './styles';
import { FormattedMessage } from 'react-intl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from 'utils';
import { addCollectiveFilter } from '../../redux/actions';

const Filterform = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  status,
  setStatus,
  setAgreementId,
  companyId,
  setCompanyId
}) => {
  const classes = useStyles();
  const { agreement_types } = useSelector((state) => state.contract);
  const dispatch = useDispatch();
  return (
    <div className={classes.root}>
      <Box display="none">
        <FormattedMessage id="Status" defaultMessage="Status" />
        <FormattedMessage id="Open" defaultMessage="Open" />
        <FormattedMessage id="Close" defaultMessage="Close" />
      </Box>
      <div className={classes.optionWrap}>
        <Box className={classes.dateWrapper}>
          <DatePicker
            views={['year', 'month']}
            label={<FormattedMessage id="Start Date" defaultMessage="Start Date" />}
            value={startDate}
            name="start_date"
            onChange={(newValue) => {
              dispatch(addCollectiveFilter({ key: 'startDate', value: newValue }));
              setStartDate(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} helperText={null} format="YYYY-MM-DD" />
            )}
          />
          <div className={classes.hyphen}>-</div>
          <DatePicker
            views={['year', 'month']}
            label={<FormattedMessage id="End Date" defaultMessage="End Date" />}
            value={endDate}
            name="end_date"
            onChange={(newValue) => {
              dispatch(addCollectiveFilter({ key: 'endDate', value: newValue }));

              setEndDate(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} helperText={null} format="YYYY-MM-DD" />
            )}
          />
        </Box>
        <select
          className={classes.select}
          value={status}
          onChange={(e) => {
            dispatch(addCollectiveFilter({ key: 'status', value: e.target.value }));
            setStatus(e.target.value);
          }}>
          <option value="">{translate('Status')}</option>
          <option value="OPEN">{translate('Open')}</option>
          <option value="CLOSED">{translate('Close')}</option>
        </select>
        <select
          className={classes.select}
          onChange={(e) => {
            dispatch(addCollectiveFilter({ key: 'type', value: e.target.value }));
            setAgreementId(e.target.value);
          }}>
          <option value="">{translate('Type of Contract')}</option>
          {agreement_types?.map((agreement) => (
            <option value={agreement?.id} key={agreement?.id}>
              {agreement?.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Filterform;
