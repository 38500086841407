import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getEzla } from '../setup/redux/actions';

const EzlaForm = () => {
  const dispatch = useDispatch();
  const { ezla_loading } = useSelector((state) => state.setup);

  const [values, setValues] = useState({
    username: '',
    password: '',
    zipPassword: '',
    nip: '',
    date: '2023-04-01'
  });

  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleSubmit = () => {
    dispatch(getEzla(values));
  };

  return (
    <div>
      <Box marginBottom={4}>
        <Typography variant="h4">
          <FormattedMessage id="Ezla Test Form" defaultMessage="Ezla Test Form" />
        </Typography>
      </Box>
      <Grid container>
        <Grid item sm={2}>
          <Box>
            <label htmlFor="">
              <Typography variant="h5" marginBottom={1}>
                <FormattedMessage id="Email" defaultMessage="Email" />
              </Typography>
            </label>
          </Box>
          <TextField
            type="text"
            autoComplete="off"
            name="username"
            value={values.username}
            onChange={handleChange}
          />
        </Grid>
        <Grid item sm={2}>
          <Box>
            <label htmlFor="">
              <Typography variant="h5" marginBottom={1}>
                <FormattedMessage id="Password" defaultMessage="Password" />
              </Typography>
            </label>
          </Box>
          <TextField
            type="password"
            name="password"
            autoComplete="new-password"
            value={values.password}
            onChange={handleChange}
          />
        </Grid>
        <Grid item sm={2}>
          <Box>
            <label htmlFor="">
              <Typography variant="h5" marginBottom={1}>
                <FormattedMessage id="Zip Password" defaultMessage="Zip Password" />
              </Typography>
            </label>
          </Box>
          <TextField
            type="password"
            autoComplete="off"
            name="zipPassword"
            value={values.zipPassword}
            onChange={handleChange}
          />
        </Grid>
        <Grid item sm={2}>
          <Box>
            <label htmlFor="">
              <Typography variant="h5" marginBottom={1}>
                <FormattedMessage id="Nip" defaultMessage="Nip" />
              </Typography>
            </label>
          </Box>
          <TextField type="text" name="nip" value={values.nip} onChange={handleChange} />
        </Grid>
        <Grid item sm={2}>
          <Box>
            <label htmlFor="">
              <Typography variant="h5" marginBottom={1}>
                <FormattedMessage id="Date" defaultMessage="Date" />
              </Typography>
            </label>
          </Box>
          <TextField type="date" name="date" value={values.date} onChange={handleChange} />
        </Grid>
        <Grid item sm={2}>
          <Box marginTop={3.5}>
            {ezla_loading ? (
              <Button variant="contained" disabled>
                <Box marginRight={2}>
                  <CircularProgress size={14} />
                </Box>
                <FormattedMessage id="Submitting" defaultMessage="Submitting" />
              </Button>
            ) : (
              <Button variant="contained" onClick={handleSubmit}>
                <FormattedMessage id="Submit" defaultMessage="Submit" />
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default EzlaForm;
