import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AuthRoutes from './authRoutes';
import DashboardRoutes from './dashboardRoutes';
import Login from 'pages/login';
import NotFound from 'pages/misc/NotFound';

const RouteList = () => {
  const routeList = [
    {
      path: '/',
      component: <Login />
    },

    ...AuthRoutes,
    ...DashboardRoutes
  ];

  return (
    <Routes>
      {routeList.map(({ path, component }) => {
        return <Route key={path} path={path} element={component} />;
      })}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default RouteList;
