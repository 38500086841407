// this will be the componene for filterForm
import { Box } from '@mui/system';
import React from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import useStyles from './styles';
import { yearList } from 'utils/dateUtils';
import { FormattedMessage } from 'react-intl';
import { translate } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { addItemFilter } from '../../redux/actions';

// const Example = () => {
//   const [startDate, setStartDate] = useState(new Date());
//   return <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />;
// };

const Filterform = ({ month, year, setYear, setMonth }) => {
  const classes = useStyles();
  const { agreement_types } = useSelector((state) => state.contract);

  const months = [
    { label: translate('January'), value: 1 },
    { label: translate('February'), value: 2 },
    { label: translate('March'), value: 3 },
    { label: translate('April'), value: 4 },
    { label: translate('May'), value: 5 },
    { label: translate('June'), value: 6 },
    { label: translate('July'), value: 7 },
    { label: translate('August'), value: 8 },
    { label: translate('September'), value: 9 },
    { label: translate('October'), value: 10 },
    { label: translate('November'), value: 11 },
    { label: translate('December'), value: 12 }
  ];

  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <div className={classes.optionWrap}>
        <Box className={classes.dateWrapper}>
          <span>{<FormattedMessage id="Year" defaultMessage="Year" />}:</span>{' '}
          <select
            className={classes.dateFilter}
            value={year}
            onChange={(e) => {
              dispatch(addItemFilter({ key: 'year', value: e.target.value }));
              setYear(e.target.value);
            }}>
            {yearList?.map((item) => (
              <option value={item?.value} key={item.label}>
                {item?.label}
              </option>
            ))}
          </select>
          <div className={classes.hyphen}>-</div>
          <span>{<FormattedMessage id="Month" defaultMessage="Month" />}</span>{' '}
          <select
            className={classes.dateFilter}
            value={month}
            onChange={(e) => {
              dispatch(addItemFilter({ key: 'month', value: e.target.value }));

              setMonth(e.target.value);
            }}>
            {months?.map((item) => (
              <option value={item?.value} key={item.label}>
                {item?.label}
              </option>
            ))}
          </select>
        </Box>
        <select className={classes.select}>
          <option value="">{translate('Type of Contract')}</option>
          {agreement_types?.map((item) => (
            <option value={item?.id} key={item?.id}>
              {item?.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Filterform;
