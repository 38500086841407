import { Box, Button } from '@mui/material';
import CustomModal from 'components/globals/modal';
import CustomTable from 'components/globals/table';
import useToggle from 'hooks/useToggle';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import EditIcon from '@mui/icons-material/Edit';
import { FormattedMessage } from 'react-intl';
import { translate } from 'utils';
import { default as useStyle, default as useStyles } from '../../styles';
import OpeningEdit from './Edit';

function OpeningView({ year, handleClose, employeeId, contractTypeId }) {
  const classes = useStyle();
  const { contributions } = useSelector((state) => state.contract);
  const [yearData, setYearData] = useState([]);
  const months = [
    { label: translate('January'), value: 1 },
    { label: translate('February'), value: 2 },
    { label: translate('March'), value: 3 },
    { label: translate('April'), value: 4 },
    { label: translate('May'), value: 5 },
    { label: translate('June'), value: 6 },
    { label: translate('July'), value: 7 },
    { label: translate('August'), value: 8 },
    { label: translate('September'), value: 9 },
    { label: translate('October'), value: 10 },
    { label: translate('November'), value: 11 },
    { label: translate('December'), value: 12 }
  ];

  const columns = [
    {
      title: <FormattedMessage id="S.N." defaultMessage="S.N." />,
      type: 'Index',
      isSortable: true,
      minWidth: 20
    },

    {
      field: (row) => months.find((item) => item.value == parseInt(row?.month))?.label,
      title: <FormattedMessage id="Month" defaultMessage="Month" />,
      minWidth: 170,
      align: 'right'
    },

    {
      field: 'tax_base',
      title: <FormattedMessage id="Tax" defaultMessage="Tax" />,
      minWidth: 80,
      align: 'right'
    },
    {
      field: (row) => {
        return (
          <Actions
            year={row?.year}
            employeeId={employeeId}
            month={row?.month}
            contractTypeId={contractTypeId}
          />
        );
      },
      title: <FormattedMessage id="Actions" defaultMessage="Actions" />,
      minWidth: 170,
      align: 'center'
    }
  ];

  useEffect(() => {
    if (contributions?.openingBalances?.length > 0) {
      const groupByYear = contributions?.openingBalances?.reduce((group, item) => {
        group[item.year] = group[item.year] ?? [];
        group[item.year].push(item);
        return group;
      }, {});
      setYearData(groupByYear[year]);
    }
  }, [contributions]);
  return (
    <div className={classes.content}>
      <Box>
        <CustomTable tableData={yearData} tableHeads={columns} />
      </Box>
      <Box className={classes.modalFooter}>
        <Box className={classes.modalFooterButtons}>
          <Button className={classes.modalFooterCancel} variant={'outlined'} onClick={handleClose}>
            <FormattedMessage id="Cancel" defaultMessage="Cancel" />
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default OpeningView;

const Actions = ({ year, employeeId, month, contractTypeId }) => {
  const handleClick = () => {
    openFunction();
  };
  const classes = useStyles();
  const [modalOpen, openFunction] = useToggle(false);

  return (
    <>
      <div className={classes.iconWrapper}>
        <EditIcon onClick={handleClick} />
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        width="50rem"
        height="auto"
        headerText="The basis for assessing contributions from other jobs">
        <OpeningEdit
          year={year}
          employeeId={employeeId}
          handleClose={handleClick}
          month={month}
          contractTypeId={contractTypeId}
        />
      </CustomModal>
    </>
  );
};
