import { NavigateNext } from '@mui/icons-material';
import { Box, Breadcrumbs, Button, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import useStyles from '../styles';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import useToggle from 'hooks/useToggle';
import CustomModal from 'components/globals/modal';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createFolder } from '../redux/actions';
import UploadFile from './UploadFile';
import { FormattedMessage } from 'react-intl';

function DocumentBreadCrumb({ url, setUrl, isEmployee = false }) {
  const [uploadOpen, uploadFunction] = useToggle(false);
  const [createOpen, createFunction] = useToggle(false);
  const [folderName, setFolderName] = useState('');

  const dispatch = useDispatch();

  const classes = useStyles();
  const { create_loading } = useSelector((state) => state.document);

  const checkLast = (index) => {
    if (index + 1 == url?.length) {
      return true;
    } else {
      return false;
    }
  };

  const handleClick = (index) => {
    const finalUrl = url?.slice(0, index + 1);
    setUrl([...finalUrl]);
  };

  const checkParentId = () => {
    if (isEmployee) {
      return url?.at(-1)?.id;
    } else if (url?.length > 2) {
      return url?.at(-1)?.id;
    } else return null;
  };

  const checkEmployee = () => {
    if (url && url[1]?.type == 'employee') {
      return 1;
    } else return 0;
  };
  const handleCreate = () => {
    if (folderName) {
      setFolderName('');
      dispatch(
        createFolder(
          { name: folderName, parent_id: checkParentId(), is_employee_folder: checkEmployee() },
          createFunction
        )
      );
    }
  };

  const showButton = () => {
    if (isEmployee) {
      return true;
    } else if (url?.length > 1) {
      if (url?.length == 2) {
        if (url[1]?.type == 'employee') {
          return false;
        } else return true;
      } else return true;
    } else return false;
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ marginBottom: '1rem', borderLeft: '2px solid #4E8AF4', paddingLeft: '0.8rem' }}>
          {url?.map((item, index) =>
            checkLast(index) ? (
              <Typography className={classes.breadcrumbItem} key={index}>
                {item?.name}
              </Typography>
            ) : (
              <Typography
                className={classes.breadcrumbItemActive}
                key={index}
                onClick={() => handleClick(index)}>
                {item?.name}
              </Typography>
            )
          )}
        </Breadcrumbs>
        {showButton() && (
          <div className={classes.buttonWrap}>
            <Button variant="outlined" onClick={uploadFunction}>
              <FileUploadIcon />
              <FormattedMessage id="Upload File" defaultMessage="Upload File" />
            </Button>
            <Button variant="contained" onClick={createFunction}>
              <CreateNewFolderIcon />
              <FormattedMessage id="Create Folder" defaultMessage="Create Folder" />
            </Button>
          </div>
        )}
      </Box>
      <CustomModal
        open={uploadOpen}
        handleClose={uploadFunction}
        height="auto"
        width="30rem"
        headerText={<FormattedMessage id="Upload File" defaultMessage="Upload File" />}>
        <UploadFile uploadFunction={uploadFunction} url={url} />
      </CustomModal>
      <CustomModal
        open={createOpen}
        handleClose={createFunction}
        height="auto"
        width="30rem"
        headerText={<FormattedMessage id="Create Folder" defaultMessage="Create Folder" />}>
        <div className={classes.createWrap}>
          <input
            type="text"
            className={classes.input}
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
          />
          <Box marginTop={3}>
            {!create_loading ? (
              <Button variant="contained" onClick={handleCreate}>
                Create
              </Button>
            ) : (
              <Button variant="contained" disabled>
                <CircularProgress size={18} sx={{ marginRight: '10px' }} />
                Creating
              </Button>
            )}
          </Box>
        </div>
      </CustomModal>
    </>
  );
}

export default DocumentBreadCrumb;
