export const GET_COMPANY_ASSIGNMENT_BEGIN = 'GET_COMPANY_ASSIGNMENT_BEGIN';
export const GET_COMPANY_ASSIGNMENT_SUCCESS = 'GET_COMPANY_ASSIGNMENT_SUCCESS';
export const GET_COMPANY_ASSIGNMENT_ERROR = 'GET_COMPANY_ASSIGNMENT_ERROR';

export const GET_ASSIGNMENT_COMPANY_BEGIN = 'GET_ASSIGNMENT_COMPANY_BEGIN';
export const GET_ASSIGNMENT_COMPANY_SUCCESS = 'GET_ASSIGNMENT_COMPANY_SUCCESS';
export const GET_ASSIGNMENT_COMPANY_ERROR = 'GET_ASSIGNMENT_COMPANY_ERROR';

export const ASSIGN_COMPANY_BEGIN = 'ASSIGN_COMPANY_BEGIN';
export const ASSIGN_COMPANY_SUCCESS = 'ASSIGN_COMPANY_SUCCESS';
export const ASSIGN_COMPANY_ERROR = 'ASSIGN_COMPANY_ERROR';

export const DELETE_ASSIGN_COMPANY_BEGIN = 'DELETE_ASSIGN_COMPANY_BEGIN';
export const DELETE_ASSIGN_COMPANY_SUCCESS = 'DELETE_ASSIGN_COMPANY_SUCCESS';
export const DELETE_ASSIGN_COMPANY_ERROR = 'DELETE_ASSIGN_COMPANY_ERROR';
