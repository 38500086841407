import BreadCrumb from 'components/globals/breadCrumb';
import MainAreaLayout from 'layouts/dashboardLayout/mainAreaLayout/MainAreaLayout';
import React, { useEffect } from 'react';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import DocumentAdd from './DocumentAdd';
import DocumentPanel from './DocumentPanel';
import { useDispatch } from 'react-redux';
import { getAllEmployeeFolder, getOtherFolder } from './redux/actions';
import { FormattedMessage } from 'react-intl';

function Document() {
  const [modalOpen, openFunction] = useToggle(false);
  const dispatch = useDispatch();

  const handleSearch = () => {
    console.log('searched');
  };

  useEffect(() => {
    dispatch(getAllEmployeeFolder());
    dispatch(getOtherFolder());
  }, []);

  return (
    <>
      <BreadCrumb
        itemName={<FormattedMessage id="Document" defaultMessage="Document" />}
        page={<FormattedMessage id="Document" defaultMessage="Document" />}
        handleSearch={handleSearch}
      />
      <MainAreaLayout>
        <DocumentPanel />
      </MainAreaLayout>

      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="35rem"
        headerText={
          <FormattedMessage
            id="Select the type of contract"
            defaultMessage="Select the type of contract"
          />
        }>
        <DocumentAdd handleClose={openFunction} />
      </CustomModal>
    </>
  );
}

export default Document;
