import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { deleteOvertime } from './redux/actions';
import useStyles from './styles';

function DeleteOvertime({ handleClose, row, employeeId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { delete_loading } = useSelector((state) => state.overtime);
  const handleDelete = () => {
    dispatch(deleteOvertime(row?.id, employeeId, handleClose));
  };
  return (
    <>
      <Box padding={5}>
        <Typography variant="h5" textAlign="center">
          {
            <FormattedMessage
              id="Are you sure to delete it?"
              defaultMessage="Are you sure to delete it?"
            />
          }
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-around" className={classes.modalFooter}>
        <>
          {delete_loading ? (
            <Button variant={'contained'} disabled>
              <CircularProgress size={18} /> <FormattedMessage id="Yes" defaultMessage="Yes" />
            </Button>
          ) : (
            <Button variant={'contained'} onClick={handleDelete}>
              {<FormattedMessage id="Yes" defaultMessage="Yes" />}
            </Button>
          )}
        </>

        <Button className={classes.modalFooterCancel} variant={'outlined'} onClick={handleClose}>
          {<FormattedMessage id="No" defaultMessage="No" />}
        </Button>
      </Box>
    </>
  );
}

export default DeleteOvertime;
