export const CREATE_ALLOWANCE_BEGIN = 'CREATE_ALLOWANCE_BEGIN';
export const CREATE_ALLOWANCE_SUCCESS = 'CREATE_ALLOWANCE_SUCCESS';
export const CREATE_ALLOWANCE_ERROR = 'CREATE_ALLOWANCE_ERROR';

export const GET_ALLOWANCE_BEGIN = 'GET_ALLOWANCE_BEGIN';
export const GET_ALLOWANCE_SUCCESS = 'GET_ALLOWANCE_SUCCESS';
export const GET_ALLOWANCE_ERROR = 'GET_ALLOWANCE_ERROR';

export const GET_SINGLE_ALLOWANCE_BEGIN = 'GET_SINGLE_ALLOWANCE_BEGIN';
export const GET_SINGLE_ALLOWANCE_SUCCESS = 'GET_SINGLE_ALLOWANCE_SUCCESS';
export const GET_SINGLE_ALLOWANCE_ERROR = 'GET_SINGLE_ALLOWANCE_ERROR';

export const EDIT_ALLOWANCE_BEGIN = 'EDIT_ALLOWANCE_BEGIN';
export const EDIT_ALLOWANCE_SUCCESS = 'EDIT_ALLOWANCE_SUCCESS';
export const EDIT_ALLOWANCE_ERROR = 'EDIT_ALLOWANCE_ERROR';

export const DELETE_ALLOWANCE_BEGIN = 'DELETE_ALLOWANCE_BEGIN';
export const DELETE_ALLOWANCE_SUCCESS = 'DELETE_ALLOWANCE_SUCCESS';
export const DELETE_ALLOWANCE_ERROR = 'DELETE_ALLOWANCE_ERROR';
