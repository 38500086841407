// this will be the componene for filterForm
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from 'utils';
import { addCompanyFilter } from '../redux/actions';
import useStyles from './styles';

const Filterform = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  paymentDate,
  setPayment,
  status,
  setStatus,
  payout,
  setPayout,
  mailSent,
  setMailSent,
  selectedCompany,
  setSelectedCompany,
  setAgreementId,
  agreementId
}) => {
  const classes = useStyles();
  const { agreement_types } = useSelector((state) => state.contract);
  const { company } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [companyList, setCompanyList] = useState([]);
  useEffect(() => {
    const filteredCompany = company
      ?.map((list) => {
        if (list?.user_is_admin === 1) {
          return { label: list?.name, value: list?.id };
        }
      })
      ?.filter((list) => list);
    setCompanyList(filteredCompany);
  }, [company]);

  return (
    <div className={classes.root}>
      <Box display="none">
        <FormattedMessage id="Status" defaultMessage="Status" />
        <FormattedMessage id="Open" defaultMessage="Open" />
        <FormattedMessage id="Close" defaultMessage="Close" />
        <FormattedMessage id="Yes" defaultMessage="Yes" />
        <FormattedMessage id="No" defaultMessage="No" />
        <FormattedMessage id="Company" defaultMessage="Company" />
        <FormattedMessage id="Mail Sent" defaultMessage="Mail Sent" />
        <FormattedMessage id="Payout Order" defaultMessage="Payout Order" />
      </Box>
      <div className={classes.optionWrap}>
        <Box className={classes.dateWrapper}>
          <DatePicker
            views={['year', 'month']}
            label={<FormattedMessage id="Start Date" defaultMessage="Start Date" />}
            value={startDate}
            name="start_date"
            onChange={(newValue) => {
              dispatch(addCompanyFilter({ key: 'startDate', value: newValue }));
              setStartDate(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} helperText={null} format="YYYY-MM-DD" />
            )}
          />
          <div className={classes.hyphen}>-</div>
          <DatePicker
            views={['year', 'month']}
            label={<FormattedMessage id="End Date" defaultMessage="End Date" />}
            value={endDate}
            name="end_date"
            onChange={(newValue) => {
              dispatch(addCompanyFilter({ key: 'endDate', value: newValue }));

              setEndDate(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} helperText={null} format="YYYY-MM-DD" />
            )}
          />
          <Box className={classes.payoutDate}>
            <DatePicker
              label={<FormattedMessage id="Payment Date" defaultMessage="Payment Date" />}
              value={paymentDate}
              name="payment_date"
              onChange={(newValue) => {
                dispatch(addCompanyFilter({ key: 'paymentDate', value: newValue }));

                setPayment(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} helperText={null} format="YYYY-MM-DD" />
              )}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-start" width={'100%'}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-label" shrink>
              <FormattedMessage id="Type of Contract" defaultMessage="Type of Contract" />
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={agreementId}
              defaultValue=""
              input={<OutlinedInput notched label="Type of Contract" />}
              onChange={(e) => {
                dispatch(addCompanyFilter({ key: 'type', value: e.target.value }));
                setAgreementId(e.target.value);
              }}>
              <MenuItem value="">
                <FormattedMessage id="None" defaultMessage="None" />
              </MenuItem>
              {agreement_types?.map((agreement) => (
                <MenuItem value={agreement?.id} key={agreement?.id}>
                  {agreement?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-label" shrink>
              <FormattedMessage id="Status" defaultMessage="Status" />
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={status}
              defaultValue=""
              input={<OutlinedInput notched label="Status" />}
              onChange={(e) => {
                dispatch(addCompanyFilter({ key: 'status', value: e.target.value }));
                setStatus(e.target.value);
              }}>
              <MenuItem value="">
                <FormattedMessage id="None" defaultMessage="None" />
              </MenuItem>
              <MenuItem value="OPEN">{translate('Open')}</MenuItem>
              <MenuItem value="CLOSED">{translate('Close')}</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-label" shrink>
              <FormattedMessage id="Payout Order" defaultMessage="Payout Order" />
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={payout}
              defaultValue=""
              input={<OutlinedInput notched label="Payout Order" />}
              onChange={(e) => {
                dispatch(addCompanyFilter({ key: 'payout', value: e.target.value }));
                setPayout(e.target.value);
              }}>
              <MenuItem value="">
                <FormattedMessage id="None" defaultMessage="None" />
              </MenuItem>
              <MenuItem value="1">{translate('Yes')}</MenuItem>
              <MenuItem value="0">{translate('No')}</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-label" shrink>
              <FormattedMessage id="Mail Sent" defaultMessage="Mail Sent" />
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={mailSent}
              defaultValue=""
              input={<OutlinedInput notched label="Mail Sent" />}
              onChange={(e) => {
                dispatch(addCompanyFilter({ key: 'mailSent', value: e.target.value }));
                setMailSent(e.target.value);
              }}>
              <MenuItem value="">
                <FormattedMessage id="None" defaultMessage="None" />
              </MenuItem>
              <MenuItem value="1">{translate('Yes')}</MenuItem>
              <MenuItem value="0">{translate('No')}</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-label" shrink>
              <FormattedMessage id="Company" defaultMessage="Company" />
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedCompany}
              defaultValue=""
              input={<OutlinedInput notched label="Company" />}
              onChange={(e) => {
                dispatch(addCompanyFilter({ key: 'selectedCompany', value: e.target.value }));
                setSelectedCompany(e.target.value);
              }}>
              <MenuItem value="">
                <FormattedMessage id="None" defaultMessage="None" />
              </MenuItem>
              {companyList?.map((list, index) => (
                <MenuItem key={index} value={list?.value}>
                  {list?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </div>
    </div>
  );
};

export default Filterform;
