import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useEffect, useRef, useState } from 'react';
import ReactToPdf from 'react-to-pdf';
import EmployeeContent from './EmployeeContent';
import Header from './Header';
import MandateContent from './MandateContent';
import useStyles from './styles';

function PayslipView({ item }) {
  const classes = useStyles();
  const [keyData, setKeyData] = useState({});
  const [inputData, setInputData] = useState({});
  const [overTimeData, setOverTimeData] = useState([]);
  const ref = useRef();

  useEffect(() => {
    setKeyData({});
    setInputData({});
    item?.outputs?.map((list) => setKeyData((keyData) => ({ ...keyData, [list.name]: list })));
    item?.inputs?.map((list) => setInputData((keyData) => ({ ...keyData, [list.name]: list })));
    const filteredData = item?.outputs?.filter(
      (list) => list?.name === 'contract_overtime_references'
    );
    setOverTimeData(filteredData);
  }, [item]);

  console.log(item, 'item');

  return (
    <>
      <div className={classes.root} ref={ref}>
        <Header item={item} keyData={keyData} />
        {item?.agreement?.agreement_type_slug == 'employment-contract' && (
          <EmployeeContent
            keyData={keyData}
            inputData={inputData}
            item={item}
            overTimeData={overTimeData}
          />
        )}
        {item?.agreement?.agreement_type_slug !== 'employment-contract' && (
          <MandateContent
            keyData={keyData}
            inputData={inputData}
            item={item}
            overTimeData={overTimeData}
          />
        )}
      </div>
      <ReactToPdf targetRef={ref} filename="payslip.pdf" scale={0.8}>
        {({ toPdf }) => (
          <div className={classes.buttonWrapper}>
            <button className={classes.downloadButton} onClick={toPdf}>
              <FileDownloadIcon /> Export
            </button>
          </div>
        )}
      </ReactToPdf>
    </>
  );
}

export default PayslipView;
