import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { translate } from 'utils';

const useStyles = makeStyles(() => ({
  listWrap: {
    display: 'flex',
    justifyContent: 'flex-start',
    columnGap: '60px',
    fontSize: '13px',
    marginBottom: '15px'
  },
  first: {
    width: '300px',
    fontWeight: '600'
  },
  second: {
    flex: '1'
  }
}));

const TerminationContainer = () => {
  const classes = useStyles();
  const { single_contract, termination_data } = useSelector((state) => state.contract);

  const checkInitiator = (key) => {
    if (key == 'employee') {
      return translate('Employee');
    }
    if (key === 'employer') {
      return translate('Employer');
    }
  };

  const checkExpiry = (key) => {
    return termination_data?.expiration_codes?.find((list) => list?.id == key)?.title;
  };

  const checkRegistration = (key) => {
    return termination_data?.deregistration_reasons?.find((list) => list?.id == key)?.title;
  };
  return (
    <ul>
      <li className={classes.listWrap}>
        <div className={classes.first}>
          <FormattedMessage id="Date" defaultMessage="Date" />
        </div>
        :<div>{single_contract?.termination_details?.date}</div>
      </li>
      <li className={classes.listWrap}>
        <div className={classes.first}>
          <FormattedMessage
            id="Reason for Deregistration"
            defaultMessage="Reason for Deregistration"
          />
        </div>
        :
        <div className={classes.second}>
          {checkRegistration(single_contract?.termination_details?.deregistration_reason_id)}
        </div>
      </li>
      <li className={classes.listWrap}>
        <div className={classes.first}>
          <FormattedMessage
            id="Expiration Code / Dissolution Mode"
            defaultMessage="Expiration Code / Dissolution Mode"
          />
        </div>
        :
        <div className={classes.second}>
          {checkExpiry(single_contract?.termination_details?.expiration_code_id)}
        </div>
      </li>
      <li className={classes.listWrap}>
        <div className={classes.first}>
          <FormattedMessage id="Legal Basis" defaultMessage="Legal Basis" />
        </div>
        :<div className={classes.second}>{single_contract?.termination_details?.legal_basis}</div>
      </li>

      <li className={classes.listWrap}>
        <div className={classes.first}>
          <FormattedMessage
            id="Party on whose initiative the termination of employment took place"
            defaultMessage="Party on whose initiative the termination of employment took place"
          />
        </div>
        :<div>{checkInitiator(single_contract?.termination_details?.initiator_party)}</div>
      </li>
      {single_contract?.termination_details?.vacation_days && (
        <li className={classes.listWrap}>
          <div className={classes.first}>
            <FormattedMessage id="Pay the Equivalent of" defaultMessage="Pay the Equivalent of" />
          </div>
          :
          <div>
            {single_contract?.termination_details?.vacation_days}{' '}
            <FormattedMessage id="Days" defaultMessage="Days" />
          </div>
        </li>
      )}
    </ul>
  );
};

export default TerminationContainer;
