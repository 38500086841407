import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentBreadCrumb from './components/DocumentBreadCrumb';
import FileWrapper from './components/FileWrapper';
import NoFiles from './components/NoFiles';
import { getSingleFolder } from './redux/actions';
import CircularProgress from '@mui/material/CircularProgress';
import useStyles from './styles';
import { Box } from '@mui/system';
import { FormattedMessage } from 'react-intl';
import { translate } from 'utils';

function DocumentPanel() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { employee_document, other_document, single_folder, single_folder_loading } = useSelector(
    (state) => state.document
  );
  const [url, setUrl] = useState([{ name: translate('Root Directory'), id: null }]);
  const initialFolders = [
    { name: translate('Employee Folders'), id: null, type: 'employee' },
    { name: translate('Company Folders'), id: null, type: 'company' }
  ];

  const handleUrl = (newUrl) => {
    setUrl([...url, newUrl]);
  };

  useEffect(() => {
    if (url?.length > 2) {
      dispatch(getSingleFolder(url.at(-1)?.id));
    }
  }, [url]);

  return (
    <>
      <div className={classes.documentPanel}>
        <DocumentBreadCrumb url={url} setUrl={setUrl} />
        {url?.length > 2 ? (
          !single_folder_loading ? (
            single_folder?.sub_folders?.length > 0 || single_folder?.files?.length > 0 ? (
              <div>
                {single_folder?.sub_folders?.map((item) => (
                  <FileWrapper
                    fileType="folder"
                    key={item?.id}
                    name={item?.name}
                    extension={item?.extension}
                    id={item?.id}
                    url={item?.url}
                    handleUrl={handleUrl}
                    type="client"
                  />
                ))}
                {single_folder?.files?.map((item) => (
                  <FileWrapper
                    fileType="file"
                    key={item?.id}
                    name={item?.name}
                    extension={item?.extension}
                    url={item?.url}
                    id={item?.id}
                    handleUrl={handleUrl}
                    type="client"
                  />
                ))}
              </div>
            ) : (
              <NoFiles url={url} />
            )
          ) : (
            <div className={classes.loader}>
              <CircularProgress />
            </div>
          )
        ) : url?.length > 1 ? (
          <div>
            {url?.at(-1).type == 'employee'
              ? employee_document?.map((item) => (
                  <FileWrapper
                    key={item?.id}
                    name={item?.name}
                    id={item?.id}
                    handleUrl={handleUrl}
                    type="employee"
                  />
                ))
              : other_document?.map((item) => (
                  <FileWrapper
                    key={item?.id}
                    name={item?.name}
                    id={item?.id}
                    handleUrl={handleUrl}
                    type="client"
                  />
                ))}
          </div>
        ) : (
          <div>
            {initialFolders?.map((item, index) => (
              <FileWrapper
                key={index}
                name={item?.name}
                id={item?.id}
                handleUrl={handleUrl}
                type={item?.type}
              />
            ))}
          </div>
        )}
      </div>
      <Box display="none">
        <FormattedMessage id="Employee Folders" defaultMessage="Employee Folders" />
        <FormattedMessage id="Company Folders" defaultMessage="Company Folders" />
      </Box>
    </>
  );
}

export default DocumentPanel;
