import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import HookFormAutoComplete from 'components/form/hookFormAutoComplete';
import { HookFormInput } from 'components/form/hookFormInput';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { translate } from 'utils';
import { changeDateFormat } from 'utils/dateUtils';

function BasicDetail({ contractId }) {
  const { single_loading } = useSelector((state) => state.employee);

  return (
    <Grid container spacing={2}>
      <Box display="none">
        <FormattedMessage
          id="Indefinite Period of Time"
          defaultMessage="Indefinite Period of Time"
        />
        <FormattedMessage id="One Time" defaultMessage="One Time" />
        <FormattedMessage id="Fixed Term" defaultMessage="Fixed Term" />
      </Box>
      <Grid item sm={4}>
        <HookFormInput
          loading={single_loading}
          name="type_gross"
          label={<FormattedMessage id="Salary Type" defaultMessage="Salary Type" />}
          defaultValue="Gross"
          disabled
        />
      </Grid>
      <Grid item sm={4}>
        <HookFormAutoComplete
          loading={single_loading}
          name="parameters.rate"
          label={<FormattedMessage id="Rate" defaultMessage="Rate" />}
          options={[
            {
              label: translate('Monthly'),
              value: 'monthly'
            },
            {
              label: translate('One Time'),
              value: 'one-time'
            }
          ]}
        />
      </Grid>
      <Grid item sm={4}>
        <HookFormInput
          name="gross_salary"
          label={<FormattedMessage id="Amount" defaultMessage="Amount" />}
          type="number"
          placeholder="3000"
          loading={single_loading}
        />
      </Grid>

      <Grid item sm={4}>
        <HookFormInput
          disabled
          name="type"
          label={<FormattedMessage id="Type of Contract" defaultMessage="Type of Contract" />}
          loading={single_loading}
        />
      </Grid>

      <Grid item sm={4}>
        <HookFormInput
          name="date_of_signing"
          type="date"
          label={<FormattedMessage id="Date of Signing" defaultMessage="Date of Signing" />}
          loading={single_loading}
          inputProps={{ max: changeDateFormat(new Date(), 'YYYY-MM-DD') }}
        />
      </Grid>

      <Grid item sm={4}>
        <HookFormInput
          name="start_date"
          type="date"
          label={<FormattedMessage id="Start Date" defaultMessage="Start Date" />}
          loading={single_loading}
        />
      </Grid>

      <Grid item sm={4}>
        <HookFormInput
          name="end_date"
          type="date"
          label={<FormattedMessage id="End Date" defaultMessage="End Date" />}
          loading={single_loading}
        />
      </Grid>
    </Grid>
  );
}

export default BasicDetail;
