import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Grid, Skeleton } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { calculateAgreement } from '../../redux/actions';
import BasicView from '../components/BasicView';
import useStyles from './styles';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

const ViewList = ({ label, value }) => {
  const classes = useStyles();
  const { single_loading } = useSelector((state) => state.employee);
  return (
    <li>
      <div className={classes.title}>{label}</div>
      <div className={classes.value}>
        {single_loading ? (
          <Skeleton height={16} animation="wave" variant="rectangular" width={200} />
        ) : (
          value
        )}
      </div>
    </li>
  );
};

export default function BasicDetail({ employeeName, single_contract }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');
  const { agreement_types } = useSelector((state) => state.contract);
  const dispatch = useDispatch();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const getAgreementType = () => {
    const agreementType = agreement_types?.find(
      (item) => item?.id == single_contract?.agreement_type_id
    );
    return agreementType?.name || '';
  };

  useEffect(() => {
    single_contract && dispatch(calculateAgreement(single_contract));
  }, [single_contract]);
  return (
    <div className={classes.accordianRoot}>
      <Accordion
        disableGutters
        elevation={0}
        square
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography variant="h5">
            {<FormattedMessage id="Info" defaultMessage="Info" />}
          </Typography>
        </AccordionSummary>
        <MuiAccordionDetails>
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <ul>
                <ViewList
                  label={<FormattedMessage id="Employee Name" defaultMessage="Employee Name" />}
                  value={employeeName}
                />
                <ViewList
                  label={<FormattedMessage id="Gross" defaultMessage="Gross" />}
                  value={single_contract?.gross_salary}
                />
                <ViewList
                  label={<FormattedMessage id="Rate" defaultMessage="Rate" />}
                  value={single_contract?.parameters?.rate}
                />

                <ViewList
                  label={<FormattedMessage id="Place of work" defaultMessage="Place of work" />}
                  value={single_contract?.parameters?.place_of_work}
                />
              </ul>
            </Grid>
            <Grid item sm={6}>
              <ul>
                <ViewList
                  label={<FormattedMessage id="Contract Number" defaultMessage="Contract Number" />}
                  value={single_contract?.contract_number}
                />
                <ViewList
                  label={
                    <FormattedMessage id="Type of Contract" defaultMessage="Type of Contract" />
                  }
                  value={getAgreementType()}
                />
                <ViewList
                  label={<FormattedMessage id="Date of Signing" defaultMessage="Date of Signing" />}
                  value={single_contract?.date_of_signing}
                />
                <ViewList
                  label={<FormattedMessage id="Start Date" defaultMessage="Start Date" />}
                  value={single_contract?.start_date}
                />
              </ul>
            </Grid>
          </Grid>
        </MuiAccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography variant="h5">
            {<FormattedMessage id="Calculation Preview" defaultMessage="Calculation Preview" />}
          </Typography>
        </AccordionSummary>
        <MuiAccordionDetails>
          <MuiAccordionDetails>
            <BasicView />
          </MuiAccordionDetails>
        </MuiAccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography variant="h5">
            {<FormattedMessage id="Income Tax" defaultMessage="Income Tax" />}
          </Typography>
        </AccordionSummary>
        <MuiAccordionDetails>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <ul>
                <ViewList
                  label={<FormattedMessage id="Tax relief" defaultMessage="Tax Relief" />}
                  value={single_contract?.parameters?.tax_relief}
                />
                <ViewList
                  label={<FormattedMessage id="Tax Rate" defaultMessage="Tax Rate" />}
                  value={single_contract?.parameters?.tax_rate}
                />
                <ViewList
                  label={
                    <FormattedMessage
                      id="Cost of getting income"
                      defaultMessage="Cost of getting income"
                    />
                  }
                  value={single_contract?.parameters?.cost_of_getting_income}
                />
                <ViewList
                  label={<FormattedMessage id="PIT exemption" defaultMessage="PIT exemption" />}
                  value={single_contract?.parameters?.pit_exemption}
                />
              </ul>
            </Grid>
          </Grid>
        </MuiAccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel4d-header">
          <Typography variant="h5">ZUS</Typography>
        </AccordionSummary>
        <MuiAccordionDetails>
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <ul>
                <ViewList
                  label={
                    <FormattedMessage
                      id="Insurance Title Code"
                      defaultMessage="Insurance Title Code"
                    />
                  }
                  value={single_contract?.parameters?.insurance_code_id}
                />

                <ViewList
                  label={<FormattedMessage id="FGSP" defaultMessage="FGSP" />}
                  value={
                    single_contract?.parameters?.fgsp_insurance_employer === '1' ? 'Yes' : 'No'
                  }
                />
                <ViewList
                  label={<FormattedMessage id="Labor Fund" defaultMessage="Labor Fund" />}
                  value={
                    single_contract?.parameters?.labor_fund_insurance_employer === '1'
                      ? 'Yes'
                      : 'No'
                  }
                />
                <ViewList
                  label={
                    <FormattedMessage id="Pension Insurance" defaultMessage="Pension Insurance" />
                  }
                  value={single_contract?.parameters?.pension_insurance === '1' ? 'Yes' : 'No'}
                />
              </ul>
            </Grid>
            <Grid item sm={6}>
              <ul>
                <ViewList
                  label={
                    <FormattedMessage
                      id="Disability Insurance"
                      defaultMessage="Disability Insurance"
                    />
                  }
                  value={single_contract?.parameters?.disability_insurance === '1' ? 'Yes' : 'No'}
                />
                <ViewList
                  label={
                    <FormattedMessage id="Sickness Insurance" defaultMessage="Sickness Insurance" />
                  }
                  value={single_contract?.parameters?.sickness_insurance === '1' ? 'Yes' : 'No'}
                />
                <ViewList
                  label={
                    <FormattedMessage id="Accident Insurance" defaultMessage="Accident Insurance" />
                  }
                  value={single_contract?.parameters?.accident_insurance === '1' ? 'Yes' : 'No'}
                />
                <ViewList
                  label={
                    <FormattedMessage id="NFZ Contribution" defaultMessage="NFZ Contribution" />
                  }
                  value={single_contract?.parameters?.nfz_contribution === '1' ? 'Yes' : 'No'}
                />
              </ul>
            </Grid>
          </Grid>
        </MuiAccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel5d-header">
          <Typography variant="h5">
            {
              <FormattedMessage
                id="Other terms of mandate"
                defaultMessage="Other terms of mandate"
              />
            }
          </Typography>
        </AccordionSummary>
        <MuiAccordionDetails>
          <Box sx={{ marginLeft: '15px', color: 'gray' }}>
            {single_contract?.parameters?.terms_of_mandate}
          </Box>
        </MuiAccordionDetails>
      </Accordion>
    </div>
  );
}
