export const CREATE_TIME_RECORDING_BEGIN = 'CREATE_TIME_RECORDING_BEGIN';
export const CREATE_TIME_RECORDING_SUCCESS = 'CREATE_TIME_RECORDING_SUCCESS';
export const CREATE_TIME_RECORDING_ERROR = 'CREATE_TIME_RECORDING_ERROR';

export const GET_TIME_RECORDING_BEGIN = 'GET_TIME_RECORDING_BEGIN';
export const GET_TIME_RECORDING_SUCCESS = 'GET_TIME_RECORDING_SUCCESS';
export const GET_TIME_RECORDING_ERROR = 'GET_TIME_RECORDING_ERROR';

export const EDIT_TIME_RECORDING_BEGIN = 'EDIT_TIME_RECORDING_BEGIN';
export const EDIT_TIME_RECORDING_SUCCESS = 'EDIT_TIME_RECORDING_SUCCESS';
export const EDIT_TIME_RECORDING_ERROR = 'EDIT_TIME_RECORDING_ERROR';

export const DELETE_TIME_RECORDING_BEGIN = 'DELETE_TIME_RECORDING_BEGIN';
export const DELETE_TIME_RECORDING_SUCCESS = 'DELETE_TIME_RECORDING_SUCCESS';
export const DELETE_TIME_RECORDING_ERROR = 'DELETE_TIME_RECORDING_ERROR';

export const GET_HOLIDAY_BASIS_BEGIN = 'GET_HOLIDAY_BASIS_BEGIN';
export const GET_HOLIDAY_BASIS_SUCCESS = 'GET_HOLIDAY_BASIS_SUCCESS';
export const GET_HOLIDAY_BASIS_ERROR = 'GET_HOLIDAY_BASIS_ERROR';

export const UPDATE_HOLIDAY_BASIS_BEGIN = 'UPDATE_HOLIDAY_BASIS_BEGIN';
export const UPDATE_HOLIDAY_BASIS_SUCCESS = 'UPDATE_HOLIDAY_BASIS_SUCCESS';
export const UPDATE_HOLIDAY_BASIS_ERROR = 'UPDATE_HOLIDAY_BASIS_ERROR';

export const GET_HOLIDAY_ADDON_BEGIN = 'GET_HOLIDAY_ADDON_BEGIN';
export const GET_HOLIDAY_ADDON_SUCCESS = 'GET_HOLIDAY_ADDON_SUCCESS';
export const GET_HOLIDAY_ADDON_ERROR = 'GET_HOLIDAY_ADDON_ERROR';
