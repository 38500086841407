import { axiosInstance } from 'apis/_axios';

// create timeRecording
export const createTimeRecordingApi = (data) => {
  return axiosInstance().post('/actions/vacation-sick-leave', data);
};

// get all timeRecording
export const getTimeRecordingApi = (employee_id) => {
  return axiosInstance().get(`/actions/vacation-sick-leave?employee_id=${employee_id}`);
};

// edit timeRecording
export const editTimeRecordingApi = (id, data) => {
  return axiosInstance().put(`/actions/vacation-sick-leave/${id}`, data);
};

// edit timeRecording
export const deleteTimeRecordingApi = (id) => {
  return axiosInstance().delete(`/actions/vacation-sick-leave/${id}`);
};

// get holiday basis
export const getHolidayBasisApi = (data) => {
  return axiosInstance().get(`/payroll/employee-holiday-basis`, { params: data });
};

// update holiday basis
export const updateHolidayBasisApi = (data) => {
  return axiosInstance().post(`/payroll/employee-holiday-basis`, data);
};

// get holiday addons
export const getHolidayAddonsApi = () => {
  return axiosInstance().get(`/actions/holiday-addons`);
};
