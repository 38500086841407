import {
  createStatementApi,
  deleteStatementApi,
  editStatementApi,
  getStatementApi,
  getStatementByIdApi
} from 'apis/statement';
import { errorToast, successToast } from 'utils/toast';
import * as actions from './types';

// create Statement
export const createStatement = (data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.CREATE_STATEMENT_BEGIN
  });
  createStatementApi(data)
    .then((res) => {
      dispatch({
        type: actions.CREATE_STATEMENT_SUCCESS
      });
      handleFunction && handleFunction();
      dispatch(getStatement(data?.agreement_id));
      successToast('Statement created Successfully');
    })
    .catch((error) => {
      dispatch({
        type: actions.CREATE_STATEMENT_ERROR
      });
      errorToast(error);
    });
};

// get all statement
export const getStatement = (agreement_id) => (dispatch) => {
  dispatch({
    type: actions.GET_STATEMENT_BEGIN
  });
  getStatementApi(agreement_id)
    .then((res) => {
      dispatch({
        type: actions.GET_STATEMENT_SUCCESS,
        payload: res.data.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_STATEMENT_ERROR
      });
      errorToast(error);
    });
};

// get single statement
export const getStatementById = (id) => (dispatch) => {
  dispatch({
    type: actions.GET_SINGLE_STATEMENT_BEGIN
  });
  getStatementByIdApi(id)
    .then((res) => {
      dispatch({
        type: actions.GET_SINGLE_STATEMENT_SUCCESS,
        payload: res.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_SINGLE_STATEMENT_ERROR
      });
      errorToast(error);
    });
};

// edit statement
export const editStatement = (id, data, employeeId, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.EDIT_STATEMENT_BEGIN
  });
  editStatementApi(id, data)
    .then((res) => {
      dispatch({
        type: actions.EDIT_STATEMENT_SUCCESS
      });
      handleFunction && handleFunction();
      successToast('Statement edited Successfully');
      dispatch(getStatement(employeeId));
    })
    .catch((error) => {
      dispatch({
        type: actions.EDIT_STATEMENT_ERROR
      });
      errorToast(error);
    });
};

// delete statement
export const deleteStatement = (id, handleFunction, agreement_id) => (dispatch) => {
  dispatch({
    type: actions.DELETE_STATEMENT_BEGIN
  });
  deleteStatementApi(id)
    .then((res) => {
      dispatch({
        type: actions.DELETE_STATEMENT_SUCCESS
      });
      handleFunction && handleFunction();
      successToast('Statement deleted Successfully');
      dispatch(getStatement(agreement_id));
    })
    .catch((error) => {
      dispatch({
        type: actions.DELETE_STATEMENT_ERROR
      });
      errorToast(error);
    });
};
