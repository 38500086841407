import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContractById } from '../../redux/actions';

import useStyles from '../../styles';
import PrintDocument from './PrintDocument';

function PrintContract({ contractId, handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { single_contract } = useSelector((state) => state.contract);
  const { departments } = useSelector((state) => state.department);

  useEffect(() => {
    contractId && dispatch(getContractById(contractId));
  }, [contractId]);

  const getDepartment = () => {
    const filteredDepartment = departments?.find(
      (list) => list?.id == single_contract?.department_id
    );
    return filteredDepartment?.title ?? '-';
  };

  const address = single_contract?.employee?.address?.[0];
  const companyAddress = single_contract?.employee?.company?.addresses?.[0];

  const checkValue = (value) => {
    if (value == 'null' || !value) {
      return '';
    } else return value;
  };

  return (
    <>
      <div className={classes.printContractSection}>
        <Box display="flex" justifyContent="space-between" paddingTop={8}>
          <div className={classes.signatureWrapper}>
            <div>
              ..............................................................................
            </div>
            <Typography variant="h6" fontWeight={300}>
              (pieczątka firmy)
            </Typography>
          </div>
          <div className={classes.signatureWrapper}>
            <div>
              ..............................................................................
            </div>
            <Typography variant="h6" fontWeight={300}>
              (miejscowość i data)
            </Typography>
          </div>
        </Box>
        <Box textAlign="center" margin={5}>
          <Typography variant="h5">{single_contract?.agreement_type_name}</Typography>
        </Box>
        <Typography variant="body1">
          zawarta w dniu {single_contract?.date_of_signing} pomiędzy:
        </Typography>
        <Typography variant="body1">
          {single_contract?.employee?.company?.name ?? ''},{' '}
          {single_contract?.employee?.company?.regon ?? ''},
          {single_contract?.employee?.company?.tax_id ?? ''}
        </Typography>
        <Typography variant="body1">
          {checkValue(companyAddress?.street)} {checkValue(companyAddress?.house_number)}{' '}
          {checkValue(companyAddress?.number_of_premises)} {checkValue(companyAddress?.zip)}{' '}
          {checkValue(companyAddress?.post)} {checkValue(companyAddress?.city)}{' '}
          {checkValue(companyAddress?.country)} {checkValue(companyAddress?.voivodeship)}{' '}
          {checkValue(companyAddress?.county)} {checkValue(companyAddress?.community)}{' '}
        </Typography>
        <Typography variant="body1">
          reprezentowanym przez: {single_contract?.representative ?? ''}
        </Typography>
        <Typography variant="body1">
          a {single_contract?.employee?.first_name ?? ''}{' '}
          {single_contract?.employee?.second_name ?? ''}
          {single_contract?.employee?.last_name ?? ''}, {single_contract?.employee?.pesel ?? ''}
        </Typography>
        <Typography variant="body1">zamieszkała/ym</Typography>
        <Typography variant="body1">
          {checkValue(address?.street)} {checkValue(address?.house_number)}{' '}
          {checkValue(address?.number_of_premises)} {checkValue(address?.zip)}{' '}
          {checkValue(address?.post)} {checkValue(address?.city)} {checkValue(address?.country)}{' '}
          {checkValue(address?.voivodeship)} {checkValue(address?.county)}{' '}
          {checkValue(address?.community)}{' '}
        </Typography>
        {single_contract?.type == 'trial' && (
          <Typography variant="body1">
            na okres próbny od {single_contract?.start_date ?? ''} do{' '}
            {single_contract?.end_date ?? ''}.
          </Typography>
        )}
        {single_contract?.type == 'fixed' && (
          <Typography variant="body1">
            na czas określony od {single_contract?.start_date ?? ''} do{' '}
            {single_contract?.end_date ?? ''}.
          </Typography>
        )}
        {single_contract?.type == 'indefinite' && (
          <Typography variant="body1">na czas nieokreślony</Typography>
        )}
        <br />
        <br />
        <br />
        <Typography variant="h5">1. Strony ustalają następujące warunki zatrudnienia:</Typography>
        <br />
        <Typography variant="body1">1) rodzaj wykonywanej pracy:</Typography>
        <Typography variant="body1">
          {single_contract?.position} ({getDepartment()})
        </Typography>
        <br />
        <Typography variant="body1">2) miejsce wykonywania pracy:</Typography>
        <Typography variant="body1">{single_contract?.parameters?.place_of_work ?? ''}</Typography>
        <br />
        <Typography variant="body1">3) wymiar czasu pracy:</Typography>
        <Typography variant="body1">
          {single_contract?.parameters?.work_hour_numerator ?? ''} /
          {single_contract?.parameters?.work_hour_denominator ?? ''}{' '}
        </Typography>
        <br />
        <Typography variant="body1">4) wynagrodzenie</Typography>
        <Typography variant="body1">{single_contract?.gross_salary ?? ''} </Typography>
        <br />
        <Typography variant="body1">5) inne warunki zatrudnienia</Typography>
        <Typography variant="body1">
          {single_contract?.parameters?.terms_of_employment ?? ''}
        </Typography>
        <br />
        <Typography variant="body1">
          6) Termin rozpoczęcia pracy {single_contract?.start_date ?? ''}
        </Typography>
        <br />
        <Typography variant="body1">
          7) .....(dopuszczalna liczba godzin pracy ponad określony w umowie wymiar czasu pracy,
          których przekroczenie uprawnia pracownika, oprócz normalnego wynagrodzenia, do dodatku, o
          którym mowa w art. 151 § 1 Kodeksu pracy- dotyczy umowy o pracę z pracownikiem
          zatrudnionym w niepełnym wymiarze czasu pracy*).
        </Typography>
        <br />
        <br />
        <br />
        <Typography variant="h5">2. Przyczyny uzasadniające zawarcie umowy</Typography>
        <br />
        <Typography variant="h5">
          {' '}
          (informacja, o której mowa w art. 29 § 11 Kodeksu pracy, o obiektywnych przyczynach
          uzasadniających zawarcie umowy o pracę na czas określony **)
        </Typography>

        <Box display="flex" justifyContent="space-between" marginTop={8}>
          <div className={classes.signatureWrapper}>
            <div>
              ..............................................................................
            </div>
            <Typography variant="h6" fontWeight={300}>
              (data i podpis pracownika)
            </Typography>
          </div>
          <div className={classes.signatureWrapper}>
            <div>
              ..............................................................................
            </div>
            <Typography variant="h6" fontWeight={300}>
              (podpis pracodawcy lub osoby reprezentującej pracodawcę)
            </Typography>
          </div>
        </Box>
        <br />
        <br />
        <Typography variant="body1">
          * Dotyczy umowy o pracę z pracownikiem zatrudnianym w niepełnym wymiarze czasu pracy.{' '}
        </Typography>
        <Typography variant="body1">
          **Dotyczy umowy o pracę z pracownikiem zatrudnianym na podstawie umowy o pracę na czas
          określony w celu, o którym mowa w art. 251 § 4 pkt 1-3 Kodeksu pracy, lub w przypadku, o
          którym mowa w art. 251 § 4 pkt 4 Kodeksu pracy.{' '}
        </Typography>
      </div>
      <PrintDocument handleClose={handleClose} />
    </>
  );
}

export default PrintContract;
