import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, CircularProgress, Tooltip, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import CustomModal from 'components/globals/modal';
import CustomTable from 'components/globals/table';
import useToggle from 'hooks/useToggle';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import EditAllowance from './EditTimeRecording';
import { deleteTimeRecording } from './redux/actions';
import useStyles from './styles';
import ViewTimeRecording from './ViewTimeRecording';

export default function TimeRecordingTable({ employeeId }) {
  const columns = [
    { title: 'S.N.', type: 'Index', isSortable: true, minWidth: 20 },
    {
      title: <FormattedMessage id="Name" defaultMessage="Name" />,
      minWidth: 100,
      isSortable: true,
      field: 'name'
    },

    {
      field: (row) => {
        if (row?.type == 'sick_leave') {
          return <FormattedMessage id="Sick Leave" defaultMessage="Sick Leave" />;
        } else if (row?.type == 'vacation_leave') {
          return <FormattedMessage id="Vacation Leave" defaultMessage="Vacation Leave" />;
        } else if (row?.type == 'unpaid_leave') {
          return <FormattedMessage id="Unpaid Leave" defaultMessage="Unpaid Leave" />;
        } else if (row?.type == 'care_leave') {
          return <FormattedMessage id="Care Leave" defaultMessage="Care Leave" />;
        } else {
          return '';
        }
      },
      title: <FormattedMessage id="Type" defaultMessage="Type" />,
      minWidth: 120,
      align: 'right'
    },
    {
      field: 'start_date',
      title: <FormattedMessage id="Start Date" defaultMessage="Start Date" />,
      minWidth: 120,
      align: 'right'
    },
    {
      field: 'end_date',
      title: <FormattedMessage id="End Date" defaultMessage="End Date" />,
      minWidth: 120,
      align: 'right'
    },

    {
      field: (row) => {
        return <Actions row={row} employeeId={employeeId} />;
      },
      title: <FormattedMessage id="Actions" defaultMessage="Actions" />,
      minWidth: 170,
      align: 'center'
    }
  ];

  const { loading, timeRecording } = useSelector((state) => state.timeRecording);
  const rows = timeRecording || [];

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <CustomTable tableData={rows} tableHeads={columns} loading={loading} />
    </Paper>
  );
}

const Actions = ({ row, employeeId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalOpen, openFunction] = useToggle(false);
  const [viewOpen, openViewFunction] = useToggle(false);
  const [showOpen, openShowFunction] = useToggle(false);
  const { delete_loading } = useSelector((state) => state.timeRecording);

  const handleClick = () => {
    openFunction();
  };
  const handleDelete = () => {
    openViewFunction();
  };

  const handleDeleteTimeRecording = () => {
    dispatch(deleteTimeRecording(row?.id, employeeId, handleDelete));
  };

  return (
    <>
      <div className={classes.iconWrapper}>
        <Tooltip title={<FormattedMessage id="Edit" defaultMessage="Edit" />}>
          <EditIcon onClick={handleClick} />
        </Tooltip>
        <Tooltip title={<FormattedMessage id="View" defaultMessage="View" />}>
          <VisibilityIcon onClick={openShowFunction} />
        </Tooltip>
        <Tooltip title={<FormattedMessage id="Delete" defaultMessage="Delete" />}>
          <DeleteIcon onClick={handleDelete} />
        </Tooltip>
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        headerText={
          <FormattedMessage id="Edit Time Recording" defaultMessage="Edit time Recording" />
        }>
        <EditAllowance handleClose={openFunction} row={row} employeeId={employeeId} />
      </CustomModal>
      <CustomModal
        open={showOpen}
        handleClose={openShowFunction}
        height="auto"
        headerText={
          <FormattedMessage id="View Time Recording" defaultMessage="View time Recording" />
        }>
        <ViewTimeRecording handleClose={openShowFunction} row={row} employeeId={employeeId} />
      </CustomModal>
      <CustomModal
        open={viewOpen}
        handleClose={openViewFunction}
        width="36rem"
        height="18rem"
        headerText={`Delete - ${row?.name}`}>
        <Box padding={5}>
          <Typography variant="h5" textAlign="center">
            {
              <FormattedMessage
                id="Are you sure you want to delete"
                defaultMessage="Are you sure you want to delete"
              />
            }
            ?{' '}
          </Typography>
        </Box>
        <Box className={classes.modalFooterDelete}>
          {!delete_loading ? (
            <Button variant={'contained'} onClick={handleDeleteTimeRecording}>
              {<FormattedMessage id="Yes" defaultMessage="Yes" />}
            </Button>
          ) : (
            <Button variant={'contained'} disabled>
              <CircularProgress size={18} />{' '}
              {<FormattedMessage id="Deleting" defaultMessage="Deleting" />}
            </Button>
          )}

          <Button
            className={classes.modalFooterCancel}
            variant={'outlined'}
            onClick={openViewFunction}>
            {<FormattedMessage id="No" defaultMessage="No" />}
          </Button>
        </Box>
      </CustomModal>
    </>
  );
};
