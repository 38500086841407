import * as actions from './types';
const initialState = {
  itemPayrolls: [],
  item_loading: false,
  edit_payroll: false,
  collectivePayrolls: [],
  collective_loading: false,
  close_paylist_loading: false,
  modify_payroll_loading: false,
  generate_correction_loading: false,
  allPayroll: [],
  allPayroll_loading: false,
  itemFilter: {},
  collectiveFilter: {},
  payoutLoading: false,
  viewPayoutLoading: false,
  view_payouts: [],
  mail_loading: false
};

const payrollReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_ITEM_PAYROLL_BEGIN:
      return { ...state, item_loading: true, itemPayrolls: [] };

    case actions.GET_ITEM_PAYROLL_SUCCESS:
      return { ...state, itemPayrolls: action.payload, item_loading: false };

    case actions.GET_ITEM_PAYROLL_ERROR:
      return { ...state, item_loading: false };

    case actions.GET_COLLECTIVE_PAYROLL_BEGIN:
      return { ...state, collective_loading: true, collectivePayrolls: [] };

    case actions.GET_COLLECTIVE_PAYROLL_SUCCESS:
      return { ...state, collectivePayrolls: action.payload, collective_loading: false };

    case actions.GET_COLLECTIVE_PAYROLL_ERROR:
      return { ...state, collective_loading: false };

    case actions.GET_COLLECTIVE_DETAIL_SUCCESS:
      return { ...state, itemPayrolls: action.payload, item_loading: false };

    case actions.EDIT_PAYROLL_BEGIN:
      return { ...state, edit_payroll: true };

    case actions.EDIT_PAYROLL_SUCCESS:
    case actions.EDIT_PAYROLL_ERROR:
      return { ...state, edit_payroll: false };

    case actions.MODIFY_PAYROLL_BEGIN:
      return { ...state, modify_payroll_loading: true };

    case actions.MODIFY_PAYROLL_SUCCESS:
    case actions.MODIFY_PAYROLL_ERROR:
      return { ...state, modify_payroll_loading: false };

    case actions.CLOSE_PAYLIST_BEGIN:
      return { ...state, close_paylist_loading: true };

    case actions.CLOSE_PAYLIST_SUCCESS:
    case actions.CLOSE_PAYLIST_ERROR:
      return { ...state, close_paylist_loading: false };

    case actions.GET_ALL_PAYROLL_BEGIN:
      return { ...state, allPayroll_loading: true, allPayroll: [] };

    case actions.GET_ALL_PAYROLL_SUCCESS:
      return { ...state, allPayroll: action.payload, allPayroll_loading: false };

    case actions.GET_ALL_PAYROLL_ERROR:
      return { ...state, allPayroll_loading: false };

    case actions.ITEM_CORRECTION_PAYROLL_BEGIN:
      return { ...state, generate_correction_loading: true };

    case actions.ITEM_CORRECTION_PAYROLL_SUCCESS:
    case actions.ITEM_CORRECTION_PAYROLL_ERROR:
      return { ...state, generate_correction_loading: false };

    case actions.ADD_ITEM_FILTER:
      return {
        ...state,
        itemFilter: { ...state.itemFilter, [action.payload?.key]: action.payload.value }
      };

    case actions.ADD_COLLECTIVE_FILTER:
      return {
        ...state,
        collectiveFilter: { ...state.collectiveFilter, [action.payload?.key]: action.payload.value }
      };

    case actions.PAYOUT_ORDER_BEGIN:
      return { ...state, payoutLoading: true };

    case actions.PAYOUT_ORDER_SUCCESS:
    case actions.PAYOUT_ORDER_ERROR:
      return { ...state, payoutLoading: false };

    case actions.SEND_PAYOUT_MAIL_BEGIN:
      return { ...state, mail_loading: true };

    case actions.SEND_PAYOUT_MAIL_SUCCESS:
    case actions.SEND_PAYOUT_MAIL_ERROR:
      return { ...state, mail_loading: false };

    default:
      return state;
  }
};

export default payrollReducer;
