import { Edit, Report } from '@mui/icons-material';
import { Box, Button, TextField, Tooltip, Typography } from '@mui/material';
import CustomModal from 'components/globals/modal';
import { getTimeRecording } from 'components/locals/workers/components/timeRecording/redux/actions';
import TimeTableInside from 'components/locals/workers/components/timeRecording/TimeTableInside';
import useToggle from 'hooks/useToggle';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { changeDateFormat } from 'utils/dateUtils';
import { editPayroll } from '../../redux/actions';
import useStyles from '../styles';

function WorkingSettlement({ item, paylistId, paylistDate, data }) {
  const classes = useStyles();
  const date = new Date(paylistDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  return (
    <div className={classes.workingSettlement}>
      <table className={classes.table}>
        <tbody>
          <tr>
            <td colSpan={2}>
              <h4 style={{ background: '#d7d7d7', margin: '0' }}>
                <FormattedMessage id="Working Settlement" defaultMessage="Working Settlement" />
              </h4>
            </td>
          </tr>
          {item?.working_days?.value > 0 && (
            <tr>
              <td>
                <FormattedMessage
                  id="Number of working days in current month (Days)"
                  defaultMessage="Number of working days in current month (Days)"
                />
              </td>
              <td>{item?.working_days?.value}</td>
            </tr>
          )}
          {item?.rate_hours?.value > 0 && (
            <tr>
              <td>
                <FormattedMessage
                  id="Number of working days in current month (Hours)"
                  defaultMessage="Number of working days in current month (Hours)"
                />
              </td>
              <td>{parseFloat(item?.rate_hours?.value).toFixed(2)}</td>
            </tr>
          )}
          {item?.vacation_days?.value > 0 && (
            <tr>
              <td>
                <FormattedMessage
                  id="Number of vacation days"
                  defaultMessage="Number of vacation days"
                />{' '}
              </td>
              <td>{item?.vacation_days?.value}</td>
            </tr>
          )}
          {item?.vacation_hours?.value > 0 && (
            <tr>
              <td>
                <FormattedMessage
                  id="Number of vacation hours"
                  defaultMessage="Number of vacation hours"
                />{' '}
              </td>
              <td>{parseFloat(item?.vacation_hours?.value).toFixed(2)} </td>
            </tr>
          )}
          {item?.vacation_days_remaining?.value > 0 && (
            <tr>
              <td>
                <FormattedMessage
                  id="Remaining vacation to be used"
                  defaultMessage="Remaining vacation to be used"
                />{' '}
              </td>
              <td>{item?.vacation_days_remaining?.value || 0}</td>
            </tr>
          )}
          {item?.vacation_days?.value > 0 && (
            <tr>
              <td>
                <FormattedMessage id="Vacation Pay" defaultMessage="Vacation Pay" />{' '}
                {item?.vacation_days?.value > 0 &&
                (item?.rate_per_hour?.overwrite_value == null ||
                  item?.rate_per_hour?.overwrite_value == 0) &&
                item?.vacation_reference_count?.value < 3 ? (
                  <Tooltip
                    title={
                      <Typography variant="h6">
                        <FormattedMessage
                          id="Insufficient Data for Vacation Pay Calculation. You can overwrite rate per hour value by clicking the edit button"
                          defaultMessage="Insufficient Data for Vacation Pay Calculation. You can overwrite rate per hour value by clicking the edit button"
                        />
                      </Typography>
                    }>
                    <Report sx={{ color: 'red', cursor: 'pointer' }} />
                  </Tooltip>
                ) : null}
              </td>
              <td>
                <VacationPay
                  item={item}
                  data={data}
                  paylistId={paylistId}
                  paylistDate={{ year: year, month: month }}
                />
              </td>
            </tr>
          )}
          {item?.sick_days?.value > 0 && (
            <tr>
              <td>
                <FormattedMessage id="Number of sick days" defaultMessage="Number of sick days" />{' '}
              </td>
              <td>{item?.sick_days?.value}</td>
            </tr>
          )}
          {item?.sick_days_since_beginning_of_year?.value > 0 && (
            <tr>
              <td>
                <FormattedMessage
                  id="Number of sick days since beginning of year"
                  defaultMessage="Number of sick days since beginning of year"
                />{' '}
              </td>
              <td>{item?.sick_days_since_beginning_of_year?.value}</td>
            </tr>
          )}
          {item?.sick_days?.value > 0 && (
            <tr>
              <td>
                <FormattedMessage id="Sick Pay" defaultMessage="Sick Pay" />{' '}
                {item?.sick_days?.value > 0 &&
                (item?.per_day_sick_pay?.overwrite_value == null ||
                  item?.per_day_sick_pay?.overwrite_value == 0) &&
                item?.sick_reference_count?.value < 12 ? (
                  <Tooltip
                    title={
                      <Typography variant="h6">
                        <FormattedMessage
                          id="Insufficient Data for Sick Pay Calculation. You can overwrite per day sick pay value by clicking the edit button"
                          defaultMessage="Insufficient Data for Sick Pay Calculation. You can overwrite per day sick pay value by clicking the edit button"
                        />
                      </Typography>
                    }>
                    <Report sx={{ color: 'red', cursor: 'pointer' }} />
                  </Tooltip>
                ) : null}
              </td>
              <td>
                <SickPay
                  item={item}
                  paylistId={paylistId}
                  data={data}
                  paylistDate={{ year: year, month: month }}
                />
              </td>
            </tr>
          )}
          {item?.sick_days_for_care?.value > 0 && (
            <tr>
              <td>
                <FormattedMessage
                  id="Number of care leave days"
                  defaultMessage="Number of care leave days"
                />{' '}
              </td>
              <td>{item?.sick_days_for_care?.value}</td>
            </tr>
          )}
          {item?.sick_days_for_care?.value > 0 && (
            <tr>
              <td>
                <FormattedMessage id="Care Pay" defaultMessage="Care Pay" />{' '}
              </td>
              <td>{item?.care_pay?.value}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default WorkingSettlement;

const VacationPay = ({ item, paylistId, paylistDate, data }) => {
  const { year, month } = paylistDate;
  const [modalOpen, openFunction] = useToggle(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [vacation, setVacation] = useState(item?.rate_per_hour?.overwrite_value);
  const { edit_payroll } = useSelector((state) => state.payroll);

  const handleSubmit = () => {
    vacation &&
      dispatch(
        editPayroll(
          { paylist_id: paylistId, output_id: item?.rate_per_hour?.id, overwrite_value: vacation },
          year,
          month,
          openFunction
        )
      );
  };

  useEffect(() => {
    item?.rate_per_hour?.overwrite_value && setVacation(item?.rate_per_hour?.overwrite_value);
  }, [item?.rate_per_hour?.overwrite_value]);

  return (
    <>
      <div onClick={openFunction} className={classes.editOption}>
        {parseFloat(item?.vacation_pay?.value).toFixed(2)} <Edit />
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="60rem"
        width="60rem"
        headerText={
          <FormattedMessage id="Update Vacation Rate" defaultMessage="Update Vacation Rate" />
        }>
        <Box padding={3}>
          <Box marginBottom={3}>
            <Typography variant="body1">
              <FormattedMessage id="Rate per Hour" defaultMessage="Rate per Hour" />:{' '}
              {parseFloat(item?.rate_per_hour?.value).toFixed(2)}
            </Typography>
          </Box>
          <TextField
            label={
              <FormattedMessage id="Updated Rate per hour" defaultMessage="Updated Rate per hour" />
            }
            fullWidth
            type="number"
            InputLabelProps={{ shrink: true }}
            value={vacation}
            onChange={(e) => setVacation(e.target.value)}
          />
        </Box>
        <Box className={classes.modalFooter}>
          <Box className={classes.modalFooterButtons}>
            {!edit_payroll ? (
              <Button sx={{ marginRight: '1rem' }} variant={'contained'} onClick={handleSubmit}>
                <FormattedMessage id="Save" defaultMessage="Save" />
              </Button>
            ) : (
              <Button sx={{ marginRight: '1rem' }} variant={'contained'} disabled>
                <FormattedMessage id="Submitting" defaultMessage="Submitting" />
              </Button>
            )}
            <Button
              className={classes.modalFooterCancel}
              variant={'outlined'}
              onClick={openFunction}>
              <FormattedMessage id="Cancel" defaultMessage="Cancel" />
            </Button>
          </Box>
        </Box>
        <TimeTableInside
          employeeId={data?.employee?.id}
          startDate={changeDateFormat(data?.date)}
          type="vacation_leave"
        />
      </CustomModal>
    </>
  );
};

const SickPay = ({ item, paylistDate, paylistId, data }) => {
  const { year, month } = paylistDate;
  const [modalOpen, openFunction] = useToggle(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [sick, setSick] = useState();
  const { edit_payroll } = useSelector((state) => state.payroll);

  const handleSubmit = () => {
    sick &&
      dispatch(
        editPayroll(
          { paylist_id: paylistId, output_id: item?.per_day_sick_pay?.id, overwrite_value: sick },
          year,
          month,
          openFunction
        )
      );
  };

  useEffect(() => {
    item?.per_day_sick_pay?.overwrite_value && setSick(item?.per_day_sick_pay?.overwrite_value);
  }, [item?.per_day_sick_pay?.overwrite_value]);

  useEffect(() => {
    item?.employee?.id && dispatch(getTimeRecording(item?.employee?.id));
  }, [item?.employee?.id]);

  return (
    <>
      <div onClick={openFunction} className={classes.editOption}>
        {parseFloat(item?.sick_pay?.value).toFixed(2)} <Edit />
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="60rem"
        width="60rem"
        headerText={<FormattedMessage id="Sick Pay" defaultMessage="Sick Pay" />}>
        <Box padding={3}>
          <Box marginBottom={3}>
            <Typography variant="body1">
              <FormattedMessage id="Per day sick pay" defaultMessage="Per day sick pay" /> :{' '}
              {parseFloat(item?.per_day_sick_pay?.value).toFixed(2)}
            </Typography>
          </Box>
          <TextField
            label={
              <FormattedMessage
                id="Updated Per day sick pay"
                defaultMessage="Updated Per day sick pay"
              />
            }
            fullWidth
            type="number"
            InputLabelProps={{ shrink: true }}
            value={sick}
            onChange={(e) => setSick(e.target.value)}
          />
        </Box>
        <Box className={classes.modalFooter}>
          <Box className={classes.modalFooterButtons}>
            {!edit_payroll ? (
              <Button sx={{ marginRight: '1rem' }} variant={'contained'} onClick={handleSubmit}>
                Save
              </Button>
            ) : (
              <Button sx={{ marginRight: '1rem' }} variant={'contained'} disabled>
                Submitting
              </Button>
            )}
            <Button
              className={classes.modalFooterCancel}
              variant={'outlined'}
              onClick={openFunction}>
              Cancel
            </Button>
          </Box>
        </Box>
        <TimeTableInside
          employeeId={data?.employee?.id}
          startDate={changeDateFormat(data?.date)}
          type="sick_leave"
        />
      </CustomModal>
    </>
  );
};
