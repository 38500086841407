import * as React from 'react';
import Paper from '@mui/material/Paper';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearSingleEmployee, getPaginatedEmployees } from '../redux/actions';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import EditIcon from '@mui/icons-material/Edit';
import useStyles from '../styles';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import EmployeeEdit from '../EmployeeEdit';
import EmployeeView from './viewEmployee/EmployeeView';
import CustomTable from 'components/globals/table';
import { Box, Tooltip } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { translate } from 'utils';

const columns = [
  {
    title: <FormattedMessage id="S.N." defaultMessage="S.N." />,
    type: 'Index',
    isSortable: true,
    minWidth: 20
  },
  {
    title: <FormattedMessage id="Surname and Name" defaultMessage="Surname and Name" />,
    minWidth: 100,
    isSortable: true,
    field: (row) => <EmployeeViewName row={row} />
  },
  {
    field: 'contract_type',
    title: <FormattedMessage id="Type of Contract" defaultMessage="Type of Contract" />,
    minWidth: 170,
    align: 'right'
  },
  {
    title: <FormattedMessage id="Employment Form" defaultMessage="Employment Form" />,
    field: 'employment_from',
    minWidth: 170,
    align: 'right'
  },
  {
    field: 'employment_to',
    title: <FormattedMessage id="Employment To" defaultMessage="Employment To" />,
    minWidth: 120,
    align: 'right'
  },

  {
    field: 'renumeration',
    title: <FormattedMessage id="Renumeration" defaultMessage="Renumeration" />,
    minWidth: 120,
    align: 'right'
  },
  {
    field: (row) => {
      return <Actions row={row} />;
    },
    title: <FormattedMessage id="Actions" defaultMessage="Actions" />,
    minWidth: 170,
    align: 'center'
  }
];

export default function EmployeeTable() {
  const dispatch = useDispatch();

  const { employees_paginated, paginated_loading } = useSelector((state) => state.employee);
  const rows = employees_paginated || [];

  useEffect(() => {
    dispatch(getPaginatedEmployees(0));
  }, []);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <CustomTable tableData={rows} tableHeads={columns} loading={paginated_loading} />
    </Paper>
  );
}

const Actions = ({ row }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalOpen, openFunction] = useToggle(false);
  const [documentOpen, openDocumentFunction] = useToggle(false);
  const firstName = row?.first_name ?? '';
  const secondName = row?.middle_name ?? '';
  const lastName = row?.last_name ?? '';
  const fullName = lastName + ' ' + firstName + ' ' + secondName;

  const handleClick = () => {
    openFunction();
  };

  const handleDocumentClick = () => {
    dispatch(clearSingleEmployee());
    openDocumentFunction();
  };

  return (
    <>
      <div className={classes.iconWrapper}>
        <Tooltip title={<FormattedMessage id="Documents" defaultMessage="Documents" />}>
          <FolderOpenIcon onClick={handleDocumentClick} />
        </Tooltip>
        <Tooltip title={<FormattedMessage id="Edit" defaultMessage="Edit" />}>
          <EditIcon onClick={handleClick} />
        </Tooltip>
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        headerText={<FormattedMessage id="Edit Employee" defaultMessage="Edit Employee" />}>
        <EmployeeEdit handleClose={openFunction} employeeId={row?.id} />
      </CustomModal>
      <CustomModal
        open={documentOpen}
        handleClose={openDocumentFunction}
        height="auto"
        width="120rem"
        variant="light"
        headerText={translate('Details of') + ' ' + fullName}>
        <EmployeeView id={row?.id} tabValue={7} />
      </CustomModal>
      <Box display="none">
        <FormattedMessage id="Details of" defaultMessage="Details of" />
      </Box>
    </>
  );
};

const EmployeeViewName = ({ row }) => {
  const [modalOpen, openFunction] = useToggle(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleClick = () => {
    dispatch(clearSingleEmployee());
    openFunction();
  };
  return (
    <>
      <div className={classes.fullname} onClick={handleClick}>
        {row?.last_name} {row?.first_name}
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="120rem"
        variant="light"
        headerText={`${translate('Details of')} ${row?.last_name} ${row?.first_name}`}>
        <EmployeeView id={row?.id} />
      </CustomModal>
    </>
  );
};
