import React from 'react';
import DashboardLayout from 'layouts/dashboardLayout';
import EventsPage from 'components/locals/events/Events';

const Events = () => {
  return (
    <DashboardLayout>
      <EventsPage />
    </DashboardLayout>
  );
};

export default Events;
