export const CREATE_FOLDER_BEGIN = 'CREATE_FOLDER_BEGIN';
export const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS';
export const CREATE_FOLDER_ERROR = 'CREATE_FOLDER_ERROR';

export const GET_ALL_EMPLOYEE_FOLDER_BEGIN = 'GET_ALL_EMPLOYEE_FOLDER_BEGIN';
export const GET_ALL_EMPLOYEE_FOLDER_SUCCESS = 'GET_ALL_EMPLOYEE_FOLDER_SUCCESS';
export const GET_ALL_EMPLOYEE_FOLDER_ERROR = 'GET_ALL_EMPLOYEE_FOLDER_ERROR';

export const GET_SINGLE_FOLDER_BEGIN = 'GET_SINGLE_FOLDER_BEGIN';
export const GET_SINGLE_FOLDER_SUCCESS = 'GET_SINGLE_FOLDER_SUCCESS';
export const GET_SINGLE_FOLDER_ERROR = 'GET_SINGLE_FOLDER_ERROR';

export const GET_OTHER_FOLDER_BEGIN = 'GET_OTHER_FOLDER_BEGIN';
export const GET_OTHER_FOLDER_SUCCESS = 'GET_OTHER_FOLDER_SUCCESS';
export const GET_OTHER_FOLDER_ERROR = 'GET_OTHER_FOLDER_ERROR';

export const UPLOAD_FILE_BEGIN = 'UPLOAD_FILE_BEGIN';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';
