export const CREATE_CONTRACT_BEGIN = 'CREATE_CONTRACT_BEGIN';
export const CREATE_CONTRACT_SUCCESS = 'CREATE_CONTRACT_SUCCESS';
export const CREATE_CONTRACT_ERROR = 'CREATE_CONTRACT_ERROR';

export const GET_CONTRACT_BEGIN = 'GET_CONTRACT_BEGIN';
export const GET_CONTRACT_SUCCESS = 'GET_CONTRACT_SUCCESS';
export const GET_CONTRACT_ERROR = 'GET_CONTRACT_ERROR';

export const GET_SINGLE_CONTRACT_BEGIN = 'GET_SINGLE_CONTRACT_BEGIN';
export const GET_SINGLE_CONTRACT_SUCCESS = 'GET_SINGLE_CONTRACT_SUCCESS';
export const GET_SINGLE_CONTRACT_ERROR = 'GET_SINGLE_CONTRACT_ERROR';

export const EDIT_CONTRACT_BEGIN = 'EDIT_CONTRACT_BEGIN';
export const EDIT_CONTRACT_SUCCESS = 'EDIT_CONTRACT_SUCCESS';
export const EDIT_CONTRACT_ERROR = 'EDIT_CONTRACT_ERROR';

export const DELETE_CONTRACT_BEGIN = 'DELETE_CONTRACT_BEGIN';
export const DELETE_CONTRACT_SUCCESS = 'DELETE_CONTRACT_SUCCESS';
export const DELETE_CONTRACT_ERROR = 'DELETE_CONTRACT_ERROR';

export const CLEAR_SINGLE_CONTRACT = 'CLEAR_SINGLE_CONTRACT';

export const GET_ALL_AGREEMENT_TYPE_BEGIN = 'GET_ALL_AGREEMENT_TYPE_BEGIN';
export const GET_ALL_AGREEMENT_TYPE_SUCCESS = 'GET_ALL_AGREEMENT_TYPE_SUCCESS';
export const GET_ALL_AGREEMENT_TYPE_ERROR = 'GET_ALL_AGREEMENT_TYPE_ERROR';

export const GET_OCCUPATION_CODE_BEGIN = 'GET_OCCUPATION_CODE_BEGIN';
export const GET_OCCUPATION_CODE_SUCCESS = 'GET_OCCUPATION_CODE_SUCCESS';
export const GET_OCCUPATION_CODE_ERROR = 'GET_OCCUPATION_CODE_ERROR';

export const GET_INSURANCE_CODE_BEGIN = 'GET_INSURANCE_CODE_BEGIN';
export const GET_INSURANCE_CODE_SUCCESS = 'GET_INSURANCE_CODE_SUCCESS';
export const GET_INSURANCE_CODE_ERROR = 'GET_INSURANCE_CODE_ERROR';

export const CALCULATE_AGREEMENT_BEGIN = 'CALCULATE_AGREEMENT_BEGIN';
export const CALCULATE_AGREEMENT_SUCCESS = 'CALCULATE_AGREEMENT_SUCCESS';
export const CALCULATE_AGREEMENT_ERROR = 'CALCULATE_AGREEMENT_ERROR';

export const GET_CONTRIBUTION_BEGIN = 'GET_CONTRIBUTION_BEGIN';
export const GET_CONTRIBUTION_SUCCESS = 'GET_CONTRIBUTION_SUCCESS';
export const GET_CONTRIBUTION_ERROR = 'GET_CONTRIBUTION_ERROR';

export const UPDATE_CONTRIBUTION_BEGIN = 'UPDATE_CONTRIBUTION_BEGIN';
export const UPDATE_CONTRIBUTION_SUCCESS = 'UPDATE_CONTRIBUTION_SUCCESS';
export const UPDATE_CONTRIBUTION_ERROR = 'UPDATE_CONTRIBUTION_ERROR';

export const UPDATE_SOCIAL_SECURITY_BEGIN = 'UPDATE_SOCIAL_SECURITY_BEGIN';
export const UPDATE_SOCIAL_SECURITY_SUCCESS = 'UPDATE_SOCIAL_SECURITY_SUCCESS';
export const UPDATE_SOCIAL_SECURITY_ERROR = 'UPDATE_SOCIAL_SECURITY_ERROR';

export const UPDATE_SECURITY_LIMIT_BEGIN = 'UPDATE_SECURITY_LIMIT_BEGIN';
export const UPDATE_SECURITY_LIMIT_SUCCESS = 'UPDATE_SECURITY_LIMIT_SUCCESS';
export const UPDATE_SECURITY_LIMIT_ERROR = 'UPDATE_SECURITY_LIMIT_ERROR';

export const DELETE_SECURITY_LIMIT_BEGIN = 'DELETE_SECURITY_LIMIT_BEGIN';
export const DELETE_SECURITY_LIMIT_SUCCESS = 'DELETE_SECURITY_LIMIT_SUCCESS';
export const DELETE_SECURITY_LIMIT_ERROR = 'DELETE_SECURITY_LIMIT_ERROR';

export const CLEAR_CALCULATE = 'CLEAR_CALCULATE';
export const CLEAR_CONTRIBUTION = 'CLEAR_CONTRIBUTION';

export const GET_AGREEMENT_LOG_BEGIN = 'GET_AGREEMENT_LOG_BEGIN';
export const GET_AGREEMENT_LOG_SUCCESS = 'GET_AGREEMENT_LOG_SUCCESS';
export const GET_AGREEMENT_LOG_ERROR = 'GET_AGREEMENT_LOG_ERROR';

export const GET_TAX_RATE_BEGIN = 'GET_TAX_RATE_BEGIN';
export const GET_TAX_RATE_SUCCESS = 'GET_TAX_RATE_SUCCESS';
export const GET_TAX_RATE_ERROR = 'GET_TAX_RATE_ERROR';

export const GET_EMPLOYEE_CONTRACT_BEGIN = 'GET_EMPLOYEE_CONTRACT_BEGIN';
export const GET_EMPLOYEE_CONTRACT_SUCCESS = 'GET_EMPLOYEE_CONTRACT_SUCCESS';
export const GET_EMPLOYEE_CONTRACT_ERROR = 'GET_EMPLOYEE_CONTRACT_ERROR';

export const TERMINATE_CONTRACT_BEGIN = 'TERMINATE_CONTRACT_BEGIN';
export const TERMINATE_CONTRACT_SUCCESS = 'TERMINATE_CONTRACT_SUCCESS';
export const TERMINATE_CONTRACT_ERROR = 'TERMINATE_CONTRACT_ERROR';

export const GET_TERMINATION_DATA_BEGIN = 'GET_TERMINATION_DATA_BEGIN';
export const GET_TERMINATION_DATA_SUCCESS = 'GET_TERMINATION_DATA_SUCCESS';
export const GET_TERMINATION_DATA_ERROR = 'GET_TERMINATION_DATA_ERROR';
