import AddIcon from '@mui/icons-material/Add';
import { Button, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { HookForm } from 'components/form/hookForm';
import { HookFormProvider } from 'components/form/hookFormProvider';
import CancelModal from 'components/globals/modal/CancelModal';
import useToggle from 'hooks/useToggle';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { changeDateFormat } from 'utils/dateUtils';
import * as Yup from 'yup';
import AddHolidayBase from './AddHolidayBase';
import AllowanceForm from './Form';
import { createTimeRecording } from './redux/actions';
import useStyles from './styles';
import TimeTable from './TimeTable';

function AddTimeRecording({ handleClose, employeeId, handleRefetch }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { create_loading } = useSelector((state) => state.timeRecording);
  const newDate = new Date();
  const defaultValues = {
    name: '',
    type: 'sick_leave',
    start_date: changeDateFormat(newDate, 'YYYY-MM-DD'),
    end_date: ''
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    type: Yup.string().required('Type is required'),
    start_date: Yup.string().required('Start Date  is required'),
    end_date: Yup.string().required('End Date  is required')
  });

  const onSubmit = (data) => {
    const finalData = {
      ...data,
      employee_id: employeeId,
      waiting_period: data?.waiting_period ? 1 : 0
    };
    dispatch(createTimeRecording(finalData, handleClose, handleRefetch));
  };

  useEffect(() => {
    // dispatch(clearSingleEmployee());
  }, []);

  const [cancelOpen, cancelFunction] = useToggle(false);
  const [modalOpen, openFunction] = useToggle(false);

  return (
    <>
      <div className={classes.content}>
        <HookFormProvider
          defaultValues={defaultValues}
          resolver={useYupValidationResolver(validationSchema)}>
          <HookForm onSubmit={onSubmit}>
            <AllowanceForm />
            <InsideFormSection
              openFunction={openFunction}
              modalOpen={modalOpen}
              employeeId={employeeId}
            />
            <Box className={classes.modalFooter}>
              <Box className={classes.modalFooterButtons}>
                {!create_loading ? (
                  <Button sx={{ marginRight: '1rem' }} variant={'contained'} type="submit">
                    {<FormattedMessage id="Save" defaultMessage="Save" />}
                  </Button>
                ) : (
                  <Button sx={{ marginRight: '1rem' }} variant={'contained'} disabled>
                    <CircularProgress size={18} sx={{ marginRight: '1rem' }} /> Saving
                  </Button>
                )}

                <Button
                  className={classes.modalFooterCancel}
                  variant={'outlined'}
                  onClick={cancelFunction}>
                  {<FormattedMessage id="Cancel" defaultMessage="Cancel" />}
                </Button>
              </Box>
            </Box>
          </HookForm>
        </HookFormProvider>
      </div>
      <CancelModal
        handleClose={handleClose}
        cancelFunction={cancelFunction}
        cancelOpen={cancelOpen}
      />
    </>
  );
}

export default AddTimeRecording;

const InsideFormSection = ({ openFunction, employeeId, modalOpen }) => {
  const { watch } = useFormContext();
  const type = watch('type');
  return (
    type !== 'unpaid_leave' && (
      <>
        <Box marginLeft={4}>
          <Button onClick={openFunction} variant="contained">
            <AddIcon /> {<FormattedMessage id="Add" defaultMessage="Add" />}{' '}
          </Button>
        </Box>

        <AddHolidayBase handleClose={openFunction} employeeId={employeeId} modalOpen={modalOpen} />
        <TimeTable employeeId={employeeId} />
      </>
    )
  );
};
