import { Grid } from '@mui/material';
import EmployerCost from './mandate/EmployerCost';
import Overtime from './mandate/Overtime';
import PayslipMain from './mandate/PayslipMain';
import WorkingSettlement from './mandate/WorkingSettlement';

const MandateContent = ({ keyData, inputData, item, overTimeData }) => {
  return (
    <Grid container spacing={3}>
      <Grid item sm={7}>
        <PayslipMain item={keyData} input={inputData} />
      </Grid>
      <Grid item sm={5}>
        <WorkingSettlement
          item={keyData}
          paylistId={item?.id}
          paylistDate={item?.date}
          data={item}
        />
        <EmployerCost item={keyData} />
        {overTimeData?.length > 0 && <Overtime data={overTimeData} />}
      </Grid>
    </Grid>
  );
};

export default MandateContent;
