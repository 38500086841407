import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  modalFooter: {
    width: '100%',
    marginTop: 'auto',
    marginLeft: 'auto',
    background: '#fff',
    display: 'flex',
    padding: '1rem 3rem',
    height: '4.9rem'
  },
  modalFooterButtons: {
    fontFamily: 'Euclid',
    ':&>*': {
      margin: '0 1rem'
    }
  },
  modalFooterCancel: {
    '&:hover': {
      background: '#ddd'
    }
  },
  iconWrapper: {
    '& svg': {
      marginRight: '1.5rem',
      color: theme.palette.grey[600],
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary
      }
    }
  },
  title: {
    color: theme.palette.grey[700],
    width: '45%'
  },
  value: {
    color: theme.palette.primary.dark,
    fontWeight: 600,
    letterSpacing: '0.8px'
  },
  viewRoot: {
    margin: '30px 15px',
    fontSize: '12px',
    '& ul': {
      margin: '0',
      padding: '0',
      marginLeft: '1.6rem',
      listStyle: 'none'
    },
    '& li': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingBottom: '1.2rem'
    }
  }
}));

export default useStyles;
