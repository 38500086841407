import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import CustomModal from 'components/globals/modal';
import CustomTable from 'components/globals/table';
import useToggle from 'hooks/useToggle';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { translate } from 'utils';
import useStyles from '../../styles';
import OpeningAdd from '../openingFormSocialContribution/Add';
import OpeningView from '../openingFormSocialContribution/View';

function OpeningSocialContribution({ employeeId, contractTypeId, type }) {
  const checkSubmitted = (data) => {
    if (data == 'Payer') {
      return translate('Payer');
    } else if (data == 'ZUS') {
      return translate('ZUS');
    } else if (data == 'Insured') {
      return translate('Insured');
    } else {
      return '';
    }
  };
  const columns = [
    {
      title: <FormattedMessage id="S.N." defaultMessage="S.N." />,
      type: 'Index',
      isSortable: true,
      minWidth: 20
    },

    {
      field: 'year',
      title: <FormattedMessage id="Year" defaultMessage="Year" />,
      minWidth: 170,
      align: 'right'
    },
    {
      field: 'social_contribution_base',
      title: <FormattedMessage id="Social Contribution" defaultMessage="Social Contribution" />,
      minWidth: 80,
      align: 'right'
    },
    {
      field: (row) => {
        const filterResult = contributions?.limit_exceed?.find((list) => list?.year == row?.year);
        return filterResult?.exceed_date ?? '';
      },
      title: <FormattedMessage id="Date Exceeded" defaultMessage="Date Exceeded" />,
      minWidth: 80,
      align: 'right'
    },
    {
      field: (row) => {
        const filterResult = contributions?.limit_exceed?.find((list) => list?.year == row?.year);
        return checkSubmitted(filterResult?.submitted_by);
      },
      title: <FormattedMessage id="Submitted By" defaultMessage="Submitted By" />,
      minWidth: 80,
      align: 'right'
    },

    {
      field: (row) => {
        return <Actions year={row?.year} employeeId={employeeId} contractTypeId={contractTypeId} />;
      },
      title: <FormattedMessage id="Actions" defaultMessage="Actions" />,
      minWidth: 170,
      align: 'center'
    }
  ];
  const handleClick = () => {
    openFunction();
  };
  const [modalOpen, openFunction] = useToggle(false);
  const [rows, setRows] = useState([]);
  const { contributions } = useSelector((state) => state.contract);

  useEffect(() => {
    if (contributions?.social_security?.length > 0) {
      const groupByYear = contributions?.social_security?.reduce((group, item) => {
        group[item.year] = group[item.year] ?? [];
        group[item.year].push(item);
        return group;
      }, {});
      const tableRow = Object.entries(groupByYear)?.map((item, index) => {
        let tax_base = 0;
        let social_contribution_base = 0;
        item[1].map((base) => {
          tax_base = tax_base + base?.tax_base;
          social_contribution_base = social_contribution_base + base?.social_contribution_base;
        });
        return {
          id: index,
          tax_base: tax_base,
          social_contribution_base: social_contribution_base,
          year: item[0]
        };
      });
      setRows(tableRow || []);
    } else setRows([]);
  }, [contributions]);
  return (
    <>
      <Box display="none">
        <FormattedMessage id="Insured" defaultMessage="Insured" />
        <FormattedMessage id="ZUS" defaultMessage="ZUS" />
        <FormattedMessage id="Payer" defaultMessage="Payer" />
      </Box>
      <Box marginTop={3} marginBottom={2}>
        <Button variant="contained" onClick={handleClick} disabled={!employeeId}>
          <FormattedMessage id="Add" defaultMessage="Add" />
        </Button>
      </Box>

      <CustomTable tableData={rows} tableHeads={columns} />
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        width="50rem"
        height="auto"
        headerText={
          <FormattedMessage
            id="The basis for assessing contributions from other jobs"
            defaultMessage="The basis for assessing contributions from other jobs"
          />
        }>
        <OpeningAdd
          handleClose={openFunction}
          employeeId={employeeId}
          contractTypeId={contractTypeId}
        />
      </CustomModal>
    </>
  );
}

export default OpeningSocialContribution;

const Actions = ({ year, employeeId, contractTypeId }) => {
  const classes = useStyles();
  const [modalViewOpen, openViewFunction] = useToggle(false);

  return (
    <>
      <Box display="none">
        <FormattedMessage
          id="Detail of the Contributions of the year"
          defaultMessage="Detail of the Contributions of the year"
        />
      </Box>
      <div className={classes.iconWrapper}>
        <VisibilityIcon onClick={openViewFunction} />
      </div>
      <CustomModal
        open={modalViewOpen}
        handleClose={openViewFunction}
        width="60rem"
        height="auto"
        headerText={`${translate(`Detail of the Contributions of the year`)} ${year}`}>
        <OpeningView
          year={year}
          handleClose={openViewFunction}
          employeeId={employeeId}
          contractTypeId={contractTypeId}
        />
      </CustomModal>
    </>
  );
};
