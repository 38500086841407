import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { removeCompany } from '../redux/actions';

const RemoveCompany = ({ handleClose, slug, refetch }) => {
  const dispatch = useDispatch();
  const { removeCompanyLoading } = useSelector((state) => state.accounting);

  const handleDelete = () => {
    dispatch(
      removeCompany({ company_slug: slug }, () => {
        handleClose();
        refetch();
      })
    );
  };
  return (
    <Box margin={3}>
      <Typography variant="h5" textAlign="center">
        <FormattedMessage
          id="Are you sure want to remove this company from your office?"
          defaultMessage="Are you sure want to remove this company from your office?"
        />
      </Typography>
      <Box sx={{ color: '#d38107' }}>
        <Typography variant="h6" textAlign="center">
          <FormattedMessage
            id="Warning: All assigned users will also be removed !"
            defaultMessage="Warning: All assigned users will also be removed !"
          />
        </Typography>
      </Box>
      <Box marginTop={3} display="flex" justifyContent="center" columnGap={10}>
        {!removeCompanyLoading ? (
          <Button variant="contained" onClick={handleDelete}>
            Yes
          </Button>
        ) : (
          <Button variant="contained" disabled>
            <CircularProgress size={18} /> Yes
          </Button>
        )}
        <Button variant="outlined" onClick={handleClose}>
          No
        </Button>
      </Box>
    </Box>
  );
};

export default RemoveCompany;
