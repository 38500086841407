export const GET_COMPANY_PAYROLL_CLOSING_BEGIN = 'GET_COMPANY_PAYROLL_CLOSING_BEGIN';
export const GET_COMPANY_PAYROLL_CLOSING_SUCCESS = 'GET_COMPANY_PAYROLL_CLOSING_SUCCESS';
export const GET_COMPANY_PAYROLL_CLOSING_ERROR = 'GET_COMPANY_PAYROLL_CLOSING_ERROR';

export const EDIT_COMPANY_PAYROLL_CLOSING_BEGIN = 'EDIT_COMPANY_PAYROLL_CLOSING_BEGIN';
export const EDIT_COMPANY_PAYROLL_CLOSING_SUCCESS = 'EDIT_COMPANY_PAYROLL_CLOSING_SUCCESS';
export const EDIT_COMPANY_PAYROLL_CLOSING_ERROR = 'EDIT_COMPANY_PAYROLL_CLOSING_ERROR';

export const ADD_PUE_BEGIN = 'ADD_PUE_BEGIN';
export const ADD_PUE_SUCCESS = 'ADD_PUE_SUCCESS';
export const ADD_PUE_ERROR = 'ADD_PUE_ERROR';

export const GET_PUE_BEGIN = 'GET_PUE_BEGIN';
export const GET_PUE_SUCCESS = 'GET_PUE_SUCCESS';
export const GET_PUE_ERROR = 'GET_PUE_ERROR';

export const GET_EZLA_BEGIN = 'GET_EZLA_BEGIN';
export const GET_EZLA_SUCCESS = 'GET_EZLA_SUCCESS';
export const GET_EZLA_ERROR = 'GET_EZLA_ERROR';

export const CLEAR_EZLA = 'CLEAR_EZLA';
