import { axiosInstance } from 'apis/_axios';

// create events
export const createEventsApi = (data) => {
  return axiosInstance().post('/agreement', data);
};

// get all events
export const getEventsApi = (start_date, end_date, type, employee_id) => {
  return axiosInstance().get(`/actions/all`, {
    params: {
      start_date: start_date,
      end_date: end_date,
      type: type,
      employee_id: employee_id
    }
  });
};

// edit events
export const editEventsApi = (id, data) => {
  return axiosInstance().post(`/agreement/${id}`, data);
};

//delete events
export const deleteEventsApi = (id) => {
  return axiosInstance().delete(`/agreement/${id}`);
};
