import { errorToast, successToast } from 'utils/toast';
import * as actions from './types';

import {
  calculateAgreementApi,
  createContractApi,
  deleteContractApi,
  editContractApi,
  getAgreementLogsApi,
  getAllAgreementTypeApi,
  getContractApi,
  getContractByIdApi,
  getContributionApi,
  getEmployeeContractApi,
  getInsuranceCodeApi,
  getOccupationCodeApi,
  getTaxRateApi,
  getTerminationDataApi,
  removeSocialSecurityLimitApi,
  terminateContractApi,
  updateContributionApi,
  updateSocialSecurityApi,
  updateSocialSecurityLimitApi
} from 'apis/contract';

// create Contract
export const createContract = (data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.CREATE_CONTRACT_BEGIN
  });
  createContractApi(data)
    .then((res) => {
      dispatch({
        type: actions.CREATE_CONTRACT_SUCCESS
      });
      handleFunction && handleFunction();
      dispatch(getContract());
      successToast('Contract created Successfully');
    })
    .catch((error) => {
      dispatch({
        type: actions.CREATE_CONTRACT_ERROR
      });
      errorToast(error);
    });
};

// get all employee contract
export const getContract = (data) => (dispatch) => {
  dispatch({
    type: actions.GET_CONTRACT_BEGIN
  });
  getContractApi(data)
    .then((res) => {
      dispatch({
        type: actions.GET_CONTRACT_SUCCESS,
        payload: res.data?.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_CONTRACT_ERROR
      });
      errorToast(error);
    });
};

// get single Contract
export const getContractById = (id) => (dispatch) => {
  dispatch({
    type: actions.GET_SINGLE_CONTRACT_BEGIN
  });
  getContractByIdApi(id)
    .then((res) => {
      dispatch({
        type: actions.GET_SINGLE_CONTRACT_SUCCESS,
        payload: res.data?.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_SINGLE_CONTRACT_ERROR
      });
      errorToast(error);
    });
};

// edit employee
export const editContract = (id, data, handleFunction, employeeId) => (dispatch) => {
  dispatch({
    type: actions.EDIT_CONTRACT_BEGIN
  });
  editContractApi(id, data)
    .then((res) => {
      dispatch({
        type: actions.EDIT_CONTRACT_SUCCESS
      });
      handleFunction && handleFunction();
      employeeId && dispatch(getEmployeeContract(employeeId));
      // dispatch(getContract());
      successToast('Contract edited Successfully');
    })
    .catch((error) => {
      dispatch({
        type: actions.EDIT_CONTRACT_ERROR
      });
      errorToast(error);
    });
};

// delete employee contract
export const deleteContract = (id, data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.DELETE_CONTRACT_BEGIN
  });
  deleteContractApi(id)
    .then((res) => {
      dispatch({
        type: actions.DELETE_CONTRACT_SUCCESS
      });
      handleFunction && handleFunction();
      successToast('Contract deleted Successfully');
    })
    .catch((error) => {
      dispatch({
        type: actions.DELETE_CONTRACT_ERROR
      });
      errorToast(error);
    });
};

export const clearSingleContract = () => (dispatch) => {
  dispatch({
    type: actions.CLEAR_SINGLE_CONTRACT
  });
};

// get all agreement type
export const getAllAgreementType = () => (dispatch) => {
  dispatch({
    type: actions.GET_ALL_AGREEMENT_TYPE_BEGIN
  });
  getAllAgreementTypeApi()
    .then((res) => {
      dispatch({
        type: actions.GET_ALL_AGREEMENT_TYPE_SUCCESS,
        payload: res.data?.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_ALL_AGREEMENT_TYPE_ERROR
      });
      errorToast(error);
    });
};

// get insurance code
export const getInsuranceCode = () => (dispatch) => {
  dispatch({
    type: actions.GET_INSURANCE_CODE_BEGIN
  });
  getInsuranceCodeApi()
    .then((res) => {
      dispatch({
        type: actions.GET_INSURANCE_CODE_SUCCESS,
        payload: res.data?.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_INSURANCE_CODE_ERROR
      });
      errorToast(error);
    });
};

// get occupation code
export const getOccupationCode = () => (dispatch) => {
  dispatch({
    type: actions.GET_OCCUPATION_CODE_BEGIN
  });
  getOccupationCodeApi()
    .then((res) => {
      dispatch({
        type: actions.GET_OCCUPATION_CODE_SUCCESS,
        payload: res.data?.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_OCCUPATION_CODE_ERROR
      });
      errorToast(error);
    });
};

// get calculated agreement
export const calculateAgreement = (data) => (dispatch) => {
  dispatch({
    type: actions.CALCULATE_AGREEMENT_BEGIN
  });
  calculateAgreementApi(data)
    .then((res) => {
      dispatch({
        type: actions.CALCULATE_AGREEMENT_SUCCESS,
        payload: res.data?.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.CALCULATE_AGREEMENT_ERROR
      });
      errorToast(error);
    });
};

// get contribution
export const getContribution = (employeeId, slug) => (dispatch) => {
  dispatch({
    type: actions.GET_CONTRIBUTION_BEGIN
  });
  getContributionApi(employeeId, slug)
    .then((res) => {
      dispatch({
        type: actions.GET_CONTRIBUTION_SUCCESS,
        payload: res.data?.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_CONTRIBUTION_ERROR
      });
      errorToast(error);
    });
};

// update contribution
export const updateContribution = (data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.UPDATE_CONTRIBUTION_BEGIN
  });
  updateContributionApi(data)
    .then((res) => {
      dispatch({
        type: actions.UPDATE_CONTRIBUTION_SUCCESS,
        payload: res.data?.data
      });
      dispatch(getContribution(data.employee_id, data?.agreement_type));
      handleFunction && handleFunction();
    })
    .catch((error) => {
      dispatch({
        type: actions.UPDATE_CONTRIBUTION_ERROR
      });
      errorToast(error);
    });
};

// clear calculated contract
export const clearCalculateContract = () => (dispatch) => {
  dispatch({ type: actions.CLEAR_CALCULATE });
};

// clear contributions
export const clearContribution = () => (dispatch) => {
  dispatch({ type: actions.CLEAR_CONTRIBUTION });
};

// get insurance code
export const getAgreementLogs = (id) => (dispatch) => {
  dispatch({
    type: actions.GET_AGREEMENT_LOG_BEGIN
  });
  getAgreementLogsApi(id)
    .then((res) => {
      dispatch({
        type: actions.GET_AGREEMENT_LOG_SUCCESS,
        payload: res.data?.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_AGREEMENT_LOG_ERROR
      });
      errorToast(error);
    });
};

// get tax rates
export const getTaxRate = () => (dispatch) => {
  dispatch({
    type: actions.GET_TAX_RATE_BEGIN
  });
  getTaxRateApi()
    .then((res) => {
      dispatch({
        type: actions.GET_TAX_RATE_SUCCESS,
        payload: res.data?.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_TAX_RATE_ERROR
      });
      errorToast(error);
    });
};

// get employee contract
export const getEmployeeContract = (employeeId) => (dispatch) => {
  dispatch({
    type: actions.GET_EMPLOYEE_CONTRACT_BEGIN
  });
  getEmployeeContractApi(employeeId)
    .then((res) => {
      dispatch({
        type: actions.GET_EMPLOYEE_CONTRACT_SUCCESS,
        payload: res.data?.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_EMPLOYEE_CONTRACT_ERROR
      });
      errorToast(error);
    });
};

// terminate contract
export const terminateContract = (data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.TERMINATE_CONTRACT_BEGIN
  });
  terminateContractApi(data)
    .then((res) => {
      dispatch({
        type: actions.TERMINATE_CONTRACT_SUCCESS
      });
      handleFunction && handleFunction();
    })
    .catch((error) => {
      dispatch({
        type: actions.TERMINATE_CONTRACT_ERROR
      });
      errorToast(error);
    });
};

// get termination data
export const getTerminationData = () => (dispatch) => {
  dispatch({
    type: actions.GET_TERMINATION_DATA_BEGIN
  });
  getTerminationDataApi()
    .then((res) => {
      dispatch({
        type: actions.GET_TERMINATION_DATA_SUCCESS,
        payload: res.data?.data
      });
    })
    .catch((error) => {
      dispatch({
        type: actions.GET_TERMINATION_DATA_ERROR
      });
      errorToast(error);
    });
};

// update social security
export const updateSocialSecurity = (data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.UPDATE_SOCIAL_SECURITY_BEGIN
  });
  updateSocialSecurityApi(data)
    .then((res) => {
      dispatch({
        type: actions.UPDATE_SOCIAL_SECURITY_SUCCESS
      });
      dispatch(getContribution(data.employee_id, data?.agreement_type));
      handleFunction && handleFunction();
    })
    .catch((error) => {
      dispatch({
        type: actions.UPDATE_SOCIAL_SECURITY_ERROR
      });
      errorToast(error);
    });
};

// update social security limit
export const updateSocialSecurityLimit = (data, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.UPDATE_SECURITY_LIMIT_BEGIN
  });
  updateSocialSecurityLimitApi(data)
    .then((res) => {
      dispatch({
        type: actions.UPDATE_SECURITY_LIMIT_SUCCESS
      });
      dispatch(getContribution(data.employee_id, data?.agreement_type));
      handleFunction && handleFunction();
    })
    .catch((error) => {
      dispatch({
        type: actions.UPDATE_SECURITY_LIMIT_ERROR
      });
      errorToast(error);
    });
};

// remove social security limit
export const removeSocialSecurityLimit = (data, contractTypeId, handleFunction) => (dispatch) => {
  dispatch({
    type: actions.DELETE_SECURITY_LIMIT_BEGIN
  });
  removeSocialSecurityLimitApi(data)
    .then((res) => {
      dispatch({
        type: actions.DELETE_SECURITY_LIMIT_SUCCESS
      });
      dispatch(getContribution(data.employee_id, contractTypeId));
      handleFunction && handleFunction();
    })
    .catch((error) => {
      dispatch({
        type: actions.DELETE_SECURITY_LIMIT_ERROR
      });
      errorToast(error);
    });
};
