import React from 'react';
import DashboardLayout from 'layouts/dashboardLayout';
import SettingsPage from 'components/locals/settings';

const Settings = () => {
  return (
    <DashboardLayout>
      <SettingsPage />
    </DashboardLayout>
  );
};

export default Settings;
