import * as React from 'react';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import CustomTable from 'components/globals/table';
import { useState } from 'react';
import { useEffect } from 'react';
import ContractView from './ContractView';
import { getAgreementLogs } from '../../redux/actions';
import BasicDetail from './BasicDetail';
import { Visibility } from '@mui/icons-material';

export default function ContractHistoryTable({ id, employeeName }) {
  const columns = [
    { title: 'S.N.', type: 'Index', isSortable: true, minWidth: 20 },
    {
      title: 'Contract Number',
      minWidth: 60,
      isSortable: true,
      field: (row) => <ContractNumberView row={row} />
    },
    {
      field: 'start_date',
      title: 'Contract From',
      minWidth: 170,
      align: 'right'
    },
    {
      field: 'end_date',
      title: 'Contract To',
      minWidth: 120,
      align: 'right'
    },

    {
      field: 'type',
      title: 'Duration',
      minWidth: 170,
      align: 'right'
    },

    {
      field: 'gross_salary',
      title: 'Gross',
      minWidth: 170,
      align: 'right'
    },

    {
      field: (row) => {
        return <Actions single_contract={row} employeeName={employeeName} />;
      },
      title: 'Actions',
      minWidth: 170,
      align: 'center'
    }
  ];

  const { agreement_logs } = useSelector((state) => state.contract);
  const rows = agreement_logs || [];
  const dispatch = useDispatch();

  useEffect(() => {
    id && dispatch(getAgreementLogs(id));
  }, [id]);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <CustomTable tableData={rows} tableHeads={columns} />
    </Paper>
  );
}

const Actions = ({ single_contract, employeeName }) => {
  const handleClick = () => {
    openFunction();
  };
  const classes = useStyles();
  const [modalOpen, openFunction] = useToggle(false);

  return (
    <>
      <div className={classes.iconWrapper}>
        <Visibility onClick={handleClick} />
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        headerText="Edit Contract">
        <BasicDetail single_contract={single_contract} employeeName={employeeName} />
      </CustomModal>
    </>
  );
};

const ContractNumberView = ({ row }) => {
  const [modalOpen, openFunction] = useToggle(false);
  const { employees } = useSelector((state) => state.employee);
  const [name, setName] = useState('');
  const contractYear = new Date(row?.date_of_signing);
  const contractNumber = row?.id + '/' + contractYear.getFullYear();
  const classes = useStyles();
  const handleClick = () => {
    openFunction();
  };
  useEffect(() => {
    const employeeName = employees?.find((employee) => employee?.id == row?.employee_id);
    setName(employeeName?.fullname);
  }, [employees]);
  return (
    <>
      <div className={classes.fullname} onClick={handleClick}>
        {contractNumber}
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        width="100rem"
        variant="light"
        headerText={`Details of Contact Number ${contractNumber} - ${name}`}>
        <ContractView id={row?.id} employeeName={name} />
      </CustomModal>
    </>
  );
};
