import { Button, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import useStyles from './styles';

import CancelModal from 'components/globals/modal/CancelModal';
import useToggle from 'hooks/useToggle';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import StatementForm from './Form';
import { createStatement } from './redux/actions';

function AddStatement({ handleClose, contractId, rate }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [cancelOpen, cancelFunction] = useToggle(false);
  const [data, setData] = useState({ year: '', month: '', value: '' });
  const { create_loading } = useSelector((state) => state.statement);

  const handleSubmit = () => {
    dispatch(createStatement({ agreement_id: contractId, rate: rate, ...data }, handleClose));
  };

  return (
    <>
      <div className={classes.content}>
        <StatementForm rate={rate} setData={setData} data={data} />
        <Box className={classes.modalFooter}>
          <Box className={classes.modalFooterButtons}>
            {!create_loading ? (
              <Button sx={{ marginRight: '1rem' }} variant={'contained'} onClick={handleSubmit}>
                <FormattedMessage id="Save" defaultMessage="Save" />
              </Button>
            ) : (
              <Button sx={{ marginRight: '1rem' }} variant={'contained'} disabled>
                <CircularProgress size={20} />
              </Button>
            )}

            <Button
              className={classes.modalFooterCancel}
              variant={'outlined'}
              onClick={cancelFunction}>
              <FormattedMessage id="Cancel" defaultMessage="Cancel" />
            </Button>
          </Box>
        </Box>
      </div>
      <CancelModal
        handleClose={handleClose}
        cancelFunction={cancelFunction}
        cancelOpen={cancelOpen}
      />
    </>
  );
}

export default AddStatement;
