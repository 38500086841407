import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#F9F9FB',
    padding: '2.4rem 4.5rem',
    minHeight: 'calc(100vh - 17rem)'
  }
}));

export default useStyles;
