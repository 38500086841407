import { Autocomplete, Box, Button, TextField, Tooltip, Typography } from '@mui/material';
import useToggle from 'hooks/useToggle';
import React, { useState } from 'react';
import useStyles from '../styles';
import AddIcon from '@mui/icons-material/Add';
import CustomModal from 'components/globals/modal';
import EmployeeAdd from 'components/locals/workers/EmployeeAdd';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

function EmployeeSelect({ handleEmployeeId, customError, setCustomError }) {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [modalOpen, openFunction] = useToggle(false);
  const { employees } = useSelector((state) => state.employee);

  const handleChange = (data) => {
    setValue(data);
    handleEmployeeId(data);
    setCustomError('');
  };

  return (
    <>
      <Box
        marginTop={3}
        marginBottom={2}
        marginLeft={4.5}
        display="flex"
        justifyContent="flex-start">
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          className={classes.employeeSearch}
          options={
            employees?.map((item) => ({
              label: item?.last_name ? item?.last_name + ' ' + item?.first_name : '',
              value: item?.id
            })) || []
          }
          sx={{ width: 300 }}
          isOptionEqualToValue={(option) => option.value}
          getOptionLabel={(option) => option.label}
          onChange={(e, data) => handleChange(data?.value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={<FormattedMessage id="Add Employee" defaultMessage="Add Employee" />}
              InputLabelProps={{ shrink: true }}
              value={value}
              error={Boolean(customError)}
              helperText={customError}
            />
          )}
        />
        <Box className={classes.employeeBtn} onClick={openFunction}>
          <Tooltip
            title={
              <Typography variant="body1">
                {<FormattedMessage id="Add Employee" defaultMessage="Add Employee" />}
              </Typography>
            }
            placement="top">
            <Button variant="outlined" color="btnColor">
              <AddIcon />
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        headerText={<FormattedMessage id="Add Employee" defaultMessage="Add Employee" />}>
        <EmployeeAdd handleClose={openFunction} />
      </CustomModal>
    </>
  );
}

export default EmployeeSelect;
