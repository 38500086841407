export const SET_AUTH_USER = 'SET_AUTH_USER';

export const SET_COMPANY_ID = 'SET_COMPANY_ID';

export const FORGOT_PASSWORD_BEGIN = 'FORGOT_PASSWORD_BEGIN';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export const RESET_PASSWORD_BEGIN = 'RESET_PASSWORD_BEGIN';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const CHANGE_LOGO = 'CHANGE_LOGO';

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const SET_LANGUAGE = 'SET_LANGUAGE';

export const FETCH_COMPANY_BEGIN = 'FETCH_COMPANY_BEGIN';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_ERROR = 'FETCH_COMPANY_ERROR';

export const FETCH_COMPANY_DASHBOARD_BEGIN = 'FETCH_COMPANY_DASHBOARD_BEGIN';
export const FETCH_COMPANY_DASHBOARD_SUCCESS = 'FETCH_COMPANY_DASHBOARD_SUCCESS';
export const FETCH_COMPANY_DASHBOARD_ERROR = 'FETCH_COMPANY_DASHBOARD_ERROR';

export const FETCH_ACCOUNTING_DASHBOARD_BEGIN = 'FETCH_ACCOUNTING_DASHBOARD_BEGIN';
export const FETCH_ACCOUNTING_DASHBOARD_SUCCESS = 'FETCH_ACCOUNTING_DASHBOARD_SUCCESS';
export const FETCH_ACCOUNTING_DASHBOARD_ERROR = 'FETCH_ACCOUNTING_DASHBOARD_ERROR';

export const SET_ACCOUNTING = 'SET_ACCOUNTING';
