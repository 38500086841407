export const CREATE_STATEMENT_BEGIN = 'CREATE_STATEMENT_BEGIN';
export const CREATE_STATEMENT_SUCCESS = 'CREATE_STATEMENT_SUCCESS';
export const CREATE_STATEMENT_ERROR = 'CREATE_STATEMENT_ERROR';

export const GET_STATEMENT_BEGIN = 'GET_STATEMENT_BEGIN';
export const GET_STATEMENT_SUCCESS = 'GET_STATEMENT_SUCCESS';
export const GET_STATEMENT_ERROR = 'GET_STATEMENT_ERROR';

export const GET_SINGLE_STATEMENT_BEGIN = 'GET_SINGLE_STATEMENT_BEGIN';
export const GET_SINGLE_STATEMENT_SUCCESS = 'GET_SINGLE_STATEMENT_SUCCESS';
export const GET_SINGLE_STATEMENT_ERROR = 'GET_SINGLE_STATEMENT_ERROR';

export const EDIT_STATEMENT_BEGIN = 'EDIT_STATEMENT_BEGIN';
export const EDIT_STATEMENT_SUCCESS = 'EDIT_STATEMENT_SUCCESS';
export const EDIT_STATEMENT_ERROR = 'EDIT_STATEMENT_ERROR';

export const DELETE_STATEMENT_BEGIN = 'DELETE_STATEMENT_BEGIN';
export const DELETE_STATEMENT_SUCCESS = 'DELETE_STATEMENT_SUCCESS';
export const DELETE_STATEMENT_ERROR = 'DELETE_STATEMENT_ERROR';
