import { axiosInstance } from 'apis/_axios';

// create contract
export const createContractApi = (data) => {
  return axiosInstance().post('/agreement', data);
};

// get all contract
export const getContractApi = (data) => {
  const startDate = data?.start_date ? data?.start_date : '';
  const endDate = data?.end_date ? data?.end_date : '';
  const employeeId = data?.employee ? `&employee_id=${data?.employee}` : '';
  const agreementId = data?.agreementId ? `&agreement_type_id=${data?.agreementId}` : '';
  return axiosInstance().get(
    `/agreement?start_date=${startDate}&end_date=${endDate}${employeeId}${agreementId}`
  );
};

// get contract by id
export const getContractByIdApi = (id) => {
  return axiosInstance().get(`/agreement/${id}`);
};

// edit contract
export const editContractApi = (id, data) => {
  return axiosInstance().post(`/agreement/${id}`, data);
};

//delete contract
export const deleteContractApi = (id) => {
  return axiosInstance().delete(`/agreement/${id}`);
};

// get all active agreement type
export const getAllAgreementTypeApi = () => {
  return axiosInstance().get(`/agreement-type`);
};

// get insurance code
export const getInsuranceCodeApi = () => {
  return axiosInstance().get(`/insurance-code`);
};

// get occupation code
export const getOccupationCodeApi = () => {
  return axiosInstance().get('/occupation-code');
};

// calculate agreement
export const calculateAgreementApi = (data) => {
  return axiosInstance().post('/agreement-calculate', data);
};

// get contribution
export const getContributionApi = (employedId, slug) => {
  const agreement_type = slug ? `?agreement_type=${slug}` : '';
  return axiosInstance().get(`/opening-balance/${employedId}${agreement_type}`);
};

// update contribution
export const updateContributionApi = (data) => {
  return axiosInstance().post(`/opening-balance/upsert`, data);
};

// get agreeement log
export const getAgreementLogsApi = (id) => {
  return axiosInstance().get(`/agreement/${id}/logs`);
};

// get tax rates
export const getTaxRateApi = () => {
  return axiosInstance().get('/parameters/active_tax_rates');
};

// get employee contract
export const getEmployeeContractApi = (employedId) => {
  return axiosInstance().get(`/agreement/employee/${employedId}`);
};

// terminate contract
export const terminateContractApi = (data) => {
  return axiosInstance().post(`/agreement-terminate`, data);
};

// termination data
export const getTerminationDataApi = () => {
  return axiosInstance().get('/agreement-termination-data');
};

// update social security
export const updateSocialSecurityApi = (data) => {
  return axiosInstance().post('/opening-social-security/upsert', data);
};

// update social security limit
export const updateSocialSecurityLimitApi = (data) => {
  return axiosInstance().post('/social-security-limit/upsert', data);
};

// remove social security limit
export const removeSocialSecurityLimitApi = (data) => {
  return axiosInstance().post('/social-security-limit/remove', data);
};
