import { Box } from '@mui/material';

const EzlaJson = ({ ezlaData }) => {
  console.log(ezlaData, 'ezlaData');
  return (
    <Box margin={3}>
      <pre style={{ fontSize: '16px' }}>{ezlaData && JSON.stringify(ezlaData, null, 8)}</pre>
    </Box>
  );
};

export default EzlaJson;
