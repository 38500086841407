import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import BasicDetail from './BasicDetail';
import { useDispatch } from 'react-redux';
import { getEmployeeById } from '../../redux/actions';
import Document from './Document';
import { getFolderIdApi } from 'apis/employee';
import EmployeeContract from './EmployeeContract';
import Allowance from '../allowance/Allowance';
import TimeRecording from '../timeRecording/TimeRecording';
import Overtime from '../overtime/Overtime';
import { FormattedMessage } from 'react-intl';
import Payout from '../payout/Payout';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function EmployeeView({ id, tabValue = 0 }) {
  const [value, setVal] = useState(tabValue);
  const [folderId, setFolderId] = useState();
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleChange = (event, newValue) => {
    setVal(newValue);
  };

  useEffect(() => {
    id && dispatch(getEmployeeById(id));
    id && getFolderIdApi(id).then((res) => setFolderId(res.data?.data?.folder_id));
  }, [id]);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className={classes.viewRootTab}>
          <Tab
            label={<FormattedMessage id="Basic Details" defaultMessage="Basic Details" />}
            {...a11yProps(0)}
          />
          <Tab
            label={<FormattedMessage id="Contract" defaultMessage="Contract" />}
            {...a11yProps(1)}
          />
          <Tab
            label={
              <FormattedMessage
                id="Allowances and Deductions"
                defaultMessage="Allowances and Deductions"
              />
            }
            {...a11yProps(2)}
          />
          <Tab
            label={<FormattedMessage id="Time Recording" defaultMessage="Time Recording" />}
            {...a11yProps(3)}
          />
          <Tab
            label={<FormattedMessage id="Overtime" defaultMessage="Overtime" />}
            {...a11yProps(4)}
          />
          <Tab
            label={<FormattedMessage id="Payouts" defaultMessage="Payouts" />}
            {...a11yProps(5)}
          />
          <Tab
            label={<FormattedMessage id="Declarations" defaultMessage="Declarations" />}
            {...a11yProps(6)}
          />
          <Tab
            label={<FormattedMessage id="Documents" defaultMessage="Documents" />}
            {...a11yProps(7)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className={classes.contentWrap}>
          <BasicDetail />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className={classes.contentWrap}>
          <EmployeeContract employeeId={id} />
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className={classes.contentWrap}>
          <Allowance employeeId={id} />
        </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div className={classes.contentWrap}>
          <TimeRecording employeeId={id} />
        </div>
      </TabPanel>

      <TabPanel value={value} index={4}>
        <div className={classes.contentWrap}>
          <Overtime employeeId={id} />
        </div>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <div className={classes.contentWrap}>
          <Payout employeeId={id} />
        </div>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <div className={classes.contentWrap}></div>
      </TabPanel>
      <TabPanel value={value} index={7}>
        <div className={classes.contentWrap}>
          <Document folderId={folderId} />
        </div>
      </TabPanel>
    </>
  );
}

export default EmployeeView;
