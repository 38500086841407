import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getPayoutByEmployeeId } from '../../redux/actions';
import PayoutTable from './PayoutTable';

function Payout({ employeeId }) {
  const dispatch = useDispatch();
  useEffect(() => {
    employeeId && dispatch(getPayoutByEmployeeId(employeeId));
  }, [employeeId]);
  return (
    <div>
      <PayoutTable employeeId={employeeId} />
    </div>
  );
}

export default Payout;
