import { Button } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import useStyles from './styles';

import useToggle from 'hooks/useToggle';
import CancelModal from 'components/globals/modal/CancelModal';
import AllowanceForm from './Form';
import { FormattedMessage } from 'react-intl';

function AddAllowance({ handleClose, cancelAdd, employeeId, allowanceLength }) {
  const classes = useStyles();
  const [cancelOpen, cancelFunction] = useToggle(false);

  return (
    <>
      <div className={classes.content}>
        <AllowanceForm allowanceLength={allowanceLength} />
        <Box className={classes.modalFooter}>
          <Box className={classes.modalFooterButtons}>
            <Button sx={{ marginRight: '1rem' }} variant={'contained'} onClick={handleClose}>
              <FormattedMessage id="Save" defaultMessage="Save" />
            </Button>

            <Button
              className={classes.modalFooterCancel}
              variant={'outlined'}
              onClick={cancelFunction}>
              <FormattedMessage id="Cancel" defaultMessage="Cancel" />
            </Button>
          </Box>
        </Box>
      </div>
      <CancelModal
        handleClose={cancelAdd}
        cancelFunction={cancelFunction}
        cancelOpen={cancelOpen}
      />
    </>
  );
}

export default AddAllowance;
