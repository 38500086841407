import { Box, Grid } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from 'utils';
import { getEmployeeContract } from '../contract/redux/actions';
import AddAllowance from '../workers/components/allowance/AddAllowance';
import AddOvertime from '../workers/components/overtime/AddOvertime';
import AddTimeRecording from '../workers/components/timeRecording/AddTimeRecording';
import AddStatement from './components/AddStatement';
import EmployeeSelect from './components/EmployeeSelect';
import useStyles from './styles';

const EventCenter = ({ selectedEmployeeId, handleClose, handleRefetch }) => {
  const classes = useStyles();
  const [employeeId, setEmployeeId] = useState(selectedEmployeeId || '');
  const dispatch = useDispatch();
  const [type, setType] = useState('sick_leave');
  const { employee_contract_loading } = useSelector((state) => state.contract);

  const handleEmployeeId = (id) => {
    setEmployeeId(id);
    dispatch(getEmployeeContract(id));
  };

  return (
    <Box margin={5}>
      <Box display="none">
        <FormattedMessage id="Event Type" defaultMessage="Event Type" />
        <FormattedMessage id="Sick Leave" defaultMessage="Sick Leave" />
        <FormattedMessage id="Vacation Leave" defaultMessage="Vacation Leave" />
        <FormattedMessage id="Overtime Amount" defaultMessage="Overtime Amount" />
        <FormattedMessage id="Allowance" defaultMessage="Allowance" />
        <FormattedMessage id="Deduction" defaultMessage="Deduction" />
        <FormattedMessage id="Unpaid Leave" defaultMessage="Unpaid Leave" />
        <FormattedMessage id="Statement" defaultMessage="Statement" />
      </Box>
      <Grid container spacing={5}>
        <Grid sm={4} item>
          <EmployeeSelect handleEmployeeId={handleEmployeeId} />
        </Grid>
        <Grid item sm={1}></Grid>
        <Grid sm={6} item>
          <select
            className={classes.select}
            onChange={(e) => {
              setType(e.target.value);
            }}
            value={type}>
            <option value="">{translate('Event Type')}</option>
            <option value="sick_leave">{translate('Leave')}</option>
            <option value="overtime_amount">{translate('Overtime')}</option>
            <option value="allowance">{translate('Allowance and Deduction')}</option>
            <option value="statement">{translate('Statement')}</option>
          </select>
        </Grid>
      </Grid>
      {type == 'allowance' && employeeId && !employee_contract_loading && (
        <Box marginTop={4} border="1px solid #c3c3c3">
          <AddAllowance
            employeeId={employeeId}
            handleClose={handleClose}
            handleRefetch={handleRefetch}
          />
        </Box>
      )}
      {type == 'overtime_amount' && employeeId && !employee_contract_loading && (
        <Box marginTop={4} border="1px solid #c3c3c3">
          <AddOvertime
            employeeId={employeeId}
            handleClose={handleClose}
            handleRefetch={handleRefetch}
          />
        </Box>
      )}
      {type == 'sick_leave' && employeeId && !employee_contract_loading && (
        <Box marginTop={4} border="1px solid #c3c3c3">
          <AddTimeRecording
            employeeId={employeeId}
            handleClose={handleClose}
            handleRefetch={handleRefetch}
          />
        </Box>
      )}
      {type == 'statement' && employeeId && !employee_contract_loading && (
        <Box marginTop={4} border="1px solid #c3c3c3">
          <AddStatement
            employeeId={employeeId}
            handleClose={handleClose}
            handleRefetch={handleRefetch}
          />
        </Box>
      )}
    </Box>
  );
};

export default EventCenter;
