export const CREATE_EMPLOYEE_BEGIN = 'CREATE_EMPLOYEE_BEGIN';
export const CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS';
export const CREATE_EMPLOYEE_ERROR = 'CREATE_EMPLOYEE_ERROR';

export const GET_EMPLOYEE_BEGIN = 'GET_EMPLOYEE_BEGIN';
export const GET_EMPLOYEE_SUCCESS = 'GET_EMPLOYEE_SUCCESS';
export const GET_EMPLOYEE_ERROR = 'GET_EMPLOYEE_ERROR';

export const GET_EMPLOYEE_PAGINATED_BEGIN = 'GET_EMPLOYEE_PAGINATED_BEGIN';
export const GET_EMPLOYEE_PAGINATED_SUCCESS = 'GET_EMPLOYEE_PAGINATED_SUCCESS';
export const GET_EMPLOYEE_PAGINATED_ERROR = 'GET_EMPLOYEE_PAGINATED_ERROR';

export const GET_SINGLE_EMPLOYEE_BEGIN = 'GET_SINGLE_EMPLOYEE_BEGIN';
export const GET_SINGLE_EMPLOYEE_SUCCESS = 'GET_SINGLE_EMPLOYEE_SUCCESS';
export const GET_SINGLE_EMPLOYEE_ERROR = 'GET_SINGLE_EMPLOYEE_ERROR';

export const EDIT_EMPLOYEE_BEGIN = 'EDIT_EMPLOYEE_BEGIN';
export const EDIT_EMPLOYEE_SUCCESS = 'EDIT_EMPLOYEE_SUCCESS';
export const EDIT_EMPLOYEE_ERROR = 'EDIT_EMPLOYEE_ERROR';

export const CHANGE_EMPLOYEE_BEGIN = 'CHANGE_EMPLOYEE_BEGIN';
export const CHANGE_EMPLOYEE_SUCCESS = 'CHANGE_EMPLOYEE_SUCCESS';
export const CHANGE_EMPLOYEE_ERROR = 'CHANGE_EMPLOYEE_ERROR';

export const GET_TAX_OFFICE_BEGIN = 'GET_TAX_OFFICE_BEGIN';
export const GET_TAX_OFFICE_SUCCESS = 'GET_TAX_OFFICE_SUCCESS';
export const GET_TAX_OFFICE_ERROR = 'GET_TAX_OFFICE_ERROR';

export const GET_NHF_BEGIN = 'GET_NHF_BEGIN';
export const GET_NHF_SUCCESS = 'GET_NHF_SUCCESS';
export const GET_NHF_ERROR = 'GET_NHF_ERROR';

export const GET_PAYOUT_BEGIN = 'GET_PAYOUT_BEGIN';
export const GET_PAYOUT_SUCCESS = 'GET_PAYOUT_SUCCESS';
export const GET_PAYOUT_ERROR = 'GET_PAYOUT_ERROR';

export const CLEAR_SINGLE_EMPLOYEE = 'CLEAR_SINGLE_EMPLOYEE';

export const UPLOAD_EMPLOYEE_LIST_BEGIN = 'UPLOAD_EMPLOYEE_LIST_BEGIN';
export const UPLOAD_EMPLOYEE_LIST_SUCCESS = 'UPLOAD_EMPLOYEE_LIST_SUCCESS';
export const UPLOAD_EMPLOYEE_LIST_ERROR = 'UPLOAD_EMPLOYEE_LIST_ERROR';
