import { axiosInstance } from 'apis/_axios';

// create employee
export const createEmployeeApi = (data) => {
  return axiosInstance().post('/employee', data);
};

// get all employee
export const getEmployeeApi = (paginate = 0) => {
  return axiosInstance().get('/all-employee', {
    params: {
      paginate: paginate,
      limit: null
    }
  });
};

// get employee by id
export const getEmployeeByIdApi = (id) => {
  return axiosInstance().get(`/employee/${id}`);
};

// edit employee
export const editEmployeeApi = (id, data) => {
  return axiosInstance().post(`/employee/${id}`, data);
};

// change employee status
export const changeEmployeeStatusApi = (id) => {
  return axiosInstance().post(`/employee/${id}`);
};

// get folder id of employee
export const getFolderIdApi = (id) => {
  return axiosInstance().get(`/api/folder/${id}/folder-id`);
};

// get tax offices
export const getTaxOfficeApi = () => {
  return axiosInstance().get(`/tax-office`);
};

// get department of NHF
export const getNHFApi = () => {
  return axiosInstance().get(`/nfz-branch`);
};

// get payout by employee id
export const getPayoutByEmployeeIdApi = (employeeId) => {
  return axiosInstance().get(`/payouts/employee/${employeeId}`);
};

// download sample
export const downloadEmployeeSample = () => {
  return axiosInstance().get('/employee_sample');
};

// upload employee list
export const uploadEmployeeListApi = (data) => {
  return axiosInstance().post(`/employee-import`, data);
};
