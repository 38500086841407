import React from 'react';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from 'components/globals/table';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { getParameterByName } from '../redux/actions';
import { Skeleton } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const columns = [
  { title: '#', type: 'Index', minWidth: 20 },
  {
    field: 'date_s',
    title: <FormattedMessage id="Start Date" defaultMessage="Start Date" />,
    minWidth: 170,
    align: 'right'
  },
  {
    field: 'date_e',
    title: <FormattedMessage id="End Date" defaultMessage="End Date" />,
    minWidth: 170,
    align: 'right'
  },
  {
    field: (row) => <TaxBandData parameter={row} />,
    title: <FormattedMessage id="Value" defaultMessage="Value" />,
    minWidth: 170,
    align: 'right'
  }
];

export default function TaxBandTable({ parameter }) {
  const dispatch = useDispatch();
  const { singleParameterList, listByNameLoading } = useSelector((state) => state.parameter);

  // useEffect(() => {
  //   singleParameterList &&
  //     setTableData(
  //       singleParameterList.map((item) => ({
  //         id: item?.id,
  //         key: item?.par_name,
  //         start_date: item?.date_s,
  //         end_date: item?.date_e,
  //         value: item?.par_value
  //       }))
  //     );
  // }, [singleParameterList]);

  useEffect(() => {
    parameter?.key && dispatch(getParameterByName(parameter.key));
  }, [parameter?.key]);

  return (
    <Box padding={3}>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        {!listByNameLoading ? (
          <CustomTable tableData={singleParameterList} tableHeads={columns} />
        ) : (
          <Skeleton height={100} />
        )}
      </Paper>
    </Box>
  );
}

const TaxBandData = ({ parameter }) => {
  return (
    <>
      {parameter?.par_value?.map((tax) => (
        <>
          <div>
            {tax?.tax_from || tax?.max_tax + '+'} {!tax?.max_tax && ' - ' + tax?.tax_to} :{' '}
            {tax?.tax_rate || tax?.max_rate}%
          </div>
        </>
      ))}
    </>
  );
};
