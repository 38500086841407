import { Button, CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { HookForm } from 'components/form/hookForm';
import { HookFormProvider } from 'components/form/hookFormProvider';
import CancelModal from 'components/globals/modal/CancelModal';
import useToggle from 'hooks/useToggle';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { changeDateFormat } from 'utils/dateUtils';
import * as Yup from 'yup';
import EmployeeSelect from '../common/EmployeeSelect';
import { createContract } from '../redux/actions';
import useStyles from '../styles';
import SpecificContractForm from './Form';

function SpecificContractAdd({ handleClose, contractId, selectedEmployeeId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [employeeId, setEmployeeId] = useState(selectedEmployeeId || '');
  const [customError, setCustomError] = useState();
  const { create_loading } = useSelector((state) => state.contract);
  const [cancelOpen, cancelFunction] = useToggle(false);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const { employees } = useSelector((state) => state.employee);

  const handleEmployeeId = (id) => {
    setEmployeeId(id);
  };

  const defaultValues = {
    gross_salary: 0,
    type: 'fixed',
    date_of_signing: changeDateFormat(new Date(), 'YYYY-MM-DD'),
    start_date: changeDateFormat(new Date(), 'YYYY-MM-DD'),
    end_date: null,
    parameters: {
      work_hour_numerator: 1,
      work_hour_denominator: 1,
      cost_of_getting_income: 'standard',
      labor_fund_insurance: true,
      fgsp_insurance: true,
      own_employee: true,
      pit_exemption: null,
      tax_rate: null,
      rate: 'monthly',
      tax_relief: 'half',
      insurance_code_id: '',
      occupation_code_id: '',
      work_description: '',
      shared_taxation: 0,
      vacation_duration: 20
    },
    allowance_deductions: []
  };

  const validationSchema = Yup.object({
    gross_salary: Yup.number().min(1, 'Enter valid salary'),
    parameters: Yup.object().shape({
      rate: Yup.string().required(
        <FormattedMessage id="Rate is required" defaultMessage="Rate is required" />
      )
    }),
    start_date: Yup.date()
      .nullable()
      .notRequired()
      .when(
        'date_of_signing',
        (started, yup) => started && yup.min(started, 'End date cannot be before date of signing')
      ),
    end_date: Yup.date()
      .nullable()
      .notRequired()
      .when(
        'start_date',
        (started, yup) => started && yup.min(started, 'End date cannot be before start date')
      )
      .when('type', {
        is: (data) => (data != 'indefinite' ? true : false),
        then: Yup.date().required('End date required for Indefinite Contract Type')
      })
  });

  const onSubmit = (data) => {
    console.log(data);
    setCustomError('');
    if (employeeId) {
      const finalData = {
        ...data,
        date_of_signing: changeDateFormat(data?.date_of_signing, 'YYYY-MM-DD'),
        start_date: changeDateFormat(data?.start_date, 'YYYY-MM-DD'),
        end_date: data?.end_date ? changeDateFormat(data?.end_date, 'YYYY-MM-DD') : null,
        employee_id: employeeId,
        agreement_type_id: contractId
      };
      dispatch(createContract(finalData, handleClose));
    } else {
      setCustomError('Select Employee');
    }
  };

  useEffect(() => {
    if (selectedEmployeeId) {
      const filterEmployee = employees?.find((item) => item?.id === selectedEmployeeId);
      const firstName = filterEmployee?.first_name ?? '';
      const middleName = filterEmployee?.middle_name ?? '';
      const lastName = filterEmployee?.last_name ?? '';
      const fullName = firstName + ' ' + middleName + ' ' + lastName;
      setSelectedEmployee(fullName);
    }
  }, [selectedEmployeeId]);

  return (
    <>
      <div className={classes.content}>
        {selectedEmployee ? (
          <Box marginX={4} marginY={2}>
            <Typography variant="h5">{selectedEmployee}</Typography>
          </Box>
        ) : (
          <EmployeeSelect
            handleEmployeeId={handleEmployeeId}
            customError={customError}
            setCustomError={setCustomError}
          />
        )}
        <HookFormProvider
          defaultValues={defaultValues}
          resolver={useYupValidationResolver(validationSchema)}>
          <HookForm onSubmit={onSubmit}>
            <SpecificContractForm contractId={contractId} employeeId={employeeId} />
            <Box className={classes.modalFooter}>
              <Box className={classes.modalFooterButtons}>
                {!create_loading ? (
                  <Button sx={{ marginRight: '1rem' }} variant="contained" type="submit">
                    <FormattedMessage id="Create" defaultMessage="Create" />
                  </Button>
                ) : (
                  <Button sx={{ marginRight: '1rem' }} variant="contained" disabled>
                    <CircularProgress size={18} sx={{ marginRight: '10px' }} />
                    <FormattedMessage id="Creating" defaultMessage="Creating" />
                    ..
                  </Button>
                )}
                <Button
                  className={classes.modalFooterCancel}
                  variant={'outlined'}
                  onClick={cancelFunction}>
                  <FormattedMessage id="Cancel" defaultMessage="Cancel" />
                </Button>
              </Box>
            </Box>
          </HookForm>
        </HookFormProvider>
      </div>
      <CancelModal
        handleClose={handleClose}
        cancelFunction={cancelFunction}
        cancelOpen={cancelOpen}
      />
    </>
  );
}

export default SpecificContractAdd;
