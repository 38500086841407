import { Button, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { HookForm } from 'components/form/hookForm';
import { HookFormProvider } from 'components/form/hookFormProvider';
import CancelModal from 'components/globals/modal/CancelModal';
import useToggle from 'hooks/useToggle';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import AllowanceForm from './Form';
import { clearOvertime, createOvertime } from './redux/actions';
import useStyles from './styles';

function AddOvertime({ handleClose, employeeId, handleRefetch }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { create_loading, amount_loading } = useSelector((state) => state.overtime);

  const defaultValues = {
    name: '',
    value: '',
    hours: '',
    year: '',
    month: ''
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    hours: Yup.string().required('Hours is required'),
    value: Yup.string().required('Overtime value is required'),
    year: Yup.string().required('Year  is required'),
    month: Yup.string().required('Month  is required')
  });

  const onSubmit = (data) => {
    const finalData = {
      ...data,
      employee_id: employeeId
    };
    dispatch(createOvertime(finalData, handleClose, handleRefetch));
  };

  useEffect(() => {
    // dispatch(clearSingleEmployee());
    dispatch(clearOvertime());
  }, []);

  const [cancelOpen, cancelFunction] = useToggle(false);

  return (
    <>
      <div className={classes.content}>
        <HookFormProvider
          defaultValues={defaultValues}
          resolver={useYupValidationResolver(validationSchema)}>
          <HookForm onSubmit={onSubmit}>
            <AllowanceForm employeeId={employeeId} />
            <Box className={classes.modalFooter} justifyContent="flex-end">
              <Box className={classes.modalFooterButtons}>
                {!create_loading ? (
                  <Button
                    sx={{ marginRight: '1rem' }}
                    variant={'contained'}
                    type="submit"
                    disabled={amount_loading}>
                    {<FormattedMessage id="Save" defaultMessage="Save" />}
                  </Button>
                ) : (
                  <Button sx={{ marginRight: '1rem' }} variant={'contained'} disabled>
                    <CircularProgress size={18} sx={{ marginRight: '1rem' }} />{' '}
                    {<FormattedMessage id="Saving" defaultMessage="Saving" />}
                  </Button>
                )}

                <Button
                  className={classes.modalFooterCancel}
                  variant={'outlined'}
                  onClick={cancelFunction}>
                  {<FormattedMessage id="Cancel" defaultMessage="Cancel" />}
                </Button>
              </Box>
            </Box>
          </HookForm>
        </HookFormProvider>
      </div>
      <CancelModal
        handleClose={handleClose}
        cancelFunction={cancelFunction}
        cancelOpen={cancelOpen}
      />
    </>
  );
}

export default AddOvertime;
