import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import CancelModal from 'components/globals/modal/CancelModal';
import CustomTable from 'components/globals/table';
import useToggle from 'hooks/useToggle';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from 'utils';
import { deleteStatement } from './redux/actions';
import useStyles from './styles';

export default function StatementTable({ employeeId }) {
  const { statements, loading } = useSelector((state) => state.statement);
  const months = [
    { label: translate('January'), value: 1 },
    { label: translate('February'), value: 2 },
    { label: translate('March'), value: 3 },
    { label: translate('April'), value: 4 },
    { label: translate('May'), value: 5 },
    { label: translate('June'), value: 6 },
    { label: translate('July'), value: 7 },
    { label: translate('August'), value: 8 },
    { label: translate('September'), value: 9 },
    { label: translate('October'), value: 10 },
    { label: translate('November'), value: 11 },
    { label: translate('December'), value: 12 }
  ];
  const currentMonth = (item) => {
    const mon = months?.find((month) => month?.value == item?.month);

    return mon?.label;
  };
  const columns = [
    { title: 'S.N.', type: 'Index', isSortable: true, minWidth: 20 },
    {
      title: <FormattedMessage id="Period of the list" defaultMessage="Period of the list" />,
      minWidth: 100,
      isSortable: true,
      field: (row) => {
        return currentMonth(row) + ' , ' + row?.year;
      }
    },

    {
      field: 'final_value',
      title: <FormattedMessage id="Base Salary" defaultMessage="Base Salary" />,
      minWidth: 170,
      align: 'right'
    },

    {
      field: (row, field, index) => {
        return <Actions row={row} index={index} />;
      },
      title: <FormattedMessage id="Actions" defaultMessage="Actions" />,
      minWidth: 170,
      align: 'center'
    }
  ];

  const rows = statements?.statements || [];

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <CustomTable tableData={rows} tableHeads={columns} loading={loading} />
    </Paper>
  );
}

const Actions = ({ row }) => {
  const classes = useStyles();
  const [deleteOpen, openDeleteFunction] = useToggle(false);
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteStatement(row?.id, openDeleteFunction, row?.agreement_id));
  };

  return (
    <>
      <div className={classes.iconWrapper}>
        <Box display="none">
          <FormattedMessage
            id="View Allowances and Deduction"
            defaultMessage="View Allowances and Deduction"
          />
        </Box>

        <Tooltip title="Delete">
          <DeleteIcon onClick={openDeleteFunction} />
        </Tooltip>
      </div>

      <CancelModal
        title={
          <FormattedMessage
            id="Do you want to Delete it?"
            defaultMessage="Do you want to Delete it?"
          />
        }
        handleClose={handleDelete}
        cancelFunction={openDeleteFunction}
        cancelOpen={deleteOpen}
      />
    </>
  );
};
