import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import CustomModal from 'components/globals/modal';
import CustomTable from 'components/globals/table';
import useToggle from 'hooks/useToggle';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDepartment, editDepartment, getDepartment } from './redux/actions';
import useStyles from './styles';

const columns = [
  { title: '#', type: 'Index', minWidth: 20 },
  {
    field: 'title',
    title: <FormattedMessage id="Contribution" defaultMessage="Contribution" />,
    minWidth: 100
  },
  {
    field: (row) => <Actions departmentId={row?.id} title={row?.title} />,
    title: <FormattedMessage id="Actions" defaultMessage="Actions" />,
    minWidth: 170,
    align: 'center'
  }
];

export default function ViewTable() {
  const dispatch = useDispatch();
  const { departments } = useSelector((state) => state.department);

  const rows = departments;

  useEffect(() => {
    dispatch(getDepartment());
  }, []);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <CustomTable tableData={rows} tableHeads={columns} />
    </Paper>
  );
}

const Actions = ({ departmentId, title }) => {
  const [modalOpen, openFunction] = useToggle(false);
  const [deleteModalOpen, openFunctionDelete] = useToggle(false);

  const handleClick = () => {
    openFunction();
  };

  const [value, setValue] = useState(title);
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSubmit = () => {
    dispatch(
      editDepartment(departmentId, { title: value, status_id: 1, method: 'put' }, handleClick)
    );
  };

  const handleDelete = () => {
    dispatch(deleteDepartment(departmentId, openFunctionDelete));
  };
  const classes = useStyles();
  return (
    <>
      <div className={classes.iconWrapper}>
        <EditIcon onClick={handleClick} />
        <DeleteOutlineIcon className={classes.deleteBtn} onClick={openFunctionDelete} />
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="22rem"
        width="35rem"
        headerText={<FormattedMessage id="Edit Department" defaultMessage="Edit Department" />}>
        <Box margin={5}>
          <Grid
            spacing={3}
            container
            justifyContent="space-between"
            direction="row"
            alignItems="center">
            <Grid item sm={12}>
              <input
                type="text"
                value={value}
                placeholder={
                  <FormattedMessage id="Department Name" defaultMessage="Department Name" />
                }
                onChange={handleChange}
                className={classes.inputText}
              />
            </Grid>
            <Grid item sm={12}>
              <Button variant="contained" fullWidth onClick={handleSubmit}>
                {<FormattedMessage id="Submit" defaultMessage="Submit" />}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CustomModal>
      <CustomModal
        open={deleteModalOpen}
        handleClose={openFunctionDelete}
        height="auto"
        width="40rem"
        headerText={<FormattedMessage id="Delete Parameter" defaultMessage="Delete Parameter" />}>
        <Box marginTop={6} marginBottom={4}>
          <Typography variant="h5" textAlign="center">
            {
              <FormattedMessage
                id="Are you sure you want to delete the department?"
                defaultMessage="Are you sure you want to delete the department?"
              />
            }{' '}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="space-around">
          <Button variant={'contained'} onClick={handleDelete}>
            {<FormattedMessage id="Yes" defaultMessage="Yes" />}
          </Button>
          <Button
            className={classes.modalFooterCancel}
            variant={'outlined'}
            onClick={openFunctionDelete}>
            {<FormattedMessage id="No" defaultMessage="No" />}
          </Button>
        </Box>
      </CustomModal>
    </>
  );
};
