import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    '& svg': {
      marginRight: '1.5rem',
      color: theme.palette.grey[600],
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary
      }
    }
  },
  contentWrap: {
    padding: '0rem 3rem'
  },
  modalFooter: {
    width: '100%',
    marginTop: 'auto',
    marginLeft: 'auto',
    background: '#fff',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem 3rem',
    height: '4.9rem'
  },
  modalFooterButtons: {
    fontFamily: 'Euclid',
    ':&>*': {
      margin: '0 1rem'
    }
  },
  modalFooterCancel: {
    '&:hover': {
      background: '#ddd'
    }
  },
  fullname: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  departmentBtn: {
    marginTop: '2rem',
    '& button': {
      padding: '0.7rem 0',
      minWidth: '4rem'
    }
  },
  formContent: {
    padding: '1.5rem 3rem'
  },
  content: {
    padding: '0'
  },
  deleteBtn: {
    color: `${theme.palette.error.main} !important`
  },
  editRoot: {
    padding: '1rem 3rem',
    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0
    },
    '& input': {
      border: `1px solid ${theme.palette.grey[400]}`,
      width: '100%',
      padding: '0.5rem 1rem'
    }
  },
  label: {
    fontSize: '1.1rem'
  },
  inputText: {
    fontSize: '1.3rem',
    border: `1px solid ${theme.palette.grey[400]}`,
    marginBottom: '1rem',
    padding: '0.4rem 1rem'
  }
}));

export default useStyles;
