import Paper from '@mui/material/Paper';

import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import ParameterView from './components/ParameterView';
import TaxBandTable from './components/TaxBandTable';
import { clearParameterByName } from './redux/actions';
import useStyles from './styles';

export default function ParameterTable() {
  const { parameters, adminParameters } = useSelector((state) => state.parameter);
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  const socialRowsEmployee = [
    {
      id: 1,
      contribution: (
        <>
          <FormattedMessage id="Pension Insurance" defaultMessage="Pension Insurance" />
          (%)
        </>
      ),
      key: 'pension_insurance_employee',
      value:
        parameters?.find((item) => item.par_name == 'pension_insurance_employee')?.par_value ||
        adminParameters?.find((item) => item.par_name == 'pension_insurance_employee')?.par_value +
          ' *'
    },
    {
      id: 2,
      contribution: (
        <>
          <FormattedMessage id="Disability Insurance" defaultMessage="Disability Insurance" />
          (%)
        </>
      ),
      key: 'disability_insurance_employee',
      value:
        parameters?.find((item) => item.par_name == 'disability_insurance_employee')?.par_value ||
        adminParameters?.find((item) => item.par_name == 'disability_insurance_employee')
          ?.par_value + ' *'
    },
    {
      id: 3,
      contribution: (
        <>
          <FormattedMessage id="Sickness Insurance" defaultMessage="Sickness Insurance" /> (%)
        </>
      ),
      key: 'sickness_insurance_employee',
      value:
        parameters?.find((item) => item.par_name == 'sickness_insurance_employee')?.par_value ||
        adminParameters?.find((item) => item.par_name == 'sickness_insurance_employee')?.par_value +
          ' *'
    }
  ];

  const socialRowsEmployer = [
    {
      id: 4,
      contribution: (
        <>
          <FormattedMessage id="Pension Insurance" defaultMessage="Pension Insurance" /> (%)
        </>
      ),
      key: 'pension_insurance_employer',
      value:
        parameters?.find((item) => item.par_name == 'pension_insurance_employer')?.par_value ||
        adminParameters?.find((item) => item.par_name == 'pension_insurance_employer')?.par_value +
          ' *'
    },
    {
      id: 5,
      contribution: (
        <>
          <FormattedMessage id="Disability Insurance" defaultMessage="Disability Insurance" /> (%)
        </>
      ),
      key: 'disability_insurance_employer',
      value:
        parameters?.find((item) => item.par_name == 'disability_insurance_employer')?.par_value ||
        adminParameters?.find((item) => item.par_name == 'disability_insurance_employer')
          ?.par_value + ' *'
    },
    {
      id: 6,
      contribution: (
        <>
          <FormattedMessage id="Accident Insurance" defaultMessage="Accident Insurance" /> (%)
        </>
      ),
      key: 'accident_insurance_employer',
      value:
        parameters?.find((item) => item.par_name == 'accident_insurance_employer')?.par_value ||
        adminParameters?.find((item) => item.par_name == 'accident_insurance_employer')?.par_value +
          ' *'
    },
    {
      id: 7,
      contribution: (
        <>
          <FormattedMessage
            id="Insurance for labor fund"
            defaultMessage="Insurance for labor fund"
          />{' '}
          (%)
        </>
      ),
      key: 'labor_fund_insurance_employer',
      value:
        parameters?.find((item) => item.par_name == 'labor_fund_insurance_employer')?.par_value ||
        adminParameters?.find((item) => item.par_name == 'labor_fund_insurance_employer')
          ?.par_value + ' *'
    },
    {
      id: 8,
      contribution: (
        <>
          <FormattedMessage id="Insurance for FGŚP" defaultMessage="Insurance for FGŚP" /> (%)
        </>
      ),
      key: 'fgsp_insurance_employer',
      value:
        parameters?.find((item) => item.par_name == 'fgsp_insurance_employer')?.par_value ||
        adminParameters?.find((item) => item.par_name == 'fgsp_insurance_employer')?.par_value +
          ' *'
    },
    {
      id: 9,
      contribution: (
        <>
          <FormattedMessage
            id="Insurance for FEP - Bridge Pension Fund"
            defaultMessage="Insurance for FEP - Bridge Pension Fund"
          />{' '}
          (%)
        </>
      ),
      key: 'fep_insurance_employer',
      value:
        parameters?.find((item) => item.par_name == 'fep_insurance_employer')?.par_value ||
        adminParameters?.find((item) => item.par_name == 'fep_insurance_employer')?.par_value + ' *'
    }
  ];

  const healthContributionRows = [
    {
      id: 10,
      contribution: (
        <>
          <FormattedMessage id="NFZ Contribution" defaultMessage="NFZ Contribution" /> (%)
        </>
      ),
      key: 'health_insurance_employee',
      value:
        parameters?.find((item) => item.par_name == 'health_insurance_employee')?.par_value ||
        adminParameters?.find((item) => item.par_name == 'health_insurance_employee')?.par_value +
          ' *'
    }
  ];
  const taxElements = [
    {
      id: 11,
      contribution: (
        <>
          <FormattedMessage id="Lack" defaultMessage="Lack" /> (PLN)
        </>
      ),
      key: 'tax_cost_lack',
      value: parameters?.find((item) => item.par_name == 'tax_cost_lack')
        ? parameters?.find((item) => item.par_name == 'tax_cost_lack').par_value
        : adminParameters?.find((item) => item.par_name == 'tax_cost_lack')?.par_value + ' *'
    },
    {
      id: 12,
      contribution: (
        <>
          <FormattedMessage id="Usual" defaultMessage="Usual" /> (PLN)
        </>
      ),
      key: 'tax_cost_usual',
      value:
        parameters?.find((item) => item.par_name == 'tax_cost_usual')?.par_value ||
        adminParameters?.find((item) => item.par_name == 'tax_cost_usual')?.par_value + ' *'
    },
    {
      id: 13,
      contribution: (
        <>
          <FormattedMessage id="Elevated" defaultMessage="Elevated" /> (PLN)
        </>
      ),
      key: 'tax_cost_elevated',
      value:
        parameters?.find((item) => item.par_name == 'tax_cost_elevated')?.par_value ||
        adminParameters?.find((item) => item.par_name == 'tax_cost_elevated')?.par_value + ' *'
    }
  ];
  const taxDiscount = [
    {
      id: 14,
      contribution: (
        <>
          <FormattedMessage id="Tax Allowance" defaultMessage="Tax Allowance" /> (PLN)
        </>
      ),
      key: 'tax_allowance',
      value:
        parameters?.find((item) => item.par_name == 'tax_allowance')?.par_value ||
        adminParameters?.find((item) => item.par_name == 'tax_allowance')?.par_value + ' *'
    },
    {
      id: 15,
      contribution: (
        <>
          <FormattedMessage id="Tax Credit Age" defaultMessage="Tax Credit Age" /> (PLN)
        </>
      ),
      key: 'tax_credit_age',
      value: parameters?.find((item) => item.par_name == 'tax_credit_age')?.par_value || ' *'
    },
    {
      id: 16,
      contribution: (
        <>
          <FormattedMessage
            id="Tax Relief Amount : PLN (Eg: for 26 years age)"
            defaultMessage="Tax Relief Amount : PLN (Eg: for 26 years age)"
          />{' '}
          (PLN)
        </>
      ),
      key: 'tax_relief_free_amount',
      value:
        parameters?.find((item) => item.par_name === 'tax_relief_free_amount')?.par_value || ' *'
    },
    {
      id: 17,
      contribution: (
        <>
          <FormattedMessage id="Tax Rates" defaultMessage="Tax Rates" /> (PLN)
        </>
      ),
      key: 'tax_band_rate',
      value: '-'
    },
    {
      id: 18,
      contribution: (
        <>
          <FormattedMessage id="Minimum wage for Work" defaultMessage="Minimum wage for Work" />{' '}
          (PLN)
        </>
      ),
      key: 'employment_minimum_wage',
      value:
        parameters?.find((item) => item.par_name === 'employment_minimum_wage')?.par_value || ' *'
    }
  ];
  const mandateSocialContributionRows = [
    {
      id: 25,
      contribution: (
        <>
          <FormattedMessage
            id="Limit of sickness contribution"
            defaultMessage="Limit of sickness contribution"
          />{' '}
          (PLN)
        </>
      ),
      key: 'sickness_contribution_limit_mandate',
      value:
        parameters?.find((item) => item.par_name === 'sickness_contribution_limit_mandate')
          ?.par_value || ' *'
    }
  ];
  const mandateIncomeCostsRows = [
    {
      id: 26,
      contribution: (
        <>
          <FormattedMessage id="Lack" defaultMessage="Lack" /> (%)
        </>
      ),
      key: 'mandate_tax_cost_lack',
      value: parameters?.find((item) => item.par_name === 'mandate_tax_cost_lack')
        ? parameters?.find((item) => item.par_name === 'mandate_tax_cost_lack')?.par_value
        : ' *'
    },
    {
      id: 27,
      contribution: (
        <>
          <FormattedMessage id="Standard" defaultMessage="Standard" /> (%)
        </>
      ),
      key: 'mandate_tax_cost_standard',
      value:
        parameters?.find((item) => item.par_name === 'mandate_tax_cost_standard')?.par_value || ' *'
    },
    {
      id: 28,
      contribution: (
        <>
          <FormattedMessage id="Copyright" defaultMessage="Copyright" /> (%)
        </>
      ),
      key: 'mandate_tax_cost_copyright',
      value:
        parameters?.find((item) => item.par_name === 'mandate_tax_cost_copyright')?.par_value ||
        ' *'
    }
  ];
  const mandateTaxAllowanceRows = [
    {
      id: 29,
      contribution: (
        <>
          <FormattedMessage id="Lack" defaultMessage="Lack" /> (PLN)
        </>
      ),
      key: 'mandate_tax_allownace_lack',
      value:
        parameters?.find((item) => item.par_name === 'mandate_tax_allownace_lack')?.par_value ||
        ' *'
    },
    {
      id: 30,
      contribution: '1/3 (PLN)',
      key: 'mandate_tax_allownace_one_third',
      value:
        parameters?.find((item) => item.par_name === 'mandate_tax_allownace_one_third')
          ?.par_value || ' *'
    },
    {
      id: 31,
      contribution: '1/2 (PLN)',
      key: 'mandate_tax_allownace_half',
      value:
        parameters?.find((item) => item.par_name === 'mandate_tax_allownace_half')?.par_value ||
        ' *'
    },
    {
      id: 32,
      contribution: (
        <>
          <FormattedMessage id="Single" defaultMessage="Single" /> (PLN)
        </>
      ),
      key: 'mandate_tax_allownace_single',
      value:
        parameters?.find((item) => item.par_name === 'mandate_tax_allownace_single')?.par_value ||
        ' *'
    }
  ];
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab
              label={<Typography variant="h5">Employement Contract</Typography>}
              {...a11yProps(0)}
            />
            <Tab
              label={<Typography variant="h5">Contract of Mandate</Typography>}
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <Typography variant="h5">
                <FormattedMessage id="Company Paramters" defaultMessage="Company Paramters" />
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {<FormattedMessage id="S. No." defaultMessage="S. No." />}
                      </TableCell>
                      <TableCell>
                        {<FormattedMessage id="Title" defaultMessage="Title" />}
                      </TableCell>
                      <TableCell>
                        {<FormattedMessage id="Value" defaultMessage="Value" />}
                      </TableCell>
                      <TableCell>
                        {<FormattedMessage id="Action" defaultMessage="Action" />}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography variant="h5">
                          <strong>
                            <FormattedMessage
                              id="Social Contribution"
                              defaultMessage="Social Contribution"
                            />
                          </strong>
                          <FormattedMessage id="By Employee" defaultMessage="By Employee" />
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {socialRowsEmployee.map((item, index) => (
                      <TableRow key={index}>
                        <>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{item.contribution}</TableCell>
                          <TableCell>{item.value}</TableCell>
                          <TableCell>
                            <Actions parameter={item} />
                          </TableCell>
                        </>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography variant="h5">
                          <strong>
                            <FormattedMessage
                              id="Social Contribution"
                              defaultMessage="Social Contribution"
                            />
                          </strong>
                          <FormattedMessage id="By Employer" defaultMessage="By Employer" />
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {socialRowsEmployer.map((item, index) => (
                      <TableRow key={index}>
                        <>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{item.contribution}</TableCell>
                          <TableCell>{item.value}</TableCell>
                          <TableCell>
                            <Actions parameter={item} />
                          </TableCell>
                        </>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography variant="h5">
                          <strong>
                            <FormattedMessage
                              id="NFZ Contribution"
                              defaultMessage="NFZ Contribution"
                            />
                          </strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {healthContributionRows.map((item, index) => (
                      <TableRow key={index}>
                        <>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{item.contribution}</TableCell>
                          <TableCell>{item.value}</TableCell>
                          <TableCell>
                            <Actions parameter={item} />
                          </TableCell>
                        </>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography variant="h5">
                          <strong>
                            <FormattedMessage
                              id="Cost of Getting Incoome"
                              defaultMessage="Cost of Getting Incoome"
                            />
                          </strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {taxElements.map((item, index) => (
                      <TableRow key={index}>
                        <>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{item.contribution}</TableCell>
                          <TableCell>{item.value}</TableCell>
                          <TableCell>
                            <Actions parameter={item} />
                          </TableCell>
                        </>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography variant="h5">
                          <strong>
                            <FormattedMessage id="Tax" defaultMessage="Tax" />
                          </strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {taxDiscount.map((item, index) => (
                      <TableRow key={index}>
                        <>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{item.contribution}</TableCell>
                          <TableCell>{item.value}</TableCell>
                          <TableCell>
                            {item.key !== 'tax_band_rate' ? (
                              <Actions parameter={item} />
                            ) : (
                              <TaxBandAction parameter={item} />
                            )}
                          </TableCell>
                        </>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            <Grid item sm={12}>
              <Box display="flex" justifyContent="right">
                <Typography variant="h5">
                  <FormattedMessage
                    id="*Values set by the system user. Add new values suitable for your company"
                    defaultMessage="*Values set by the system user. Add new values suitable for your company"
                  />
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <Typography variant="h5">Company Paramters (Contract of Mandate)</Typography>
            </Grid>
            <Grid item sm={12}>
              <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {<FormattedMessage id="S. No." defaultMessage="S. No." />}
                      </TableCell>
                      <TableCell>
                        {<FormattedMessage id="Title" defaultMessage="Title" />}
                      </TableCell>
                      <TableCell>
                        {<FormattedMessage id="Value" defaultMessage="Value" />}
                      </TableCell>
                      <TableCell>
                        {<FormattedMessage id="Action" defaultMessage="Action" />}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography variant="h5">
                          <strong>
                            <FormattedMessage
                              id="Social Contribution"
                              defaultMessage="Social Contribution"
                            />
                          </strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {mandateSocialContributionRows.map((item, index) => (
                      <TableRow key={index}>
                        <>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{item.contribution}</TableCell>
                          <TableCell>{item.value}</TableCell>
                          <TableCell>
                            <Actions parameter={item} />
                          </TableCell>
                        </>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography variant="h5">
                          <strong>
                            <FormattedMessage
                              id="Cost of Getting Incoome"
                              defaultMessage="Cost of Getting Incoome"
                            />
                          </strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {mandateIncomeCostsRows.map((item, index) => (
                      <TableRow key={index}>
                        <>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{item.contribution}</TableCell>
                          <TableCell>{item.value}</TableCell>
                          <TableCell>
                            <Actions parameter={item} />
                          </TableCell>
                        </>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography variant="h5">
                          <strong>
                            <FormattedMessage id="Tax" defaultMessage="Tax" />
                          </strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {mandateTaxAllowanceRows.map((item, index) => (
                      <TableRow key={index}>
                        <>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{item.contribution}</TableCell>
                          <TableCell>{item.value}</TableCell>
                          <TableCell>
                            <Actions parameter={item} />
                          </TableCell>
                        </>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            <Grid item sm={12}>
              <Box display="flex" justifyContent="right">
                <Typography variant="h5">*Values not set</Typography>
              </Box>
            </Grid>
          </Grid>
        </TabPanel>
      </Box>

      {/* <Grid container spacing={3} sx={{ marginTop: '10px' }}>
        <Grid item sm={12}>
          <Typography variant="h5">Taxation</Typography>
        </Grid>
        <Grid item sm={12}>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <CustomTable tableData={taxationRows} tableHeads={columns} />
          </Paper>
        </Grid>
      </Grid> */}
    </>
  );
}

const Actions = ({ parameter }) => {
  const [modalOpen, openFunction] = useToggle(false);
  const dispatch = useDispatch();

  const handleClick = () => {
    openFunction();
    dispatch(clearParameterByName());
  };
  const classes = useStyles();
  return (
    <>
      <div className={classes.iconWrapper}>
        <Tooltip title={<FormattedMessage id="View parameter" defaultMessage="View parameter" />}>
          <VisibilityIcon onClick={handleClick} />
        </Tooltip>
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="45rem"
        headerText={parameter?.contribution}>
        <ParameterView handleClose={openFunction} parameter={parameter} />
      </CustomModal>
    </>
  );
};

const TaxBandAction = ({ parameter }) => {
  const [modalOpen, openFunction] = useToggle(false);
  const dispatch = useDispatch();
  const handleClick = () => {
    openFunction();
    dispatch(clearParameterByName());
  };
  const classes = useStyles();

  return (
    <>
      <div className={classes.iconWrapper}>
        <Tooltip title={<FormattedMessage id="View Tax Band" defaultMessage="View Tax Band" />}>
          <VisibilityIcon onClick={handleClick} />
        </Tooltip>
      </div>
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="45rem"
        headerText={parameter?.contribution}>
        <TaxBandTable parameter={parameter} />
      </CustomModal>
    </>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
