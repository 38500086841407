import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import AllowanceTable from './AllowanceTable';
import { Box } from '@mui/system';
import CustomModal from 'components/globals/modal';
import useToggle from 'hooks/useToggle';
import AddAllowance from './AddAllowance';
import { useDispatch } from 'react-redux';
import { getEmployeeContract } from 'components/locals/contract/redux/actions';
import { getAllowance } from './redux/actions';
import { FormattedMessage } from 'react-intl';

function Allowance({ employeeId, contractId }) {
  const [modalOpen, openFunction] = useToggle(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEmployeeContract(employeeId));
    dispatch(getAllowance(employeeId, contractId));
  }, []);

  return (
    <>
      <Box marginBottom={2}>
        <Button variant="contained" onClick={openFunction}>
          <AddIcon />
          {<FormattedMessage id="Add" defaultMessage="Add" />}
        </Button>
      </Box>
      <AllowanceTable employeeId={employeeId} />
      <CustomModal
        open={modalOpen}
        handleClose={openFunction}
        height="auto"
        headerText={
          <FormattedMessage
            id="Add Allowances and Deductions"
            defaultMessage="Add Allowances and Deductions"
          />
        }>
        <AddAllowance handleClose={openFunction} employeeId={employeeId} contractId={contractId} />
      </CustomModal>
    </>
  );
}

export default Allowance;
