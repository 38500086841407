// login check
export const isLoggedIn = () =>
  (localStorage.getItem('token') || localStorage.getItem('token')) != undefined ? true : false;
export const getAccounting = () => localStorage.getItem('NewCompany');
import { useIntl } from 'react-intl';
import Store from 'redux/store';

// get employee name by id
export const getEmployeeName = (employeeId) => {
  const employee = Store.getState()?.employee;
  return employee?.employees?.find((employee) => employee?.id == employeeId);
};

// translation

export const translate = (id) => {
  const data = useIntl();
  return data.messages?.[id] || id;
};

export const appVersion = () => {
  return window?._env_?.REACT_APP_VERSION || process.env.REACT_APP_VERSION;
};

export const parseAddress = (addressData = [], remove, removeKey) => {
  const finalAddress = addressData.map((address, index) => {
    if (index == 0) {
      return { ...address, same_address_type: '' };
    } else if (address.same_address_type) {
      return {
        type: address.type,
        same_address_type: address.same_address_type
      };
    } else {
      return address;
    }
  });
  return finalAddress;
};
