import { Button, CircularProgress, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { HookForm } from 'components/form/hookForm';
import { HookFormProvider } from 'components/form/hookFormProvider';
import CancelModal from 'components/globals/modal/CancelModal';
import useToggle from 'hooks/useToggle';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeName } from 'utils';
import { changeDateFormat } from 'utils/dateUtils';
import * as Yup from 'yup';
import { editContract, getContractById } from '../redux/actions';
import useStyles from '../styles';
import EmployeeContractForm from './Form';

function SpecificContractEdit({ handleClose, contractId, employeeId, refetch }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { single_contract, edit_loading } = useSelector((state) => state.contract);
  const [cancelOpen, cancelFunction] = useToggle(false);

  const defaultValues = {
    gross_salary: 0,
    type: 'fixed',
    date_of_signing: changeDateFormat(new Date(), 'YYYY-MM-DD'),
    start_date: changeDateFormat(new Date(), 'YYYY-MM-DD'),
    end_date: null,
    parameters: {
      work_hour_numerator: 1,
      work_hour_denominator: 1,
      cost_of_getting_income: 'standard',
      labor_fund_insurance: true,
      fgsp_insurance: true,
      own_employee: true,
      pit_exemption: null,
      tax_rate: null,
      rate: 'monthly',
      tax_relief: 'half',
      insurance_code_id: '',
      occupation_code_id: '',
      work_description: '',
      shared_taxation: 0,
      vacation_duration: 20
    },
    allowance_deductions: []
  };

  const validationSchema = Yup.object({
    gross_salary: Yup.number().min(1, 'Enter valid salary'),
    parameters: Yup.object().shape({
      rate: Yup.string().required(
        <FormattedMessage id="Rate is required" defaultMessage="Rate is required" />
      )
    }),
    start_date: Yup.date()
      .nullable()
      .notRequired()
      .when(
        'date_of_signing',
        (started, yup) => started && yup.min(started, 'End date cannot be before date of signing')
      ),
    end_date: Yup.date()
      .nullable()
      .notRequired()
      .when(
        'start_date',
        (started, yup) => started && yup.min(started, 'End date cannot be before start date')
      )
  });

  const onSubmit = (data) => {
    const finalData = {
      ...data,
      date_of_signing: changeDateFormat(data?.date_of_signing, 'YYYY-MM-DD'),
      start_date: changeDateFormat(data?.start_date, 'YYYY-MM-DD'),
      end_date: data?.end_date ? changeDateFormat(data?.end_date, 'YYYY-MM-DD') : null,
      employee_id: single_contract?.employee_id,
      agreement_type_id: single_contract?.agreement_type_id,
      _method: 'patch'
    };
    dispatch(
      editContract(
        single_contract?.id,
        finalData,
        () => {
          handleClose(), refetch();
        },
        employeeId
      )
    );
  };

  useEffect(() => {
    dispatch(getContractById(contractId));
  }, [contractId]);

  const employeeName = getEmployeeName(single_contract?.employee_id);

  return (
    <>
      <div className={classes.content}>
        <Box
          marginTop={3}
          marginBottom={2}
          marginLeft={4.5}
          display="flex"
          justifyContent="flex-start">
          <TextField
            sx={{ minWidth: '30rem' }}
            label={<FormattedMessage id="Employee Name" defaultMessage="Employee Name" />}
            InputLabelProps={{ shrink: true }}
            value={`${employeeName?.last_name} ${employeeName?.first_name}`}
            disabled
          />
        </Box>

        <div>
          <HookFormProvider
            defaultValues={defaultValues}
            resolver={useYupValidationResolver(validationSchema)}>
            <HookForm onSubmit={onSubmit}>
              <SetContractDetails single_contract={single_contract} defaultValues={defaultValues} />

              <Box className={classes.modalFooter}>
                <Box className={classes.modalFooterButtons}>
                  {!edit_loading ? (
                    <Button sx={{ marginRight: '1rem' }} variant="contained" type="submit">
                      {<FormattedMessage id="Update" defaultMessage="Update" />}
                    </Button>
                  ) : (
                    <Button sx={{ marginRight: '1rem' }} variant="contained" disabled>
                      <CircularProgress size={18} sx={{ marginRight: '10px' }} />
                      {<FormattedMessage id="Updating" defaultMessage="Updating" />}
                    </Button>
                  )}

                  <Button
                    className={classes.modalFooterCancel}
                    variant={'outlined'}
                    onClick={cancelFunction}>
                    {<FormattedMessage id="Cancel" defaultMessage="Cancel" />}
                  </Button>
                </Box>
              </Box>
            </HookForm>
          </HookFormProvider>
        </div>
      </div>
      <CancelModal
        handleClose={handleClose}
        cancelFunction={cancelFunction}
        cancelOpen={cancelOpen}
      />
    </>
  );
}

export default SpecificContractEdit;

const SetContractDetails = ({ single_contract, defaultValues }) => {
  const { setValue } = useFormContext();
  const { single_loading } = useSelector((state) => state.contract);
  useEffect(() => {
    single_contract &&
      Object.entries(defaultValues)?.map((key) => {
        if (key[0] != 'parameters') {
          setValue(key[0], single_contract[key[0]] || key[1]);
        } else {
          Object.entries(key[1])?.map((parameter) => {
            setValue(
              `parameters[${parameter[0]}]`,
              single_contract?.parameters[parameter[0]] || parameter[1]
            );
          });
        }
      });
  }, [single_contract]);
  if (single_loading) {
    return null;
  }
  return (
    <EmployeeContractForm
      edit={true}
      contractSingleId={single_contract?.id}
      contractId={single_contract?.agreement_type_id}
      employeeId={single_contract?.employee_id}
    />
  );
};
