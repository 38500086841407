export const FETCH_ACCOUNTING_BEGINS = 'FETCH_ACCOUNTING_BEGINS';
export const FETCH_ACCOUNTING_SUCCESS = 'FETCH_ACCOUNTING_SUCCESS';
export const FETCH_ACCOUNTING_ERROR = 'FETCH_ACCOUNTING_ERROR';

export const FETCH_ACCOUNTING_PROFILE_BEGINS = 'FETCH_ACCOUNTING_PROFILE_BEGINS';
export const FETCH_ACCOUNTING_PROFILE_SUCCESS = 'FETCH_ACCOUNTING_PROFILE_SUCCESS';
export const FETCH_ACCOUNTING_PROFILE_ERROR = 'FETCH_ACCOUNTING_PROFILE_ERROR';

export const EDIT_ACCOUNTING_PROFILE_BEGINS = 'EDIT_ACCOUNTING_PROFILE_BEGINS';
export const EDIT_ACCOUNTING_PROFILE_SUCCESS = 'EDIT_ACCOUNTING_PROFILE_SUCCESS';
export const EDIT_ACCOUNTING_PROFILE_ERROR = 'EDIT_ACCOUNTING_PROFILE_ERROR';

export const FETCH_SINGLE_COMPANY_BEGINS = 'FETCH_SINGLE_COMPANY_BEGINS';
export const FETCH_SINGLE_COMPANY_SUCCESS = 'FETCH_SINGLE_COMPANY_SUCCESS';
export const FETCH_SINGLE_COMPANY_ERROR = 'FETCH_SINGLE_COMPANY_ERROR';

export const EDIT_ADMIN_BEGINS = 'EDIT_ADMIN_BEGINS';
export const EDIT_ADMIN_SUCCESS = 'EDIT_ADMIN_SUCCESS';
export const EDIT_ADMIN_ERROR = 'EDIT_ADMIN_ERROR';

export const CREATE_COMPANY_ADMIN_BEGIN = 'CREATE_COMPANY_ADMIN_BEGIN';
export const CREATE_COMPANY_ADMIN_SUCCESS = 'CREATE_COMPANY_ADMIN_SUCCESS';
export const CREATE_COMPANY_ADMIN_ERROR = 'CREATE_COMPANY_ADMIN_ERROR';

export const FETCH_COMPANY_ADMIN_BEGIN = 'FETCH_COMPANY_ADMIN_BEGIN';
export const FETCH_COMPANY_ADMIN_SUCCESS = 'FETCH_COMPANY_ADMIN_SUCCESS';
export const FETCH_COMPANY_ADMIN_ERROR = 'FETCH_COMPANY_ADMIN_ERROR';

export const DELETE_ADMIN_USER_BEGIN = 'DELETE_ADMIN_USER_BEGIN';
export const DELETE_ADMIN_USER_SUCCESS = 'DELETE_ADMIN_USER_SUCCESS';
export const DELETE_ADMIN_USER_ERROR = 'DELETE_ADMIN_USER_ERROR';

export const CHANGE_ADMIN_STATUS_BEGIN = 'CHANGE_ADMIN_STATUS_BEGIN';
export const CHANGE_ADMIN_STATUS_SUCCESS = 'CHANGE_ADMIN_STATUS_SUCCESS';
export const CHANGE_ADMIN_STATUS_ERROR = 'CHANGE_ADMIN_STATUS_ERROR';

export const CHANGE_COMPANY_STATUS_BEGIN = 'CHANGE_COMPANY_STATUS_BEGIN';
export const CHANGE_COMPANY_STATUS_SUCCESS = 'CHANGE_COMPANY_STATUS_SUCCESS';
export const CHANGE_COMPANY_STATUS_ERROR = 'CHANGE_COMPANY_STATUS_ERROR';

export const REMOVE_COMPANY_BEGIN = 'REMOVE_COMPANY_BEGIN';
export const REMOVE_COMPANY_SUCCESS = 'REMOVE_COMPANY_SUCCESS';
export const REMOVE_COMPANY_ERROR = 'REMOVE_COMPANY_ERROR';
